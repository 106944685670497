import React, {useContext, useEffect, useState} from "react"
import headshot from 'images/blog/avatar-100.jpg'
import RequestDemoModal from "../../../pages/common/RequestDemoModal"
import {globalAuthManager, globalServices} from "../../../../services/GlobalServices"
import {apiGet} from "../../../../services/api-call-helpers"
import * as keys from 'keys'
// import {mixpanel} from "../../../../services/mixpanel"
import ReceiveUpdatesModal from "../../../pages/common/ReceiveUpdatesModal"
import {Link} from "react-router-dom"
import BlogSignupSection from "../../common/BlogSignupSection"

import BannerImage500 from "images/blog/Blog_20210930_BufferPerformance/safety-net-500.jpg"
import BannerImage from "images/blog/Blog_20210930_BufferPerformance/safety-net.jpg"
import jonAvatar from 'images/blog/avatar-100.jpg'
import chartSep from 'images/blog/Blog_20210930_BufferPerformance/SepChart.png'
import chart9mo from 'images/blog/Blog_20210930_BufferPerformance/9MoChart.png'
import HypotheticalPerformanceAlert from "../../../common/alerts/HypotheticalPerformanceAlert";

const TLTOverwriteContext = React.createContext({})

const documentTitle = "Clarivor Blog"
const postTitle = 'Buffer Performance in September 2021'
const postSubtitle = 'How did buffers perform in the worst month for equities since March 2020?'
const postAuthor = 'Jon Spiegel, CFA'
const publishedOnText = 'September 30, 2021'
const publishedOnShort = 'Sep 30, 2021'
const path = '/blog/BufferPerformance_20210930'

const HeroSection = (props) => (
    <section
        style={{
            backgroundImage: ["url(" + BannerImage + ")"],
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            position: 'relative',
            zIndex: 0,
            backgroundSize: 'cover',
            height: '50vh'
        }}>
    </section>
)

const HeaderSection = (props) => (
    <section className="pt-8 pt-md-11">
        <RequestDemoModal/>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 col-md-12 col-lg-10 col-xl-10">


                    <h1 className="display-4 text-center">
                        {props.title}
                    </h1>


                    <p className="lead mb-7 text-center text-muted">
                        {props.subtitle}
                    </p>


                    <div className="row align-items-center py-5 border-top border-bottom">
                        <div className="col-auto">


                            <div className="avatar avatar-lg">
                                <img src={props.avatarImage} alt="..."
                                     className="avatar-img rounded-circle"/>
                            </div>

                        </div>
                        <div className="col ml-n5">
                            <h6 className="text-uppercase mb-0">
                                {props.author}
                            </h6>
                        </div>
                        <div className="col-auto">
                            <time className="font-size-sm text-muted" dateTime="2019-05-20">
                                {props.publishedOnText}
                            </time>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
)


function Summary(props) {
    return (
        <React.Fragment>
            <h3 className={'font-weight-bold mt-6'}>
                Summary
            </h3>
            <ul>
                <li className='my-2'>
                    Buffered option strategies generally outperformed traditional equities in September, as U.S.
                    large-cap stocks sold off approximately 5%, the worst monthly performance since March 2020.
                </li>
                <li className='my-2'>
                    Depending on buffer trade dates and maturity, performance would have varied for different buffers
                    linked
                    to the SPY ETF.
                </li>
                <li className='my-2'>
                    In the twelve months leading up to September, U.S. large cap total return was close to 30%, causing
                    buffers to generally underperform.
                </li>
            </ul>
        </React.Fragment>
    )
}


function Section1(props) {
    return (
        <React.Fragment>
            <h3 className={'font-weight-bold mt-6'}>
                Buffers behaved defensively in September
            </h3>
            <p>

            </p>
            <p>
                Buffered option strategies linked to equity ETFs are typically structured to have less downside risk
                than traditional equity investments. For example, a buffer might protect against the first 10%
                market losses, while providing exposure to the first 10% market gains.
            </p>
            <p>
                Therefore, buffered strategies are often less sensitive to market sell-offs, as we saw over the past
                month. For more information about buffers, see <Link to={'/blog/Blog_20210525_BufferDelta'}>this
                post</Link>.
            </p>
            <div className='card bg-light'>
                <div className='card-body p-2'>
                    <img style={{width: "100%"}} src={chartSep}/>
                </div>
            </div>
            <h3 className={'font-weight-bold mt-6'}>
                Path dependency
            </h3>
            <p>
                The chart above shows the hypothetical performance of three buffered strategies. Each buffered strategy
                is comprised of 12-month options. The strikes are selected to provide protection against the first 10%
                price decline, while providing upside to a cap based on historical market pricing.
            </p>
            <p>
                From the chart it seems that the buffers traded more recently (and having maturity dates further in the
                future) had more exposure to the market.
            </p><p>
            This is because of an important property of buffers: as the underlying security rallies, typically a buffer
            become less sensitive to the market. Since the SPY ETF grinded higher over the prior
            12 months, older buffers had less sensitivity to the market going into the sell-off.
        </p>
            <p>
                The following chart illustrates the longer-term performance of the 12-month SPY buffer traded last
                December.
                Clearly, the buffer underperforms in the strong rally. Additionally, as the rally continues the buffer
                becomes less sensitive to changes in the price of the ETF.
            </p>
            <div className='card bg-light'>
                <div className='card-body p-2'>
                    <img style={{width: "100%"}} src={chart9mo}/>
                </div>
            </div>
        </React.Fragment>
    )
}

function BufferPerformanceProjectContainer(props) {
    const [projectObj, setProjectObj] = useState(null)

    useEffect(() => {
        apiGet(keys.API_ENDPOINT.PROJECTS, {id: props.projectId}, globalAuthManager).then(
            res => {
                const [project] = res.data
                setProjectObj(project)
            }
        )
    }, [])

    const contextValue = {
        projectObj: projectObj,
        projectId: props.projectId
    }

    return (
        <TLTOverwriteContext.Provider value={contextValue}>
            {props.children}
        </TLTOverwriteContext.Provider>
    )
}


function DisclosuresLink(props) {
    return (
        <a data-toggle="modal" href="#disclaimerModal"
           onClick={() => null}>Disclosures</a>
        // onClick={() => mixpanel.track('Disclosure Clicked')}>Disclosures</a>
    )
}

function TermsOfUseLink(props) {
    return (
        <Link to={'/terms-of-use'} target={'_blank'}
              onClick={() => null}>Terms of Use</Link>
        // onClick={() => mixpanel.track('Terms of Use Clicked')}>Terms of Use</Link>
    )
}

function BacktestingSourceDisclaimer(props) {
    return (
        <div>
            <hr/>
            <p className='font-italic text-muted'>
                Source: Clarivor LLC. These results are backtested and may not be indicative of future performance.
                Changes in strategy parameters, such as the buffer level, the cap, or roll dates, may have a material
                impact on results.
                Each buffer includes an annual deduction of 0.49% to reflect management fees and commissions. SPY(TR)
                has
                dividends immediately reinvested, shown with no additional management fee.  The performance of a Sep '21
                buffer, which expired mid-month, will be addressed in another post.

                {/*Options are rolled seven days prior to expiration at bid or offer.*!/*/}
                {/*Option prices are observed at 3:45pm NY time. Back-tested strategies include 0.47% annualized cost to*/}
                {/*reflect management fees and commissions.*/}
                <br/>
                <br/>
                NOTHING IN THIS POST SHOULD BE CONSTRUED AS INVESTMENT ADVICE. <br/>
                <DisclosuresLink/> and <TermsOfUseLink/> apply.
                <br/>
            </p>
        </div>
    )
}


function PostBody(props) {
    return (
        <React.Fragment>
            <section className="pt-0 pt-md-0">
                <ReceiveUpdatesModal/>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-12 col-lg-10 col-xl-9">
                            <Summary/>
                            <Section1/>
                            <BacktestingSourceDisclaimer/>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}


export default function BufferPerformance_20210803(props) {
    const projectId = null  // this is a static blog post

    useEffect(() => {
        document.title = documentTitle
    }, [])

    const headerProps = {
        title: postTitle,
        subtitle: postSubtitle,
        avatarImage: headshot,
        author: postAuthor,
        publishedOnText: publishedOnShort,
    }

    return (
        <React.Fragment>
            <HeroSection/>
            <HeaderSection {...headerProps} />
            <BufferPerformanceProjectContainer projectId={projectId}>
                <PostBody/>
            </BufferPerformanceProjectContainer>
            <BlogSignupSection fromLocation={'Blog Post TLT Overwriting'}/>
            <div className={'container'}>
                <HypotheticalPerformanceAlert/>
            </div>
        </React.Fragment>
    )
}


export function RichBufferPerformance202109(props) {
    return (
        <section className="pt-7 pt-md-10">
            <div className="container">
                <div className="row">
                    <div className="col-12">

                        <div className="card card-row shadow-light-lg mb-6 lift lift-lg">
                            <div className="row no-gutters">
                                <div className="col-12">

                                <span className="badge badge-pill badge-light badge-float badge-float-inside">
                                <span className="h6 text-uppercase">Buffers</span>
                                </span>

                                </div>
                                <Link className="col-12 col-md-6 order-md-2 bg-cover card-img-right"
                                      style={{backgroundImage: "url(" + BannerImage500 + ")"}}
                                      to={path}>

                                    <img src="assets/img/photos/photo-27.jpg" alt="..."
                                         className="img-fluid d-md-none invisible"/>

                                    <div
                                        className="shape shape-left shape-fluid-y svg-shim text-white d-none d-md-block">
                                        <svg viewBox="0 0 112 690" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 0h62.759v172C38.62 384 112 517 112 517v173H0V0z"
                                                  fill="currentColor"></path>
                                        </svg>
                                    </div>

                                </Link>
                                <div className="col-12 col-md-6 order-md-1">

                                    <Link className="card-body" to={path}>

                                        <h3>
                                            {postTitle}
                                        </h3>

                                        <p className="mb-0 text-muted">
                                            {postSubtitle}
                                        </p>

                                    </Link>

                                    <a className="card-meta" href="#!">

                                        <hr className="card-meta-divider"/>

                                        <div className="avatar avatar-sm mr-2">
                                            <img src={jonAvatar} alt="..."
                                                 className="avatar-img rounded-circle"/>
                                        </div>

                                        <h6 className="text-uppercase text-muted mr-2 mb-0">
                                            Jon Spiegel
                                        </h6>

                                        <p className="h6 text-uppercase text-muted mb-0 ml-auto">
                                            <time dateTime="2020-10-23">{publishedOnShort}</time>
                                        </p>

                                    </a>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>

    )
}
