import React, {useContext, useEffect, useState} from "react"
import {Link} from "react-router-dom"
import incomeMonitorImage from '../../../../images/blog/IncomeMonitor/IncomeMonitorRichViewScreenshot.png'
import jonAvatar from 'images/blog/avatar-100.jpg'

const IncomePostContext = React.createContext({})

const POST_TITLE = 'Clarivor Income Monitor'
const POST_SUBTITLE = 'Options can offer attractive income strategies, but tracking and comparing strategies is challenging.  This tool tracks strategies across major ETFs that target different levels of income.'
const PATH = '/tools/income-monitor'

export function RichViewIncomeMonitor(props) {
    return (
        <section className="pt-7 pt-md-10">
            <div className="container">
                <div className="row">
                    <div className="col-12">

                        <div className="card card-row shadow-light-lg mb-6 lift lift-lg">
                            <div className="row no-gutters">
                                <div className="col-12">

                            <span className="badge badge-pill badge-light badge-float badge-float-inside">
                    <span className="h6 text-uppercase">Income</span>
                  </span>

                                </div>
                                <Link className="col-12 col-md-6 order-md-2 bg-cover card-img-right"
                                      style={{backgroundImage: "url(" + incomeMonitorImage + ")"}}
                                      to={PATH}>

                                    <div
                                        className="shape shape-left shape-fluid-y svg-shim text-white d-none d-md-block">
                                        <svg viewBox="0 0 112 690" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 0h62.759v172C38.62 384 112 517 112 517v173H0V0z"
                                                  fill="currentColor"></path>
                                        </svg>
                                    </div>

                                </Link>
                                <div className="col-12 col-md-6 order-md-1">

                                    <Link className="card-body" to={PATH}>

                                        <h3>
                                            {POST_TITLE}
                                        </h3>

                                        <p className="mb-0 text-muted">
                                            {POST_SUBTITLE}
                                        </p>

                                    </Link>

                                    <a className="card-meta" href="#!">

                                        <hr className="card-meta-divider"/>

                                        <div className="avatar avatar-sm mr-2">
                                            <img src={jonAvatar} alt="..."
                                                 className="avatar-img rounded-circle"/>
                                        </div>

                                        <h6 className="text-uppercase text-muted mr-2 mb-0">
                                            Jon Spiegel
                                        </h6>

                                        <p className="h6 text-uppercase text-muted mb-0 ml-auto">
                                            <time dateTime="2020-01-14">Jan 14</time>
                                        </p>

                                    </a>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>

    )
}

