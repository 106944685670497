import React, {Component} from 'react'
import headshot from 'images/headshot_rectangle_color.jpg'
import linkedInLogo from 'images/LI-In-Bug.png'
import {Fade} from "react-reveal"
import RequestDemoModal from "../common/RequestDemoModal";
import {mixpanel} from "../../../services/mixpanel";

export default class AboutPrinciplesSection extends Component {
    render() {
        return (
            <section className='mb-10'>
                <RequestDemoModal/>

                <div className='container'>
                    <Fade clear>
                        <div className='row my-5 d-flex justify-content-center'>
                            <div className='col-12 col-md-10 col-lg-8'>
                                <h1 align className='text-dark'>What is Clarivor?</h1>

                                <p className='text-secondary'>Clarivor is an SEC-registered investment advisor and
                                    technology company. We focus on managing liquid option strategies for institutional
                                    investors,
                                    advisors, and other professionals. Unlike traditional managers, we
                                    provide these custom investment solutions as an online service.
                                </p>
                            </div>
                        </div>
                    </Fade>

                    <Fade clear>
                        <div className='row my-5 d-flex justify-content-center'>
                            <div className='col-12 col-md-10 col-lg-8'>
                                <h1 align className='text-dark'>What we do</h1>

                                <p className='text-secondary'>
                                    We develop infrastructure and perform research to enable investors to achieve better
                                    results by making complex investment strategies easier to incorporate in their
                                    portfolios.
                                </p>
                                <p className='text-secondary'>
                                    Option strategies in particular have always been cumbersome for investors. Each
                                    aspect of using option strategies involves repetitive tasks that make it difficult
                                    for portfolio managers to focus on value-added activities and decision-making.
                                </p>
                                <p className='text-secondary'>Taking advantage of recent advancements in technology, we
                                    solve many of the challenges option investors face with a modern web application
                                    designed for flexibility and ease-of-use. When you sign up for an account, you have
                                    access to
                                    powerful tools that handle research and analysis, investing and risk management, all
                                    through your browser. Contact us for a <a data-toggle="modal"
                                                                              className='font-weight-bold'
                                                                              href="#requestDemoModal" onClick={() => {
                                        mixpanel.track('Request Demo About Page Clicked')
                                    }}>demo</a> to see if Clarivor is a good fit for
                                    your needs.
                                </p>
                            </div>
                        </div>
                    </Fade>

                    {/*        <hr className="hr-md my-6 my-md-8 border-gray-400"/>*/}

                    <Fade clear>
                        <div className='row my-5 d-flex justify-content-center'>
                            <div className='col-12 col-md-10 col-lg-8'>
                                <h1 align className='text-dark'>Founder</h1>

                                <p className='text-secondary'>Jon Spiegel started building Clarivor in 2018 to help professional
                                    investors enjoy greater success in an increasingly challenging environment. </p>
                                <p className='text-secondary'>Prior to founding Clarivor, Jon held senior roles in risk
                                    management, quantitative strategies and derivatives at Merrill Lynch, Bank
                                    of America and Deutsche Bank.</p>
                                <p className='text-secondary'>In sales, trading and structuring functions Jon developed
                                    investment solutions
                                    for an extremely broad range of institutional and retail clients. In these roles he
                                    developed quantitative strategies underlying
                                    over $10
                                    billion of investment products and supervised the execution of over $20 billion of
                                    structured derivatives transactions.</p>
                                <p className='text-secondary'>Jon holds an MBA from the University of Chicago and a
                                    BS from Columbia University. He is also a CFA Charterholder.</p>
                            </div>
                        </div>
                    </Fade>

                    <Fade big>
                        <div className='row my-5 d-flex justify-content-center'>
                            <div className='col-12 col-md-8 d-flex flex-wrap justify-content-center align-items-center'
                                 align='center'>
                                <div className={'card bg-light border-secondary px-4 py-4'}>
                                    <div className={'row align-items-center'}>
                                        <div className={'col-12 col-sm-6'}>
                                            <img className="figure-img img-fluid rounded lift lift-lg my-3"
                                                 src={headshot} style={{maxWidth: '200px'}} alt="..."/>
                                        </div>
                                        <div className={'col-sm-6 text-secondary text-sm-left'}>
                                            Jon Spiegel, CFA<br/>
                                            Founder<br/>
                                            <a href={'https://www.linkedin.com/in/jon-spiegel-04a9a3/'} target="_blank">
                                                <img src={linkedInLogo} style={{maxWidth: '25px'}} alt="..."/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fade>


                    <div className='row my-5'>
                        <div className='col-12 col-md-5' align='center'>
                            {/*<img src={nounCompass} style={{height: '15vw', width: '15vw'}}/>*/}
                        </div>
                        {/*<div className='col-12 col-md-7'>*/}
                        {/*    <h1 align>Guiding Principles</h1>*/}
                        {/*    <p className='text-secondary'>Clarivor believes investment management is in the early stages of*/}
                        {/*        a major transformation driven by technology.*/}
                        {/*    </p>*/}
                        {/*    <p className='text-secondary'>The increasing use of machines to process data and perform*/}
                        {/*        automated tasks gives investment professionals the potential to save time and make more*/}
                        {/*        informed decisions.*/}
                        {/*    </p>*/}
                        {/*    <p className='text-secondary'>Clarivor is focused on the current and future needs of investment*/}
                        {/*        managers navigating a landscape characterized by a proliferation of quantitative*/}
                        {/*        strategies and increased competition.</p>*/}

                        {/*    <p className='text-secondary'>By developing technology for creating, implementing and managing*/}
                        {/*        option-based investments, Clarivor eliminates hurdles that hold back investment managers*/}
                        {/*        from a universe of enhanced opportunities.</p>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </section>
        )
    }
}

