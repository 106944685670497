import React, {Component, useEffect} from 'react';
import * as keys from 'keys'
import axios from 'axios'
import StrategyHoldings from 'components/investment-app/portfolio/StrategyHoldings'
import {apiGet} from "../services/api-call-helpers";
import {globalAuthManager} from "../services/GlobalServices";

export default function TestingPanel(props) {
    const [called, setCalled] = React.useState(false)

    useEffect(
        () => {
            if (globalAuthManager && globalAuthManager.accessToken && !called) {
                setCalled(true)
                apiGet(keys.API_ENDPOINT.ORDERS, {}, globalAuthManager).then(
                    res => {
                        console.log(res.data)
                    }
                )

                setTimeout(() => {

                        apiGet(keys.API_ENDPOINT.RECENT_CONTRACT_EXECUTIONS, {}, globalAuthManager).then(
                            res => {
                                console.log(res.data)
                            })
                    }

                    , 10000)

            }
        }, [globalAuthManager.accessToken]
    )


    return (<div className='container'>
        Ipsum Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec auctor, nisl eget ultricies
    </div>)
}

