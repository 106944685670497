import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import React, {useEffect} from "react"
import * as keys from "../../../../keys"


function createChartOptions(tupleLists) {

    let options = {
        chart: {
            type: 'scatter',
            // zoomType: 'xy',
            backgroundColor: 'transparent',
        },
        title:
            {
                text: ''
            },
        // subtitle: {
        //     text: 'Source: Heinz  2003'
        // },
        xAxis: {
            title: {
                enabled: true,
                text: 'Percent ETF Is Below All-Time High'
            },
            labels: {
                formatter: function () {
                    return (Math.round(this.value) > 0 ? ' + ' : '') + this.value*100 + '%';
                }
            },
            startOnTick: true,
            endOnTick: true,
            showLastLabel: true,
            min: -.3,
            max: 0,
        },
        yAxis: {
            title: {
                text: 'Option Strategy Upside (call strike % OTM)'
            },
            max: .3,
            labels: {
                formatter: function () {
                    return (Math.round(this.value) > 0 ? ' + ' : '') + this.value*100 + '%';
                }
            },
        },
        legend: {
            layout: 'vertical',
            align: 'left',
            verticalAlign: 'top',
            x: 400,
            y: 40,
            floating: true,
            backgroundColor: Highcharts.defaultOptions.chart.backgroundColor,
            borderWidth: 1,
        },
        plotOptions: {
            scatter: {
                marker: {
                    radius: 4,
                    states: {
                        hover: {
                            enabled: true,
                            lineColor: '#EEEEEE'
                        }
                    }
                },
                states: {
                    hover: {
                        marker: {
                            enabled: false
                        }
                    }
                },
                tooltip: {
                    headerFormat: '<b>{series.name}</b><br>',
                    pointFormat: '{point.x} from high, {point.y} upside'
                }
            }
        },
        series: [{
            name: '2010 - 2019',
            color: '#CCCCCC',
            data: tupleLists[0],
        }, {
            name: '2020',
            color: keys.Color.purple,
            data: tupleLists[1]
        }, {
            name: '2020-12-08',
            color: keys.Color.orange,
            data: tupleLists[2],
            marker: {
                radius: 5
            }
        },
        ],

        credits: {
            enabled: false
        },

    }

    return options
}


export default function XYPlot(props) {
    let seriesList = props.seriesList

    // if (!seriesList || seriesList.length == 0) {
    //     return null
    // }

    const options = createChartOptions(props.tupleLists)

    return (
        <HighchartsReact highcharts={Highcharts}
                         options={options}/>
    )
}
