import {Redirect, Route, Switch} from "react-router-dom";
import React from "react";
import {track_referral_email_link} from "../services/clarivor-tracking";

function EmailReferrals(props) {
    return (<Switch>
            <Route
                path={props.match.path + "/:trackIds"}
                render={(props) => {
                    const trackIds = props.match.params['trackIds']

                    track_referral_email_link(trackIds, props.location.pathname)

                    const redirectPath = props.location.pathname.substring(props.match.url.length)
                    return (
                        <Redirect to={redirectPath}/>
                    )
                }
                }
            />
        </Switch>
    )
}


export default function Referrals(props) {
    return (
        <Switch>
            <Route
                path={props.match.path + "/e"}
                render={(props) => {
                    return (
                        <EmailReferrals {...props}/>
                    )
                }
                }
            />
            <Route
                path=""
                render={(props) => {
                    return (
                        <Redirect to={'/'}/>
                    )
                }
                }
            />
        </Switch>
    )

}