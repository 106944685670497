import React, {useEffect} from 'react'
import NavBar from 'components/common/NavBarDefault'
import RequestDemoModal from "../common/RequestDemoModal"
import Footer from 'components/common/Footer'
import {setLKBody} from 'lkcss'
// import advisorHeroImage from '../../../images/advisor-ipad-outside.png'
import advisorHeroImage from 'images/investment-manager-at-desk.jpg'
import frustratedTraderImage from '../../../images/frustrated-trader.png'
import planScreenshot from '../../../images/plan-screenshot.png'
import {Check} from "react-feather"
import macBook from 'lk/dist/assets/img/devices/macbook.svg'
import AdvisorsPricingSection from "./AdvisorsPricingSection"
import {Fade} from "react-reveal"
import BreadcrumbNav from "../../common/BreadcrumbNav"
import {track_ux_event} from "../../../services/clarivor-tracking"
import {globalAuthManager} from "../../../services/GlobalServices"


function LightbulbSVG(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height={props.heightStr} width={props.widthStr}
             fill={props.fillStr} version="1.1" x="0px" y="0px" viewBox="0 0 100 100"
             enable-background="new 0 0 100 100">
            <path
                d="M50,23.587c-16.27,0-22.799,12.574-22.799,21.417c0,12.917,10.117,22.451,12.436,32.471h20.726  c2.32-10.02,12.436-19.554,12.436-32.471C72.799,36.161,66.271,23.587,50,23.587z"/>
            <path
                d="M39.637,87.161c0,3.001,1.18,4.181,4.181,4.181h0.374h0.052l0.41,1.231C45.278,94.449,46.042,95,48.019,95h3.963  c1.978,0,2.74-0.551,3.365-2.427l0.409-1.231h0.052h0.375c3.002,0,4.18-1.18,4.18-4.181V80.91H39.637V87.161z"/>
            <path
                d="M50,18.265c1.26,0,2.072-0.814,2.072-2.073v-9.12C52.072,5.813,51.26,5,50,5c-1.259,0-2.072,0.813-2.072,2.073v9.12  C47.928,17.452,48.741,18.265,50,18.265z"/>
            <path
                d="M68.313,23.727c0.994,0.774,2.135,0.634,2.91-0.357l5.614-7.187c0.776-0.992,0.636-2.135-0.356-2.909  c-0.992-0.776-2.135-0.636-2.91,0.357l-5.613,7.186C67.18,21.81,67.322,22.952,68.313,23.727z"/>
            <path
                d="M91.157,36.373c-0.306-1.222-1.291-1.815-2.513-1.51l-8.85,2.207c-1.222,0.305-1.814,1.29-1.51,2.512  c0.305,1.223,1.291,1.814,2.513,1.51l8.849-2.206C90.869,38.581,91.462,37.595,91.157,36.373z"/>
            <path
                d="M86.757,60.48l-8.331-3.709c-1.15-0.512-2.225-0.099-2.736,1.052c-0.512,1.151-0.1,2.224,1.051,2.737l8.33,3.707  c1.15,0.514,2.225,0.101,2.736-1.05C88.32,62.068,87.907,60.994,86.757,60.48z"/>
            <path
                d="M28.779,23.37c0.775,0.992,1.917,1.131,2.909,0.357c0.992-0.776,1.132-1.917,0.357-2.91l-5.615-7.186  c-0.775-0.992-1.917-1.132-2.909-0.357s-1.131,1.917-0.356,2.909L28.779,23.37z"/>
            <path
                d="M21.715,39.583c0.305-1.223-0.288-2.208-1.51-2.513l-8.849-2.207c-1.222-0.303-2.208,0.289-2.513,1.511  c-0.303,1.222,0.288,2.207,1.511,2.512l8.848,2.206C20.424,41.396,21.41,40.805,21.715,39.583z"/>
            <path
                d="M21.575,56.771l-8.331,3.711c-1.151,0.511-1.563,1.586-1.05,2.735c0.511,1.151,1.586,1.563,2.736,1.052l8.331-3.711  c1.151-0.511,1.563-1.586,1.05-2.735C23.799,56.673,22.726,56.261,21.575,56.771z"/>
        </svg>)
}

function UmbrellaSVG(props) {
    return (
        <svg height={props.heightStr} width={props.widthStr} fill={props.fillStr} xmlns="http://www.w3.org/2000/svg"
             version="1.1" x="0px" y="0px" viewBox="0 0 64 64"
             style={{enableBackground: 'new 0 0 64 64;"'}}>
            <path
                d="M37.2,10.3c-1.5-0.1-2.3-0.5-3.1-0.4V7.9c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2V10c-1.1,0.2-1.8,0.1-3.1,0.4  C13.5,12.6,3.2,23.7,2,37.4c2.8-2.1,6.2-3.4,9.9-3.4c3.8,0,7.3,1.3,10,3.4c2.3-1.8,5-2.9,8-3.3l0,17.9c0,1.1-0.9,2-2,2  c-1.1,0-2-0.9-2-2c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2c0,3.3,2.7,6,6,6c3.3,0,6-2.7,6-6V34.1c3,0.4,5.8,1.5,8,3.3  c2.8-2.1,6.2-3.4,10-3.4c3.7,0,7.2,1.3,9.9,3.4C60.8,23.7,50.5,12.6,37.2,10.3z"></path>
        </svg>
    )
}

function DollarSign(props) {
    return (
        <svg height={props.heightStr} width={props.widthStr} fill={props.fillStr} xmlns="http://www.w3.org/2000/svg"
             data-name="Layer 1"
             viewBox="0 0 100 100" x="0px" y="0px">
            <path
                d="M75.93,53.65C72,49,65.67,46,57.15,44.78A1.78,1.78,0,0,1,55.63,43V30.37a1.76,1.76,0,0,1,.82-1.49,1.73,1.73,0,0,1,1.69-.12,22,22,0,0,1,5,3.3,3.12,3.12,0,0,0,2,.7,4.64,4.64,0,0,0,1.44-.47L74,27.62c.73-.46.73-.85.75-1a1,1,0,0,0-.42-.81.4.4,0,0,1-.13-.13,28.72,28.72,0,0,0-17.16-9.37,1.78,1.78,0,0,1-1.45-1.74V7.41A1.91,1.91,0,0,0,53.72,5.5H47a1.91,1.91,0,0,0-1.91,1.91v6.94a1.76,1.76,0,0,1-1.52,1.74,32,32,0,0,0-8.94,2.51c-8.41,3.84-12.43,9.34-12.3,16.82a16,16,0,0,0,3.27,9.66c3.63,4.87,9.69,8,18,9.27a1.76,1.76,0,0,1,1.48,1.74v15.8a1.71,1.71,0,0,1-.73,1.44,1.78,1.78,0,0,1-1.61.23,28.83,28.83,0,0,1-11.51-8,1,1,0,0,1-.1-.11,1.82,1.82,0,0,0-1.59-.77,5.79,5.79,0,0,0-1.22.4l-6.78,4c-.86.49-.86.92-.86,1.14a1.18,1.18,0,0,0,.37.8,1.58,1.58,0,0,0,.12.15A32.15,32.15,0,0,0,28,77.93a33.93,33.93,0,0,0,15.62,6.7,1.75,1.75,0,0,1,1.48,1.74v6.24A1.91,1.91,0,0,0,47,94.5h6.73a1.91,1.91,0,0,0,1.91-1.89V86.35a1.75,1.75,0,0,1,1.5-1.74,33.29,33.29,0,0,0,13.36-4.82c5.81-3.85,8.7-9,8.82-15.71A15.12,15.12,0,0,0,75.93,53.65ZM45.08,41.25a1.75,1.75,0,0,1-.77,1.45,1.69,1.69,0,0,1-1,.3,1.72,1.72,0,0,1-.64-.11c-4.57-1.76-5.54-5-5.57-7.37h0c0-2.06.84-4.92,4.94-6.88a.28.28,0,0,1,.11-.05l.52-.2a1.7,1.7,0,0,1,1.62.18A1.75,1.75,0,0,1,45.08,30Zm14.7,31.14a12.08,12.08,0,0,1-1.66.87,1.82,1.82,0,0,1-.72.15,1.73,1.73,0,0,1-1.77-1.75V57.78a1.78,1.78,0,0,1,.72-1.42,1.73,1.73,0,0,1,1.57-.25c5.56,1.74,6.7,5.35,6.68,8.07A9.38,9.38,0,0,1,59.78,72.39Z"></path>
        </svg>)
}

// Boost Arrows
function BoostArrows(props) {
    return (
        <svg height={props.heightStr} width={props.widthStr} fill={props.fillStr} xmlns="http://www.w3.org/2000/svg"
             version="1.1" x="0px" y="0px" viewBox="0 0 50 50"
             enable-background="new 0 0 50 50">
            <path
                d="M24.985,9.56c-0.416-0.717-1.09-0.717-1.506,0l-5.654,9.793c-0.412,0.717-0.076,1.303,0.756,1.303h2.139  c0.076,0,0.164,0,0.252,0v28.344h6.521V20.655c0.088,0,0.176,0,0.252,0h2.139c0.83,0,1.166-0.586,0.756-1.303L24.985,9.56z"></path>
            <path
                d="M48.646,11.329l-5.654-9.791c-0.414-0.717-1.09-0.717-1.504,0l-5.652,9.791c-0.414,0.719-0.078,1.305,0.752,1.305h2.139  c0.082,0,0.164,0,0.252,0v36.365h6.525V12.634c0.084,0,0.172,0,0.25,0h2.143C48.722,12.634,49.062,12.048,48.646,11.329z"></path>
            <path
                d="M8.513,18.31c-0.412-0.717-1.092-0.717-1.506,0l-5.654,9.791c-0.412,0.717-0.074,1.305,0.754,1.305h2.139  c0.08,0,0.168,0,0.254,0v19.594h6.523V29.405c0.088,0,0.172,0,0.252,0h2.139c0.828,0,1.166-0.588,0.754-1.305L8.513,18.31z"></path>
        </svg>
    )
}


function WarningSVG(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height={props.heightStr} width={props.widthStr}
             fill={props.fillStr} version="1.1" x="0px" y="0px" viewBox="0 0 100 100">
            <g>
                <path
                    d="M16.2,84.6c1.5,0.4,3.1,0.4,4.7,0.4c9.7,0,19.4,0,29.1,0c9.7,0,19.4,0,29.1,0c1.6,0,3.2,0,4.7-0.4c6-1.6,8.7-8.3,5.4-13.8   C78.9,53.7,68.5,36.6,58.1,19.5c-1.9-3.2-5-4.7-8.1-4.8c-3.1,0-6.1,1.6-8.1,4.8c-10.5,17-20.8,34.1-31.1,51.3   C7.5,76.3,10.2,83,16.2,84.6z M50,75.7C50,75.7,50,75.7,50,75.7C50,75.7,50,75.7,50,75.7c-2.5,0-4.5-2-4.6-4.5c0-2.5,2-4.5,4.6-4.5   c2.5,0,4.6,2,4.6,4.5C54.5,73.7,52.5,75.6,50,75.7z M45,38c0.1-2.9,2.1-4.9,4.9-5c0,0,0,0,0.1,0s0,0,0.1,0c2.9,0,4.9,2.1,4.9,5   c0.1,3.1,0.1,15.1,0,18c-0.1,3.1-2,4.7-5,4.7c-3,0-4.9-1.6-5-4.7C44.9,53.1,44.9,41.1,45,38z"/>
            </g>
        </svg>
    )
}

function AdvisorHeroSection(props) {
    return (
        <section className="py-10 bg-white bg-gradient-light">
            <div className="container">
                <div className="row align-items-center">
                    <Fade right>
                        <div className="col-12 col-md-6 order-md-2">
                            <img src={advisorHeroImage}
                                 className="img-fluid mw-md-130 mw-lg-110 mb-6 mb-md-0 rounded"
                                 alt="..."
                            />
                        </div>
                    </Fade>
                    <Fade left>
                        <div className="col-12 col-md-6 order-md-1 align-items-center">
                            <h1 className="display-5 text-left text-dark">
                                Implement Custom Option Strategies with Clarivor
                            </h1>
                            <p className="lead text-left text-dark">
                                Clarivor's web-based technology makes it easy to provide your clients with option
                                strategies
                                efficiently and transparently.
                            </p>
                        </div>
                    </Fade>
                </div>
                {/*<Fade bottom>*/}
                {/*    <div className='row justify-content-center mt-6'>*/}
                {/*        <a data-toggle="modal" href="#requestDemoModal" style={{textDecoration: 'none'}}>*/}
                {/*            <button className="btn btn-primary lift mt-2 d-flex">*/}
                {/*                <span className={'my-auto'}>Request Demo</span>*/}
                {/*            </button>*/}
                {/*        </a>*/}
                {/*    </div>*/}
                {/*</Fade>*/}
            </div>
        </section>
    )
}


function ValuePropositionSection(props) {
    return (
        <section className="py-10 bg-gradient-light">
            <div className="container">
                <div className="row justify-content-center">
                    {/* when above section has only one column (<md), heading is left-aligned*/}
                    <div className="col-12 col-md-10 col-lg-8 text-left text-md-center text-dark">
                        {/*<div className='mb-md-4'>*/}
                        {/*    /!*<LightbulbSVG fillStr='#9ebce8' widthStr={'100px'} heightStr={'100px'}/>*!/*/}
                        {/*    <LightbulbSVG fillStr='#95AAC9' widthStr={'75px'} heightStr={'75px'}/>*/}
                        {/*</div>*/}
                        <h1>
                            Tailored Solutions to Meet the Challenges of the Current Market
                        </h1>
                    </div>
                </div>
                <div className="row justify-content-center my-2 my-md-6 ">
                    <div className="col-10 col-sm-8 col-md-7 col-lg-4 py-lg-0">
                        <DollarSign fillStr="#1c2b4f" widthStr={'35px'} heightStr={'35px'}/>
                        <h4 className="mt-2 font-weight-bold">INCOME</h4>
                        <p>Generate yield from diversified sources with custom option writing strategies.
                        </p>
                    </div>
                    <div className="col-10 col-sm-8 col-md-7 col-lg-4 py-lg-0">
                        <UmbrellaSVG fillStr="#1c2b4f" widthStr={'35px'} heightStr={'35px'}/>
                        <h4 className="mt-2 font-weight-bold">RISK MANAGEMENT</h4>
                        <p>Reduce exposures to concentrated positions and macro risks with bespoke defensive
                            strategies.
                        </p>
                    </div>
                    <div className="col-10 col-sm-8 col-md-7 col-lg-4 py-lg-0">
                        <BoostArrows fillStr="#1c2b4f" widthStr={'35px'} heightStr={'35px'}/>
                        <h4 className="mt-2 font-weight-bold">ENHANCED RETURNS</h4>
                        <p>Identify opportunities to generate potentially higher
                            returns using advanced screening tools.
                        </p>
                    </div>
                </div>
                <div className="row justify-content-center my-4 my-md-7">
                    <div className="col align-items-center">
                        <a data-toggle="modal" href="#requestDemoModal" style={{textDecoration: 'none'}}>
                            <button className="btn btn-success lift mt-2 d-flex mx-auto"
                                    onClick={
                                        () => track_ux_event(localStorage.uuid,
                                            'Request Demo Clicked', {source: 'Advisor Solutions 1'}, globalAuthManager)
                                    }>
                                <span className={' my-auto'}>Request Demo</span>
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}


function ChallengesSection(props) {
    return (
        <section className="py-10 bg-gradient-light">
            <div className="container py-8">
                <div className="row align-items-center justify-content-center">
                    <div className="col-12 col-md-8 col-lg-6 px-1 px-md-0">
                        <div
                            className="img-fluid mw-md-150 mw-lg-130 mb-6 mb-md-0 rounded py-6 px-6 d-flex"
                            alt="..."
                            style={{
                                backgroundImage: ['linear-gradient(180deg, rgba(38, 32, 60, .6) 0%, rgba(38, 32, 60, .6) 100%)', "url(" + frustratedTraderImage + ")"],
                                backgroundRepeat: 'no-repeat',
                                position: 'relative',
                                zIndex: 0,
                                backgroundSize: 'cover',
                                minHeight: '350px'
                            }}
                        >
                            <h1 className="display-3 text-md-left text-white align-self-center">
                                Option strategies traditionally require significant manual effort.
                            </h1>
                        </div>
                    </div>
                    <div className="col-12 col-md-8 col-lg-6 py-6">
                        <div className="row justify-content-center">
                            <div className="col-12 text-center">
                                <h6 className="text-muted mb-8 badge badge-pill badge-danger-soft">
                                    TYPICAL PAIN-POINTS
                                </h6>
                                <h2 className="text-secondary">
                                    <span className="font-weight-bold">Market data</span> to process
                                </h2>
                                <hr className="hr-md"/>
                                <h2 className="text-secondary">
                                    <span className="font-weight-bold">Transactions</span> to reconcile
                                </h2>
                                <hr className="hr-md"/>
                                <h2 className="text-secondary">
                                    <span className="font-weight-bold">Risks</span> to monitor
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}


//  Automated platform, save you time
//  Automated, institutional grade platform

//  Institutional grade
//  Founder managed risk and firms like Merrill Lynch, Bank of America and Deutsche Bank
//  Solutions for pensions, insurers, hedge funds, swf's
//  12 years experience
function GuideSection(props) {
    return (
        <section className="py-10 bg-gradient-light">
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-12 col-md-10 text-center h1 mb-4 mb-md-8">
                        Built for Advisors Who Want to <br className="d-md-block d-lg-block d-xl-none d-none"/> Offer Flexible Solutions
                    </div>
                </div>
                <div className="row align-items-center justify-content-center">
                    <div className="col-12">
                        <div className="row pt-6 pt-md-0 justify-content-center">
                            <div className="col-12 col-md-8 text-center">
                                <div className="card bg-gray-200 mb-6">
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <div className="badge badge-rounded-circle badge-primary mt-1 mr-4">
                                                <Check className={'mt-n1'}/>
                                            </div>
                                            <p className="h3 text-left">
                                                Automated investment platform delivering institutional-grade services
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="card bg-gray-200 mb-6">
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <div className="badge badge-rounded-circle badge-primary mt-1 mr-4">
                                                <Check className={'mt-n1'}/>
                                            </div>
                                            <p className="h3 text-left">
                                                Tackling the challenges faced by a broad range of
                                                institutional and retail investors</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card bg-gray-200 mb-6">
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <div className="badge badge-rounded-circle badge-primary mt-1 mr-4">
                                                <Check className={'mt-n1'}/>
                                            </div>
                                            <p className="h3 text-left">
                                                Based on 12 years of experience at investment banks,
                                                managing risk and serving sophisticated clients
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}


function PlanSection(props) {
    return (
        <section className="py-10 bg-gradient-light">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8 text-center">
<span className="badge badge-pill badge-primary-soft mb-3">
<span className="h6 text-uppercase">Investment Process</span>
</span>
                        <h1>
                            How Clarivor Works
                        </h1>
                    </div>
                </div>

                <div className="row align-items-center mt-4">
                    <div className="col-12 col-md-6 col-lg-7">
                        <div className="device device-macbook">
                            <div className="device device-macbook">
                                <img src={planScreenshot} className="device-screen" alt="..."/>
                                <img src={macBook} className="img-fluid" alt="..."/>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-5 pt-4">
                        {/*<div className="d-flex">*/}
                        {/*    <div className="badge badge-lg badge-rounded-circle badge-primary-soft mt-1">*/}
                        {/*        <span>1</span>*/}
                        {/*    </div>*/}
                        {/*    <div className="ml-5">*/}
                        {/*        <h3>*/}
                        {/*            Sign up*/}
                        {/*        </h3>*/}
                        {/*        <p className="text-gray-700 mb-6">*/}
                        {/*            Your firm becomes a client of Clarivor, and you open end-client brokerage*/}
                        {/*            accounts*/}
                        {/*            at*/}
                        {/*            a supported custodian*/}
                        {/*        </p>*/}

                        {/*    </div>*/}

                        {/*</div>*/}
                        <div className="d-flex">


                            <div className="badge badge-lg badge-rounded-circle badge-primary-soft mt-1">
                                <span>1</span>
                            </div>


                            <div className="ml-5">


                                <h3>
                                    Screen
                                </h3>


                                <p className="text-gray-700 mb-6">
                                    Use automated screens to select strategies based on investment objectives and market
                                    conditions.
                                </p>

                            </div>

                        </div>
                        <div className="d-flex">
                            <div className="badge badge-lg badge-rounded-circle badge-primary-soft mt-1">
                                <span>2</span>
                            </div>


                            <div className="ml-5">


                                <h3>
                                    Invest
                                </h3>


                                <p className="text-gray-700 mb-6">
                                    Instruct Clarivor to execute strategies in client brokerage
                                    accounts.
                                </p>

                            </div>

                        </div>
                        <div className="d-flex">
                            <div className="badge badge-lg badge-rounded-circle badge-primary-soft mt-1">
                                <span>3</span>
                            </div>


                            <div className="ml-5">


                                <h3>
                                    Manage Risk
                                </h3>


                                <p className="text-gray-700 mb-0">
                                    Access real-time risk reports, perform "what-if" scenario analyses, and adjust
                                    portfolio exposures.
                                </p>

                            </div>

                        </div>

                    </div>
                </div>

                <div className="row justify-content-center my-4 my-md-7">
                    <a data-toggle="modal" href="#requestDemoModal" style={{textDecoration: 'none'}}>
                        <button className="btn btn-success lift mt-2 d-flex"
                                onClick={
                                    () => track_ux_event(localStorage.uuid,
                                        'Request Demo Clicked', {source: 'Advisor Solutions 2'}, globalAuthManager)
                                }
                        >
                            <span className={'my-auto'}>Request Demo</span>
                        </button>
                    </a>
                </div>
            </div>
        </section>
    )
}


//
// Explanatory paragraph
// ...products with retail fees that are difficult to risk-manage
function Homepage(props) {
    let aNewWayRef = React.createRef()
    let pricingRef = React.createRef()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const scrollToANewWay = () => {
        aNewWayRef.current.scrollIntoView({behavior: 'smooth'})
    }

    const scrollToPricing = () => {
        pricingRef.current.scrollIntoView({behavior: 'smooth'})
    }

    setLKBody()

    return (
        <React.Fragment>
            <NavBar {...props}/>
            <BreadcrumbNav pathList={['Solutions', 'Advisors']}/>
            <RequestDemoModal source="AdvisorsPage"/>
            <AdvisorHeroSection {...props} handleLearnMoreClicked={scrollToANewWay}/>
            <Fade clear>
                <ValuePropositionSection/>
            </Fade>
            <Fade clear>
                <ChallengesSection/>
            </Fade>
            <Fade clear>
                <PlanSection/>
            </Fade>
            <Fade clear>
                <GuideSection/>
            </Fade>
            {/*<AdvisorsPricingSection/>*/}

            <a id={'learnMore'}></a>

            {/*<PricingSection sectionRef={pricingRef}/>*/}
            {/*<SignupFormSection {...props}/>*/}
            <Footer {...props}/>
        </React.Fragment>
    )
}

export default Homepage

