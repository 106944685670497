import { nominalTypeHack } from "prop-types";
import axios from 'axios'
import {formatDate} from 'util/dates'

export default class AccountStrategyAllocationsManager
{
    constructor(apiURL, authManager)
    {
        this.url = apiURL
        this.authManager = authManager
        this.allocations = null
    }


    async refreshAsync() {
        let seriesOptions = []
        let nReceived = 0

        const config = {
            headers: {'Authorization': "Bearer " + this.authManager.accessToken},
        }

        const res = await axios.get(this.url, config)
        this.allocations = res.data
        return this.allocations
    }




}