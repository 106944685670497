import React, {useContext, useEffect, useState} from "react"
import headshot from 'images/blog/avatar-100.jpg'
import RequestDemoModal from "../../../pages/common/RequestDemoModal"
import {globalAuthManager, globalServices} from "../../../../services/GlobalServices"
import {apiGet} from "../../../../services/api-call-helpers"
import * as keys from 'keys'
// import {mixpanel} from "../../../../services/mixpanel"
import ReceiveUpdatesModal from "../../../pages/common/ReceiveUpdatesModal"
import {Link} from "react-router-dom"
import BlogSignupSection from "../../common/BlogSignupSection"

// import BannerImage from 'images/blog/Blog_20230203_SPYPuts/banner.jpg'
import BannerImage500 from 'images/blog/Blog_20230815_LongCallSpreads/ship.jpg'

import ppIllustration from 'images/blog/Blog_20230815_LongCallSpreads/principal-protected-strategy.png'
import callSpreadDiagram from 'images/blog/Blog_20230815_LongCallSpreads/call-spread diagram.png'
import annualizedIncomeChart from 'images/blog/Blog_20230815_LongCallSpreads/annualized-income-chart.jpg'
import selectedStrikeChart from 'images/blog/Blog_20230815_LongCallSpreads/selected-strike-chart.jpg'

import jonAvatar from 'images/blog/avatar-100.jpg'

const ProjectContext = React.createContext({})

const documentTitle = "Clarivor Blog"
const postTitle = 'Protected Equity Strategies'
const postSubtitle = 'Exploring Bond Alternatives with Equity Upside'
const postAuthor = 'Jon Spiegel, CFA'
const publishedOnText = 'August 16, 2023'
const publishedOnShort = 'Aug 16'
const topic = 'protection'
export const path = 'protection-strategies-20230816'

const HeroSection = (props) => (
    <section
        className='bg-dark text-white'
        style={{
            position: 'relative',
            zIndex: 0,
            minHeight: '35vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start', // This line has been modified to left align the text.
            backgroundColor: '#f0f0f0', // You can adjust the color as you wish
            color: '#000', // You can adjust the color as you wish
            padding: '20px'
        }}>

        <div className='container bg-dark text-white'>
            <div className={'row justify-content-center'}>
                <h1 style={{
                    fontSize: '3.0em',
                    fontWeight: 'bold',
                    textAlign: 'center' // This line has been modified to left align the text.
                }}>
                    {postTitle}
                </h1>
                <div className="px-md-12 mt-6">
                    <h2 style={{
                        fontSize: '1.5em',
                        textAlign: 'center' // This line has been modified to left align the text.
                    }}>
                        {postSubtitle}
                    </h2>
                    <p style={{
                        fontSize: '1em',
                        textAlign: 'center'
                    }}>
                        {publishedOnText}
                    </p>
                </div>
            </div>
        </div>

    </section>)

const HeaderSection = (props) => (
    <section className="pt-8 pt-md-11">
        <RequestDemoModal/>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 col-md-12 col-lg-10 col-xl-10">


                    <h1 className="display-4 text-center">
                        {props.title}
                    </h1>


                    {/*<p className="lead mb-7 text-center text-muted">*/}
                    <p className="lead px-10 mb-7 text-center text-muted">
                        {props.subtitle}
                    </p>


                    <div className="row align-items-center py-5 border-top border-bottom">
                        <div className="col-auto">


                            <div className="avatar avatar-lg">
                                <img src={props.avatarImage} alt="..."
                                     className="avatar-img rounded-circle"/>
                            </div>

                        </div>
                        <div className="col ml-n5">
                            <h6 className="text-uppercase mb-0">
                                {props.author}
                            </h6>
                        </div>
                        <div className="col-auto">
                            <time className="font-size-sm text-muted" dateTime="2019-05-20">
                                {props.publishedOnText}
                            </time>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
)


function Summary(props) {
    return (
        <React.Fragment>
            {/*<h3 className={'font-weight-bold mt-6'}>*/}
            {/*    Summary*/}
            {/*</h3>*/}
            {/*<ul>*/}
            {/*    <li className='my-2'>*/}
            {/*        Over the past year inflation and rates have steadily trended higher. While the trend has reversed*/}
            {/*        over the past couple weeks, some investors may believe the market is still in a long-term*/}
            {/*        transition to a higher-rate regime.*/}
            {/*    </li>*/}
            {/*    <li className='my-2'>*/}
            {/*        The recent pull-back could provide an opportunity to investors who want to hedge against higher*/}
            {/*        rates and protect their portfolios from the potential impact across asset classes.*/}
            {/*    </li>*/}
            {/*    <li className='my-2'>*/}
            {/*        In order to hedge rates with no upfront costs and limited downside risk, an investor can*/}
            {/*        buy a put on the TLT ETF with funds raised from selling a TLT call-spread.*/}
            {/*    </li>*/}
            {/*</ul>*/}
        </React.Fragment>
    )
}

function UpsideTable(props) {

    const tdClassName = 'text-center py-2'

    return (
        <div className='row justify-content-center'>
            <div className='col-12 col-md-10 col-lg-8'>
                <table className="table">
                    <thead>
                    <tr>
                        <th scope="col" className='text-center'>Stock</th>
                        <th scope="col" className='text-center'>Upside Cap</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td className={tdClassName}>TSLA</td>
                        <td className={tdClassName}>26.7%</td>
                    </tr>
                    <tr>
                        <td className={tdClassName}>META</td>
                        <td className={tdClassName}>24.9%</td>
                    </tr>
                    <tr>
                        <td className={tdClassName}>NVDA</td>
                        <td className={tdClassName}>24.3%</td>
                    </tr>
                    <tr>
                        <td className={tdClassName}>AMZN</td>
                        <td className={tdClassName}>23.2%</td>
                    </tr>
                    <tr>
                        <td className={tdClassName}>AAPL</td>
                        <td className={tdClassName}>21.6%</td>
                    </tr>
                    <tr>
                        <td className={tdClassName}>MSFT</td>
                        <td className={tdClassName}>20.3%</td>
                    </tr>
                    <tr>
                        <td className={tdClassName}>GOOGL</td>
                        <td className={tdClassName}>17.6%</td>
                    </tr>
                    <tr>
                        <td className={tdClassName + ' text-white'}>[]</td>
                        <td className={tdClassName + ' text-white'}>[]</td>
                    </tr>
                    <tr>
                        <td className={tdClassName}>QQQ</td>
                        <td className={tdClassName}>17.9%</td>
                    </tr>
                    <tr>
                        <td className={tdClassName}>SPY</td>
                        <td className={tdClassName}>16.6%</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

function Section1(props) {
    const putRef = React.createRef()
    const psRef = React.createRef()
    const collarRef = React.createRef()
    const pscRef = React.createRef()


    return (
        <React.Fragment>
            {/*<h3 className={'font-weight-bold mt-6'}>*/}
            {/*    Summary*/}
            {/*</h3>*/}
            {/*<ul>*/}
            {/*    <li className='my-2'>*/}

            {/*    </li>*/}
            {/*    <li>*/}

            {/*    </li>*/}
            {/*    <li>*/}

            {/*    </li>*/}
            {/*</ul>*/}
            <h3 className={'font-weight-bold mt-6'}>
                Introduction
            </h3>
            <p>
                A new ETF in the market, TJUL, was launched recently, offering 100% downside protection with exposure
                to large-cap equities. While "buffer" ETFs, which provide partial downside protection, have been growing
                in popularity in recent years, TJUL is the first to include 100% protection.
            </p><p>
            This strategy could appeal to risk-averse bond investors who are willing to forego interest income in
            exchange for participation in equity market price appreciation. The curiosity created by the product launch
            suggests that many investors may be interested in obtaining exposure to equities in a defensive manner.
        </p><p>
            Meanwhile, investors and advisors can use listed options to implement similar strategies linked to single
            stocks or other ETFs, with customization.
        </p>
            <p>
                In some cases, option strategies on other underlyings can provide similar protection with greater upside
                potential. For example, with similar protection, investors could get more potential upside
                from stocks like NVDA and TSLA, compared with SPY.
            </p>
            <h3 className={'font-weight-bold mt-6'}>
                Breaking Down the Strategy
            </h3>

            <p>
                A simple way to think about a protective strategy with a similar objective to TJUL’s is to consider it
                as a combination of: (1) a zero-coupon bond and (2) a call spread on the underlying security (SPY for
                TJUL). In other words, interest from the bond is used to purchase the call spread. </p><p>
        </p>
            <div className='card mx-auto my-6'>
                <div className='card-body p-2  bg-white text-center'>
                    <h3 className='text-center'>
                        100% Protected Strategy Illustration
                    </h3>
                    <img src={ppIllustration} className='img-fluid'/>
                    {/*        <p className='text-center mt-3' style={{fontSize: '0.8rem'}}>*/}
                    {/*            As of 5/19/2023<sup>1</sup>*/}
                    {/*</p>*/}
                </div>
            </div>
            <p>
                For example, consider a $1000 investment in a protected strategy. If a 2-year
                zero-coupon bond costs $900 per $1000 of face value, then the investor would have $100
                remaining to
                buy
                a call spread. At maturity, the investor would have $1000 in principal from the zero-coupon bond,
                plus
                any additional payoff provided by the call spread. </p>
            <p>
                The call spread is long a strike at-the-money and short a strike out-of-the-money. Therefore, the call
                spread provides the investor with price appreciation of the underlying security, up to a cap, as shown
                below.
                <div className='card mx-auto my-6'>
                    <div className='card-body p-2  bg-white text-center'>
                        <h3 className='text-center'>
                            Call Spread Value at Expiration
                        </h3>
                        <img src={callSpreadDiagram} className='img-fluid'/>
                        {/*        <p className='text-center mt-3' style={{fontSize: '0.8rem'}}>*/}
                        {/*            As of 5/19/2023<sup>1</sup>*/}
                        {/*</p>*/}
                    </div>
                </div>
            </p>
            <p>
                While using a risk-free zero-coupon bond is intuitively the simplest way to guarantee that the exact
                amount of principal is available to the investor at maturity, other solutions may be more practical and
                could provide economically similar exposure. For example, an investor could sell a small portion of bond
                holdings to buy the call spread, calibrated so that at maturity the bond portfolio’s interest income
                replenishes the value of the bonds sold. Investors also may be able to tolerate some credit risk in
                order to generate higher income and pay for more upside.
            </p>
            <h3 className={'font-weight-bold mt-6'}>
                Upside Potential Across Different Securities:
            </h3>

            <p>
                SPY reflects a broad equity benchmark, so many investors may therefore seek protected exposure to SPY.
                However, some investors may wish to access exposure to other securities in a protected manner. In some
                cases, the option market will provide more upside on other securities.
            </p>
            <p>
                To compare the potential upside across securities in the listed option market, we use an expiration date
                of 2025-06-20 and a pricing date of 2023-06-20 to approximate a two-year holding period.
            </p>
            <p>
                As our benchmark, we consider a SPY call spread with a 16.62% upside, which corresponds to the upside
                provided
                by TJUL. On 20-Jun, this call spread cost approximately 9.84% of the notional value.
            </p>
            <p>
                Applying the budget of 9.84% of notional to other securities, we find that upside caps on many stocks
                are higher than that of SPY. Some investors may be particularly interested in principal protection
                on the following mega-cap stocks:
            </p>
            <UpsideTable/>
            <p>
                Investors familiar with structured products may know that payoffs can appear more attractive on
                stocks with high dividends. However, in this case dividends tend to be higher for SPY than for
                these technology stocks. Rather, the higher upside caps on these technology stocks appear due to the
                way the options market is pricing the volatilities.
            </p>

            <h3 className={'font-weight-bold mt-6'}>
                Conclusion
            </h3>
            <p>
                The launch of the TJUL ETF represents a notable entry into the market, offering 100% downside protection
                with exposure to large-cap equities. For investors who can use options, strategies on other underlyings
                may provide more customized exposure. As the data suggests, certain individual stocks, like
                NVDA and TSLA, present potential for higher upside caps compared to the broad equity benchmark SPY when
                employing a similar protective strategy.
            </p>

        </React.Fragment>
    )
}

function BufferPerformanceProjectContainer(props) {
    const [projectObj, setProjectObj] = useState(null)

    useEffect(() => {
        apiGet(keys.API_ENDPOINT.PROJECTS, {id: props.projectId}, globalAuthManager).then(
            res => {
                const [project] = res.data
                setProjectObj(project)
            }
        )
    }, [])

    const contextValue = {
        projectObj: projectObj,
        projectId: props.projectId
    }

    return (
        <ProjectContext.Provider value={contextValue}>
            {props.children}
        </ProjectContext.Provider>
    )
}


function DisclosuresLink(props) {
    return (
        <a data-toggle="modal" href="#disclaimerModal"
           onClick={() => null}>Disclosures</a>
// onClick={() => mixpanel.track('Disclosure Clicked')}>Disclosures</a>
    )
}

function TermsOfUseLink(props) {
    return (
        <Link to={'/terms-of-use'} target={'_blank'}
              onClick={() => null}>Terms of Use</Link>
// onClick={() => mixpanel.track('Terms of Use Clicked')}>Terms of Use</Link>
    )
}

function BacktestingSourceDisclaimer(props) {
    return (
        <div>
            <hr/>
            {/*<p className='font-italic text-muted'>*/}
            {/*    Strategy Details: <br/><br/>*/}
            {/*    The calendar collar strategy back-tested above buys 12-month puts, 20%*/}
            {/*    out-of-the-money (OTM) on the SPY*/}
            {/*    ETF. Each month, a 1-month call is sold with strike closest to 1/12th of the value of a 12-month, 20%*/}
            {/*    OTM put. The 12-month put is rolled 7 days prior to maturity, while the short calls are rolled 3 days*/}
            {/*    prior to maturity. <br/><br/>*/}
            {/*    Similarly, the traditonal collar strategy buys a 12-month 20% OTM put and sells a 12-month call of*/}
            {/*    approximately equal value.<br/><br/>*/}
            {/*    The weights of the option overlays and SPY are rebalanced quarterly.<br/><br/>*/}
            {/*    Each option strategy back-test above includes an annual deduction of 0.39% to reflect management fees*/}
            {/*    and commissions.<br/><br/>*/}

            {/*    SPY (TR) has dividends immediately reinvested, shown with no additional management fee.*/}
            {/*</p>*/}
            <p className='font-italic text-muted'>
                Source: Clarivor LLC.<br/>
                {/*<sup>1</sup>5/19/2023 selected as a representative date for illustrative purposes.<br/>*/}
                Prices across listed strikes are interpolated for comparison across underlyings.<br/>
                Intended for professional investors - options can entail complex risks and may not be suitable for
                particular situations. <br/>
                {/*Listed strikes and maturities are rounded for comparison.<br/>*/}
                {/*Strikes and maturities are estimated from listed strikes and tenors for easier comparison.*/}
                {/*All buffers above refer to a SPY 12-month buffer traded on 12/18/2021, providing 10% downside*/}
                {/*protection, collateralized with cash.*/}
                {/*Options can entail complex risks and may not be suitable for all investors.<br/>*/}
                {/*Strikes are selected from the listed market.*/}
                {/*Options are rolled seven days prior to expiration at bid or offer.*/}
                {/*Back-tested results may not be indicative of future returns.*/}
                {/*Options can entail complex risks and may not be suitable for all investors. */}
                Nothing in this post should
                be construed as investment advice.<br/>
                <DisclosuresLink/> and <TermsOfUseLink/> apply.<br/>
            </p>
        </div>
    )
}


function PostBody(props) {
    return (
        <React.Fragment>
            <section className="pt-0 pt-md-0">
                <ReceiveUpdatesModal/>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-12 col-lg-10 col-xl-9">
                            {/*<Summary/>*/}
                            <Section1/>
                            <BacktestingSourceDisclaimer/>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}


export default function Blog_20230815_LongCallSpreads(props) {
    const projectId = null  // this is a static blog post

    useEffect(() => {
        document.title = documentTitle
    }, [])

    const headerProps = {
        title: postTitle,
        subtitle: postSubtitle,
        avatarImage: headshot,
        author: postAuthor,
        publishedOnText: publishedOnText,
    }

    return (
        <React.Fragment>
            <HeroSection/>
            {/*<HeaderSection {...headerProps} />*/}
            {/*<BufferPerformanceProjectContainer projectId={projectId}>*/}
            <PostBody/>
            {/*</BufferPerformanceProjectContainer>*/}
            <BlogSignupSection fromLocation={'Blog Post TLT Overwriting'}/>
        </React.Fragment>
    )
}


export function RichLongCallSpreads20230815(props) {
    return (
        <section className="pt-7 pt-md-10">
            <div className="container">
                <div className="row">
                    <div className="col-12">

                        <div className="card card-row shadow-light-lg mb-6 lift lift-lg">
                            <div className="row no-gutters">
                                <div className="col-12">

<span className="badge badge-pill badge-light badge-float badge-float-inside">
<span className="h6 text-uppercase">{topic}</span>
</span>
                                </div>
                                <Link className="col-12 col-md-6 order-md-2 bg-cover card-img-right"
                                      style={{backgroundImage: "url(" + BannerImage500 + ")"}}
                                      to={props.blogPath + path}>

                                    <img src="assets/img/photos/photo-27.jpg" alt="..."
                                         className="img-fluid d-md-none invisible"/>

                                    <div
                                        className="shape shape-left shape-fluid-y svg-shim text-white d-none d-md-block">
                                        <svg viewBox="0 0 112 690" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 0h62.759v172C38.62 384 112 517 112 517v173H0V0z"
                                                  fill="currentColor"></path>
                                        </svg>
                                    </div>

                                </Link>
                                <div className="col-12 col-md-6 order-md-1">

                                    <Link className="card-body" to={props.blogPath + path}>

                                        <h3>
                                            {postTitle}
                                        </h3>

                                        <p className="mb-0 text-muted">
                                            {postSubtitle}
                                        </p>

                                    </Link>

                                    <a className="card-meta" href="#!">

                                        <hr className="card-meta-divider"/>

                                        <div className="avatar avatar-sm mr-2">
                                            <img src={jonAvatar} alt="..."
                                                 className="avatar-img rounded-circle"/>
                                        </div>

                                        <h6 className="text-uppercase text-muted mr-2 mb-0">
                                            Jon Spiegel
                                        </h6>

                                        <p className="h6 text-uppercase text-muted mb-0 ml-auto">
                                            <time dateTime="2020-10-23">{publishedOnShort}</time>
                                        </p>

                                    </a>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>

    )
}
