import React, {Component, useContext, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'

import NavBar from 'components/common/NavBar'
import Footer from 'components/common/Footer'
import SignInModal from 'components/common/SignInModal'
import {apiGet} from "../../../services/api-call-helpers";
import * as keys from "../../../keys";
import {globalAuthManager} from "../../../services/GlobalServices";
import {AppContext} from "../../contexts";

// import 'lkcss'
// import lk from 'dk/src/assets/css/theme.module.css'
// import {mapClasses} from 'util/cssModule'


export function AdviserClientAgreementSection(props) {
    const [entityType, setEntityType] = useState(null)
    const [entityState, setEntityState] = useState(null)
    const appContext = useContext(AppContext)

    useEffect(() => {
        apiGet(keys.API_ENDPOINT.ADVISORY_CLIENT, {}, globalAuthManager).then(
            res => {
                setEntityState(res.data.client_info.stateOfFormation)
                setEntityType(res.data.client_info.entityType)
            }
        )
    }, [appContext.isLoggedIn])

    let entityTypeComponent = entityType ?
        <span className={''}><u>{entityType}</u></span> :
        <span className={'text-muted font-italic'}>[ sign in to view entity type ]</span>

    let entityStateComponent = entityState ?
        <span className={''}><u>{entityState}</u></span> :
        <span className={'text-muted font-italic'}>[sign in to view entity state]</span>

    return (
        <section className="">
            <div className="container" style={{maxWidth: '760px'}}>
                <div className="row align-items-center">
                    <div className="col-12 col-md">
                        <h1 className="display-4 mb-2">
                            Clarivor Client Agreement for Advisers
                        </h1>
                        <p className="font-size-lg text-gray-700 mb-md-0">
                            Last Revised: 2022-09-09
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">

                        <hr className="my-6 my-md-8"/>

                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className='my-4'>
                            <p className="text-gray-800">
                                This Clarivor Client Agreement (the “Agreement”) is by and between you, or the entity
                                you
                                are acting on behalf (the “Client”), and Clarivor LLC, a Delaware limited liability
                                company
                                that is registered as an investment adviser under Investment Advisers Act of 1940, as
                                amended (the “Advisers Act”), with the U.S. Securities and Exchange Commission
                                (“Clarivor”
                                or “Adviser”). Pursuant to this Agreement, Clarivor will provide Client with
                                internet-based
                                investment advisory services and, if applicable, use of web-based investment software
                                services that Client subscribes to on Clarivor’s website (the “Subscribed Services”).
                                This
                                Agreement becomes effective when Clarivor approves Client’s application, after Client
                                has
                                clicked “I agree” and submitted the form (“Acceptance Form”) on Clarivor’s website
                                indicating acceptance of this Agreement. YOU ACKNOWLEDGE THAT CLICKING “I AGREE” IS
                                EQUIVALENT TO YOUR LEGAL SIGNATURE.
                            </p>
                        </div>

                        <div className='mt-8'>
                            <p className="text-gray-800">
                        <span
                            className="badge badge-rounded-circle badge-success-soft mt-1 mr-4 font-weight-bold font-size-large">
                            1.
                        </span>
                                {/*<span className="font-weight-bold text-uppercase" >Introduction</span>*/}
                                <span
                                    className="font-weight-bold text-uppercase text-uppercase">Description of Services</span>
                            </p>

                            <p className="text-gray-800">
                                For each Subscribed Service selected in Client’s account (“Account”) on Clarivor’s
                                website, Clarivor shall have the power, authority and responsibilities described
                                below: </p>
                            <p>

                                <p className={'text-gray-800 ml-6'}>
                                    <span className='font-weight-bold'>Research Service:</span> If Client subscribes to
                                    the Research Service, Clarivor shall have the power and authority to provide
                                    investment advice to Client, and Client will have access to the research
                                    functionality of Clarivor’s web-based software (the “Research Software”). Based on
                                    information submitted by Client, the Research Software will enable Client to view
                                    investment recommendations that Clarivor makes available through the Research
                                    Software. Additionally the Research Software will enable Client to screen investment
                                    strategies and view back-tested performance and risk analysis of customized
                                    portfolios. The Research Software will tailor investment recommendations to Client’s
                                    individual needs based on interactions with Client through Clarivor’s website. The
                                    Research Software additionally provides the flexibility to enable Client to view
                                    back-testing, performance and risk information on strategies that may conflict with
                                    Clarivor’s investment recommendations for Client. Under the Research Service,
                                    Clarivor will not supervise or direct, on a discretionary or non-discretionary
                                    basis, any of Client’s accounts.
                                </p>
                                <p className={'text-gray-800 ml-6'}>
                                    <span className='font-weight-bold'>Solutions Service:</span> If Client subscribes to
                                    the Solutions Service, Client shall designate one or more brokerage accounts (or
                                    account partitions) held at Interactive Brokers or Charles Schwab (“Brokerage
                                    Accounts”) exclusively for managing investments
                                    using the portfolio management functionality of Clarivor’s web-based software (the
                                    “Portfolio Management Software”). The Solutions Service includes the Research
                                    Service. Additionally, Clarivor shall have the power and authority to execute trades
                                    in the Brokerage Accounts on a non-discretionary basis, as directed by Client
                                    through the Portfolio Management Software. The Portfolio Management Software enables
                                    Client to direct trades for customized portfolios and view information related to
                                    performance and risk. The Portfolio Management Software provides the flexibility to
                                    enable Client to direct trades in the Brokerage Accounts that may not be consistent
                                    with Clarivor’s investment recommendations for Client.
                                </p>
                                <p className={'text-gray-800 ml-6'}>
                                    <span className='font-weight-bold'>Discretionary Service:</span> If Client
                                    subscribes to the Discretionary Service, Client shall designate one or more
                                    Brokerage Accounts for assets managed by Clarivor. Clarivor shall have the power and
                                    authority to supervise and direct on a discretionary basis the investments of and
                                    for the account of Client, including the purchase and sale of any securities and
                                    instruments and any other transaction therein and, unless specifically directed
                                    otherwise in writing by Client, the transactions in the Brokerage Accounts shall be
                                    made in accordance with the investment goals provided by Client and as they may be
                                    amended from time to time by Client by notice to Clarivor.
                                </p>
                            </p>
                        </div>

                        <div className='mt-8'>
                            <p className="text-gray-800">
                            <span
                                className="badge badge-rounded-circle badge-success-soft mt-1 mr-4 font-weight-bold font-size-large">
                                2.
                            </span>
                                <span
                                    className="font-weight-bold text-uppercase text-uppercase">Client Representations
                            </span>
                            </p>

                            <p className="text-gray-800">
                                Client is a {entityTypeComponent} duly
                                organized and validly existing under the
                                laws of the State of {entityStateComponent} with
                                the power to own and possess its
                                assets and carry on its business as it is now being conducted.
                            </p><p>
                            Client represents and confirms that Client is authorized to enter into an Agreement with
                            Clarivor and that such engagement, pursuant to this Agreement, does not violate any
                            obligations by which Client is bound, either legally or contractually. The individual acting
                            on behalf of Client represents that he or she has the legal authority to enter this
                            Agreement on behalf of Client. Client will promptly notify Clarivor in any changes to such
                            legal authority.
                        </p><p>
                            Client is (1) registered as an investment adviser under the Advisers Act or applicable State
                            law and shall maintain such registration or (2) exempt from registration as an investment
                            adviser under applicable law and shall maintain such exemption. Client and its personnel, as
                            applicable, are duly registered, licensed or qualified as an investment adviser in each
                            jurisdiction where the conduct of its business requires such registration and is in
                            compliance in all material respects with applicable law requiring such registration,
                            licensing or qualification.
                        </p><p>
                            Neither Client nor any “person associated with” Client (within the meaning of the Advisers
                            Act) has been subject to any disqualification that would be a basis for denial, suspension
                            or revocation of registration of an investment adviser under Section 203(e) of the Advisers
                            Act, and there are no proceedings of any governmental authority pending or, to the knowledge
                            of Client, threatened that would reasonably be expected to result in any such
                            disqualification, denial, suspension or revocation.
                        </p><p>
                            Client shall comply in all material respects with all requirements applicable to Client
                            under the Advisers Act, including Rule 206(4)-7 thereunder, and any applicable State or
                            Federal law, including as applicable the Investment Company Act of 1940, as amended.
                        </p><p>
                            Client has implemented anti-money laundering policies and procedures that are reasonably
                            designed to comply with applicable law and shall comply in all material respects with such
                            policies and procedures. Client, upon reasonable request by Clarivor, will provide such
                            information as Clarivor may need to satisfy applicable anti-money laundering laws and
                            regulations.
                        </p><p>
                            Clarivor assumes no obligation with respect to, and shall not be responsible for, the
                            expenses of Client or any investment advice provided by Client.
                        </p><p>
                            Client understands that Clarivor does not collect information about the financial situation
                            of individuals or entities to which Client provides investment advice using Clarivor’s
                            services, and Client is entirely responsible for determinations of investment suitability.
                        </p><p>
                            Client agrees it will use the Subscribed Services in a manner consistent with Client’s own
                            obligations and internal restrictions. Client agrees to promptly notify Clarivor of any new
                            obligations or restrictions that could impact Client’s ability to use the Subscribed
                            Services in accordance with this section.
                        </p><p>
                            Client agrees to hold Clarivor, its members, directors, officers, employees, contractors,
                            and agents harmless against any losses, costs or claims suffered or arising out of Client’s
                            failure to act in accordance with its obligations or to provide Clarivor with any
                            information required to be furnished hereunder.
                        </p><p>
                            If the Subscribed Services include the Research Service or the Solutions Service, client
                            acknowledges that the Subscribed Services may enable Client to view information or analysis
                            related to strategies, or direct trades in securities, that conflict with Clarivor’s
                            recommendations. In using these services Client takes sole responsibility for any judgments
                            as to any securities and other related financial instruments in which Client transacts.
                            Unless Client subscribes to Discretionary Service, Client shall have the final
                            decision-making authority regarding Clarivor’s investment recommendations. Client
                            understands and acknowledges that Clarivor does not warrant any rate of return, market value
                            or performance of any Brokerage Account or aggregation of Brokerage Accounts.
                        </p><p>
                            Client shall be responsible for all decisions concerning the voting of proxies for
                            securities held in any Client accounts. Clarivor cannot provide any advice or take any
                            action with respect to the voting of these proxies.
                        </p>

                        </div>

                        <div className='mt-8'>
                            <p className="text-gray-800">
                        <span
                            className="badge badge-rounded-circle badge-success-soft mt-1 mr-4 font-weight-bold font-size-large">
                            3.
                        </span>
                                <span
                                    className="font-weight-bold text-uppercase text-uppercase">Expenses and Fees</span>
                            </p>

                            <p className={'text-gray-800 ml-6'}>
                                <span className='font-weight-bold'>Subscription Fee:</span>For the services Research
                                Service or Solutions Service, Client will pay Clarivor a fixed monthly Subscription Fee
                                in the amount specified on the Acceptance Form. The stated Subscription Fee is for a
                                single user on Clarivor’s website. Client and Clarivor may negotiate amended fees for
                                additional website users. The Subscription Fee shall be payable in advance, on the first
                                day of each monthly billing cycle. The first day of the first billing cycle will be the
                                later of (1) the effective date of this agreement and (2) the day following any free
                                trial period specified on the Acceptance Form. Client will be charged via credit card
                                kept on file with Clarivor’s payments vendor.
                            </p>
                            <p className={'text-gray-800 ml-6'}>
                                <span className='font-weight-bold'>Asset Fee:</span> For the Solutions Service or
                                Discretionary Service, Client will pay Clarivor an annual advisory fee, in accordance
                                with schedule of rates (“Rate Schedule”) specified on the Acceptance Form. The Asset Fee
                                is payable monthly, in arrears, based on the net asset value (NAV) of portfolio assets
                                under management in the Brokerage Accounts, accrued daily. The Rate Schedule shall
                                specify the applicable percentage fee to apply to each range of NAVs. For the avoidance
                                of doubt, depending on the Rate Schedule the Solutions Service may be subject to both a
                                Subscription Fee and Asset Fee. The Asset Fee will be directly deducted from each Client
                                Brokerage Account by the custodian. Client hereby authorizes Clarivor to be paid
                                directly from their Brokerage Accounts held by the custodian. The custodian will send a
                                statement to Client at least quarterly. Where it is not practical to deduct fees
                                directly from the Brokerage Accounts, Client will be sent an invoice at the end of the
                                quarter. The invoice is payable upon receipt.
                            </p>

                            <p>
                                Expenses related to the ordinary servicing of Client’s Brokerage Accounts, including
                                custody fees, security transaction fees, and/or program fees shall be paid by Client.
                                Other non-ordinary fees or fees incurred at the direction of Client shall be paid by
                                Client. ETF operating fees and investment product fees are deducted from the asset value
                                of those investments as defined in the prospectus of the sponsor for each product.
                            </p>

                            <p>
                                Clarivor shall not be compensated on the basis of a share of capital gains realized upon
                                sale securities or capital appreciation of the funds in which Client is invested. </p>

                            <p>
                                Clarivor may modify the terms in this Section prospectively in its sole discretion on at
                                least 30 days prior written notice. Please refer to Section 18 of this Agreement.
                            </p>
                        </div>

                        <div className='mt-8'>
                            <p className="text-gray-800">
                                <span
                                    className="badge badge-rounded-circle badge-success-soft mt-1 mr-4 font-weight-bold font-size-large">
                                    4.
                                </span>
                                <span
                                    className="font-weight-bold text-uppercase text-uppercase">Custody and Brokerage Transactions</span>
                            </p>
                            <p className={'text-gray-800'}>
                                If client subscribes to the Solutions Service or Discretionary Service:
                            </p>

                            <p className={'text-gray-800 ml-6'}>
                                Client has appointed Interactive Brokers or Charles Schwab (“Custodian”) to take and have possession of
                                the assets of the Brokerage Accounts. </p>

                            <p className={'text-gray-800 ml-6'}>
                                Per the instruction of Client, Clarivor will direct and place all orders for the
                                execution of transactions with or through Custodian, under Client’s independent,
                                exclusive agreement with Custodian. Client shall be responsible for such brokerage
                                expense as billed directly by Custodian. Client acknowledges that directing the
                                brokerage activities solely to Custodian may result in the loss of best execution of
                                orders at the most favorable prices reasonably obtainable.</p>

                            <p className={'text-gray-800 ml-6'}>
                                The terms of the Brokerage Accounts, which contains the assets to which this Agreement
                                pertains, shall be determined solely by and between Client and Custodian. Clarivor shall
                                not be liable to Client for any act, conduct or omission by Custodian acting as broker
                                or custodian. Clarivor shall not be responsible for ensuring Custodian’s compliance with
                                the terms of the Brokerage Accounts and payment of brokerage or custodian charges and
                                fees. Client acknowledges that Custodian will provide duplicate confirms and/or
                                electronic access to Clarivor for all trades in Brokerage Accounts. Clarivor is
                                authorized and empowered to issue instructions to Custodian and to request information
                                about the Brokerage Accounts from Custodian.
                            </p>
                        </div>

                        <div className='mt-8'>
                            <p className="text-gray-800">
                                <span
                                    className="badge badge-rounded-circle badge-success-soft mt-1 mr-4 font-weight-bold font-size-large">
                                    5.
                                </span>
                                <span
                                    className="font-weight-bold text-uppercase text-uppercase">Aggregation</span>
                            </p>
                            <p className={'text-gray-800'}>
                                If client subscribes to the Discretionary Service:
                            </p>

                            <p className={'text-gray-800 ml-6'}>
                                Based on the account ownership structure and independent agreements between Client and
                                Custodian, Clarivor may or may not aggregate security trades with other accounts that
                                Clarivor is authorized to execute trades in.
                            </p>
                            <p className={'text-gray-800 ml-6'}>
                                Clarivor is authorized in its discretion to aggregate purchases and sales and other
                                transactions made for the Brokerage Accounts with purchases and sales and other
                                transactions in the same or similar securities or instruments of the same issuer or
                                counterpart for other clients of Clarivor or with affiliates of Clarivor. When
                                transactions are so aggregated, the actual prices applicable to the aggregated
                                transactions will be averaged, and the Brokerage Accounts will be deemed to have
                                purchased or sold its proportionate share of the instruments involved at the average
                                price so obtained. </p>
                        </div>

                        <div className='mt-8'>
                            <p className="text-gray-800">
                                <span
                                    className="badge badge-rounded-circle badge-success-soft mt-1 mr-4 font-weight-bold font-size-large">
                                    6.
                                </span>
                                <span
                                    className="font-weight-bold text-uppercase text-uppercase">Confirmation of Trades</span>
                            </p>
                            <p className={'text-gray-800'}>
                                If client subscribes to the Solutions Service or Discretionary Service:
                            </p>

                            <p className={'text-gray-800 ml-6'}>
                                Client and Clarivor will direct that confirmations of any transactions effected for the
                                Brokerage Accounts will be sent, in conformity with applicable law, to Client with a
                                copy to Clarivor.
                            </p>
                        </div>

                        <div className='mt-8'>
                            <p className="text-gray-800">
                                <span
                                    className="badge badge-rounded-circle badge-success-soft mt-1 mr-4 font-weight-bold font-size-large">
                                    7.
                                </span>
                                <span
                                    className="font-weight-bold text-uppercase text-uppercase">Liability</span>
                            </p>
                            <p className={'text-gray-800'}>
                                The federal and State securities laws impose liabilities under certain circumstances
                                on persons who do act in good faith. Therefore, this agreement does not constitute a
                                waiver of any Client’s legal rights under common law or Federal and State securities
                                laws.
                            </p>
                        </div>

                        <div className='mt-8'>
                            <p className="text-gray-800">
                                <span
                                    className="badge badge-rounded-circle badge-success-soft mt-1 mr-4 font-weight-bold font-size-large">
                                    8.
                                </span>
                                <span
                                    className="font-weight-bold text-uppercase text-uppercase">Indemnification</span>
                            </p>
                            <p className={'text-gray-800'}>
                                Client agrees to hold Clarivor, its members, directors, officers, employees,
                                contractors, and agents harmless from all damages, costs, or losses (collectively,
                                "Losses") arising directly or indirectly out of Client’s use of the Subscribed Services;
                                provided, however Clarivor shall not be indemnified for any Losses to the extent such
                                Losses are caused by Clarivor's own gross negligence, bad faith, willful malfeasance or
                                breach of fiduciary duty.
                            </p>
                        </div>

                        <div className='mt-8'>
                            <p className="text-gray-800">
                                <span
                                    className="badge badge-rounded-circle badge-success-soft mt-1 mr-4 font-weight-bold font-size-large">
                                    9.
                                </span>
                                <span
                                    className="font-weight-bold text-uppercase text-uppercase">Conflicts of Interest</span>
                            </p>
                            <p className={'text-gray-800'}>
                                Client agrees that Clarivor may refrain from rendering any advice or services concerning
                                securities of companies of which any of Clarivor, its members, directors, officers,
                                employees, or any of Clarivor’s affiliates, may have substantial economic interest,
                                until Clarivor is able to fully disclose any conflicts of interest to Client.
                            </p>
                        </div>

                        <div className='mt-8'>
                            <p className="text-gray-800">
                                <span
                                    className="badge badge-rounded-circle badge-success-soft mt-1 mr-4 font-weight-bold font-size-large">
                                    10.
                                </span>
                                <span
                                    className="font-weight-bold text-uppercase text-uppercase">Non-Exclusive Advisory Services</span>
                            </p>
                            <p className={'text-gray-800'}>
                                It is understood that Clarivor performs investment advisory services for various
                                clients. Client agrees that Clarivor may give advice and take action with respect to any
                                of its other clients which may differ from advice given, or the timing or nature of
                                action taken, with respect to the external Client accounts. However, in practice,
                                Clarivor, to the extent practical, will endeavor to recommend investment opportunities
                                to Client on a fair and equitable basis relative to other clients. Nothing in this
                                Agreement shall limit or restrict Clarivor or any of its members, directors, officers,
                                affiliates or employees from buying, selling or trading in any securities or other
                                assets for its or their own account or accounts, and Client acknowledges that Clarivor,
                                its members, directors, officers, affiliates and employees, and other clients of
                                Clarivor, may at any time acquire, increase, decrease or dispose of portions of
                                investments which are at the same time being acquired, held or disposed of for the
                                external Client accounts. Clarivor will not have any obligation to initiate the purchase
                                or sale, or to recommend for purchase or sale, for the external Client accounts any
                                security or other asset which Clarivor, its members, directors, officers, affiliates or
                                employees may purchase, hold or sell for its or their own accounts or for the accounts
                                of any other clients of Clarivor. </p>
                        </div>

                        <div className='mt-8'>
                            <p className="text-gray-800">
                                <span
                                    className="badge badge-rounded-circle badge-success-soft mt-1 mr-4 font-weight-bold font-size-large">
                                    11.
                                </span>
                                <span
                                    className="font-weight-bold text-uppercase text-uppercase">Reliance of Information</span>
                            </p>
                            <p className={'text-gray-800'}>
                                Client understands that Clarivor and each of its agents, in the performance of its
                                obligations and duties under the Agreement, is entitled to rely upon the accuracy of
                                information furnished by Client or on its behalf, without further investigation.
                            </p>
                        </div>

                        <div className='mt-8'>
                            <p className="text-gray-800">
                                <span
                                    className="badge badge-rounded-circle badge-success-soft mt-1 mr-4 font-weight-bold font-size-large">
                                    12.
                                </span>
                                <span
                                    className="font-weight-bold text-uppercase text-uppercase">Termination and Cancellation</span>
                            </p>
                            <p className={'text-gray-800'}>
                                Clarivor may not assign this Agreement for purposes of the Advisers Act without the
                                prior consent of Client. Neither Client nor Clarivor may assign, convey or otherwise
                                transfer any of their rights, obligations or interests under this Agreement without the
                                prior consent of the other party. This Agreement shall continue until terminated by
                                either party. The Agreement may be terminated, at any time, by either party, by
                                providing advance written notice to the other party. Client will be responsible for the
                                prorated fees based on the number of days in the billing cycle, up to and including the
                                date of termination. If fees have been prepaid by Client, upon termination or in the
                                case of nonperformance, any fees paid in advance will be prorated to the date of
                                termination and any excess will be refunded to client.
                            </p>
                        </div>

                        <div className='mt-8'>
                            <p className="text-gray-800">
                                <span
                                    className="badge badge-rounded-circle badge-success-soft mt-1 mr-4 font-weight-bold font-size-large">
                                    13.
                                </span>
                                <span
                                    className="font-weight-bold text-uppercase text-uppercase">Governing Law Disputes</span>
                            </p>
                            <p className={'text-gray-800'}>
                                To the extent federal law does not apply to this Agreement, it shall be construed in
                                accordance with the laws of the State of Delaware. </p>
                        </div>

                        <div className='mt-8'>
                            <p className="text-gray-800">
                                <span
                                    className="badge badge-rounded-circle badge-success-soft mt-1 mr-4 font-weight-bold font-size-large">
                                    14.
                                </span>
                                <span
                                    className="font-weight-bold text-uppercase text-uppercase">Disclosure</span>
                            </p>
                            <p className={'text-gray-800'}>
                                Clarivor represents it is registered as an investment adviser under the Advisers Act
                                with the U.S. Securities and Exchange Commission. Client acknowledges receipt of
                                Clarivor’s disclosure <a className={'font-weight-bold'}
                                                         href='https://adviserinfo.sec.gov/firm/brochure/309994'>brochure</a> containing
                                all necessary information regarding Clarivor’s
                                services and fees, as applicable and governed by law. If the appropriate disclosure
                                statement was not delivered to Client at least 48 hours prior to Client entering into
                                any written or oral advisory contract with this investment Clarivor, then Client has the
                                right to terminate the contract without penalty within five business days after entering
                                into the contract. For the purposes of this provision, a contract is considered entered
                                when Client has submitted the Acceptance Form indicating acceptance with this Agreement.
                            </p>
                        </div>

                        <div className='mt-8'>
                            <p className="text-gray-800">
                                <span
                                    className="badge badge-rounded-circle badge-success-soft mt-1 mr-4 font-weight-bold font-size-large">
                                    15.
                                </span>
                                <span
                                    className="font-weight-bold text-uppercase text-uppercase">Privacy</span>
                            </p>
                            <p className={'text-gray-800'}>
                                Client has received and reviewed Clarivor’s <Link to={'/privacy'}>Privacy Policy</Link>.
                                Except as otherwise agreed in writing or as required by law, Clarivor will keep
                                confidential all information concerning Client’s identity, financial affairs, or
                                investments; provided, however, that Client authorizes Clarivor to contact Client’s
                                accountants, attorneys and other consultants as deemed necessary by Clarivor.</p>
                        </div>

                        <div className='mt-8'>
                            <p className="text-gray-800">
                                <span
                                    className="badge badge-rounded-circle badge-success-soft mt-1 mr-4 font-weight-bold font-size-large">
                                    16.
                                </span>
                                <span
                                    className="font-weight-bold text-uppercase text-uppercase">Disclaimer</span>
                            </p>
                            <p className={'text-gray-800'}>
                                Client acknowledges that no information received from Clarivor may be construed as tax
                                advice, legal advice, or an offer to buy or sell securities.
                            </p>
                        </div>

                        <div className='mt-8'>
                            <p className="text-gray-800">
                                <span
                                    className="badge badge-rounded-circle badge-success-soft mt-1 mr-4 font-weight-bold font-size-large">
                                    17.
                                </span>
                                <span
                                    className="font-weight-bold text-uppercase text-uppercase">General</span>
                            </p>
                            <p className={'text-gray-800'}>
                                If any provision of this Agreement will be held invalid or unenforceable by a court, the
                                remaining provisions of this Agreement will remain in full force and effect, and the
                                provision or portion thereof affected will be construed so as to be enforceable to the
                                maximum extent permissible by law.
                            </p>
                        </div>

                        <div className='mt-8'>
                            <p className="text-gray-800">
                                <span
                                    className="badge badge-rounded-circle badge-success-soft mt-1 mr-4 font-weight-bold font-size-large">
                                    18.
                                </span>
                                <span
                                    className="font-weight-bold text-uppercase text-uppercase">Updating this Agreement</span>
                            </p>
                            <p className={'text-gray-800'}>
                                Clarivor may amend or otherwise modify this Agreement in its sole discretion from time
                                to time, consistent with the terms of this Agreement and provided that such amendment or
                                modification does not adversely affect the Client, in which case Clarivor will update
                                the “Last Revised” date at the top of this Agreement. Clarivor will use reasonable
                                efforts to attempt to provide notice to Client and, where required by applicable law,
                                Clarivor will obtain Client’s consent. Notice may be by email to Client at the last
                                email address provided to Clarivor, by posting notice of such changes on the Subscribed
                                Services, or by other means, consistent with applicable law. However, it is Client’s
                                sole responsibility to review this Agreement from time to time to view any such changes.
                                The updated Agreement will be effective as of the time of posting, or such later date as
                                may be specified in the updated Agreement. This Agreement is not subject to any oral
                                amendment or modification.
                            </p>
                        </div>

                        <div className='mt-8'>
                            <p className="text-gray-800">
                                <span
                                    className="badge badge-rounded-circle badge-success-soft mt-1 mr-4 font-weight-bold font-size-large">
                                    19.
                                </span>
                                <span
                                    className="font-weight-bold text-uppercase text-uppercase">Notices</span>
                            </p>
                            <p className={'text-gray-800'}>
                                All notices required or permitted to be sent to Clarivor under this Agreement shall be
                                sent to legal@clarivor.com
                            </p>
                        </div>

                        <div className='mt-8'>
                            <p className="text-gray-800">
                                <span
                                    className="badge badge-rounded-circle badge-success-soft mt-1 mr-4 font-weight-bold font-size-large">
                                    20.
                                </span>
                                <span
                                    className="font-weight-bold text-uppercase text-uppercase">Electronic Delivery</span>
                            </p>
                            <p className={'text-gray-800'}>
                                Client agrees to the delivery by email and posting to the Clarivor Website
                                (www.clarivor.com) of all notices and documents required to be delivered by Clarivor
                                under this Agreement. Client agrees to periodically check Clarivor website for such
                                notices and documents. </p>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}


