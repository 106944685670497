import React, {useContext, useEffect, useState} from "react"
import headshot from 'images/blog/avatar-100.jpg'
import RequestDemoModal from "../../../pages/common/RequestDemoModal"
import {globalAuthManager, globalServices} from "../../../../services/GlobalServices"
import {apiGet} from "../../../../services/api-call-helpers"
import * as keys from 'keys'
// import {mixpanel} from "../../../../services/mixpanel"
import ReceiveUpdatesModal from "../../../pages/common/ReceiveUpdatesModal"
import {Link} from "react-router-dom"
import BlogSignupSection from "../../common/BlogSignupSection"

import BannerImage from 'images/blog/Blog_20220120_TLTHedges/FederalReserve2022-02-25.jpg'
import BannerImage500 from 'images/blog/Blog_20220120_TLTHedges/FederalReserve2022-02-25_500.jpg'

import putPayoff from 'images/blog/Blog_20220120_TLTHedges/PutPayoff.png'
import putPrice from 'images/blog/Blog_20220120_TLTHedges/TLT6m90PutPrice.png'
import putSpreadPayoff from 'images/blog/Blog_20220120_TLTHedges/PutSpreadPayoff.png'
import putSpreadPrice from 'images/blog/Blog_20220120_TLTHedges/TLT6m90-80PutSpreadPrice.png'
import collarPayoff from 'images/blog/Blog_20220120_TLTHedges/CollarPayoff.png'
import collarPrice from 'images/blog/Blog_20220120_TLTHedges/TLT6m90CollarStrike.png'
import pscPayoff from 'images/blog/Blog_20220120_TLTHedges/PSCPayoff.png'
import pscPrice from 'images/blog/Blog_20220120_TLTHedges/TLT6m95-80PutSpreadCollarStrike.png'

import prosAndCons from 'images/blog/Blog_20220120_TLTHedges/ProsAndCons.png'

import jonAvatar from 'images/blog/avatar-100.jpg'

const TLTOverwriteContext = React.createContext({})

const documentTitle = "Clarivor Blog"
const postTitle = 'Hedging Rates with TLT Options'
const postSubtitle = 'Structuring custom hedges for cost efficiency'
const postAuthor = 'Jon Spiegel, CFA'
const publishedOnText = 'January 27, 2022'
const publishedOnShort = 'Jan 27'
export const path = 'tlt-hedges-20220127'

const HeroSection = (props) => (
    <section
        style={{
            backgroundImage: ["url(" + BannerImage + ")"],
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            position: 'relative',
            zIndex: 0,
            backgroundSize: 'cover',
            height: '35vh'
        }}>
    </section>
)

const HeaderSection = (props) => (
    <section className="pt-8 pt-md-11">
        <RequestDemoModal/>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 col-md-12 col-lg-10 col-xl-10">


                    <h1 className="display-4 text-center">
                        {props.title}
                    </h1>


                    {/*<p className="lead mb-7 text-center text-muted">*/}
                    <p className="lead px-10 mb-7 text-center text-muted">
                        {props.subtitle}
                    </p>


                    <div className="row align-items-center py-5 border-top border-bottom">
                        <div className="col-auto">


                            <div className="avatar avatar-lg">
                                <img src={props.avatarImage} alt="..."
                                     className="avatar-img rounded-circle"/>
                            </div>

                        </div>
                        <div className="col ml-n5">
                            <h6 className="text-uppercase mb-0">
                                {props.author}
                            </h6>
                        </div>
                        <div className="col-auto">
                            <time className="font-size-sm text-muted" dateTime="2019-05-20">
                                {props.publishedOnText}
                            </time>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
)


function Summary(props) {
    return (
        <React.Fragment>
            <h2 className={'font-weight-bold mt-6'}>
                Summary
            </h2>
            <ul>
                <li className='my-2'>
                    As the Fed considers reducing the size of its balance sheet in 2022, a rise in U.S. interest rates
                    could put pressure on asset prices and create significant challenges for investment portfolios.
                </li>
                <li className='my-2'>
                    As a result, some investment managers may want to hedge interest rates directly, using options on
                    the TLT ETF.
                </li>
                <li className='my-2'>
                    While buying puts on TLT may be expensive currently, investment managers might look to
                    structure hedges more efficiently using multi-leg option strategies, while taking into account
                    specific portfolio risks.
                </li>
            </ul>
        </React.Fragment>
    )
}


function Section1(props) {
    const putRef = React.createRef()
    const psRef = React.createRef()
    const collarRef = React.createRef()
    const pscRef = React.createRef()


    return (
        <React.Fragment>
            <h2 className={'font-weight-bold mt-6'}>
                Overview
            </h2>
            <p>
                This month the 10-year treasury rate exceeded 1.8% for the first time since January 2020, as Fed Chair
                Jerome Powell called inflation a "severe threat" in testimony during his Senate confirmation hearing.
                The FOMC press conference yesterday confirmed that the Fed is looking to normalize monetary conditions.
                With the Fed likely to raise rates, end its bond purchasing, and consider reducing the size of
                its balance sheet, higher rates are a continued risk for 2022.
            </p>
            <p>
                Higher interest rates could create a head-wind for many assets that may have benefited from
                accommodative monetary policy in recent
                years. To manage the risk of a rate sell-off, in addition to adjusting asset allocations, some
                investment managers may wish to hedge the potential for higher rates directly.
            </p>
            <p>
                Options on the TLT ETF are accessible instruments that offer relatively direct exposure to
                rates. Since the ETF tracks an index of long-term treasury bonds, the price of the ETF is sensitive to
                changes in
                U.S. interest rates. Therefore put options on TLT can pay off when rates rise (bonds prices fall), and
                call options can pay off when rates fall (bond prices rise).
            </p>
            <p>
                As the market currently prices in a relatively elevated level of volatility for TLT, put option prices
                may be higher than investors would budget for. To cheapen the cost of hedging, investors could cap the
                protection purchased (use a put-spread) or sell calls to pay for the puts (use a collar).
            </p>
            <p>
                The most appropriate hedge for a given portfolio may depend on the investor's preferences and current
                positioning:
            </p>
            <ul>
                <li>Investors willing to pay for tail risk protection against a rise in rates may prefer to
                    buy <Link to='#' onClick={() => putRef.current.scrollIntoView({behavior: "smooth"})}>put options
                        outright</Link>.
                </li>
                <li>Investors willing to pay for protection against moderately higher rates may prefer to
                    buy <Link to="#"
                              onClick={() => psRef.current.scrollIntoView({behavior: "smooth"})}>put-spreads</Link>.
                </li>
                <li>Investors willing take a hedging loss if rates drop significantly, in exchange for tail risk
                    protection,
                    may prefer to use a <Link to="#"
                                              onClick={() => collarRef.current.scrollIntoView({behavior: "smooth"})}>collar
                        on TLT</Link>.
                </li>
                <li>Investors willing to take a hedging loss if rates drop significantly, in exchange for protection
                    against a
                    moderate rise in rates, may prefer to use a <Link to="#"
                                                                      onClick={() => pscRef.current.scrollIntoView({behavior: "smooth"})}>put-spread-collar
                        on TLT</Link>.
                </li>
            </ul>

            <p>
                The following sections discuss more details about these strategies.
            </p>

            <h2 className={'font-weight-bold mt-6'} ref={putRef}>
                1. TLT Put
            </h2>
            <p>
                Buying a TLT put option provides insurance against the price of TLT falling below the put's specified
                strike price prior to expiration. In many cases, to keep the cost of a put low, investors may want to
                buy a put that is
                significantly out-of-the-money, such that the protection only takes effect after TLT has sold off by a
                certain amount, for example 10%.
            </p>

            <div className='card mx-auto my-6' style={{maxWidth: '90%'}}>
                <div className='card-body p-2  bg-white'>
                    <h3 className='text-center'>
                        Put Payoff
                    </h3>
                    <img src={putPayoff} className='img-fluid'/>
                </div>
            </div>

            <p>
                One potential challenge of buying put options is that the market may charge a premium for the insurance
                provided. When volatility increases, as it has recently for TLT, the premium may increase.
                As of Jan 26th, the price of a TLT 6-month put, struck at 90% of the current price of TLT, is higher
                than
                it has been approximately 72% of the time over the past 10 years.
            </p>
            <div className='card mx-auto my-6' style={{maxWidth: '90%'}}>
                <div className='card-body p-2  bg-white'>
                    <h3 className='text-center'>
                        Historical TLT Put Price
                    </h3>
                    <img src={putPrice} className='img-fluid'/>
                </div>
            </div>

            <h2 className={'font-weight-bold mt-6'} ref={psRef}>
                2. TLT Put Spread
            </h2>
            <p>
                A put spread combines the purchase of a put with the sale of another put, typically with the same
                maturity and lower strike, which caps the protection provided. An investment manager might prefer a
                put spread over a put to either (1) reduce the net price paid for the hedge or (2) receive protection
                that is closer to the money, for a similar price.
            </p>
            <div className='card mx-auto my-6' style={{maxWidth: '90%'}}>
                <div className='card-body p-2  bg-white'>
                    <h3 className='text-center'>
                        Put Spread Payoff
                    </h3>
                    <img className='img-fluid' src={putSpreadPayoff}/>
                </div>
            </div>
            <p>
                While a TLT put spread, like a put, provides insurance on the price of TLT and can
                expire with no value, a put spread can be more efficient in terms of premium spent. Since the put spread
                is both long and short individual put legs, the net price may reflect less risk premium, compared with
                the price of an individual put. The efficiency of the put spread may also benefit if the
                short strike is priced with a higher risk premium than the long strike.
            </p>
            <p>
                As of Jan 26th, the price of a TLT 6-month 90% - 80% put spread is higher than it has been approximately
                55% of the time over the past 10 years.
            </p>
            <div className='card mx-auto my-6' style={{maxWidth: '90%'}}>
                <h3 className='text-center'>
                    Historical TLT Put Spread Price
                </h3>
                <div className='card-body p-2  bg-white'>
                    <img className='img-fluid' src={putSpreadPrice}/>
                </div>
            </div>

            <h2 className={'font-weight-bold mt-6'} ref={collarRef}>
                3. TLT Collar
            </h2>
            <p>
                A collar typically involves buying an out-of-the-money put, combined with selling an out-of-the-money
                call.
                Collars can be structured so that the net price is close to zero. While there may be no upfront cost
                to buying a collar, the strategy will typically incur losses when the call expires in the money.
            </p>
            <p>
                Selling TLT calls can result in a liability if rates decline. Therefore, investors should size collars
                such that losses on the call option are manageable. A collar of significant size may be
                better suited to portfolios that have assets, like long-dated bonds, that are very likely to
                rise in value should rates decline.
            </p>
            <div className='card mx-auto my-6' style={{maxWidth: '90%'}}>
                <div className='card-body p-2  bg-white'>
                    <h3 className='text-center'>
                        Collar Payoff
                    </h3>
                    <img className='img-fluid' src={collarPayoff}/>
                </div>
            </div>
            <p>
                As a traditional collar provides tail risk protection against a bond sell-off in exchange for giving up
                gains, the market may charge a significant risk premium for this strategy. However this premium
                can fluctuate as the market shifts from charging a higher premium for hedging against higher or lower
                rates.
            </p>
            <p>
                When a collar strategy is structured to have zero upfront cost, the price of the strategy may be
                expressed as the strike of the call that needs to be sold
                for a given level of protection. For example, to buy a TLT 6-month put with strike of 10% out of the
                money, as of Jan 26th an investor would need to sell a TLT 6-month call approximately 8.9%
                out-of-the-money.
                This call-strike is higher that it has been approximately 60% of the time over the past
                10 years, which may be attractive despite a recently higher risk premium for higher rates.
            </p>
            <div className='card mx-auto my-6' style={{maxWidth: '90%'}}>
                <h3 className='text-center'>
                    Historical TLT Collar Call Strike (Zero Net Cost)
                </h3>
                <div className='card-body p-2  bg-white'>
                    <img className='img-fluid' src={collarPrice}/>
                </div>
            </div>

            <h2 className={'font-weight-bold mt-6'} ref={pscRef}>
                4. TLT Put Spread Collar
            </h2>
            <p>
                By adding a short out-of-the-money put to the collar, the put-spread-collar (PSC) combines some of the
                features of the put-spread and the collar. Like the collar, the PSC can have zero
                cost at inception. However, the PSC can incorporate protection against more moderate
                declines in rates, or provide greater upside (higher call-strike) by cheapening the protection.
            </p>
            <p>
                Like the collar, the PSCs should be sized such that the potential liability on the short-call
                is manageable for the relevant portfolio.
            </p>
            <div className='card mx-auto my-6' style={{maxWidth: '90%'}}>
                <h3 className='text-center'>
                    Put Spread Collar Payoff
                </h3>
                <div className='card-body p-2  bg-white'>
                    <img className='img-fluid' src={pscPayoff}/>
                </div>
            </div>
            <p>
                Of the four strategies discussed here, the PSC may be most likely receive a
                benefit in pricing when the market prices in more volatility. Since the PSC sells two
                option legs and buys
                one, the strategy is net short options. Since option prices tend to rise when the market prices in
                higher volatility,
                the pricing of the PSC can become more attractive in times of higher volatility.
            </p>
            <p>
                Since the PSC may be structured to have zero upfront cost, the price may be expressed
                as the strike of the call required to buy a put-spread of given strikes. For example, as of Jan 26th
                a TLT 6-month 95%-80% put-spread may be purchased by selling a call approximately 4.6% out of the money,
                providing more upside than approximately 70% of the time over the past 10 years.
            </p>
            <div className='card mx-auto my-6' style={{maxWidth: '90%'}}>
                <h3 className='text-center'>
                    Historical TLT Put Spread Collar Call Strike
                </h3>
                <div className='card-body p-2  bg-white'>
                    <img className='img-fluid' src={pscPrice}/>
                </div>
            </div>

            <h2 className={'font-weight-bold mt-6'}>
                Conclusion
            </h2>
            <p>
                TLT options are accessible and flexible instruments that investment managers can use to manage
                interest-rate risk in 2022. Depending on investor goals, and their existing portfolios, managers
                should use different strategies. Below is a summary of the concepts discussed.
            </p>
            <div className='card mx-auto my-6' style={{maxWidth: '90%'}}>
                <div className='card-body p-2  bg-white'>
                    <img className='img-fluid' src={prosAndCons}/>
                </div>
            </div>

            <h2 className={'font-weight-bold mt-6'}>
                Other TLT option strategies:
            </h2>
            <p>
                See the following posts for more of Clarivor's work on TLT option strategies.
            </p>
            <ul>
                <li>
                    <Link to='/blog/selling-tlt-puts-20211104'>Selling Puts on TLT (2021-11-04)</Link>
                </li>
                <li>
                    <Link to='/blog/TLT_Overwriting_20210413'>Getting Paid More to Own Treasuries (2021-04-13)</Link>
                </li>
            </ul>
        </React.Fragment>
    )
}

function BufferPerformanceProjectContainer(props) {
    const [projectObj, setProjectObj] = useState(null)

    useEffect(() => {
        apiGet(keys.API_ENDPOINT.PROJECTS, {id: props.projectId}, globalAuthManager).then(
            res => {
                const [project] = res.data
                setProjectObj(project)
            }
        )
    }, [])

    const contextValue = {
        projectObj: projectObj,
        projectId: props.projectId
    }

    return (
        <TLTOverwriteContext.Provider value={contextValue}>
            {props.children}
        </TLTOverwriteContext.Provider>
    )
}


function DisclosuresLink(props) {
    return (
        <a data-toggle="modal" href="#disclaimerModal"
           onClick={() => null}>Disclosures</a>
// onClick={() => mixpanel.track('Disclosure Clicked')}>Disclosures</a>
    )
}

function TermsOfUseLink(props) {
    return (
        <Link to={'/terms-of-use'} target={'_blank'}
              onClick={() => null}>Terms of Use</Link>
// onClick={() => mixpanel.track('Terms of Use Clicked')}>Terms of Use</Link>
    )
}

function BacktestingSourceDisclaimer(props) {
    return (
        <div>
            <hr/>
            <p className='font-italic text-muted'>
                Source: Clarivor LLC. These results are back-tested and may not be indicative of future performance.
                Percent strikes and expirations expressed in months are interpolated from listed options.
                {/*All buffers above refer to a SPY 12-month buffer traded on 12/18/2021, providing 10% downside*/}
                {/*protection, collateralized with cash.*/}
                {/*Options can entail complex risks and may not be suitable for all investors.<br/>*/}
                {/*Each option strategy back-test above includes an annual deduction of 0.49% to reflect management fees*/}
                {/*and commissions. SPY (TR) and AGG (TR) have dividends immediately reinvested, shown with no additional*/}
                {/*management fee.*/}
                {/*Strikes are selected from the listed market.*/}
                {/*Options are rolled seven days prior to expiration at bid or offer.*/}
                Option prices are observed at 3:45pm NY time.
                <br/>
                <br/>
                NOTHING IN THIS POST SHOULD BE CONSTRUED AS INVESTMENT ADVICE. <br/>
                <DisclosuresLink/> and <TermsOfUseLink/> apply.
                <br/>
            </p>
        </div>
    )
}


function PostBody(props) {
    return (
        <React.Fragment>
            <section className="pt-0 pt-md-0">
                <ReceiveUpdatesModal/>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-12 col-lg-10 col-xl-9">
                            <Summary/>
                            <Section1/>
                            <BacktestingSourceDisclaimer/>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}


export default function BufferPerformance_20210803(props) {
    const projectId = null  // this is a static blog post

    useEffect(() => {
        document.title = documentTitle
    }, [])

    const headerProps = {
        title: postTitle,
        subtitle: postSubtitle,
        avatarImage: headshot,
        author: postAuthor,
        publishedOnText: publishedOnShort,
    }

    return (
        <React.Fragment>
            <HeroSection/>
            <HeaderSection {...headerProps} />
            <BufferPerformanceProjectContainer projectId={projectId}>
                <PostBody/>
            </BufferPerformanceProjectContainer>
            <BlogSignupSection fromLocation={'Blog Post TLT Overwriting'}/>
        </React.Fragment>
    )
}


export function RichTLTHedges20220120(props) {
    return (
        <section className="pt-7 pt-md-10">
            <div className="container">
                <div className="row">
                    <div className="col-12">

                        <div className="card card-row shadow-light-lg mb-6 lift lift-lg">
                            <div className="row no-gutters">
                                <div className="col-12">

<span className="badge badge-pill badge-light badge-float badge-float-inside">
<span className="h6 text-uppercase">rates</span>
</span>
                                </div>
                                <Link className="col-12 col-md-6 order-md-2 bg-cover card-img-right"
                                      style={{backgroundImage: "url(" + BannerImage500 + ")"}}
                                      to={props.blogPath + path}>

                                    <img src="assets/img/photos/photo-27.jpg" alt="..."
                                         className="img-fluid d-md-none invisible"/>

                                    <div
                                        className="shape shape-left shape-fluid-y svg-shim text-white d-none d-md-block">
                                        <svg viewBox="0 0 112 690" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 0h62.759v172C38.62 384 112 517 112 517v173H0V0z"
                                                  fill="currentColor"></path>
                                        </svg>
                                    </div>

                                </Link>
                                <div className="col-12 col-md-6 order-md-1">

                                    <Link className="card-body" to={props.blogPath + path}>

                                        <h3>
                                            {postTitle}
                                        </h3>

                                        <p className="mb-0 text-muted">
                                            {postSubtitle}
                                        </p>

                                    </Link>

                                    <a className="card-meta" href="#!">

                                        <hr className="card-meta-divider"/>

                                        <div className="avatar avatar-sm mr-2">
                                            <img src={jonAvatar} alt="..."
                                                 className="avatar-img rounded-circle"/>
                                        </div>

                                        <h6 className="text-uppercase text-muted mr-2 mb-0">
                                            Jon Spiegel
                                        </h6>

                                        <p className="h6 text-uppercase text-muted mb-0 ml-auto">
                                            <time dateTime="2020-10-23">{publishedOnShort}</time>
                                        </p>

                                    </a>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>

    )
}
