import {nominalTypeHack} from "prop-types";
import axios from 'axios'


export default class IndicatorStatsManager {
    constructor(api_url, auth) {
        this.url = api_url
        this.indicatorStatsObjs = null
        this.indexIndicatorId = null
    }

    setIndices() {
        console.log('setindices', this.indicatorStatsObjs)
        let n = this.indicatorStatsObjs.length;

        let dict2 = {};
        for (let i = 0; i < n; i++) {
            dict2[this.indicatorStatsObjs[i].id] = i;
        }
        this.indexIndicatorId = dict2
        console.log(this.indexIndicatorId)
    }

    async loadAsync(authManager) {
        const config = {
            headers: {'Authorization': "Bearer " + authManager.accessToken}
        };

        try {
            let res = await axios.get(this.url, config)

            const statobjs = res.data

            this.indicatorStatsObjs = []
            for (let i = 0; i < statobjs.length; i++) {
                let statsData = statobjs[i].stats
                if (typeof statsData == 'string' || statsData instanceof String) {
                    statsData = JSON.parse(statsData)
                }

                let statobj = {
                    name: statobjs[i].name,
                    stats: statsData,
                    id: statobjs[i].user_indicator,
                    user_indicator: statobjs[i].user_indicator,
                    indicator_class: statobjs[i].indicator_class,
                    params: statobjs[i].params
                }
                this.indicatorStatsObjs.push(statobj)
            }

            this.setIndices()
        } catch (e) {
            console.error(e)
        }

        return this.indicatorStatsObjs
    }

    refreshFromServer(callback, authManager) {
        const config = {
            headers: {'Authorization': "Bearer " + authManager.accessToken}
        };
        axios.get(this.url, config)
            .then(res => {
                    const statobjs = res.data

                    console.log('refreshed stats', res.data)

                    this.indicatorStatsObjs = []
                    for (let i = 0; i < statobjs.length; i++) {
                        let statsData = statobjs[i].stats
                        if (typeof statsData == 'string' || statsData instanceof String) {
                            statsData = JSON.parse(statsData)
                        }

                        let statobj = {
                            name: statobjs[i].name,
                            stats: statsData,
                            id: statobjs[i].user_indicator,
                            user_indicator: statobjs[i].user_indicator
                        }
                        this.indicatorStatsObjs.push(statobj)
                    }

                    this.setIndices()

                    callback(this)
                }
            );
    }

    getById(id) {
        // console.log(this.indexIndicatorId)
        return this.indicatorStatsObjs[this.indexIndicatorId[id]]
    }




    // static bestPricingUnderliers(bestPricingLists, maxUnderliers=3) {
    //     const maxLength = 0
    //     let tickers = []
    //     for (let iCol = 0; iCol < bestPricingLists.length; iCol++) {
    //          for (let iRow = 0; iRow < bestPricingLists[iCol].length; iRow++) {
    //             const screenItem = bestPricingLists[iCol][iRow]
    //             let index = tickers.findIndex(ticker => ticker === screenItem.userStrategyObj.params.ticker)
    //             if (index < 0) {
    //                 tickers.push(screenItem.userStrategyObj.params.ticker)
    //                 if (tickers.length === maxLength) {
    //                     return tickers
    //                 }
    //             }
    //         }
    //     }
    //     return tickers
    // }
    static compareScreenObjs(screenObj1, screenObj2) {
        const score1 = screenObj1.percentile * screenObj1.indicatorSign
        const score2 = screenObj2.percentile * screenObj2.indicatorSign

        if (score1 > score2) {
            return 1
        } else {
            return -1
        }
    }

    static bestPricingUnderliers(bestPricingLists, maxUnderliers = 3) {
        const maxLength = 0
        let tickers = []
        const nRows = Math.max(...bestPricingLists.map(bpl => bpl.length))
        for (let iRow = 0; iRow < nRows; iRow++) {
            let rowObjs = []
            // get all the objects in this row.
            for (let iCol = 0; iCol < bestPricingLists.length; iCol++) {
                if (iRow < bestPricingLists[iCol].length) {
                    rowObjs.push(bestPricingLists[iCol][iRow])
                }
            }
            // if enough space, use all the tickers in this row
            if (maxUnderliers - tickers.length > rowObjs.length) {
                tickers.push(...rowObjs.map(rowObj => rowObj.userStrategyObj.params.ticker))
                if (tickers.length == maxUnderliers) {
                    return tickers
                }
            } else {
                // otherwise choose the top scoring.
                rowObjs.sort(obj => (.5 - Math.random()))  // bad sorting algo but ok for this
                console.log(rowObjs)
                for (let iRowObj = 0; iRowObj < rowObjs.length; iRowObj++) {
                    tickers.push(rowObjs[iRowObj].userStrategyObj.params.ticker)
                    if (tickers.length == maxUnderliers) {
                        return tickers
                    }
                }
            }
        }
    }
}

