function erf(x) {
    // save the sign of x
    var sign = (x >= 0) ? 1 : -1;
    x = Math.abs(x);

    // constants
    var a1 =  0.254829592;
    var a2 = -0.284496736;
    var a3 =  1.421413741;
    var a4 = -1.453152027;
    var a5 =  1.061405429;
    var p  =  0.3275911;

    // A&S formula 7.1.26
    var t = 1.0/(1.0 + p*x);
    var y = 1.0 - (((((a5 * t + a4) * t) + a3) * t + a2) * t + a1) * t * Math.exp(-x * x);
    return sign * y; // erf(-x) = -erf(x);
}

function cdf(x, mean, variance) {
    return 0.5 * (1 + erf((x - mean) / (Math.sqrt(2 * variance))));
  }
  

export function callPrice(fwd, strike, t, vol, df) {
    const d1 = ( Math.log(fwd/strike) - Math.pow(vol, 2) * t/2 ) / ( vol * Math.sqrt(t))
    const d2 = d1 - vol*Math.sqrt(t)

    const nd1 = cdf(d1, 0, 1)
    const nd2 = cdf(d2, 0, 1)

    const call = df*( fwd*nd1 - strike*nd2 )

    return call
}

export function putPrice(fwd, strike, t, vol, df) {
    const d1 = ( Math.log(fwd/strike) - Math.pow(vol, 2) * t/2 ) / ( vol * Math.sqrt(t))
    const d2 = d1 - vol*Math.sqrt(t)

    const nnd1 = cdf(-d1, 0, 1)
    const nnd2 = cdf(-d2, 0, 1)

    const put = df*( strike*nnd2 - fwd*nnd1)

    return put
}


function calcImpliedVol(px, fwd, strike, t, df) {
    
}