import React, {useContext, useEffect, useState} from "react"
import headshot from 'images/blog/avatar-100.jpg'
import RequestDemoModal from "../../../pages/common/RequestDemoModal"
import {mixpanel} from "../../../../services/mixpanel"
import ReceiveUpdatesModal from "../../../pages/common/ReceiveUpdatesModal"
import {Link} from "react-router-dom"
import BlogSignupSection from "../../common/BlogSignupSection";
import rocketImage500 from "../../../../images/blog/AYearSinceCovid-20210216/john-baker-rocket-500.jpg"
import heroImage from "../../../../images/blog/AYearSinceCovid-20210216/john-baker-rocket.jpg"
import jonAvatar from 'images/blog/avatar-100.jpg'
import performanceImage from "../../../../images/blog/AYearSinceCovid-20210216/performance.png"

const IncomePostContext = React.createContext({})

const POST_TITLE = 'A Year Since Pre-COVID'
const POST_SUBTITLE = 'A when equity markets were at their pre-COVID peak, who would have thought small-caps would be up another 35% a year later?'
const POST_PATH = "/blog/AYearSincePreCovid_20210216"

export function RichViewOneYearSincePreCovid(props) {
    return (
        <section className="pt-7 pt-md-10">
            <div className="container">
                <div className="row">
                    <div className="col-12">

                        <div className="card card-row shadow-light-lg mb-6 lift lift-lg">
                            <div className="row no-gutters">
                                <div className="col-12">

                            <span className="badge badge-pill badge-light badge-float badge-float-inside">
                    <span className="h6 text-uppercase">Equities</span>
                  </span>

                                </div>
                                <Link className="col-12 col-md-6 order-md-2 bg-cover card-img-right"
                                      style={{backgroundImage: "url(" + rocketImage500 + ")"}}
                                      to={POST_PATH}>

                                    {/*<img src="assets/img/photos/photo-27.jpg" alt="..."*/}
                                    {/*     className="img-fluid d-md-none invisible"/>*/}

                                    <div
                                        className="shape shape-left shape-fluid-y svg-shim text-white d-none d-md-block">
                                        <svg viewBox="0 0 112 690" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 0h62.759v172C38.62 384 112 517 112 517v173H0V0z"
                                                  fill="currentColor"></path>
                                        </svg>
                                    </div>

                                </Link>
                                <div className="col-12 col-md-6 order-md-1">

                                    <Link className="card-body" to={POST_PATH}>

                                        <h3>
                                            {POST_TITLE}
                                        </h3>

                                        <p className="mb-0 text-muted">
                                            {POST_SUBTITLE}
                                        </p>

                                    </Link>

                                    <a className="card-meta" href="#!">

                                        <hr className="card-meta-divider"/>

                                        <div className="avatar avatar-sm mr-2">
                                            <img src={jonAvatar} alt="..."
                                                 className="avatar-img rounded-circle"/>
                                        </div>

                                        <h6 className="text-uppercase text-muted mr-2 mb-0">
                                            Jon Spiegel
                                        </h6>

                                        <p className="h6 text-uppercase text-muted mb-0 ml-auto">
                                            <time dateTime="2020-12-09">Feb 16</time>
                                        </p>

                                    </a>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>

    )
}


const HeroSection = (props) => (
    <section
        style={{
            backgroundImage: ["url(" + heroImage + ")"],
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            position: 'relative',
            zIndex: 0,
            backgroundSize: 'cover',
            height: '50vh'
        }}>
    </section>
)

const HeaderSection = (props) => (
    <section className="pt-8 pt-md-11">
        <RequestDemoModal/>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 col-md-12 col-lg-10 col-xl-10">


                    <h1 className="display-4 text-center">
                        {props.title}
                    </h1>


                    <p className="lead mb-7 text-center text-muted">
                        {props.subtitle}
                    </p>


                    <div className="row align-items-center py-5 border-top border-bottom">
                        <div className="col-auto">


                            <div className="avatar avatar-lg">
                                <img src={props.avatarImage} alt="..."
                                     className="avatar-img rounded-circle"/>
                            </div>

                        </div>
                        <div className="col ml-n5">
                            <h6 className="text-uppercase mb-0">
                                {props.author}
                            </h6>
                        </div>
                        <div className="col-auto">
                            <time className="font-size-sm text-muted" dateTime="2019-05-20">
                                {props.publishedOnText}
                            </time>
                        </div>
                    </div>

                </div>
            </div>

        </div>

    </section>
)


const beaLink = "https://www.bea.gov/news/2020/gross-domestic-product-state-2nd-quarter-2020"


function Section1(props) {
    return (
        <React.Fragment>
            {/*<h3 className={'font-weight-bold mt-6'}>*/}
            {/*    Low rates and high asset prices*/}
            {/*</h3>*/}
            <p>
                This week marks a year since the pre-COVID peak in U.S. equity markets. In the following seven weeks
                stocks plunged in a historically swift sell-off.
            </p>

            {/*<h3 className={'font-weight-bold mt-6'}>*/}
            {/*    Option income is atypically high*/}
            {/*</h3>*/}
            <ul>
                <li className='my-2'>U.S. large-cap (SPY) suffered a drawdown of over 33%.
                </li>
                <li className='my-2'>
                    Large-cap tech (QQQ) pulled back less, with a 28% drawdown.
                </li>
                <li className='my-2'>
                    Small-caps (IWM) had a higher drawdown of over 40%.
                </li>
            </ul>

            <p>
                Yet here we are a year later after a tremendous rally. Of the three ETFs listed above, QQQ has been the
                largest gainer (+44%). While IWM had sold off the deepest in the early days of the pandemic, its
                outperformance since early November has been notable.
            </p>
            <p>
                As our <Link to={'/tools/income-monitor'}>income monitor</Link> shows, income available from options
                remains elevated. While this is very rare with the market at its high, the option market appears to be
                priced for a further rally. Skeptical investors could benefit from selling call options for income or
                hedging. For more information about
                implementing option strategies <a className='mx-auto' data-toggle="modal" href="#requestDemoModal">contact
                us for a demo</a>.
            </p>
            <div className='card bg-light'>
                <div className='card-body'>
                    <img src={performanceImage} style={{width: '100%'}}/>
                </div>
            </div>
        </React.Fragment>
    )
}


function RequestDemoSection(props) {
    return (
        <section>
            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-12 col-md-8 col-lg-6'>
                        <div className='card border border-primary'>
                            <div className='card-body'>
                                <div className='row justify-content-center'>
                                    <h3 className={'text-center'}>
                                        Get in touch with Clarivor for more analysis on income and other option
                                        strategies
                                    </h3>
                                </div>
                                <div className='row justify-content-center'>
                                    <a className='mx-auto' data-toggle="modal" href="#requestDemoModal"
                                       style={{textDecoration: 'none'}}
                                       onClick={() => {
                                           mixpanel.track('Request Demo clicked')
                                       }}>
                                        <button className="btn btn-success lift mt-2 d-flex">
                                            <span className={'my-auto'}>Request Demo</span>
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

function DisclosuresLink(props) {
    return (
        <a data-toggle="modal" href="#disclaimerModal"
           onClick={() => mixpanel.track('Disclosure Clicked')}>Disclosures</a>
    )
}

function TermsOfUseLink(props) {
    return (
        <Link to={'/terms-of-use'} target={'_blank'}
              onClick={() => mixpanel.track('Terms of Use Clicked')}>Terms of Use</Link>
    )
}

function BacktestingSourceDisclaimer(props) {
    return (
        <div className='card'>
            <div className='card-body font-italic text-muted'>
                Source: Clarivor LLC. Any applicable fees, commissions or taxes are not reflected.
                These results are back-tested and may not be indicative of future performance. Option strategies can
                pose risk of material loss.
                NOTHING IN THIS POST SHOULD BE CONSIDERED INVESTMENT
                ADVICE. <DisclosuresLink/> and <TermsOfUseLink/> apply.
            </div>
        </div>
    )
}

function PostBody(props) {
    return (
        <React.Fragment>
            <section className="pt-6 pt-md-8">
                <ReceiveUpdatesModal/>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-12 col-lg-10 col-xl-9">
                            {/*<Summary/>*/}
                            <Section1/>
                            {/*<UsingABuyWrite/>*/}
                            {/*<MaturitySelection/>*/}
                            <BacktestingSourceDisclaimer/>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}


export default function AYearSincePreCovid(props) {
    const projectId = 'd75fdb17-c532-432c-b950-b4e009fd4fde'

    useEffect(() => {
        document.title = "Clarivor Blog: " + POST_TITLE
    }, [])

    const headerProps = {
        title: POST_TITLE,
        subtitle: POST_SUBTITLE,
        avatarImage: headshot,
        author: 'Jon Spiegel, CFA',
        publishedOnText: 'February 16, 2021',
    }

    return (
        <React.Fragment>
            <HeroSection/>
            <HeaderSection {...headerProps} />
            {/*<ProjectContainer projectId={projectId}>*/}
            <PostBody/>
            {/*</ProjectContainer>*/}
            {/*<RequestDemoSection/>*/}
            <BlogSignupSection fromLocation={'Blog Post 20210216'}/>
        </React.Fragment>
    )
}

