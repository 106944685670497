import React, {Component} from 'react'
import {Link} from 'react-router-dom'

import NavBar from "../../common/NavBarDefault"
import Footer from 'components/common/Footer'
import BreadcrumbNav from "../../common/BreadcrumbNav"

// import 'lkcss'
// import lk from 'dk/src/assets/css/theme.module.css'
// import {mapClasses} from 'util/cssModule'


export default class PrivacyPolicy extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                <NavBar {...this.props}/>
                <BreadcrumbNav pathList={['Legal', 'Privacy Policy']}/>
                <PrivacyPolicyBody/>
                <Footer/>
            </div>
        )
    }
}


function PrivacyPolicyBody() {
    return (
        <section className="pt-8 pt-md-11 pb-8 pb-md-14 bg-light">
            <div className="container" style={{maxWidth: '760px'}}>

                <div className="row align-items-center">
                    <div className="col-12 col-md">

                        <h1 className="display-4 mb-2">
                            Privacy Policy
                        </h1>

                        <p className="font-size-lg text-gray-700 mb-md-0">
                            Last Revised 2021-04-26
                        </p>

                    </div>
                    <div className="col-auto">

                        {/* <a href="#!" className="btn btn-primary-soft">
              Print
            </a> */}

                    </div>
                </div>
                <div className="row">
                    <div className="col-12">

                        <hr className="my-4 my-md-6"/>

                    </div>
                </div>
                <div className="row">
                    <div className="col-12">

                        <p className="text-gray-800">
                            Welcome to the Privacy Policy for Clarivor LLC (“<span
                            className="font-weight-bold">Clarivor</span>,” “<span className="font-weight-bold">we</span>"
                            or “<span className="font-weight-bold">us</span>"). This Privacy Policy describes how we
                            collect, use and disclose information from our users, including with respect to the Clarivor
                            website, content, applications, services, tools and features located at www.clarivor.com, as
                            well as investment advisory services provided by Clarivor (collectively, the “<span
                            className="font-weight-bold">Services</span>”).
                            For purposes of this Privacy Policy, “<span className="font-weight-bold">you</span>” and
                            “<span
                            className="font-weight-bold">your</span>” means you as the user of the Services. If you use
                            the Services on behalf of a company or other entity then "you" includes you and that entity,
                            and you represent and warrant that (a) you are an authorized representative of the entity
                            with the authority to bind the entity to these Terms, and (b) you agree to these Terms on
                            the entity's behalf. </p>
                        <p className="text-gray-800">
                            The Services can only be accessed and used subject to the Clarivor Terms of Use and this
                            Privacy Policy. PLEASE READ THIS PRIVACY POLICY CAREFULLY. BY USING, ACCESSING, OR
                            DOWNLOADING ANY OF THE SERVICES, YOU AGREE THAT YOU ARE BOUND BY THE CLARIVOR TERMS OF
                            SERVICE AND PRIVACY POLICY. IF YOU DO NOT AGREE TO OUR TERMS OF USE AND PRIVACY POLICY,
                            THEN YOU MAY NOT ACCESS AND USE THE SERVICES.
                        </p>

                        <p className="text-gray-800 mt-4 mt-md-6">
            <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4 font-weight-bold font-size-large">
                1.
            </span>
                            <span className="font-weight-bold">TYPES OF INFORMATION WE COLLECT</span>
                        </p>

                        <p className="text-gray-800">
                            We may collect the following categories of information from you (“<span
                            className="font-weight-bold">Information</span>”), which includes:
                        </p>

                        <ul>

                            <li><p className="text-gray-800">
                                Information that identifies (whether directly or indirectly) a particular individual,
                                such as the individual’s name, postal address, email address and telephone number
                                (“<span className="font-weight-bold">Personal Information</span>”). Personal Information
                                includes registration information you provide when you create an account, such as your
                                name, email address, username and password. </p>
                            </li>

                            <li>
                                <p className="text-gray-800">
                                    Information that does not directly or indirectly identify, and cannot
                                    reasonably be used to identify, an individual user (“<span
                                    className="font-weight-bold">Non-Personal Information</span>”). When
                                    Non-Personal Information is directly or indirectly associated with Personal
                                    Information, this Non-Personal Information is treated as Personal
                                    Information.
                                </p>
                            </li>
                            <li>
                                <p className="text-gray-800">
                                    Information related to investment advisory for clients (“<span
                                    className="font-weight-bold">Client Information</span>”), as
                                    described
                                    in Section 12.
                                </p>
                            </li>
                            <li>
                                <p className="text-gray-800">
                                    Information you provide to us when you access or use the Services and each time
                                    you interact with the Services or Clarivor, for example, when you provide
                                    information to the Services, update information on your account or communicate with
                                    us by email.
                                </p>
                            </li>
                            <li>
                                <p className="text-gray-800">
                                    Information collected automatically by our servers that records certain information
                                    about how a user uses our Services (“<span
                                    className="font-weight-bold">Log Data</span>”). Log Data may include information
                                    such as a user’s Internet Protocol (IP) address, browser type, operating system, the
                                    web page that a user was visiting before accessing our Services, the pages or
                                    features of our Services to which a user browsed and the time spent on those pages
                                    or features, search terms, the links on our Services that a user clicked on and
                                    other statistics. We use Log Data to administer the Services and we analyze (and may
                                    engage third parties to analyze) Log Data to improve, customize and enhance our
                                    Services by expanding their features and functionality and tailoring them to our
                                    users’ needs and preferences. </p>
                            </li>

                            <li>
                                <p className="text-gray-800">
                                    To enhance your experience with our Services, we and our partners, affiliates,
                                    tracking utility companies and service providers use cookies or similar technologies
                                    to analyze trends, administer the Services and to gather demographic information
                                    about our user base as a whole. Cookies are text files that are stored on your
                                    computer’s browser or temporarily in your computer’s memory. For more information
                                    about cookies, and how to disable them, please see "Your Rights and Choices"
                                    below. </p>
                            </li>

                        </ul>

                        <p className="text-gray-800 mt-4 mt-md-6">
            <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4 font-weight-bold font-size-large">
                2.
            </span>
                            <span className="font-weight-bold">HOW WE COLLECT YOUR INFORMATION</span>
                        </p>

                        <ul>

                            <li>
                                <p className="text-gray-800">
                                    You may give us the information described in Section 1 above by filling in forms or
                                    by corresponding with us by email or otherwise. This includes personal data you
                                    provide when you:
                                </p>
                                <ul>
                                    <li>create an account on our website;</li>
                                    <li>use the Services;</li>
                                    <li>request marketing to be sent to you; or</li>
                                    <li>give us feedback or contact us.</li>
                                </ul>
                            </li>
                            <p></p>

                            <li>
                                <p className="text-gray-800">
                                    As you interact with our website, we will automatically collect certain technical
                                    data about your equipment, browsing actions and patterns. We collect this personal
                                    data by using cookies, web beacons, server logs and other similar technologies. We
                                    may also receive technical data about you if you visit other websites employing our
                                    cookies.
                                </p>
                            </li>

                            <li>
                                <p className="text-gray-800">
                                    We will receive personal data about you from various third parties and public
                                    sources such as technical data, analytics providers, advertising networks, search
                                    information providers, contact, financial and transaction data from providers of
                                    technical, payment and delivery services, identity and contact data from data
                                    brokers or aggregators, and identity and contact data from publicly available
                                    sources.
                                </p>
                            </li>
                            <li>
                                <p className="text-gray-800">
                                    (See Section 12 regarding Client Information)
                                </p>
                            </li>
                        </ul>

                        <p className="text-gray-800 mt-4 mt-md-6">
            <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4 font-weight-bold font-size-large">
                3.
            </span>
                            <span className="font-weight-bold">USE OF YOUR INFORMATION BY CLARIVOR</span>
                        </p>

                        <p className="text-gray-800">
                            Clarivor may use your data for the following purposes:
                        </p>

                        <ul>

                            <li>
                                <p className="text-gray-800">
                                    To provide you with the Services and the products and services you request.
                                </p>
                            </li>

                            <li>
                                <p className="text-gray-800">
                                    To administer your Clarivor account.
                                </p>
                            </li>

                            <li>
                                <p className="text-gray-800">
                                    To communicate with you about your account or transactions with us and send you
                                    information about features of the Services or changes to our policies.
                                </p>
                            </li>

                            <li>
                                <p className="text-gray-800">
                                    To process payments.
                                </p>
                            </li>

                            <li>
                                <p className="text-gray-800">
                                    To ensure consistency with local law and choices and controls that may be available
                                    to you.
                                </p>
                            </li>

                            <li>
                                <p className="text-gray-800">
                                    To personalize content, experiences and advertising.
                                </p>
                            </li>

                            <li>
                                <p className="text-gray-800">
                                    To detect, investigate and prevent activities that may violate our policies or be
                                    illegal.
                                </p>
                            </li>

                            <li>
                                <p className="text-gray-800">
                                    To optimize or improve the content, products, services, and features of the
                                    Services.
                                </p>
                            </li>

                            <li>
                                <p className="text-gray-800">
                                    To personalize and improve the Services and user experiences, to increase the
                                    functionality and user friendliness of the Services, to deliver content or features
                                    that match user profiles or interests.
                                </p>
                            </li>

                            <li>
                                <p className="text-gray-800">
                                    To monitor and analyze the Services usage and trends and otherwise measure the
                                    effectiveness of the services.
                                </p>
                            </li>

                            <li>
                                <p className="text-gray-800">
                                    (See Section 12 regarding Client Information)
                                </p>
                            </li>
                        </ul>

                        <p className="text-gray-800">
                            Clarivor may also send you offers and promotions for our products and services. If you no
                            longer wish to receive such offers and promotions, you may unsubscribe as set forth in
                            Section 5.
                        </p>


                        <p className="text-gray-800 mt-4 mt-md-6">
            <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4 font-weight-bold font-size-large">
                4.
            </span>
                            <span className="font-weight-bold">SHARING YOUR INFORMATION WITH OTHER COMPANIES</span>
                        </p>

                        <p className="text-gray-800">
                            We may disclose your Information in a variety of circumstances in connection with the
                            operation of the Services as described below:
                        </p>

                        <ul>

                            <li><p className="text-gray-800">
                                With third party service providers, such as payment processors or analytics providers,
                                that help us provide our products and service or provide the Services on our behalf;
                            </p></li>

                            <li><p className="text-gray-800">
                                With our affiliates, subsidiaries or parent companies;
                            </p></li>

                            <li><p className="text-gray-800">
                                In response to a request for information if we believe disclosure is in accordance with
                                any applicable law such as to comply with a subpoena, regulation or legal process, or as
                                otherwise required by any applicable law, rule or regulation;
                            </p></li>

                            <li><p className="text-gray-800">
                                If we believe your actions are inconsistent with the spirit or language of our user
                                agreements or policies or that such sharing is necessary or appropriate to protect the
                                rights, property and safety of the Services, its users, it employees, or others;
                            </p></li>

                            <li><p className="text-gray-800">
                                Proactively with local, state and federal law enforcement and/or with other web and
                                mobile marketplaces if we believe there is harm to another User or the general public;
                            </p></li>

                            <li><p className="text-gray-800">
                                As may be required or permitted pursuant to any applicable law, rule or regulation or
                                court or administrative order;
                            </p></li>

                            <li><p className="text-gray-800">
                                In connection with an actual or potential merger, sale, acquisition, assignment, or
                                transfer of all or part of our assets, affiliates, lines of business, or products,
                                including at bankruptcy;
                            </p></li>

                            <li><p className="text-gray-800">
                                With other advertising companies in the digital advertising ecosystem to enable them and
                                us to better target ads to you;
                            </p></li>

                            <li><p className="text-gray-800">
                                With your consent or as otherwise disclosed to you at the time of collection; and
                            </p></li>

                            <li><p className="text-gray-800">
                                We may disclose aggregated, anonymized information to third parties.
                            </p></li>

                            <li><p className="text-gray-800">
                                (See Section 12 regarding Client Information)
                            </p></li>
                        </ul>


                        <p className="text-gray-800 mt-4 mt-md-6">
            <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4 font-weight-bold font-size-large">
                5.
            </span>
                            <span className="font-weight-bold">YOUR RIGHTS AND CHOICES</span>
                        </p>

                        <ul>

                            <li><p className="text-gray-800">
                                Subject to applicable law, you may have the right to request access to and receive
                                details about the personal information we maintain about you, update and correct
                                inaccuracies in your personal data, and have the information blocked or deleted, as
                                appropriate. You may access, update or correct your profile information at any time by
                                logging into your account. If you wish to delete or deactivate your account, you may do
                                so by emailing privacy@clarivor.com, but note that we may retain certain information as
                                required by
                                law or for legitimate business purposes. We may also retain cached or archived copies of
                                information about you for a certain period of time.
                            </p></li>

                            <li><p className="text-gray-800">
                                You may unsubscribe from our promotional emails at any time by following the
                                instructions included in those emails. Please note that if you choose to opt out of
                                receiving such communications, we may continue to send you non-promotional emails.
                            </p></li>
                            <li><p className="text-gray-800">
                                Most web browsers are set to accept cookies by default. You may be able to refuse the
                                use of cookies by selecting the appropriate settings on your browser; however, please
                                note that if you refuse our use of cookies, you may not be able to use the full
                                functionality of the Services.
                            </p></li>
                            <li><p className="text-gray-800">
                                If you do not want to provide your location to us, please use the settings available on
                                your mobile device to limit this sharing. Please note that if you limit sharing of your
                                geolocation data, certain features of the mobile website may not work.
                            </p></li>
                            <li><p className="text-gray-800">
                                If you are a California resident, you may contact us by emailing privacy@clarivor.com,
                                with any
                                questions or to request a list of third parties to whom we may disclose Information for
                                marketing purposes and the categories of information we may disclose.
                            </p></li>
                            <li><p className="text-gray-800">
                                (See Section 12 regarding Client Information)
                            </p></li>

                        </ul>


                        <p className="text-gray-800 mt-4 mt-md-6">
            <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4 font-weight-bold font-size-large">
                6.
            </span>
                            <span className="font-weight-bold">THIRD PARTY WEBSITES AND LINKS</span>
                        </p>

                        <p className="text-gray-800">
                            Our Services may contain links to other sites operated by third parties. Clarivor does not
                            control such other sites and is not responsible for their content, their privacy policies,
                            or their use of Personal Information. Clarivor’s inclusion of such links does not, by
                            itself, imply any endorsement of the content on such sites or of their owners or operators
                            except as disclosed on the Services. Clarivor expressly disclaims any and all liability for
                            the actions of third parties, including but without limitation to actions relating to the
                            use and/or disclosure of Personal Information by third parties. Any information submitted by
                            you directly to these third parties is subject to that third party's privacy policy.
                        </p>


                        <p className="text-gray-800 mt-4 mt-md-6">
            <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4 font-weight-bold font-size-large">
                7.
            </span>
                            <span className="font-weight-bold">CHILDREN'S PRIVACY</span>
                        </p>

                        <p className="text-gray-800">
                            We do not seek or knowingly collect any Personal Information about children under 13 years
                            of age. If we become aware that we have unknowingly collected Personal Information from a
                            child under the age of 13, we will make commercially reasonable efforts to delete such
                            information from our database.
                        </p>
                        <p className="text-gray-800">
                            If you are the parent or guardian of a minor child who has provided us with Personal
                            Information, you may contact us at privacy@clarivor.com to request it be deleted.
                        </p>


                        <p className="text-gray-800 mt-4 mt-md-6">
            <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4 font-weight-bold font-size-large">
                8.
            </span>
                            <span className="font-weight-bold">DATA SECURITY, INTEGRITY AND RETENTION</span>
                        </p>

                        <p className="text-gray-800">
                            We care about the integrity and security of your Personal Information. We use commercially
                            reasonable measures to protect your Personal Information. We also use measures to enhance
                            security, such as analyzing account behavior for fraudulent or otherwise anomalous behavior,
                            may limit use of site features in response to possible signs of abuse, and may suspend or
                            disable accounts for violations of our Terms of Use or Privacy Policy.
                        </p>
                        <p className="text-gray-800">
                            Although we allow you to set privacy options that limit access to your information, please
                            be aware that no security measures are perfect or impenetrable. We cannot guarantee that
                            only authorized persons will view your information. We cannot ensure that information you
                            share on the Services will not become publicly available. We are not responsible for third
                            party circumvention of any privacy settings or security measures on the Services. You can
                            reduce these risks by using common sense security practices such as choosing a strong
                            password, using different passwords for different services. Please be aware that no method
                            of transmitting information over the Internet or storing information is completely secure.
                            Accordingly, we cannot guarantee the absolute security of any information.
                        </p>

                        <p className="text-gray-800 mt-4 mt-md-6">
            <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4 font-weight-bold font-size-large">
                9.
            </span>
                            <span className="font-weight-bold">UPDATING THIS PRIVACY POLICY</span>
                        </p>

                        <p className="text-gray-800">
                            We may modify this Privacy Policy from time to time in which case we will update the “Last
                            Revised” date at the top of this Privacy Policy. If we make changes that are material, we
                            will use reasonable efforts to attempt to provide notice to you and, where required by
                            applicable law, we will obtain your consent. Notice may be by email to you at the last email
                            address you provided us, by posting notice of such changes on the Services, or by other
                            means, consistent with applicable law. However, it is your sole responsibility to review the
                            Privacy Policy from time to time to view any such changes. The updated Privacy Policy will
                            be effective as of the time of posting, or such later date as may be specified in the
                            updated Privacy Policy. <span className="font-weight-bold">IF YOU DO NOT ACCEPT AND AGREE TO THIS PRIVACY POLICY, INCLUDING ANY CHANGES THERETO, THEN YOU MUST NOT ACCESS OR USE THE SERVICES</span>.
                        </p>


                        <p className="text-gray-800 mt-4 mt-md-6">
            <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4 font-weight-bold font-size-large">
                10.
            </span>
                            <span className="font-weight-bold">LINKS TO OTHER WEBSITES</span>
                        </p>

                        <p className="text-gray-800">
                            We are not responsible for the practices employed by websites or applications linked to or
                            from the Services, nor the information or content contained therein. Please remember that
                            when you use a link to go from the Services to another website, our Privacy Policy is no
                            longer in effect. Your browsing and interaction on any other website or application,
                            including those that have a link on our website, is subject to that website's own rules and
                            policies. Please read over those rules and policies before proceeding.
                        </p>

                        <p className="text-gray-800 mt-4 mt-md-6">
            <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4 font-weight-bold font-size-large">
                11.
            </span>
                            <span className="font-weight-bold">HOW TO CONTACT US</span>
                        </p>

                        <p className="text-gray-800">
                            Any questions or concerns regarding the use or disclosure of Personal Information should be
                            directed to Clarivor at privacy@clarivor.com.
                        </p>

                        <p className="text-gray-800">
                            You may also contact us regarding the Services or this Privacy Policy at: <Link
                            to="/contact">www.clarivor.com/contact</Link>.
                        </p>


                        <p className="text-gray-800 mt-4 mt-md-6">
            <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4 font-weight-bold font-size-large">
                12.
            </span>
                            <span
                                className="font-weight-bold">PROVISIONS APPLICABLE TO INVESTMENT ADVISORY CLIENTS</span>
                        </p>

                        <p className="text-gray-800">
                            The provisions in this Section 12 are applicable only to individuals and entities who are
                            party
                            to a separate, written client advisory agreement with Clarivor (“Clients”). For Clients, in
                            the event that a provision in this Section 12 is inconsistent with another section of this
                            policy, the provision in this section shall apply. </p>

                        <p className="text-gray-800">
                            Clarivor is committed to safeguarding the use of Client Information, which consists of your
                            nonpublic, personal information that we have as your investment advisor. We protect the
                            security and confidentiality of the Client Information we have and make efforts to ensure
                            that such information is used for proper business purposes in connection with the management
                            or servicing of your account. Our relationship with you is our most important asset. We
                            understand that you have entrusted us with your private information, and we do everything we
                            can to maintain that trust.
                        </p>

                        <p className="text-gray-800">
                            We do not sell Client Information to anyone. Nor does Clarivor provide Client Information to
                            others except for discrete and proper business purposes in connection with the servicing and
                            management of your account as discussed below.
                        </p>
                        <p className="text-gray-800">
                            Details of our approach to privacy with regard to how Client Information is collected and
                            used are set forth in this Section 12.
                        </p>

                        <p className="text-gray-800">
                            <span className='font-weight-bold'>Client Information that Clarivor Collects</span><br/>
                        </p>
                        <p>
                            You typically provide Client Information when you complete the process required to become
                            our Client. This information may include your:
                        </p>
                        <div className='row justify-content-center'>
                            <div className='col-12 col-sm-6'>
                                <ul>
                                    <li>Name and address</li>
                                    <li> E-mail address</li>
                                    <li>Phone number</li>
                                    <li>Social security or taxpayer identification number</li>
                                </ul>
                            </div>
                            <div className='col-12 col-sm-6'>
                                <ul>
                                    <li>Assets</li>
                                    <li>Income</li>
                                    <li>Investment activity</li>
                                    <li>Accounts at other institutions</li>
                                </ul>
                            </div>
                        </div>
                        <p className="text-gray-800">
                            In addition, we may collect Client Information from the following sources:
                        </p>
                        <ul>
                            <li>Information we receive on Brokerage Agreements, Managed Account Agreements
                                and other Subscription and Account Opening Documents
                            </li>
                            <li>Information we receive in the course of establishing a customer relationship
                                including, but not limited to, applications, forms, and questionnaires
                            </li>
                            <li>Information about your transactions with us or others</li>
                        </ul>

                        <p className="text-gray-800">
                        </p>

                        <p className="text-gray-800">
                            <span className='font-weight-bold'>Client Information Clarivor Shares</span><br/>
                        </p>
                        <p>
                            Clarivor works to provide products and services that benefit our customers. We may share
                            Client Information with non-affiliated third parties (such as brokers and custodians) as
                            necessary for us to provide agreed services and products to you consistent with applicable
                            law. We may also disclose Client Information to other financial institutions with whom we
                            have joint business arrangements for proper business purposes in connection with the
                            management or servicing of your account. In addition, your Client Information may also be
                            disclosed to you, persons we believe to be your authorized agent or representative,
                            regulators in order to satisfy Clarivor’s regulatory obligations, and is otherwise required
                            or permitted by law. Lastly, we may disclose Client Information to companies we hire to
                            help administrate our business. Companies we hire to provide services of this kind are not
                            allowed to use Client Information for their own purposes and are contractually
                            obligated to maintain strict confidentiality. We limit their use of Client Information
                            to the performance of the specific service we have requested.
                            <br/><br/>For the avoidance of doubt, we do not sell Client Information to anyone.
                        </p>


                        <p className="text-gray-800">
                            <span className='font-weight-bold'>Information about Former Clients</span><br/>
                        </p>
                        <p>
                            Clarivor does not disclose, and does not intend to disclose, Client Information to
                            non-affiliated third parties with respect to persons who are no longer our clients.
                        </p>

                        <p className="text-gray-800">
                            <span className='font-weight-bold'>Confidentiality and Security</span><br/>
                        </p>
                        <p>
                            Our employees are advised about the firm's need to respect the confidentiality of our
                            customers' Client Information. Additionally, we maintain physical, procedural and electronic
                            safeguards in an effort to protect the information from access by unauthorized parties.
                        </p>

                        <p className="text-gray-800">
                            <span className='font-weight-bold'>We'll keep you Informed</span><br/>
                        </p>
                        <p>
                            We will send you notice of our privacy policy annually for as long as you maintain an
                            ongoing relationship with us. Periodically we may revise our privacy policy and will provide
                            you with a revised policy if the changes materially alter the previous privacy policy. We
                            will not, however, revise our privacy policy to permit the sharing of Client Information
                            other than as described in this notice unless we first notify you and provide you with an
                            opportunity to prevent the information sharing. You may obtain a copy of our current privacy
                            policy by visiting www.clarivor.com/privacy.
                        </p>
                    </div>
                </div>

            </div>
        </section>
    )

}