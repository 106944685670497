import Modal from "../../common/Modal"
import React, {useState} from "react"
import brandLogo from 'images/clarivor-p-500.png'
import axios from "axios"
import {API_ENDPOINT} from "../../../keys"
import {mixpanel} from "../../../services/mixpanel";


function RequestUpdatesForm(props) {
    const [formData, setFormData] = useState({})

    const handleSubmit = async (event) => {
        event.preventDefault()

        const params = {
            form_data: formData,
            form_name: 'Receive Updates',
        }

        try {
            await axios.post(API_ENDPOINT.CONTACT_FORM, params)
            mixpanel.alias(formData.email)
            props.setSubmitted(true)
        } catch (error) {
            {
                window.alert('An error occurred.  Please email contact@clarivor.com')
            }
        }

        return false
    }

    const handleFieldChange = (event) => {
        let newFormData = JSON.parse(JSON.stringify(formData))

        newFormData[event.target.name] = event.target.value

        setFormData(newFormData)
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row justify-content-center">
                <div className="col-12 col-md-10">
                    <div className={'row justify-content-center my-6'}>
                        <img src={brandLogo} style={{width: '50%'}}/>
                    </div>
                    <h4 className="text-dark px-4 mb-5">
                        Please enter your contact info below to receive updates from Clarivor.
                    </h4>
                </div>
            </div>

            < div
                className="row justify-content-center">
                < div
                    className="col-12 col-md-10">
                    <div
                        className="form-group mb-5">
                        <input
                            name='firstName'
                            type="text"
                            required
                            className="form-control"
                            id="firstName"
                            onChange={handleFieldChange}
                            placeholder="First Name"/>
                    </div>
                    <div
                        className="form-group mb-5">
                        <input
                            name='lastName'
                            type="text"
                            required
                            className="form-control"
                            id="lastName"
                            onChange={handleFieldChange}
                            placeholder="Last Name"/>
                    </div>
                    <div className="form-group mb-5">
                        <input name='email' type="email" required className="form-control"
                               id="email"
                               onChange={handleFieldChange}
                               placeholder="Email"/>
                    </div>
                    <div
                        className="form-group mb-5">
                        <input
                            name='company'
                            type="text"
                            className="form-control"
                            id="company"
                            onChange={handleFieldChange}
                            required
                            placeholder="Company"/>
                    </div>
                    <div className="form-group mb-5">
                        <button type="submit" className="btn btn-block btn-outline-success lift">
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </form>
    )
}

function RequestUpdateSubmittedForm(props) {
    return (
        <React.Fragment>
            <div className="row justify-content-center">
                <div className="col-12">
                    <div className={'row justify-content-center my-6'}>
                        <img src={brandLogo} style={{width: '50%'}}/>
                    </div>
                    <h4 className="text-dark px-4 mb-5">
                        Thank you. Look out for updates from Clarivor soon. In the meantime if you have any questions, please email <span className='font-weight-bold'>contact@clarivor.com</span>.
                    </h4>
                    <div className='col-12 d-flex'>
                        <button className='btn btn-success mx-auto' data-dismiss='modal'>OK</button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

function RequestModalBody(props) {
    const [submitted, setSubmitted] = useState(false)

    if (!submitted) {
        return <RequestUpdatesForm setSubmitted={setSubmitted}/>
    } else {
        return <RequestUpdateSubmittedForm/>
    }
}


export default function ReceiveUpdatesModal(props) {
    let modalBody = <RequestModalBody/>

    return (
        <Modal modalID={'receiveUpdatesModal'} type='' cssModule={null} modalTitle="Request Updates"
               bodyComponent={modalBody}/>
    )
}
