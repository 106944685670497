import React, {useContext, useEffect, useState} from "react"
import headshot from 'images/blog/avatar-100.jpg'
import RequestDemoModal from "../../../pages/common/RequestDemoModal"
import {globalAuthManager, globalServices} from "../../../../services/GlobalServices"
import {apiGet} from "../../../../services/api-call-helpers"
import * as keys from 'keys'
import {mixpanel} from "../../../../services/mixpanel"
import ReceiveUpdatesModal from "../../../pages/common/ReceiveUpdatesModal"
import {Link} from "react-router-dom"
import BlogSignupSection from "../../common/BlogSignupSection"
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {
    pieChartOptions,
    spyTltSeriesOptions,
    spyGldSeriesOptions,
    spyEfaSeriesOptions
} from "./buywrite-diversification-charts"

import BannerImage500 from "images/blog/Blog_20210803_BuyWriteDiversification/digital-stock-exchange-panel-500.jpg"
import BannerImage from "images/blog/Blog_20210803_BuyWriteDiversification/digital-stock-exchange-panel.jpg"
import jonAvatar from 'images/blog/avatar-100.jpg'
import HypotheticalPerformanceAlert from "../../../common/alerts/HypotheticalPerformanceAlert";

const TLTOverwriteContext = React.createContext({})

const documentTitle = "Clarivor Blog"
const postTitle = 'Diversifying Your Buy-Writes'
const postSubtitle = 'Over-writing more than one asset can help mitigate the risk of buy-write under-performance'
const postAuthor = 'Jon Spiegel, CFA'
const publishedOnText = 'August 3, 2021'
const publishedOnShort = 'Aug 3, 2021'
const path = '/blog/BuyWriteDiversification_20210803'

const HeroSection = (props) => (
    <section
        style={{
            backgroundImage: ["url(" + BannerImage + ")"],
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            position: 'relative',
            zIndex: 0,
            backgroundSize: 'cover',
            height: '50vh'
        }}>
    </section>
)

const HeaderSection = (props) => (
    <section className="pt-8 pt-md-11">
        <RequestDemoModal/>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 col-md-12 col-lg-10 col-xl-10">


                    <h1 className="display-4 text-center">
                        {props.title}
                    </h1>


                    <p className="lead mb-7 text-center text-muted">
                        {props.subtitle}
                    </p>


                    <div className="row align-items-center py-5 border-top border-bottom">
                        <div className="col-auto">


                            <div className="avatar avatar-lg">
                                <img src={props.avatarImage} alt="..."
                                     className="avatar-img rounded-circle"/>
                            </div>

                        </div>
                        <div className="col ml-n5">
                            <h6 className="text-uppercase mb-0">
                                {props.author}
                            </h6>
                        </div>
                        <div className="col-auto">
                            <time className="font-size-sm text-muted" dateTime="2019-05-20">
                                {props.publishedOnText}
                            </time>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
)


function Summary(props) {
    return (
        <React.Fragment>
            <h3 className={'font-weight-bold mt-6'}>
                Summary
            </h3>
            <ul>
                <li className='my-2'>
                    Income-seeking investors may look more closely at over-writing equities in the current environment,
                    with rates low, stock prices high, and option prices at moderate levels.
                </li>
                <li className='my-2'>
                    One concern that may hold back investors is the potential under-performance of buy-write strategies
                    against benchmarks, especially having observed U.S. equity large-caps rally about 33% over the
                    past 12 months.
                </li>
                <li className='my-2'>
                    By over-writing multiple assets, investors may be able to reduce the risk of under-performance vs.
                    benchmarks, especially when over-writing assets with low correlation.
                </li>
            </ul>
        </React.Fragment>
    )
}


function Section1(props) {
    return (
        <React.Fragment>
            <h3 className={'font-weight-bold mt-6'}>
                Equity buy-writes in the current environment
            </h3>
            <p>
                Over the past few years, buy-writes may have seemed to lose their luster. Large cap U.S. equity
                prices rose more than 240% over the past decade, so many call-selling strategies would have
                under-performed.
            </p>
            <p>
                Meanwhile, despite equities at record levels, income from selling call options may be relatively high.
                For example, as of August 3rd, our <Link to='/tools/income-monitor' target="_blank">income
                monitor</Link> shows that prices of 3-month
                call options on SPY are higher than they've been 57% of the time over the past 5 years.
            </p>
            <p>
                Even if receiving income that seems reasonable, investors may still hesitate to risk under-performing
                benchmarks
                when over-writing equities. One way to potentially reduce this risk is by also over-writing other
                assets, especially if they have low correlation to equities.
            </p>
            <h3 className={'font-weight-bold mt-6'}>
                Diversifying with buy-writes on other assets
            </h3>
            <p>
                Investing in traditional assets with low correlations can be a way to improve overall
                portfolio risk adjusted returns. Similarly, over-writing multiple assets with low correlations may be
                effective for reducing the potential under-performance of portfolios using buy-write strategies.
            </p>
            <p>
                Typically assets with low correlations have large returns in the same direction relatively infrequently.
                Therefore, when one asset rallies through the call strike sold, there may be a good chance that the
                other asset has not.
            </p>
            <p>
                When over-writing different asset classes, investors still need to consider their overall asset
                allocations, but replacing some amount of traditional allocations with buy-writes, across asset classes,
                can reduce the risk of any one buy-write contributing to portfolio under-performance against a
                benchmark.
            </p>
            <h3 className={'font-weight-bold mt-6'}>
                Using a treasury ETF
            </h3>
            <p>
                As TLT has exhibited correlation of -49% with SPY, we'd expect a significant historical benefit from
                diversification, which we find in the data. When targeting 4% annual option income, in 88% of periods
                when the a buy-write on SPY underperformed the SPY ETF, a similar buy-write on TLT would have
                outperformed the TLT ETF. Only in 4.2% of periods
                would
                both buy-writes have under-performed their ETFs:
            </p>
            <div className='card bg-light'>
                <div className='card-body p-2'>
                    <HighchartsReact highcharts={Highcharts}
                                     options={pieChartOptions('SPY and TLT Buy-Writes', spyTltSeriesOptions)}/>
                </div>
            </div>
            <h3 className={'font-weight-bold mt-6'}>
                Using a gold ETF
            </h3>
            <p>
                GLD has realized a near-zero correlation of +3% with SPY, and historically a GLD buy-write has
                diversified the performance of the SPY buy-write vs SPY. We find that 89% of the time when a
                buy-write on SPY underperformed the SPY ETF, the buy-write on GLD would have outperformed the GLD ETF.
                Only in 3.9% of periods would both buy-writes have under-performed their benchmarks:
            </p>
            <div className='card bg-light'>
                <div className='card-body p-2'>
                    <HighchartsReact highcharts={Highcharts}
                                     options={pieChartOptions('SPY and GLD Buy-Writes', spyGldSeriesOptions)}/>
                </div>
            </div>
            <h3 className={'font-weight-bold mt-6'}>
                Using an international equity ETF
            </h3>
            <p>
                Like most equity assets, EFA has exhibited a large positive correlation with SPY (+91%). While some
                potential diversification benefit is evident, the signs of diversification are less than those using TLT
                or GLD. We find that 54% of the time when the a buy-write on SPY underperformed the SPY ETF,
                the buy-write on EFA would have outperformed the EFA ETF.
                In 16% of periods both buy-writes would have under-performed their benchmarks:
            </p>
            <div className='card bg-light'>
                <div className='card-body p-2'>
                    <HighchartsReact highcharts={Highcharts}
                                     options={pieChartOptions('SPY and EFA Buy-Writes', spyEfaSeriesOptions)}/>
                </div>
            </div>
            <h3 className={'font-weight-bold mt-6'}>
                Methodology
            </h3>
            <p>
                To calculate the percentages above, we observe 3-month periods starting and ending on trading days. For
                each ETF, we estimate the strike of a 3-month call that can be sold for 1% of the ETF price. For a given
                ETF over a given period, the buy-write strategy is deemed to outperform if the expiration value of the
                call is less than the price it would have been sold at. We use ETF data from June 3, 2008 to
                July 30, 2021 and option data from June 3, 2008 to April 30, 2021.
            </p>
        </React.Fragment>
    )
}

function BuywriteDiversificationProjectContainer(props) {
    const [projectObj, setProjectObj] = useState(null)

    useEffect(() => {
        apiGet(keys.API_ENDPOINT.PROJECTS, {id: props.projectId}, globalAuthManager).then(
            res => {
                const [project] = res.data
                setProjectObj(project)
            }
        )
    }, [])

    const contextValue = {
        projectObj: projectObj,
        projectId: props.projectId
    }

    return (
        <TLTOverwriteContext.Provider value={contextValue}>
            {props.children}
        </TLTOverwriteContext.Provider>
    )
}


function DisclosuresLink(props) {
    return (
        <a data-toggle="modal" href="#disclaimerModal"
           onClick={() => mixpanel.track('Disclosure Clicked')}>Disclosures</a>
    )
}

function TermsOfUseLink(props) {
    return (
        <Link to={'/terms-of-use'} target={'_blank'}
              onClick={() => mixpanel.track('Terms of Use Clicked')}>Terms of Use</Link>
    )
}

function BacktestingSourceDisclaimer(props) {
    return (
        <div>
            <hr/>
            <p className='font-italic text-muted'>
                Source: Clarivor LLC. These results are backtested and may not be indicative of future performance.
                Changing strategy parameters, such as the option strike, maturity and roll timing, can have a significant impact on results.
                The buy-write returns include a quarterly deduction of 0.12% to reflect management fees and commissions.

                {/*Options are rolled seven days prior to expiration at bid or offer.*!/*/}
                {/*Option prices are observed at 3:45pm NY time. Back-tested strategies include 0.47% annualized cost to*/}
                {/*reflect management fees and commissions.*/}
                <br/>
                <br/>
                NOTHING IN THIS POST SHOULD BE CONSTRUED AS INVESTMENT ADVICE. <br/>
                <DisclosuresLink/> and <TermsOfUseLink/> apply.
                <br/>
            </p>
        </div>
    )
}


function PostBody(props) {
    return (
        <React.Fragment>
            <section className="pt-0 pt-md-0">
                <ReceiveUpdatesModal/>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-12 col-lg-10 col-xl-9">
                            <Summary/>
                            <Section1/>
                            <BacktestingSourceDisclaimer/>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}


export default function BuyWriteDiversification_20210803(props) {
    const projectId = null  // this is a static blog post

    useEffect(() => {
        document.title = documentTitle
    }, [])

    const headerProps = {
        title: postTitle,
        subtitle: postSubtitle,
        avatarImage: headshot,
        author: postAuthor,
        publishedOnText: publishedOnShort,
    }

    return (
        <React.Fragment>
            <HeroSection/>
            <HeaderSection {...headerProps} />
            <BuywriteDiversificationProjectContainer projectId={projectId}>
                <PostBody/>
            </BuywriteDiversificationProjectContainer>
            <BlogSignupSection fromLocation={'Blog Post TLT Overwriting'}/>
            <div className='container'>
                <HypotheticalPerformanceAlert/>
            </div>
        </React.Fragment>
    )
}


export function RichBuyWriteDiversification202108(props) {
    return (
        <section className="pt-7 pt-md-10">
            <div className="container">
                <div className="row">
                    <div className="col-12">

                        <div className="card card-row shadow-light-lg mb-6 lift lift-lg">
                            <div className="row no-gutters">
                                <div className="col-12">

                                <span className="badge badge-pill badge-light badge-float badge-float-inside">
                                <span className="h6 text-uppercase">Income</span>
                                </span>

                                </div>
                                <Link className="col-12 col-md-6 order-md-2 bg-cover card-img-right"
                                      style={{backgroundImage: "url(" + BannerImage500 + ")"}}
                                      to={path}>

                                    <img src="assets/img/photos/photo-27.jpg" alt="..."
                                         className="img-fluid d-md-none invisible"/>

                                    <div
                                        className="shape shape-left shape-fluid-y svg-shim text-white d-none d-md-block">
                                        <svg viewBox="0 0 112 690" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 0h62.759v172C38.62 384 112 517 112 517v173H0V0z"
                                                  fill="currentColor"></path>
                                        </svg>
                                    </div>

                                </Link>
                                <div className="col-12 col-md-6 order-md-1">

                                    <Link className="card-body" to={path}>

                                        <h3>
                                            {postTitle}
                                        </h3>

                                        <p className="mb-0 text-muted">
                                            {postSubtitle}
                                        </p>

                                    </Link>

                                    <a className="card-meta" href="#!">

                                        <hr className="card-meta-divider"/>

                                        <div className="avatar avatar-sm mr-2">
                                            <img src={jonAvatar} alt="..."
                                                 className="avatar-img rounded-circle"/>
                                        </div>

                                        <h6 className="text-uppercase text-muted mr-2 mb-0">
                                            Jon Spiegel
                                        </h6>

                                        <p className="h6 text-uppercase text-muted mb-0 ml-auto">
                                            <time dateTime="2020-10-23">{publishedOnShort}</time>
                                        </p>

                                    </a>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>

    )
}
