import React, {useContext, useEffect, useState} from "react"
import headshot from 'images/blog/avatar-100.jpg'
import RequestDemoModal from "../../../pages/common/RequestDemoModal"
import {globalAuthManager, globalServices} from "../../../../services/GlobalServices"
import {apiGet} from "../../../../services/api-call-helpers"
import * as keys from 'keys'
import {mixpanel} from "../../../../services/mixpanel"
import ReceiveUpdatesModal from "../../../pages/common/ReceiveUpdatesModal"
import {Link} from "react-router-dom"
import BlogSignupSection from "../../common/BlogSignupSection"
import TLTHeroImage from "images/blog/TLTOverwrite-202104/treasury-building.jpg"
import TLTBacktestImage from "images/blog/TLTOverwrite-202104/tlt-overwrite-backtest.jpg"
import jonAvatar from 'images/blog/avatar-100.jpg'
import HypotheticalPerformanceAlert from "../../../common/alerts/HypotheticalPerformanceAlert";

const TLTOverwriteContext = React.createContext({})

const documentTitle = "Clarivor Blog: TLT Overwriting"
const postTitle = 'Getting Paid More to Own Treasuries'
const postSubtitle = ''

const HeroSection = (props) => (
    <section
        style={{
            backgroundImage: ["url(" + TLTHeroImage + ")"],
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            position: 'relative',
            zIndex: 0,
            backgroundSize: 'cover',
            height: '50vh'
        }}>
    </section>
)

const HeaderSection = (props) => (
    <section className="pt-8 pt-md-11">
        <RequestDemoModal/>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 col-md-12 col-lg-10 col-xl-10">


                    <h1 className="display-4 text-center">
                        {props.title}
                    </h1>


                    <p className="lead mb-7 text-center text-muted">
                        {props.subtitle}
                    </p>


                    <div className="row align-items-center py-5 border-top border-bottom">
                        <div className="col-auto">


                            <div className="avatar avatar-lg">
                                <img src={props.avatarImage} alt="..."
                                     className="avatar-img rounded-circle"/>
                            </div>

                        </div>
                        <div className="col ml-n5">
                            <h6 className="text-uppercase mb-0">
                                {props.author}
                            </h6>
                        </div>
                        <div className="col-auto">
                            <time className="font-size-sm text-muted" dateTime="2019-05-20">
                                {props.publishedOnText}
                            </time>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
)


function Summary(props) {
    return (
        <React.Fragment>
            <h3 className={'font-weight-bold mt-6'}>
                Summary
            </h3>
            <ul>
                <li className='my-2'>
                    In the current environment where yields and risk premia are scarce, options on TLT are screening
                    relatively expensive.
                </li>
                <li className='my-2'>
                    While bond prices have sold off recently, the potential for higher U.S. taxes could limit their
                    downside.
                </li>
                <li className='my-2'>
                    Investors can receive an annualized yield of approximately 6.75% for holding TLT and
                    selling a 3-month call option 5% out-of-the-money.
                </li>
            </ul>
        </React.Fragment>
    )
}


function Section1(props) {
    return (
        <React.Fragment>
            <h3 className={'font-weight-bold mt-6'}>
                Rationale
            </h3>
            <p>
                Since November, Treasury bond prices have mostly given up their gains from the beginning of the
                pandemic. The move in rates has likely reflected expectations for higher spending under a
                Democrat-controlled government. </p>
            <p>
                Recently however, discussions in Washington have shifted toward higher taxes, which could support bond
                prices.
            </p>
            <p> Over the past decade, investors anticipating a return to higher interest rates have been
                disappointed many times. For those who believe rates will remain range-bound over the near-term,
                overwriting TLT is a way to generate yield by taking advantage of potentially elevated option prices.
                TLT is an ETF that holds 20+ year Treasury Bonds.
            </p>
            <h3 className={'font-weight-bold mt-6'}>
                Strategy
            </h3>
            <p>
                A TLT 3-month call option, struck 5% out-of-the-money, is currently priced at approximately 1.15% of
                the ETF price. That is more expensive than about 80% of the time over the past 5-10 years. Including the
                yield of holding the ETF, the annualized yield of the strategy is approximately 6.75%.
            </p>
            <p>
                Investors should note that TLT holds Treasuries that are longer-dated than many core bond allocations,
                so the ETF is often more sensitive to changes in interest rates than shorter-dated bond portfolios.
            </p>
            <h3 className={'font-weight-bold mt-6'}>
                Backtested Performance
            </h3>
            <p>
                Over the past 10 years, the overwriting strategy would have had a similar total return to holding TLT
                outright, while reducing volatility and draw-downs.
            </p>
            <p>
                The overwriting strategy's total returns would have underperformed TLT in 2011 - 2016, as there were
                several periods where Treasury prices moved higher sharply, and selling calls caps returns from the ETF.
            </p>
            <p>
                Meanwhile the overwriting strategy would have generally outperformed TLT during periods of steady or rising
                rates.
            </p>
            <div className='card bg-light'>
                <div className='card-body p-2'>
                    <img src={TLTBacktestImage} style={{width: '100%'}}/>
                </div>
            </div>
        </React.Fragment>
    )
}

const WrittenSection = (props) => {

    const handleToolScroll = (event) => {
        event.preventDefault()
        props.scrollToTool()
    }

    return (
        <section>
            <hr className="hr-md"/>
        </section>
    )
}


function SelectorsWrapper(props) {
    return (
        <div className='card mt-4'>
            <div className='card-header bg-gray-300 text-secondary p-4'>
                Compare Put Strategies
            </div>
            <div className='card-body bg-light p-4'>
                {props.children}
                <div className="form-check">
                    {/*<ShowSPYCheckbox/>*/}
                </div>
            </div>
        </div>
    )
}


function ChartWrapper(props) {
    return (
        <div className='card mt-4 px-0'>
            <div className='card-body border px-0'>
                {props.children}
            </div>
        </div>
    )

}

function TLTOverwriteProjectContainer(props) {
    const [projectObj, setProjectObj] = useState(null)

    useEffect(() => {
        apiGet(keys.API_ENDPOINT.PROJECTS, {id: props.projectId}, globalAuthManager).then(
            res => {
                const [project] = res.data
                setProjectObj(project)
            }
        )
    }, [])

    const contextValue = {
        projectObj: projectObj,
        projectId: props.projectId
    }

    return (
        <TLTOverwriteContext.Provider value={contextValue}>
            {props.children}
        </TLTOverwriteContext.Provider>
    )
}


function RequestDemoSection(props) {
    return (
        <section>
            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-12 col-md-8 col-lg-6'>
                        <div className='card border border-primary'>
                            <div className='card-body'>
                                <div className='row justify-content-center'>
                                    <h3 className={'text-center'}>
                                        Get in touch with Clarivor for further analysis.
                                    </h3>
                                </div>
                                <div className='row justify-content-center'>
                                    <a className='mx-auto' data-toggle="modal" href="#requestDemoModal"
                                       style={{textDecoration: 'none'}}
                                       onClick={() => {
                                           mixpanel.track('Request Demo clicked')
                                       }}>
                                        <button className="btn btn-success lift mt-2 d-flex">
                                            <span className={'my-auto'}>Request Demo</span>
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

function DisclosuresLink(props) {
    return (
        <a data-toggle="modal" href="#disclaimerModal"
           onClick={() => mixpanel.track('Disclosure Clicked')}>Disclosures</a>
    )
}

function TermsOfUseLink(props) {
    return (
        <Link to={'/terms-of-use'} target={'_blank'}
              onClick={() => mixpanel.track('Terms of Use Clicked')}>Terms of Use</Link>
    )
}

function BacktestingSourceDisclaimer(props) {
    return (
        <div className='card'>
            <div className='card-body font-italic text-muted'>
                Source: Clarivor LLC. These results are backtested and may not be indicative of future
                performance. Options are rolled seven days prior to expiration at bid or offer.
                Changes in strategy parameters, like strikes, expirations, and the timing of option rolls,
                can materially affect results.
                Option prices are observed at 3:45pm NY time. Dividends are reinvested and the overwriting strategy
                is shown net of a 0.47% accrued cost to reflect investment management fees and commissions.
                Option prices discussed are interpolated between listed expirations and
                strikes for purposes of illustration.
                <br/>
                <br/>
                NOTHING IN THIS POST SHOULD BE CONSTRUED AS INVESTMENT
                ADVICE. <DisclosuresLink/> and <TermsOfUseLink/> apply.
                <br/>
            </div>
        </div>
    )
}


function TLTOverwritePostBody(props) {
    return (
        <React.Fragment>
            <section className="pt-0 pt-md-0">
                <ReceiveUpdatesModal/>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-12 col-lg-10 col-xl-9">
                            <Summary/>
                            <Section1/>
                            <BacktestingSourceDisclaimer/>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}


export default function TLT_Overwriting_20210412(props) {
    const projectId = null  // this is a static blog post

    useEffect(() => {
        document.title = documentTitle
    }, [])

    const headerProps = {
        title: postTitle,
        subtitle: postSubtitle,
        avatarImage: headshot,
        author: 'Jon Spiegel, CFA',
        publishedOnText: 'April 13, 2021',
    }

    return (
        <React.Fragment>
            <HeroSection/>
            <HeaderSection {...headerProps} />
            <TLTOverwriteProjectContainer projectId={projectId}>
                <TLTOverwritePostBody/>
            </TLTOverwriteProjectContainer>
            <div className='container'>
                <HypotheticalPerformanceAlert/>
            </div>
            <BlogSignupSection fromLocation={'Blog Post TLT Overwriting'}/>
        </React.Fragment>
    )
}


export function RichViewTLTOverwrite(props) {
    return (
        <section className="pt-7 pt-md-10">
            <div className="container">
                <div className="row">
                    <div className="col-12">

                        <div className="card card-row shadow-light-lg mb-6 lift lift-lg">
                            <div className="row no-gutters">
                                <div className="col-12">

                            <span className="badge badge-pill badge-light badge-float badge-float-inside">
                    <span className="h6 text-uppercase">Income</span>
                  </span>

                                </div>
                                <Link className="col-12 col-md-6 order-md-2 bg-cover card-img-right"
                                      style={{backgroundImage: "url(" + TLTHeroImage + ")"}}
                                      to="/blog/TLT_Overwriting_20210413">

                                    <img src="assets/img/photos/photo-27.jpg" alt="..."
                                         className="img-fluid d-md-none invisible"/>

                                    <div
                                        className="shape shape-left shape-fluid-y svg-shim text-white d-none d-md-block">
                                        <svg viewBox="0 0 112 690" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 0h62.759v172C38.62 384 112 517 112 517v173H0V0z"
                                                  fill="currentColor"></path>
                                        </svg>
                                    </div>

                                </Link>
                                <div className="col-12 col-md-6 order-md-1">

                                    <Link className="card-body" to="/blog/TLT_Overwriting_20210413">

                                        <h3>
                                            Getting Paid More to Own Treasuries
                                        </h3>

                                        <p className="mb-0 text-muted">
                                            Overwriting a Treasury ETF for higher yield
                                        </p>

                                    </Link>

                                    <a className="card-meta" href="#!">

                                        <hr className="card-meta-divider"/>

                                        <div className="avatar avatar-sm mr-2">
                                            <img src={jonAvatar} alt="..."
                                                 className="avatar-img rounded-circle"/>
                                        </div>

                                        <h6 className="text-uppercase text-muted mr-2 mb-0">
                                            Jon Spiegel
                                        </h6>

                                        <p className="h6 text-uppercase text-muted mb-0 ml-auto">
                                            <time dateTime="2020-10-23">Apr 12</time>
                                        </p>

                                    </a>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>

    )
}
