import React, {Component, useContext, useState} from 'react'
import brandLogo from 'images/clarivor-p-500.png'
import {Link} from 'react-router-dom'
// import lk from "*.module.css"
import {AppContext} from "../contexts"
import SignInModal from "./SignInModal"
import {mixpanel} from "../../services/mixpanel"
import {globalAuthManager} from "../../services/GlobalServices"
import {mapClasses} from "../../util/cssModule"
import {X} from "react-feather"

// import lk from 'lk/dist/assets/css/theme.module.css'

// not using module:
// const mapClasses = (module, classstr) => module == null ? classstr : module[classstr]
// const mapClassesLK = (classname) => lk[classname]

const navBarStyle = {
    paddingRight: '1rem',
    paddingLeft: '1rem',
    paddingTop: '1.25rem',
    paddingBottom: '1.25rem',
}

const navBarBrandStyle = {
    paddingTop: '.15rem',
    paddingBottom: '.15rem',
    marginRight: '0',
    fontFamily: 'HKGroteskPro',
    fontSize: '1.0625rem',
}

const navBarButtonStyle = {
    paddingTop: '0.5625rem',
    paddingRight: '1rem',
    paddingBottom: '0.5625rem',
    paddingLeft: '1rem',
    fontWeight: '600',
    fontFamily: 'HKGroteskPro',
    fontSize: '1.0625rem',
}

const navBarToggleStyle = {
    color: '#506690',
    borderColor: 'transparent'
}

class SignInButton extends Component {

    render() {

        return (
            <button type='button' className={mapClasses(null, "navbar-btn btn btn-sm btn-outline-dark lift ml-auto")}
                    data-toggle="modal"
                    data-target="#modalSigninVertical"
                    style={navBarButtonStyle}>
                Sign in
            </button>
        )
    }

}

function SignOutButton(props) {
    const appContext = React.useContext(AppContext)

    const logout = () => {
        const sourcePath = props.location ? props.location.pathname : ''
        mixpanel.track('Signed Out', {Source: sourcePath})
        globalAuthManager.logout()
    }

    return (
        <button type='button' className={mapClasses(null, "navbar-btn btn btn-sm btn-light ml-auto")}
                style={navBarButtonStyle}
                onClick={logout}
                data-toggle="tooltip"
                data-placement="top"
                title={"Sign out " + appContext.username}
        >
            Sign out
        </button>
    )
}


class UserOrSignInButton extends Component {

    render() {
        switch (this.props.isLoggedIn) {
            case false:
                return <SignInButton {...this.props} />
            case true:
                return <SignOutButton {...this.props} />
            default:
                return (
                    <button type='button'
                            className={mapClasses(null, "navbar-btn btn btn-sm btn-light ml-auto text-light")}
                            style={navBarButtonStyle}>
                        Waiting
                    </button>
                )
        }
    }
}

const lkNavLinkStyle = {
    margin: '0px',
    fontFamily: 'HKGroteskPro',
    fontSize: '1.0625rem',
    lineHeight: '1.6',
    textAlign: 'left',
    backgroundColor: 'transparent',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    fontWeight: '600',
}

function NavBar(props) {
    const context = useContext(AppContext)

    return (
        <React.Fragment>
            <SignInModal {...props}/>
            <nav className={mapClasses(null, "navbar navbar-expand-md  navbar-light bg-white")}
                 style={navBarStyle}>

                {/* Brand  */}
                <span className={mapClasses(null, "navbar-brand")} style={navBarBrandStyle}>
          <Link to='/home'><img src={brandLogo} style={{maxWidth: '150px'}} className="navbar-brand-img"
                                alt="..."/></Link>
        </span>

                {/* Toggler  */}
                <button className={mapClasses(null, "navbar-toggler")} type="button" data-toggle="collapse"
                        data-target="#navbarCollapse"
                        aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation"
                        style={navBarToggleStyle}>
                    <span className={mapClasses(null, "navbar-toggler-icon")}></span>
                </button>

                {/* Collapse  */}
                <div className={mapClasses(null, "collapse navbar-collapse")} id="navbarCollapse">

                    {/* Toggler  */}
                    <button className={mapClasses(null, "navbar-toggler text-secondary my-auto")} type="button"
                            data-toggle="collapse"
                            data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false"
                            aria-label="Toggle navigation">
                        <X className='mt-n1 pt-2'/>
                    </button>

                    {/* Navigation  */}
                    <ul className={mapClasses(null, "navbar-nav ml-auto")}>
                        <React.Fragment>
                            {context.approved === true ?
                                <li className={mapClasses(null, "nav-item")}>
                                    <Link to="/app" className={mapClasses(null, "nav-link")} style={lkNavLinkStyle}>
                                        App
                                    </Link>
                                </li>
                                : null}


                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" id="navbarSolutions" data-toggle="dropdown"
                                   href="#" aria-haspopup="true" aria-expanded="false">
                                    Solutions
                                </a>
                                {/*<div className={"dropdown-menu dropdown-menu-xs " + showSolutions ? 'show' : ''}*/}
                                <div className={"dropdown-menu dropdown-menu-xs"}
                                     aria-labelledby="navbarSolution">
                                    {/*<div className="row no-gutters">*/}
                                    {/*<div className="col-2">*/}
                                    {/*/!* Heading *!/*/}
                                    {/*<h6 className="dropdown-header">*/}
                                    {/*    Advisors*/}
                                    {/*</h6>*/}

                                    {/* List */}
                                    <Link className="dropdown-item mx-0" style={{maxWidth: '100%'}} to="/advisors">
                                        Advisors
                                    </Link>
                                    <Link className="dropdown-item mx-0" style={{maxWidth: '100%'}} to="/institutions">
                                        Institutions
                                    </Link>
                                    {/*</div>*/}
                                    {/*</div>*/}
                                </div>
                                {/* / .row */}
                            </li>


                            <li className="nav-item dropdown">
                                <a className="nav-link" id="navbarCompany"
                                   href="/blog" >
                                    Blog
                                </a>
                            </li>

                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" id="navbarCompany" data-toggle="dropdown"
                                   href="#" aria-haspopup="true" aria-expanded="false">
                                    Company
                                </a>
                                <div className="dropdown-menu dropdown-menu-xs" aria-labelledby="navbarCompany">
                                    {/*<div className="row no-gutters">*/}
                                    {/*<div className="col-2">*/}
                                    {/*/!* Heading *!/*/}
                                    {/*<h6 className="dropdown-header">*/}
                                    {/*    Advisors*/}
                                    {/*</h6>*/}

                                    {/* List */}
                                    <Link className="dropdown-item mx-0" style={{maxWidth: '100%'}} to="/about">
                                        About
                                    </Link>
                                    {/*<Link className="dropdown-item mx-0" style={{maxWidth: '100%'}} to="/blog">*/}
                                    {/*    Blog*/}
                                    {/*</Link>*/}
                                    {/*<Link className="dropdown-item mx-0" style={{maxWidth: '100%'}} to="/jobs">*/}
                                    {/*    Jobs*/}
                                    {/*</Link>*/}
                                    <Link className="dropdown-item mx-0" style={{maxWidth: '100%'}} to="/contact">
                                        Contact
                                    </Link>
                                    {/*</div>*/}
                                    {/*</div>*/}
                                </div>
                                {/* / .row */}
                            </li>

                            {/*<li className={mapClasses(null, "nav-item")}>*/}
                            {/*    <Link to="/blog" className={mapClasses(null, "nav-link")} style={lkNavLinkStyle}>*/}
                            {/*        Blog*/}
                            {/*    </Link>*/}
                            {/*</li>*/}
                            {/*<li className={mapClasses(null, "nav-item")}>*/}
                            {/*    <Link to="/about" className={mapClasses(null, "nav-link")} style={lkNavLinkStyle}>*/}
                            {/*        About*/}
                            {/*    </Link>*/}
                            {/*</li>*/}
                            {/*<li className={mapClasses(null, "nav-item")}>*/}
                            {/*    <Link to="/jobs" className={mapClasses(null, "nav-link")} style={lkNavLinkStyle}>*/}
                            {/*        Jobs*/}
                            {/*    </Link>*/}
                            {/*</li>*/}
                        </React.Fragment>
                    </ul>

                    <UserOrSignInButton isLoggedIn={context.isLoggedIn}/>

                </div>


            </nav>


        </React.Fragment>

    )
}


export default NavBar