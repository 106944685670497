import React, {useContext, useEffect, useState} from "react"
import headshot from 'images/blog/avatar-100.jpg'
import RequestDemoModal from "../../../pages/common/RequestDemoModal"
import {globalAuthManager, globalServices} from "../../../../services/GlobalServices"
import {apiGet} from "../../../../services/api-call-helpers"
import * as keys from 'keys'
// import {mixpanel} from "../../../../services/mixpanel"
import ReceiveUpdatesModal from "../../../pages/common/ReceiveUpdatesModal"
import {Link} from "react-router-dom"
import BlogSignupSection from "../../common/BlogSignupSection"

import BannerImage from 'images/blog/Blog_20220308_SPYPutSpread/market-selloff-stress.jpg'
import BannerImage500 from 'images/blog/Blog_20220308_SPYPutSpread/market-selloff-stress-500.jpg'

import chartPutVsPutspreadPrice from 'images/blog/Blog_20220308_SPYPutSpread/chart-put-vs-putspread-price.png'
import tablePutVsPutspread from 'images/blog/Blog_20220308_SPYPutSpread/table-put-vs-putspread.png'

import prosAndCons from 'images/blog/Blog_20220120_TLTHedges/ProsAndCons.png'

import jonAvatar from 'images/blog/avatar-100.jpg'

const TLTOverwriteContext = React.createContext({})

const documentTitle = "Clarivor Blog"
const postTitle = 'Equity Put Spreads'
const postSubtitle = 'Buying hedges when the market is already pricing in higher risk'
const postAuthor = 'Jon Spiegel, CFA'
const publishedOnText = 'March 8, 2022'
const publishedOnShort = 'Mar 8'
export const path = 'spy-put-spread-20220308'

const HeroSection = (props) => (
    <section
        style={{
            backgroundImage: ["url(" + BannerImage + ")"],
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            position: 'relative',
            zIndex: 0,
            backgroundSize: 'cover',
            height: '35vh'
        }}>
    </section>
)

const HeaderSection = (props) => (
    <section className="pt-8 pt-md-11">
        <RequestDemoModal/>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 col-md-12 col-lg-10 col-xl-10">


                    <h1 className="display-4 text-center">
                        {props.title}
                    </h1>


                    {/*<p className="lead mb-7 text-center text-muted">*/}
                    <p className="lead px-10 mb-7 text-center text-muted">
                        {props.subtitle}
                    </p>


                    <div className="row align-items-center py-5 border-top border-bottom">
                        <div className="col-auto">


                            <div className="avatar avatar-lg">
                                <img src={props.avatarImage} alt="..."
                                     className="avatar-img rounded-circle"/>
                            </div>

                        </div>
                        <div className="col ml-n5">
                            <h6 className="text-uppercase mb-0">
                                {props.author}
                            </h6>
                        </div>
                        <div className="col-auto">
                            <time className="font-size-sm text-muted" dateTime="2019-05-20">
                                {props.publishedOnText}
                            </time>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
)


function Summary(props) {
    return (
        <React.Fragment>
            <h2 className={'font-weight-bold mt-6'}>
                Summary
            </h2>
            <ul>
                <li className='my-2'>
                    As the Fed considers reducing the size of its balance sheet in 2022, a rise in U.S. interest rates
                    could put pressure on asset prices and create significant challenges for investment portfolios.
                </li>
                <li className='my-2'>
                    As a result, some investment managers may want to hedge interest rates directly, using options on
                    the TLT ETF.
                </li>
                <li className='my-2'>
                    While buying puts on TLT may be expensive currently, investment managers might look to
                    structure hedges more efficiently using multi-leg option strategies, while taking into account
                    specific portfolio risks.
                </li>
            </ul>
        </React.Fragment>
    )
}


function Section1(props) {
    const putRef = React.createRef()
    const psRef = React.createRef()
    const collarRef = React.createRef()
    const pscRef = React.createRef()


    return (
        <React.Fragment>
            <h2 className={'font-weight-bold mt-6'}>
                {/*Overview*/}
            </h2>
            <p>
                About two weeks into the war in Ukraine, with no clear signs of it subsiding, U.S. large cap stocks
                (SPY) are off about 12% from their December high, and 3% below their their January low.
            </p>
            <p>

                Unfortunately for those looking to reduce equity risk, hedging costs have increased since the invasion,
                as they typically do when the market prices in higher uncertainty.
            </p>
            <p>

                Investors may be concerned about “overpaying” to insure their portfolios against losses. That is, they
                don’t want to pay an inflated risk premium that later in retrospect wasn’t required.
            </p>
            <p>

                One way to potentially reduce the risk premium paid for hedging is to buy put-spreads instead of
                outright put options.
            </p>
            <p>

                For example, as of March 7th, a 3-month 95% put on SPY cost approximately 4% of the value of the
                underlying shares. This put option should insure against losses in SPY in excess of 5%.
            </p>
            <p>

                Meanwhile the 95%-85% put-spread cost approximately 2%. The difference in price reflects that the
                put-spread has a maximum payoff of 10% of the underlying share value.
            </p>
            <p>

                To illustrate how the put-spread can exhibit less risk premium, in early January a comparable 3-month
                put cost about 2% in early January, and the comparable put-spread cost about 1.2%.
            </p>
            <div className='card mx-auto my-6' style={{maxWidth: '90%'}}>
                <div className='card-body p-2  bg-white'>
                    <h3 className='text-center'>
                        Price of Put vs. Put-Spread
                    </h3>
                    <img src={chartPutVsPutspreadPrice} className='img-fluid'/>
                </div>
            </div>
            <p>

                While the put-spread became more expensive over the past two months (0.8% of the underlying share
                price), the price of the outright put changed significantly more (2% of the underlying share price).
            </p>
            <p>
                While buying an outright put may require paying a risk premium to the market for insurance against
                losses, the buyer of a put-spread can also receive a risk premium by selling the put with the lower
                strike.
            </p>
            <p>
                Therefore, investors who can forgo insurance against the tail might be able to hedge more efficiently.
            </p>
            <div className='card mx-auto my-6' style={{maxWidth: '90%'}}>
                <div className='card-body p-2  bg-white'>
                    <h3 className='text-center'>
                        Summary of Typical Characteristics
                    </h3>
                    <img src={tablePutVsPutspread} className='img-fluid'/>
                </div>
            </div>
        </React.Fragment>
    )
}

function BufferPerformanceProjectContainer(props) {
    const [projectObj, setProjectObj] = useState(null)

    useEffect(() => {
        apiGet(keys.API_ENDPOINT.PROJECTS, {id: props.projectId}, globalAuthManager).then(
            res => {
                const [project] = res.data
                setProjectObj(project)
            }
        )
    }, [])

    const contextValue = {
        projectObj: projectObj,
        projectId: props.projectId
    }

    return (
        <TLTOverwriteContext.Provider value={contextValue}>
            {props.children}
        </TLTOverwriteContext.Provider>
    )
}


function DisclosuresLink(props) {
    return (
        <a data-toggle="modal" href="#disclaimerModal"
           onClick={() => null}>Disclosures</a>
// onClick={() => mixpanel.track('Disclosure Clicked')}>Disclosures</a>
    )
}

function TermsOfUseLink(props) {
    return (
        <Link to={'/terms-of-use'} target={'_blank'}
              onClick={() => null}>Terms of Use</Link>
// onClick={() => mixpanel.track('Terms of Use Clicked')}>Terms of Use</Link>
    )
}

function BacktestingSourceDisclaimer(props) {
    return (
        <div>
            <hr/>
            <p className='font-italic text-muted'>
                Source: Clarivor LLC. Strikes and maturities are estimated from listed strikes and tenors for easier
                comparison.

                {/*All buffers above refer to a SPY 12-month buffer traded on 12/18/2021, providing 10% downside*/}
                {/*protection, collateralized with cash.*/}
                {/*Options can entail complex risks and may not be suitable for all investors.<br/>*/}
                {/*Each option strategy back-test above includes an annual deduction of 0.49% to reflect management fees*/}
                {/*and commissions. SPY (TR) and AGG (TR) have dividends immediately reinvested, shown with no additional*/}
                {/*management fee.*/}
                {/*Strikes are selected from the listed market.*/}
                {/*Options are rolled seven days prior to expiration at bid or offer.*/}
                Option prices are observed at 3:45pm NY time.
                <br/>
                <br/>
                NOTHING IN THIS POST SHOULD BE CONSTRUED AS INVESTMENT ADVICE. <br/>
                <DisclosuresLink/> and <TermsOfUseLink/> apply.
                <br/>
            </p>
        </div>
    )
}


function PostBody(props) {
    return (
        <React.Fragment>
            <section className="pt-0 pt-md-0">
                <ReceiveUpdatesModal/>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-12 col-lg-10 col-xl-9">
                            {/*<Summary/>*/}
                            <Section1/>
                            <BacktestingSourceDisclaimer/>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}


export default function BufferPerformance_20210803(props) {
    const projectId = null  // this is a static blog post

    useEffect(() => {
        document.title = documentTitle
    }, [])

    const headerProps = {
        title: postTitle,
        subtitle: postSubtitle,
        avatarImage: headshot,
        author: postAuthor,
        publishedOnText: publishedOnShort,
    }

    return (
        <React.Fragment>
            <HeroSection/>
            <HeaderSection {...headerProps} />
            <BufferPerformanceProjectContainer projectId={projectId}>
                <PostBody/>
            </BufferPerformanceProjectContainer>
            <BlogSignupSection fromLocation={'Blog Post TLT Overwriting'}/>
        </React.Fragment>
    )
}


export function RichSPYPutSpreads20220308(props) {
    return (
        <section className="pt-7 pt-md-10">
            <div className="container">
                <div className="row">
                    <div className="col-12">

                        <div className="card card-row shadow-light-lg mb-6 lift lift-lg">
                            <div className="row no-gutters">
                                <div className="col-12">

<span className="badge badge-pill badge-light badge-float badge-float-inside">
<span className="h6 text-uppercase">hedging</span>
</span>
                                </div>
                                <Link className="col-12 col-md-6 order-md-2 bg-cover card-img-right"
                                      style={{backgroundImage: "url(" + BannerImage500 + ")"}}
                                      to={props.blogPath + path}>

                                    <img src="assets/img/photos/photo-27.jpg" alt="..."
                                         className="img-fluid d-md-none invisible"/>

                                    <div
                                        className="shape shape-left shape-fluid-y svg-shim text-white d-none d-md-block">
                                        <svg viewBox="0 0 112 690" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 0h62.759v172C38.62 384 112 517 112 517v173H0V0z"
                                                  fill="currentColor"></path>
                                        </svg>
                                    </div>

                                </Link>
                                <div className="col-12 col-md-6 order-md-1">

                                    <Link className="card-body" to={props.blogPath + path}>

                                        <h3>
                                            {postTitle}
                                        </h3>

                                        <p className="mb-0 text-muted">
                                            {postSubtitle}
                                        </p>

                                    </Link>

                                    <a className="card-meta" href="#!">

                                        <hr className="card-meta-divider"/>

                                        <div className="avatar avatar-sm mr-2">
                                            <img src={jonAvatar} alt="..."
                                                 className="avatar-img rounded-circle"/>
                                        </div>

                                        <h6 className="text-uppercase text-muted mr-2 mb-0">
                                            Jon Spiegel
                                        </h6>

                                        <p className="h6 text-uppercase text-muted mb-0 ml-auto">
                                            <time dateTime="2020-10-23">{publishedOnShort}</time>
                                        </p>

                                    </a>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>

    )
}
