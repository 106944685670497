import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import React from "react"
import * as keys from "../../../../keys"


function createChartOptions(seriesOptions, chartColors) {
    let options =
        {
            series: seriesOptions,

            rangeSelector: {
                enabled: false
            },

            navigator: {
                enabled: false
            },

            scrollbar: {
                enabled: false
            },

            chart: {
                backgroundColor: 'transparent',

                events: {
                    render: function (event) {

                    }
                },
                style: {
                    fontFamily: "Arial, Helvetica, sans-serif"
                }
            },

            legend: {
                enabled: true,
                itemStyle: {
                    "color": "#333333",
                    "cursor": "pointer",
                    "fontSize": "12px",
                    "fontWeight": "normal",
                    "textOverflow": "ellipsis"
                }
            },

            title: {
                text: 'Total Return of QQQ vs SPY ETFs'
            },

            credits: {
                enabled: false
            },

            xAxis: {
                type: 'datetime'
            },

            yAxis: {
                title: {
                    text: ''
                },

                labels: {
                    formatter: function () {
                        return (Math.round(this.value) > 0 ? ' + ' : '') + this.value + '%';
                    }
                },
                plotLines: [{
                    value: 0,
                    width: 2,
                    color: 'silver'
                }]
            },

            plotOptions: {
                series: {
                    allowPointSelect: true,
                    compare: 'percent',
                    compareBase: 0,
                    point: {
                        events: {
                            select: (event) => null
                        }
                    }
                }
            },

            tooltip: {
                // pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.change}%)<br/>',
                pointFormat: '<span style="color:{series.color}">{series.name}</span>: ({point.change}%)<br/>',
                valueDecimals: 2,
                split: true
            },

            exporting: {enabled: false},

            colors: Object.values(keys.Color)
        }

    return options
}


export default function PerformanceChart(props) {
    let seriesList = props.seriesList

    if (!seriesList || seriesList.length == 0) {
        return null
    }

    return (
        <HighchartsReact highcharts={Highcharts} constructorType={'stockChart'}
                         options={createChartOptions(seriesList)}/>
    )
}
