import React, {useContext, useEffect, useState} from "react"
import {Link, Redirect} from 'react-router-dom'
import {AppContext, AccountManagementContext} from "../../contexts"
import {globalAuthManager} from "../../../services/GlobalServices"
import {apiGet, apiPost} from "../../../services/api-call-helpers"
import * as keys from 'keys'


const defaultFeeArrangements = [
    {
        service: 'solutions',
        fixedMonthlyFee: 99,
        assetFee: .0045,
        freeAsset: 250000
    },
    {
        service: 'research',
        fixedMonthlyFee: 49,
    },
    {
        service: 'discretionary',
        assetFee: .0045,
    },
]

export default function AgreeToTermsPage(props) {
    const appContext = useContext(AppContext)
    const accountManagementContext = useContext(AccountManagementContext)
    const [productSelection, setProductSelection] = useState(null)
    const [agreeToTOU, setAgreeToTOU] = useState(false)
    const [agreeToClientAgreement, setAgreeToClientAgreement] = useState(false)
    const [negotiatedFeeArrangement, setNegotiatedFeeArrangement] = useState(null)
    const [clientInfo, setClientInfo] = useState({})
    const [clientAgreementSigned, setClientAgreementSigned] = useState(false)

    useEffect(() => {
        if (appContext.isLoggedIn) {
            apiGet(keys.API_ENDPOINT.USER_PROFILE, {}, globalAuthManager).then(
                res => {
                    setProductSelection(res.data.product)
                }
            ).catch(error => console.log(error))

            apiGet(keys.API_ENDPOINT.FEES, {}, globalAuthManager).then(
                res => {
                    if (res.data != null && res.data.length > 0) {
                        setNegotiatedFeeArrangement(res.data[0].data)
                        setClientAgreementSigned(res.data[0].client_agreement_signed)
                    }
                }
            )

            apiGet(keys.API_ENDPOINT.ADVISORY_CLIENT, {}, globalAuthManager).then(
                res => {
                    setClientInfo(res.data.client_info)
                }
            )
        }

    }, [appContext.isLoggedIn])


    if (appContext.isLoggedIn == null) {
        return null
    } else if (appContext.isLoggedIn === false) {
        return <Redirect to={'/account'}/>
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        if (agreeToTOU && (agreeToClientAgreement || clientAgreementSigned)) {
            const params = {
                agreed_to_terms: true,
                agreed_to_client_agreement: true,
                product: productSelection,
                fees: negotiatedFeeArrangement ? negotiatedFeeArrangement : defaultFeeArrangements
            }

            // if client agreement is signed, then we need to set agreed_to_client_agreement to false
            if (clientAgreementSigned) {
                params.fees = {fees: 'N/A - Separate Client Agreement Signed'}
                params.agreed_to_client_agreement = false
            }

            apiPost(keys.API_ENDPOINT.AGREE_TO_TERMS, params, globalAuthManager).then(res => {
                    let stripePriceId = null
                    if (negotiatedFeeArrangement != null) {
                        stripePriceId = negotiatedFeeArrangement.stripePriceId  // need to look into new stripe.
                    }

                    if (stripePriceId == null) {
                        accountManagementContext.history.push('/account/approval-pending')
                    } else {
                        // redirect to stripe

                        // let clientUrl = 'https://www.clarivor.com'
                        //
                        // const stripe = await loadStripe('pk_live_51H5itJCGbR7ypSj9I1VofEHftQitp41e34yqy607XM6xhuu48E9th5xofX6pxIzW5WX4LpgXu1jU5BJz74czMH3Y00gQTg9ZJ2')
                        //
                        // const {error} = await stripe.redirectToCheckout({
                        //     lineItems: [
                        //         {price: priceId, quantity: 1}
                        //     ],
                        //     mode: 'subscription',
                        //     successUrl: clientUrl + '/account/approval-pending',
                        //     cancelUrl: clientUrl + '/account/agree-to-terms',
                        // })
                    }
                }
            ).catch(error => {
                let errorMessage = 'Error submitting.'
                try {
                    const msg = error.response.data.message
                    if (msg) {
                        errorMessage = 'Error: msg'
                    }
                } catch {

                }
                window.alert(errorMessage)
            })
        }
    }


    function discretionaryCheckbox(feeFloat) {
        let feeStr = (100 * feeFloat).toFixed(2)

        return (
            <div className="form-check my-2">
                <input className="form-check-input" type="radio"
                       name={'productSelectionRadios'}
                       id={'discretionary'}
                       value={'discretionary'}
                       onChange={() => {
                           setProductSelection('discretionary')
                       }}
                       required={true}
                       checked={productSelection === 'discretionary'}
                />
                <label className="form-check-label"
                       htmlFor={'solutions'}>{`Discretionary (${feeStr}% Asset Fee)`}</label>
            </div>
        )
    }

    function solutionsCheckbox(fixedMonthlyFee, assetFee, freeAsset, freeTrialDays) {
        let useSubscriptionFee = fixedMonthlyFee == null ? 99 : fixedMonthlyFee
        let useAssetFee = assetFee == null ? .0045 : assetFee

        const description = `Solutions (Subscription Fee: $${useSubscriptionFee}/mo, Asset Fee: ${(useAssetFee * 100).toFixed(2)}%)`

        return (
            <div className="form-check my-2">
                <input className="form-check-input" type="radio"
                       name={'productSelectionRadios'}
                       id={'solutions'}
                       value={'solutions'}
                       onChange={() => {
                           setProductSelection('solutions')
                       }}
                       required={true}
                       checked={productSelection === 'solutions'}
                />
                <label className="form-check-label"
                       htmlFor={'solutions'}>{description}</label>
            </div>
        )
    }

    function researchCheckbox(fixedMonthlyFee, freeTrialDays, description) {
        let useDescription = description
        if (useDescription == null) {
            useDescription = `Research Service ($${fixedMonthlyFee}/mo)`
        }

        return (
            <div className="form-check my-2">
                <input className="form-check-input" type="radio"
                       name={'productSelectionRadios'}
                       id={'research'}
                       value={'research'}
                       onChange={() => {
                           setProductSelection('research')
                       }}
                       required={true}
                       checked={productSelection === 'research'}
                />
                <label className="form-check-label"
                       htmlFor={'research'}>Research Service: {useDescription}</label>
            </div>
        )
    }

    function defaultProductCheckboxes() {
        let [solutionsFees] = defaultFeeArrangements.filter(fa => fa.service === 'solutions')
        let [researchFees] = defaultFeeArrangements.filter(fa => fa.service === 'research')
        let [discretionaryFees] = defaultFeeArrangements.filter(fa => fa.service === 'discretionary')

        return (
            <div>
                {
                    solutionsCheckbox(solutionsFees.fixedMonthlyFee, solutionsFees.assetFee, solutionsFees.freeAsset)
                }
                {
                    researchCheckbox(researchFees.fixedMonthlyFee)
                }
                {
                    discretionaryCheckbox(discretionaryFees.assetFee)
                }
            </div>
        )
    }

    let checkboxes = null

    if (negotiatedFeeArrangement == null) {
        checkboxes = defaultProductCheckboxes()
    } else if (negotiatedFeeArrangement.service === 'discretionary') {
        checkboxes = discretionaryCheckbox(negotiatedFeeArrangement.assetFee)
    } else if (negotiatedFeeArrangement.service === 'solutions') {
        checkboxes = solutionsCheckbox(negotiatedFeeArrangement.fixedMonthlyFee, negotiatedFeeArrangement.assetFee)
    } else if (negotiatedFeeArrangement.service === 'research') {
        checkboxes = researchCheckbox(negotiatedFeeArrangement.fixedMonthlyFee, negotiatedFeeArrangement.freeTrialDays,
            negotiatedFeeArrangement.description)
    }


    const agreementPath = clientInfo.clientType == 'adviser' ? '/adviser-client-agreement' : '/client-agreement'

    return (
        <section className="pt-8 pt-md-11 pb-8 pb-md-14 bg-light" style={{minHeight: '70vh'}}>
            <div className="container">
                <div className='row justify-content-center my-5'>
                    <div className='col-12 col-md-8 text-center'>
                        <div className={'card'}>
                            <div className={'card-body text-left'}>
                                <form onSubmit={handleSubmit}>
                                    {
                                        clientAgreementSigned ? null :
                                            <React.Fragment>
                                                <p>Product Selection:</p>
                                                <div className={'pl-md-3'}>
                                                    {
                                                        checkboxes
                                                    }
                                                </div>
                                                <hr className={'my-6'}/>
                                            </React.Fragment>
                                    }
                                    {
                                        clientAgreementSigned ?
                                            <React.Fragment>
                                                <div className={'row'}>
                                                    <div className='col-12'>
                                                        Please check the following box to acknowledge that you have read
                                                        and
                                                        accept
                                                        the <Link to={'/terms'} target='_blank'
                                                                  className={'my-auto'}> Website Terms of Use.</Link>
                                                    </div>
                                                </div>
                                                <div className={'row'}>
                                                    <div
                                                        className='col-12 d-flex justify-content-center'> {/* Use Flexbox for horizontal centering */}
                                                        <div className="form-check my-3 mx-auto">
                                                            <input className="form-check-input" type="checkbox"
                                                                   name={'termsOfUse'}
                                                                   id={'termsOfUse'}
                                                                   onChange={(event) => {
                                                                       setAgreeToTOU(event.target.checked)
                                                                   }}
                                                                   required={true}
                                                            />
                                                            <label className="form-check-label"
                                                                   htmlFor={'termsOfUse'}>I agree</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <p>Please read and agree with the following terms to continue:</p>
                                                <div className={'row'}>
                                                    <div className={'col-6 d-flex align-middle'}>
                                                        <Link to={agreementPath} target='_blank' className={'my-auto'}>
                                                            Client Advisory Agreement
                                                        </Link>
                                                    </div>
                                                    <div className={'col-6'}>
                                                        <div className="form-check my-3">
                                                            <input className="form-check-input" type="checkbox"
                                                                   name={'termsOfUse'}
                                                                   id={'termsOfUse'}
                                                                   onChange={(event) => {
                                                                       setAgreeToClientAgreement(event.target.checked)
                                                                   }}
                                                                   required={true}
                                                            />
                                                            <label className="form-check-label"
                                                                   htmlFor={'termsOfUse'}>I agree</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'row'}>
                                                    <div className='col-6 d-flex align-middle'>
                                                        <Link to='/terms' target='_blank' className={'my-auto'}>
                                                            Website Terms of Use
                                                        </Link>
                                                    </div>
                                                    <div className={'col-6'}>
                                                        <div className="form-check my-3">
                                                            <input className="form-check-input" type="checkbox"
                                                                   name={'termsOfUse'}
                                                                   id={'termsOfUse'}
                                                                   onChange={(event) => {
                                                                       setAgreeToTOU(event.target.checked)
                                                                   }}
                                                                   required={true}
                                                            />
                                                            <label className="form-check-label"
                                                                   htmlFor={'termsOfUse'}>I agree</label>
                                                        </div>
                                                    </div>
                                                </div>

                                            </React.Fragment>
                                    }
                                    <div className="mt-6">
                                        <button type="submit" className="btn btn-block btn-success lift">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

