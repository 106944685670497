import React, {useContext, useEffect} from "react"
import * as keys from 'keys'
import {AccountManagementContext} from "../../contexts"
import {Link} from "react-router-dom"

const FormContext = React.createContext({})

// required is true by default
const TextFieldGroup = (props) => {
    const context = useContext(FormContext)
    let required = true
    if (props.required != null) {
        required = props.required
    }

    return (<div className="form-label-group">
            <input type="text" className="form-control form-control-flush" name={props.name}
                   placeholder={props.label} onChange={context.handleTextChange} required={required}
                   defaultValue={context.defaultFormFields[props.name]}
            />

            <label>{props.label}</label>
        </div>
    )
}


const LegalEntityFields = (props) => {
    const context = useContext(FormContext)
    let required = true
    if (props.required != null) {
        required = props.required
    }

    const legalEntityChoices = ['Limited Liability Company', 'Corporation', 'Partnership', 'Sole Proprietorship', 'Other']

    return (
        <div className='row pb-6'>
            <div className='col'>
                <div className="form-label-group">
                    <select className="form-control form-control-flush" name={'entityType'}
                            onChange={context.handleTextChange} required={required}
                    >
                        <option value=''>(Select)</option>
                        {
                            legalEntityChoices.map(lec =>
                                <option value={lec} selected={lec === context.formFields.entityType}>{lec}</option>)
                        }
                    </select>
                    <label>Organization Type</label>
                </div>
            </div>
            <div className='col'>
                <div className="form-label-group">
                    <select className="form-control form-control-flush" name={'stateOfFormation'}
                            onChange={context.handleTextChange} required={required}
                    >
                        {
                            <option value=''>(Select)</option>
                        }
                        {
                            keys.STATES.map((nameAbbrPair) => <option
                                value={nameAbbrPair[0]}
                                selected={nameAbbrPair[0] === context.formFields.stateOfFormation}>{nameAbbrPair[0]}</option>)
                        }
                    </select>
                    <label>State of Formation</label>
                </div>
            </div>
        </div>
    )
}


const StateAndZipFields = (props) => {
    const context = useContext(FormContext)
    let required = true
    if (props.required != null) {
        required = props.required
    }

    return (
        <div className='row'>
            <div className='col'>
                <div className="form-label-group">
                    <select className="form-control form-control-flush" name={'state'}
                            onChange={context.handleTextChange} required={required}
                    >
                        {
                            <option value=''>(Select)</option>
                        }
                        {
                            keys.STATES.map((nameAbbrPair) => <option
                                value={nameAbbrPair[0]}
                                selected={nameAbbrPair[0] === context.formFields.state}>{nameAbbrPair[0]}</option>)
                        }
                    </select>
                    <label>State</label>
                </div>
            </div>
            <div className='col'>
                <TextFieldGroup name={'zip'} label={'Zip'}/>
            </div>
        </div>
    )
}


// props:
//  setFieldValue(k, v)
//  submit()
//  errorMessage
export default function ContactInfoForm(props) {
    const [clientType, setClientType] = React.useState(null)
    const [companyLabel, setCompanyLabel] = React.useState('Company')
    const [termsOfUseChecked, setTermsOfUseChecked] = React.useState(false)
    const context = useContext(AccountManagementContext)


    useEffect(() => setClientType(props.defaultFormFields.clientType), [props.defaultFormFields.clientType])
    useEffect(() => setTermsOfUseChecked(props.defaultFormFields.termsOfUseCheck), [props.defaultFormFields.termsOfUseCheck])

    const handleTextChange = (event) => {
        // console.log('handleTextChange', event.target.name, event.target.value)
        props.setFieldValue(event.target.name, event.target.value)
    }

    const handleAgreeToTermsChanged = (event) => {
        props.setFieldValue('termsOfUseCheck', event.target.checked)
        setTermsOfUseChecked(event.target.checked)
    }

    const handleClientTypeChanged = (event) => {
        // confusing whether value is a string or bool...
        let newClientType = event.target.value.toLowerCase()
        setClientType(newClientType)

        props.setFieldValue('clientType', newClientType)
        let newLabel = ''
        if (newClientType === keys.CLIENT_TYPES.ADVISER) {
            newLabel = 'Adviser'
            setCompanyLabel(newLabel)
        } else if (newClientType === keys.CLIENT_TYPES.INSTITUTION) {
            newLabel = 'Institution'
            setCompanyLabel(newLabel)
        }
    }

    return (
        <FormContext.Provider value={{
            handleTextChange: handleTextChange,
            defaultFormFields: props.defaultFormFields,
            formFields: props.formFields
        }}>
            <form onSubmit={props.submit}>
                <div className={'card'}>
                    <div className={'card-body text-left'}>
                        <div className="row justify-content-center">
                            {
                                props.errorMessage ?
                                    <span className='text-danger my-3'>{props.errorMessage}</span> :
                                    null
                            }
                        </div>
                        <TextFieldGroup name={'firstName'} label={'First Name'}/>
                        <TextFieldGroup name={'middleInitial'} label={'MI'} required={false}/>
                        <TextFieldGroup name={'lastName'} label={'Last Name'}/>
                        <div className={'py-3'}>
                            <div className="form-check my-3">
                                <input type="radio" className="form-check-input mt-2"
                                       name="isIndividual"
                                       id={'isAdviser'}
                                       required={true}
                                       onChange={handleClientTypeChanged} value={keys.CLIENT_TYPES.ADVISER}
                                       checked={clientType === keys.CLIENT_TYPES.ADVISER}
                                />
                                <label className="form-check-label ml-4" htmlFor={'notIsIndividual'}>I am signing up on
                                    behalf of an investment adviser.</label>
                            </div>
                            <div className="form-check my-3">
                                <input type="radio" className="form-check-input mt-2"
                                       name="isIndividual"
                                       id={'isInstitution'}
                                       required={true}
                                       onChange={handleClientTypeChanged} value={keys.CLIENT_TYPES.INSTITUTION}
                                       checked={clientType === keys.CLIENT_TYPES.INSTITUTION}
                                />
                                <label className="form-check-label ml-4" htmlFor={'notIsIndividual'}>I am signing up on
                                    behalf of an institution that invests only for its own account.</label>
                            </div>
                            {
                                context.product === keys.PRODUCT_IDS.basicTool ? null :
                                    <div className="form-check my-3">
                                        <input type="radio" className="form-check-input mt-2" name="isIndividual"
                                               id={'isIndividual'}
                                               required={true}
                                               onChange={handleClientTypeChanged} value={keys.CLIENT_TYPES.INDIVIDUAL}
                                               htmlFor={'notIsIndividual'}
                                               checked={clientType === keys.CLIENT_TYPES.INDIVIDUAL}
                                        />
                                        <label className="form-check-label ml-4">I am signing up as an
                                            individual.</label>
                                    </div>
                            }
                        </div>
                        <hr/>

                        {
                            clientType === keys.CLIENT_TYPES.INDIVIDUAL ? null :
                                <React.Fragment>
                                    <TextFieldGroup name={'company'} label={companyLabel + ' Name'}/>
                                    {
                                        context.product === keys.PRODUCT_IDS.basicTool ? null :
                                            <LegalEntityFields name={'entityType'} label={'Organization Type'}/>
                                    }
                                </React.Fragment>
                        }

                        <TextFieldGroup name={'streetAddress1'} label={companyLabel + ' Street Address 1'}/>
                        <TextFieldGroup name={'streetAddress2'} label={companyLabel + ' Street Address 2'}
                                        required={false}/>

                        <TextFieldGroup name={'city'} label={'City'}/>
                        <StateAndZipFields/>
                        {/*<TextFieldGroup name={'country'} label={'Country'}/>*/}
                        <TextFieldGroup name={'phone'} label={'Phone Number'}/>

                        {
                            // for basic tool, we put the agree to terms in the same form as the contact info.
                            context.product !== keys.PRODUCT_IDS.basicTool ? null :
                                <React.Fragment>
                                    {/*<hr className={'my-6'}/>*/}
                                    <div className='row my-8'>
                                        <div className={'col-6'}>
                                            <div className="form-check my-3">
                                                <input className="form-check-input" type="checkbox"
                                                       name={'termsOfUseCheck'}
                                                       id={'termsOfUseCheck'}
                                                       required={true}
                                                       onChange={handleAgreeToTermsChanged}
                                                       checked={termsOfUseChecked}
                                                />
                                                <label className="form-check-label"
                                                       htmlFor={'termsOfUseCheck'}>I agree to the <Link to='/terms'
                                                                                                         target='_blank'
                                                                                                         className={'my-auto'}>
                                                    Terms of Use
                                                </Link>
                                                </label>
                                            </div>
                                        </div>
                                        {/*<div className='col-6 d-flex align-middle'>*/}
                                        {/*</div>*/}
                                    </div>
                                </React.Fragment>
                        }

                        <div className="mt-6">
                            <button type="submit" className="btn btn-block btn-success lift">Submit</button>
                        </div>
                    </div>
                </div>
            </form>
        </FormContext.Provider>
    )
}


