import React, {Suspense, lazy, useEffect,} from 'react'
import {Route, Switch, Redirect,} from 'react-router-dom'
import Homepage from 'components/pages/homepage/Homepage'
import AdvisorsPage from 'components/pages/advisors/AdvisorsPage'
import InstitutionsPage from 'components/pages/institutions/InstitutionsPage'
import {Component} from 'react'
import * as keys from 'keys'
import PrivacyPolicy from 'components/pages/legal/PrivacyPolicy'
import ContactPage from "./components/pages/contact/ContactPage"
import {AppContext} from "./components/contexts"
import NavBar from "./components/common/NavBarDefault"
import Footer from "./components/common/Footer"
// import {mixpanel} from 'services/mixpanel'
import {globalAuthManager, initGlobalAuthManager} from "./services/GlobalServices"
import About from "./components/pages/about/About"
import Jobs from "./components/pages/jobs/Jobs"
import {apiGet} from "./services/api-call-helpers"
import AccountManagementContainer from "./components/pages/account/AccountManagementContainer"
import PricingSection from "./components/pages/homepage/PricingSection"
import TermsOfUseSection from "./components/pages/legal/TermsOfUseSection"
import NavBarInvestmentApp from "./components/common/NavBarInvestmentApp"
// import ReactGA from 'react-ga'
import {ClientAgreementSection} from "./components/pages/legal/ClientAgreementSection"
import {AdviserClientAgreementSection} from "./components/pages/legal/AdviserClientAgreementSection"
import Referrals from "./components/Referrals"
import BlogContainer from './components/BlogContainer'
import DisclaimerDiv from "./components/pages/legal/DisclaimerDiv"
import Unsubscribe from "./components/Unsubscribe"
import {v4 as uuidv4} from 'uuid'
import {track_ux_event} from "./services/clarivor-tracking"
import 'bootstrap-slider-master/dist/css/bootstrap-slider.css'
import IncomeMonitorPage from "./components/tools/IncomeMonitor"
import BreadcrumbNav from "./components/common/BreadcrumbNav"
import NavbarTest from "./components/common/NavBarTest"
import {setLKBody} from "./lkcss"
import {setDKBody} from "./dkcss"
import ReactModal from 'react-modal'
import {AlertProvider, useAlert} from "./components/common/alerts/AlertsManager";
import CookieConsentAlert from "./components/common/alerts/CookieConsentAlert";
import {API_ENDPOINT} from "keys";
import TestingPanel from "./components/TestingPanel";
import PageNotFound from "./components/pages/common/PageNotFound";

ReactModal.setAppElement('#root')

const InvestmentApp = lazy(() => import('./components/InvestmentApp'))
const InvestmentToolsPage = lazy(() => import('./components/pages/product/InvestmentToolsPage'))
const TradeHubWrapper = lazy(() => import('./components/TradeHubAppWrapper'))

const MAIN_TITLE = 'Clarivor: Custom Option Strategies'

// mixpanel.init("601b8caf04c83c9fdb7efd5442b607ff")
function AddCookieAlertWrapper() {
    useAlert(CookieConsentAlert, () => true, 1);
    return null;
}

function InvestmentAppLoading(props) {
    return (
        <React.Fragment>
            <link rel="stylesheet" type="text/css"
                  href={process.env.PUBLIC_URL + "/dk/assets/css/theme.min.css"}/>
            <NavBarInvestmentApp/>
            <div className={'container'}>
                <div
                    className={'alert alert-light'}>
                    Loading
                    ...
                </div>
            </div>
        </React.Fragment>
    )
}


function browserNotSupported() {
    try {
        const userAgent = window.navigator.userAgent

        if (userAgent.indexOf('Chrome/43.0.2357.134') >= 0) {
            return true
        } else if (userAgent.indexOf('Chrome/36.0.1944.0') >= 0) {
            return true
        } else {
            return false
        }
    } catch (e) {
        return false
    }
}

function BrowserNotSupportedPage() {
    return (
        <React.Fragment>
            <link rel="stylesheet" type="text/css"
                  href={process.env.PUBLIC_URL + "/lk/assets/css/theme.min.css"}/>
            <section>
                <div className="container">
                    <h1 className="my-10 display-3">Your browser is old and not supported. <br/>
                        Please update it to visit Clarivor.</h1>
                </div>
            </section>
        </React.Fragment>
    )
}

class App extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoggedIn: null,
            agreedToTerms: null,
        }

        this.authManager = initGlobalAuthManager(keys.API_ENDPOINT.TOKEN, keys.API_ENDPOINT.TOKENREFRESH, this.setLoggedInState)

        if (localStorage.uuid == null) {
            localStorage.uuid = uuidv4()
        }

        track_ux_event(localStorage.uuid, 'App Loaded', {
            referrer: document.referrer,
            pathname: this.props.location.pathname
        }, globalAuthManager)
    }

    async componentDidMount() {

        if (browserNotSupported()) {
            track_ux_event(localStorage.uuid, 'Browser Not Supported', {userAgent: window.navigator.userAgent}, globalAuthManager)
        }
// ReactGA.initialize('UA-139968594-1')
// ReactGA.pageview('/homepage')

// mixpanel.track('Site Mounted')

// Initialize google analytics page view tracking
        this.props.history.listen(location => {
            track_ux_event(localStorage.uuid, 'New Location', {pathname: location.pathname}, globalAuthManager)
// ReactGA.set({page: location.pathname}) // Update the user's current page
// ReactGA.pageview(location.pathname) // Record a pageview for the given page
// mixpanel.track('Route Location', {pathname: location.pathname})
        })

        if (!this.authManager.haveStoredRefreshToken()) {
// pinging server either way:
            await this.authManager.tryLoginWithRefreshTokenAsync()
            this.setState({isLoggedIn: false})
        } else {
            try {
                let result = await this.authManager.tryLoginWithRefreshTokenAsync()
                this.setState({isLoggedIn: result})
            } catch (e) {
                console.error(e)
            }
        }
    }

    refreshUserProfile = () => {
        apiGet(keys.API_ENDPOINT.USER_PROFILE, {}, globalAuthManager).then(
            res => {
                const userProfile = res.data
                this.setState({
                    agreedToTerms: userProfile.agreed_to_terms,
                    userPrivileges: userProfile.privileges,
                    approved: userProfile.approved_by_clarivor_dt != null,
                    defaultApp: userProfile.default_app
                })
            }
        ).catch(e => {
            console.error(e)
        })
    }

    setLoggedInState = async (s) => {
        if (s == true) {
            this.setState({isLoggedIn: s})

            this.refreshUserProfile()

            apiGet(keys.API_ENDPOINT.USER, {}, this.authManager).then(
                res => {
                    const username = res.data.username
                    this.setState({username: username})
                }
            )
        } else {
            this.setState({isLoggedIn: s})
        }
    }

    setAcceptedTermsState = async (s) => {
        this.setState({agreedToTerms: s})
    }

    render() {
        if (browserNotSupported()) {
            return <BrowserNotSupportedPage/>
        }

        return (
            <AppContext.Provider value={{
                userPrivileges: this.state.userPrivileges,
                username: this.state.username,
                isLoggedIn: this.state.isLoggedIn,
                agreedToTerms: this.state.agreedToTerms,
                approved: this.state.approved,
                setLoggedInState: this.setLoggedInState,
                refreshUserProfile: this.refreshUserProfile,
                defaultApp: this.state.defaultApp,
            }}>
                <AlertProvider>
                    <AddCookieAlertWrapper/>
                    <Switch>
                        <Route
                            exact path="/"
                            render={(props) => {
                                if (this.state.isLoggedIn && this.state.approved) {
                                    if (this.state.defaultApp === 'tradehub') {
                                        return <Redirect to={'/tradehub'}/>
                                    } else {
                                        return <Redirect to={'/app'}/>
                                    }
                                }

                                document.title = MAIN_TITLE

                                return (
                                    <React.Fragment>
                                        <link rel="stylesheet" type="text/css"
                                              href={process.env.PUBLIC_URL + "/lk/assets/css/theme.min.css"}/>
                                        <Homepage {...props}
                                                  isLoggedIn={this.state.isLoggedIn}
                                                  authManager={this.authManager}/>
                                    </React.Fragment>
                                )
                            }
                            }
                        />
                        <Route
                            path={["/advisors", "/advisor-solutions"]}
                            render={(props) => {
                                document.title = 'Clarivor for Advisors'
                                return (
                                    <React.Fragment>
                                        <link rel="stylesheet" type="text/css"
                                              href={process.env.PUBLIC_URL + "/lk/assets/css/theme.min.css"}/>
                                        <AdvisorsPage {...props}
                                                      isLoggedIn={this.state.isLoggedIn}
                                                      authManager={this.authManager}/>
                                    </React.Fragment>
                                )
                            }
                            }
                        />
                        <Route
                            path={["/product/tools",]}
                            render={(props) => {
                                document.title = 'Clarivor Investment Tools'
                                return (
                                    <React.Fragment>
                                        <link rel="stylesheet" type="text/css"
                                              href={process.env.PUBLIC_URL + "/lk/assets/css/theme.min.css"}/>
                                        <Suspense fallback={null}>
                                            <InvestmentToolsPage {...props}
                                                                 isLoggedIn={this.state.isLoggedIn}
                                                                 authManager={this.authManager}/>
                                        </Suspense>
                                    </React.Fragment>
                                )
                            }
                            }
                        />
                        <Route
                            path="/institutions"
                            render={(props) => {
                                document.title = 'Clarivor for Institutions'
                                return (
                                    <React.Fragment>
                                        <link rel="stylesheet" type="text/css"
                                              href={process.env.PUBLIC_URL + "/lk/assets/css/theme.min.css"}/>
                                        <InstitutionsPage {...props}
                                                          isLoggedIn={this.state.isLoggedIn}
                                                          authManager={this.authManager}/>
                                    </React.Fragment>
                                )
                            }
                            }
                        />
                        <Route
                            path="/r"
                            render={(props) => {
                                return (
                                    <Referrals {...props}/>
                                )
                            }
                            }
                        />
                        <Route
                            exact path="/home"
                            render={(props) => {
                                document.title = MAIN_TITLE
                                return (
                                    <React.Fragment>
                                        <link rel="stylesheet" type="text/css"
                                              href={process.env.PUBLIC_URL + "/lk/assets/css/theme.min.css"}/>
                                        <Homepage {...props}
                                                  isLoggedIn={this.state.isLoggedIn}
                                                  authManager={this.authManager}/>
                                    </React.Fragment>
                                )
                            }
                            }
                        />
                        <Route
                            exact path="/test-5431"
                            render={(props) => {
                                return (
                                    <TestingPanel/>
                                )
                            }
                            }
                        />
                        <Route
                            exact path="/about"
                            render={(props) => {
                                document.title = 'About Clarivor'
                                return (
                                    <React.Fragment>
                                        <link rel="stylesheet" type="text/css"
                                              href={process.env.PUBLIC_URL + "/lk/assets/css/theme.min.css"}/>
                                        <About {...props} isLoggedIn={this.state.isLoggedIn}
                                               authManager={this.authManager}/>
                                    </React.Fragment>
                                )
                            }}
                        />
                        <Route
                            exact path="/jobs"
                            render={(props) => {
                                document.title = 'Jobs at Clarivor'

                                return (
                                    <React.Fragment>
                                        <link rel="stylesheet" type="text/css"
                                              href={process.env.PUBLIC_URL + "/lk/assets/css/theme.min.css"}/>
                                        <Jobs {...props} isLoggedIn={this.state.isLoggedIn}
                                              authManager={this.authManager}/>
                                    </React.Fragment>
                                )
                            }}
                        />
                        <Route
                            path="/app"
                            render={(props) => {
                                document.title = MAIN_TITLE

                                if (this.state.isLoggedIn == null || this.state.agreedToTerms == null || this.state.approved == null) {
                                    return null
                                } else if (this.state.isLoggedIn === false) {
                                    return <Redirect to="/"/>
                                } else if (this.state.isLoggedIn === true && this.state.approved === false) {
                                    return <Redirect to="/account"/>
                                } else if (this.state.isLoggedIn === true && this.state.approved === true) {
                                    return (
                                        <Suspense fallback={<InvestmentAppLoading/>}>
                                            <React.Fragment>
                                                <link rel="stylesheet" type="text/css"
                                                      href={process.env.PUBLIC_URL + "/dk/assets/css/theme.min.css"}/>
                                                <InvestmentApp isLoggedIn={this.state.isLoggedIn}
                                                               {...props}
                                                               authManager={this.authManager}
                                                               agreedToTerms={this.state.agreedToTerms}/>
                                            </React.Fragment>
                                        </Suspense>
                                    )
                                } else {
                                    return null
                                }
                            }
                            }
                        />
                        <Route
                            path="/tradehub"
                            render={(props) => {
                                document.title = MAIN_TITLE
                                if (this.state.isLoggedIn == null || this.state.agreedToTerms == null || this.state.approved == null
                                    // || this.state.defaultApp !== 'tradehub'
                                ) {
                                    return null
                                } else if (this.state.isLoggedIn === false) {
                                    return <Redirect to="/"/>
                                } else if (this.state.isLoggedIn === true && this.state.approved === false) {
                                    return <Redirect to="/account"/>
                                } else if (this.state.isLoggedIn === true && this.state.approved === true) {
                                    let appNameList = []
                                    if (this.state.username === 'chat-demo@clarivor.com' || this.state.username === 'master@clarivor.com') {
                                        appNameList.push('ChatApplet')
                                    }

                                    if (this.state.username === 'covered-call-demo@clarivor.com') {
                                        appNameList.push('CoveredCallDemoApplet')
                                    }

                                    if (this.state.username === 'master@clarivor.com') {
                                        appNameList.push('CoveredCallAdvisorApplet')
                                    }

                                    if (['cmp_demo@clarivor.com', 'paper_demo@clarivor.com'].includes(this.state.username)) {
                                        appNameList.push('CustomModelPortfolioImplementerApplet')
                                        appNameList.push('CoveredCallDemoApplet')
                                        appNameList.push('CoveredCallAdvisorApplet')
                                    }

                                    return (
                                        <Suspense fallback={() => <div></div>}>
                                            <React.Fragment>
                                                <link rel="stylesheet" type="text/css"
                                                      href={process.env.PUBLIC_URL + "/dk/assets/css/theme.min.css"}/>
                                                <TradeHubWrapper isLoggedIn={this.state.isLoggedIn}
                                                                 appletNameList={appNameList}
                                                                 {...props}
                                                                 authManager={this.authManager}
                                                                 agreedToTerms={this.state.agreedToTerms}/>
                                            </React.Fragment>
                                        </Suspense>
                                    )
                                } else {
                                    return null
                                }
                            }
                            }
                        />
                        <Route
                            path={["/blog"]}
                            render={(props) => {
                                document.title = "Clarivor Blog"

                                setLKBody()

                                return (
                                    <React.Fragment>
                                        <link rel="stylesheet" type="text/css"
                                              href={process.env.PUBLIC_URL + "/lk/assets/css/theme.min.css"}/>
                                        <BlogContainer isLoggedIn={this.state.isLoggedIn}
                                                       {...props}/>
                                    </React.Fragment>
                                )
                            }
                            }
                        />
                        <Route
                            path={["/tools/income-monitor"]}
                            render={(props) => {
                                document.title = 'Clarivor Income Monitor'
                                return (
                                    <React.Fragment>
                                        <link rel="stylesheet" type="text/css"
                                              href={process.env.PUBLIC_URL + "/dk/assets/css/theme.min.css"}/>
                                        <IncomeMonitorPage isLoggedIn={this.state.isLoggedIn}
                                                           {...props}/>
                                    </React.Fragment>
                                )
                            }
                            }
                        />
                        {/*<Route*/}
                        {/*    path={["/tools"]}*/}
                        {/*    render={(props) =>*/}
                        {/*        (*/}
                        {/*            <React.Fragment>*/}
                        {/*                <link rel="stylesheet" type="text/css"*/}
                        {/*                      href={process.env.PUBLIC_URL + "/lk/assets/css/theme.min.css"}/>*/}
                        {/*                <Tools isLoggedIn={this.state.isLoggedIn}*/}
                        {/*                       {...props}/>*/}
                        {/*            </React.Fragment>*/}
                        {/*        )*/}
                        {/*    }*/}
                        {/*/>*/}
                        <Route
                            exact path={["/privacy", "/privacy-policy"]}
                            // exact path="/privacy-policy"
                            render={(props) => {
                                document.title = 'Clarivor Privacy Policy'

                                return (
                                    <React.Fragment>
                                        <link rel="stylesheet" type="text/css"
                                              href={process.env.PUBLIC_URL + "/lk/assets/css/theme.min.css"}/>
                                        <PrivacyPolicy {...props} authManager={this.authManager}
                                                       isLoggedIn={this.state.isLoggedIn}
                                                       authManager={this.authManager}
                                        />
                                    </React.Fragment>
                                )
                            }}

                        />
                        <Route
                            exact path={["/terms", "/terms-of-use"]}
                            render={(props) => {
                                document.title = 'Clarivor Terms of Use'

                                return (
                                    <React.Fragment>
                                        <link rel="stylesheet" type="text/css"
                                              href={process.env.PUBLIC_URL + "/lk/assets/css/theme.min.css"}/>
                                        <NavBar {...this.props} isLoggedIn={this.state.isLoggedIn}/>
                                        <BreadcrumbNav pathList={['Legal', 'Terms of Use']}/>
                                        <div className="container pt-8 pt-md-11"></div>
                                        {/* adding space*/}
                                        <TermsOfUseSection/>
                                        <Footer isLoggedIn={this.props.isLoggedIn}/>
                                    </React.Fragment>
                                )
                            }
                            }
                        />
                        <Route
                            exact path={["/disclosures", "/disclaimers"]}
                            render={(props) => {
                                document.title = 'Clarivor Disclosures'
                                return (
                                    <React.Fragment>
                                        <link rel="stylesheet" type="text/css"
                                              href={process.env.PUBLIC_URL + "/lk/assets/css/theme.min.css"}/>
                                        <NavBar {...this.props} isLoggedIn={this.state.isLoggedIn}/>
                                        <BreadcrumbNav pathList={['Legal', 'Disclosures']}/>
                                        <section>
                                            <div className="container pt-8 pt-md-11">
                                                <div className="row justify-content-center">
                                                    <div className="col col-12 col-lg-8 col-md-10">
                                                        <DisclaimerDiv/>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <Footer isLoggedIn={this.props.isLoggedIn}/>
                                    </React.Fragment>
                                )
                            }}

                        />
                        <Route
                            exact path={["/client-agreement",]}
                            render={(props) => {
                                document.title = 'Clarivor Client Agreement'

                                return (
                                    <React.Fragment>
                                        <link rel="stylesheet" type="text/css"
                                              href={process.env.PUBLIC_URL + "/lk/assets/css/theme.min.css"}/>
                                        <NavBar {...this.props} isLoggedIn={this.state.isLoggedIn}/>
                                        <ClientAgreementSection/>
                                        <Footer isLoggedIn={this.props.isLoggedIn}/>
                                    </React.Fragment>
                                )
                            }
                            }
                        />
                        <Route
                            exact path={["/adviser-client-agreement",]}
                            render={(props) => {
                                document.title = 'Clarivor Client Agreement for Advisers'

                                return (
                                    <React.Fragment>
                                        <link rel="stylesheet" type="text/css"
                                              href={process.env.PUBLIC_URL + "/lk/assets/css/theme.min.css"}/>
                                        <NavBar {...this.props} isLoggedIn={this.state.isLoggedIn}/>
                                        <BreadcrumbNav pathList={['Legal', 'Client Agreement for Advisers']}/>
                                        <div className="mt-8"/>
                                        <AdviserClientAgreementSection/>
                                        <div className="mt-8"/>
                                        <Footer isLoggedIn={this.props.isLoggedIn}/>
                                    </React.Fragment>
                                )
                            }
                            }
                        />
                        <Route
                            exact path="/contact"
                            render={(props) => {
                                document.title = 'Contact Clarivor'

                                return (
                                    <React.Fragment>
                                        <link rel="stylesheet" type="text/css"
                                              href={process.env.PUBLIC_URL + "/lk/assets/css/theme.min.css"}/>
                                        <ContactPage isLoggedIn={this.state.isLoggedIn}
                                                     location={this.props.location}
                                        />
                                    </React.Fragment>
                                )
                            }
                            }

                        />
                        <Route
                            path="/account"
                            render={(props) => {
                                document.title = 'Clarivor User Information'

                                return (<div style={{height: '100%'}}>
                                    <link rel="stylesheet" type="text/css"
                                          href={process.env.PUBLIC_URL + "/lk/assets/css/theme.min.css"}/>
                                    <NavBar {...this.props} isLoggedIn={this.state.isLoggedIn}/>
                                    <AccountManagementContainer isLoggedIn={this.state.isLoggedIn} {...props}/>
                                    <Footer isLoggedIn={this.props.isLoggedIn}/>
                                </div>)
                            }
                            }
                        />
                        <Route
                            exact path={["/signup-for-basic", "/free-signup", "/signup-free", "/free-version"]}
                            render={(props) => {
                                return <Redirect
                                    to={{pathname: '/account/create/basic', 'state': {product: 'basicTool'}}}/>
                            }
                            }
                        />
                        <Route
                            exact path={["/client-sign-up"]}
                            render={(props) => {
                                return <Redirect to={{pathname: '/account/create', 'state': {}}}/>
                            }
                            }
                        />
                        {/*<Route*/}
                        {/*    path="/pricing"*/}
                        {/*    render={(props) => {*/}
                        {/*        window.scrollTo(0, 0)*/}
                        {/*        document.title = 'Clarivor Pricing'*/}
                        {/*        return (*/}
                        {/*            <React.Fragment>*/}
                        {/*                <link rel="stylesheet" type="text/css"*/}
                        {/*                      href={process.env.PUBLIC_URL + "/lk/assets/css/theme.min.css"}/>*/}
                        {/*                <NavBar {...this.props} isLoggedIn={this.state.isLoggedIn}/>*/}
                        {/*                <PricingSection/>*/}
                        {/*                <Footer isLoggedIn={this.props.isLoggedIn}/>*/}
                        {/*            </React.Fragment>*/}
                        {/*        )*/}
                        {/*    }*/}
                        {/*    }*/}
                        {/*/>*/}
                        <Route
                            exact path="/remove-email"
                            render={(props) => {
                                window.scrollTo(0, 0)
                                document.title = 'Clarivor'
                                return (
                                    <React.Fragment>
                                        <link rel="stylesheet" type="text/css"
                                              href={process.env.PUBLIC_URL + "/lk/assets/css/theme.min.css"}/>
                                        <NavBar {...this.props} isLoggedIn={this.state.isLoggedIn}/>
                                        <BreadcrumbNav pathList={['Remove email']}/>
                                        <Unsubscribe {...props}/>
                                        <Footer isLoggedIn={this.props.isLoggedIn}/>
                                    </React.Fragment>
                                )
                            }
                            }
                        />
                        <Route
                            exact path="/remove-email/:unsubscribeId"
                            render={(props) => {
                                window.scrollTo(0, 0)
                                document.title = 'Clarivor'
                                const unsubscribeId = props.match.params.unsubscribeId
                                return (
                                    <React.Fragment>
                                        <link rel="stylesheet" type="text/css"
                                              href={process.env.PUBLIC_URL + "/lk/assets/css/theme.min.css"}/>
                                        <NavBar {...this.props} isLoggedIn={this.state.isLoggedIn}/>
                                        <Unsubscribe {...props} unsubscribeId={unsubscribeId}/>
                                        <Footer isLoggedIn={this.props.isLoggedIn}/>
                                    </React.Fragment>
                                )
                            }
                            }
                        />
                        {/*<Route*/}
                        {/*    path="/stripe-test"*/}
                        {/*    render={(props) =>*/}
                        {/*        (*/}
                        {/*            <React.Fragment>*/}
                        {/*                <link rel="stylesheet" type="text/css"*/}
                        {/*                      href={process.env.PUBLIC_URL + "/dk/assets/css/theme.min.css"}/>*/}
                        {/*                <PricingSection/>*/}
                        {/*            </React.Fragment>*/}
                        {/*        )*/}
                        {/*    }*/}
                        {/*/>*/}

                        {/*This code will capture an email referral like /e1234/blog*/}
                        <Route
                            path={["/e:code", "/e"]}
                            render={(props) => {
                                let code = ''
                                if (props.match.params.code != null) {
                                    code = props.match.params.code
                                }

                                let remainingPath = null
                                const codeLen = code.length
                                remainingPath = props.location.pathname.slice(2 + codeLen)
// mixpanel.track('Routing Email Link', {pathname: remainingPath, code: code})

                                return (
                                    <Redirect to={remainingPath}/>
                                )
                            }}
                        />
                        <Route path="*" exact={true} render={(props) => {
                            return (
                                <React.Fragment>
                                    <link rel="stylesheet" type="text/css"
                                          href={process.env.PUBLIC_URL + "/lk/assets/css/theme.min.css"}/>
                                    <PageNotFound {...props}
                                                  isLoggedIn={this.state.isLoggedIn}
                                                  authManager={this.authManager}/>
                                </React.Fragment>
                            )
                        }
                        }
                        />

                    </Switch>
                </AlertProvider>
            </AppContext.Provider>
        )
    }
}

export default App
