import React, {lazy, Suspense, useState} from "react"
import {mixpanel} from "../services/mixpanel"
import queryString from 'querystring'
import {apiPost} from "../services/api-call-helpers"
import axios from "axios"
import {API_ENDPOINT} from "../keys"

function UnsubscribeForm(props) {
    const removeEmail = props.removeEmail
    const [emailAddress, setEmailAddress] = useState(null)

    function onSubmit(event) {
        event.preventDefault()
        removeEmail(emailAddress)
    }

    return (
        <div className="card">
            <div className="card-body">
                <form onSubmit={onSubmit}>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1" className="form-label">Email address to remove:</label>
                        <input type="email" className="form-control" id="exampleInputEmail1" placeholder="Enter email"
                               onChange={(event) => setEmailAddress(event.target.value)}/>
                    </div>
                    <button type="submit" className="btn btn-sm btn-primary">Submit</button>
                </form>
            </div>
        </div>
    )
}

export default function Unsubscribe(props) {
    const [submitted, setSubmitted] = React.useState(false)
    const [errorOccurred, setErrorOccured] = React.useState(false)
    const [emailAddress, setEmailAddress] = React.useState(null)

    let qs = props.location.search
    if (qs[0] == '?') {
        qs = qs.slice(1)
    }
    let values = queryString.parse(qs)


    function removeEmail(email) {
        setEmailAddress(email)

        values['emailAddress'] = email

        const params = {
            form_data: values,
            form_name: 'Remove Email',
        }

        axios.post(API_ENDPOINT.CONTACT_FORM, params).then(
            res => setSubmitted(true)
        ).catch(error => {
            setErrorOccured(true)
        })
    }

    function renderBody() {
        if (errorOccurred) {
            return (
                <h2>
                    An error occurred.<br/>
                    Please email <strong>contact@clarivor.com</strong> to remove your email from our list.
                </h2>
            )
        } else if (submitted) {
            return (
                <h2>
                    We will stop sending email to {emailAddress}.<br/><br/>
                    Thank you for letting us know.
                </h2>
            )
        } else return (
            <UnsubscribeForm removeEmail={removeEmail}/>
        )
    }


    return (
        <section style={{minHeight: '60vh'}}>
            <div className={'container mt-8'}>
                <div className='row justify-content-center'>
                    <div className='col col-12 col-md-8'>
                        {renderBody()}
                    </div>
                </div>
            </div>
        </section>
    )
}

