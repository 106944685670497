import React, {useContext, useEffect, useState} from "react"
import headshot from 'images/blog/avatar-100.jpg'
import RequestDemoModal from "../../../pages/common/RequestDemoModal"
import {globalAuthManager, globalServices} from "../../../../services/GlobalServices"
import {apiGet} from "../../../../services/api-call-helpers"
import * as keys from 'keys'
import heroBubbles from "images/blog/QQQCollar-2020-10/bubbles.jpg"
import {mixpanel} from "../../../../services/mixpanel"
import ReceiveUpdatesModal from "../../../pages/common/ReceiveUpdatesModal"
import {Link} from "react-router-dom"
import IndicatorTSManager from "../../../../services/IndicatorTSManager"
import QQQCollarStrikeChart from './QQQCollarStrikeChart'
import StrategyTSManager from "../../../../services/StrategyTSManager"
import ETFPerformanceComparisonChart from "./ETFPerformanceComparisonChart"
import CollarPayoffDiagram from "./CollarPayoffDiagram"
import BlogSignupSection from "../../common/BlogSignupSection";
import QQQCollar202010Image from "images/blog/QQQCollar-2020-10/bubbles.jpg"
import jonAvatar from 'images/blog/avatar-100.jpg'

const QQQCollarContext = React.createContext({})

const POST_PATH = '/blog/QQQCollar_20201023'


const HeroSection = (props) => (
    <section
        style={{
            backgroundImage: ["url(" + heroBubbles + ")"],
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            position: 'relative',
            zIndex: 0,
            backgroundSize: 'cover',
            height: '50vh'
        }}>
    </section>
)

const HeaderSection = (props) => (
    <section className="pt-8 pt-md-11">
        <RequestDemoModal/>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 col-md-12 col-lg-10 col-xl-10">


                    <h1 className="display-4 text-center">
                        {props.title}
                    </h1>


                    <p className="lead mb-7 text-center text-muted">
                        {props.subtitle}
                    </p>


                    <div className="row align-items-center py-5 border-top border-bottom">
                        <div className="col-auto">


                            <div className="avatar avatar-lg">
                                <img src={props.avatarImage} alt="..."
                                     className="avatar-img rounded-circle"/>
                            </div>

                        </div>
                        <div className="col ml-n5">
                            <h6 className="text-uppercase mb-0">
                                {props.author}
                            </h6>
                        </div>
                        <div className="col-auto">
                            <time className="font-size-sm text-muted" dateTime="2019-05-20">
                                {props.publishedOnText}
                            </time>
                        </div>
                    </div>

                </div>
            </div>

        </div>

    </section>
)


const beaLink = "https://www.bea.gov/news/2020/gross-domestic-product-state-2nd-quarter-2020"


function Summary(props) {
    return (
        <React.Fragment>
            <h2 className={'font-weight-bold mt-6'}>
                Summary
            </h2>
            <ul>
                <li className='my-2'>
                    The outperformance of large-cap tech stocks has occurred at a faster pace this year, as QQQ, an ETF
                    tracking the Nasdaq-100, has returned 32% year-to-date, compared with
                    7% for U.S. large-cap stocks more broadly (SPY).
                </li>
                <li className='my-2'>
                    The option market appears to price in further gains on QQQ, compared with SPY.
                </li>
                <li className='my-2'>
                    Investors who are skeptical about the potential for further gains in the Nasdaq-100 can set up a
                    potentially cost-effective hedge by selling QQQ calls to buy QQQ puts.
                </li>
            </ul>
        </React.Fragment>
    )
}


function ETFPerformanceComparison(props) {
    const context = useContext(QQQCollarContext)

    const [strategiesData, setStrategiesData] = useState([])
    const strategyTSManager = new StrategyTSManager(keys.API_ENDPOINT.PROJECT_STRATEGY_TIMESERIES_LIST, globalAuthManager, context.projectId)

    useEffect(() => {
        if (context.projectObj != null) {
            const us_ids = context.projectObj.user_strategies.map(us => us.id)
            strategyTSManager.retrieveTSListAsync(us_ids).then(
                indicatorsDataObjs => {
                    setStrategiesData(indicatorsDataObjs)
                }
            )
        }
    }, [context.projectObj])

    let seriesList = strategiesData.map(strategyData => ({
            name: strategyData.name,
            data: strategyData.data
        })
    )

    const seriesNameOrder = ['QQQ TR', 'SPY TR']

    seriesList.sort((a, b) => seriesNameOrder.findIndex(e => e === a.name) < seriesNameOrder.findIndex(e => e === b.name) ? -1 : 1)


    return (
        <ETFPerformanceComparisonChart seriesList={seriesList}/>
    )
}


function LargeCapGrowthRally(props) {
    return (
        <React.Fragment>
            <h2 className={'font-weight-bold mt-6'}>
                Recent rally in large-cap growth
            </h2>
            <p>
                Despite a historic slowdown in U.S. GDP in 2020, U.S. large growth stocks have continued their 12-year
                bull
                market, helping keeping U.S. equities overall positive this year. Some investors may therefore believe
                that QQQ could sell off significantly in a market correction.
            </p>
            <div className='card bg-light'>
                <div className='card-body'>
                    <ETFPerformanceComparison/>
                </div>
            </div>
        </React.Fragment>
    )
}

const WrittenSection = (props) => {

    const handleToolScroll = (event) => {
        event.preventDefault()
        props.scrollToTool()
    }

    return (
        <section>
            <hr className="hr-md"/>
        </section>
    )
}


function SelectorsWrapper(props) {
    return (
        <div className='card mt-4'>
            <div className='card-header bg-gray-300 text-secondary p-4'>
                Compare Put Strategies
            </div>
            <div className='card-body bg-light p-4'>
                {props.children}
                <div className="form-check">
                    {/*<ShowSPYCheckbox/>*/}
                </div>
            </div>
        </div>
    )
}


function ChartWrapper(props) {
    return (
        <div className='card mt-4 px-0'>
            <div className='card-body border px-0'>
                {props.children}
            </div>
        </div>
    )

}

function QQQCollarProjectContainer(props) {
    const [projectObj, setProjectObj] = useState(null)

    useEffect(() => {
        apiGet(keys.API_ENDPOINT.PROJECTS, {id: props.projectId}, globalAuthManager).then(
            res => {
                const [project] = res.data
                setProjectObj(project)
            }
        )
    }, [])

    const contextValue = {
        projectObj: projectObj,
        projectId: props.projectId
    }

    return (
        <QQQCollarContext.Provider value={contextValue}>
            {props.children}
        </QQQCollarContext.Provider>
    )
}


function QQQCollarStrikeChartContainer(props) {
    const [indicatorsData, setIndicatorsData] = useState(null)
    const context = useContext(QQQCollarContext)
    const indicatorTSManager = new IndicatorTSManager(keys.API_ENDPOINT.PROJECT_INDICATOR_TIMESERIES_LIST, globalAuthManager, context.projectId)

    useEffect(() => {
        if (context.projectObj != null) {
            const ui_ids = context.projectObj.user_indicators.map(ui => ui.id)
            indicatorTSManager.retrieveTSListAsync(ui_ids).then(
                indicatorsDataObjs => {
                    setIndicatorsData(indicatorsDataObjs)
                }
            )
        }
    }, context.projectObj)


    if (context.projectObj == null) {
        return null
    }

    const qqqPutsContext = {}

    let seriesList = []

    if (indicatorsData != null) {
        seriesList = indicatorsData.map(indicatorData => ({
            name: indicatorData.name,
            data: JSON.parse(JSON.stringify(indicatorData.data))
        }))
    }

    return (
        <QQQCollarStrikeChart seriesList={seriesList}/>
    )
}


function RequestDemoSection(props) {
    return (
        <section>
            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-12 col-md-8 col-lg-6'>
                        <div className='card border border-primary'>
                            <div className='card-body'>
                                <div className='row justify-content-center'>
                                    <h3 className={'text-center'}>
                                        Get in touch with Clarivor for more analysis on collars and other option
                                        strategies
                                    </h3>
                                </div>
                                <div className='row justify-content-center'>
                                    <a className='mx-auto' data-toggle="modal" href="#requestDemoModal"
                                       style={{textDecoration: 'none'}}
                                       onClick={() => {
                                           mixpanel.track('Request Demo clicked')
                                       }}>
                                        <button className="btn btn-success lift mt-2 d-flex">
                                            <span className={'my-auto'}>Request Demo</span>
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

function DisclosuresLink(props) {
    return (
        <a data-toggle="modal" href="#disclaimerModal"
           onClick={() => mixpanel.track('Disclosure Clicked')}>Disclosures</a>
    )
}

function TermsOfUseLink(props) {
    return (
        <Link to={'/terms-of-use'} target={'_blank'}
              onClick={() => mixpanel.track('Terms of Use Clicked')}>Terms of Use</Link>
    )
}

function BacktestingSourceDisclaimer(props) {
    return (
        <div className='card'>
            <div className='card-body font-italic text-muted'>
                Source: Clarivor LLC. Transactions occur at bid or
                offer. Any applicable fees or commissions are not reflected. Option prices observed at
                3:45pm NY time.
                These results are back-tested and may not be indicative of future performance. NOTHING IN THIS POST
                SHOULD BE CONSTRUED AS INVESTMENT ADVICE. <DisclosuresLink/> and <TermsOfUseLink/> apply.
            </div>
        </div>
    )
}

function UsingACollar(props) {
    return (
        <React.Fragment>
            <h2 className={'font-weight-bold mt-6'}>
                Using a collar
            </h2>
            <p>
                As discussed in <Link target='_blank' to={'/blog/SPYPuts_20201014'}>another post</Link>, holding
                puts alone as hedges can be very expensive. However, investors may be able to
                efficiently offset the cost of puts by selling calls, creating a so-called collar
                strategy.
            </p>
            <p>
                The collar strikes can be selected so that the net cost (put price - call price) is
                approximately zero. In return for gaining downside protection, an investor using a zero-cost collar caps
                their upside rather than paying cash for the put.
                At maturity, the hedge pays out as the ETF
                price falls below the put's strike. Conversely, the hedge will suffer losses if the ETF
                price
                trades above the strike of the call sold.
            </p>
            <p>
                An advantage of the collar, over some other hedging strategies that are designed to be cost-effective,
                is that the put provides uncapped downside protection below the put strike.
            </p>
            <p>
                As of October 22nd, an investor could sell a QQQ 6-month call 7.3% out-of-the-money
                to buy a 6-month put 10% out-of-the money, for approximately zero cost.
            </p>
            <div className={'card bg-light'}>
                <div className={'card-body'}>
                    <CollarPayoffDiagram/>
                </div>
            </div>
        </React.Fragment>
    )
}

function QQQvsSPY(props) {
    return (
        <React.Fragment>
            <h2 className={'font-weight-bold mt-6'}>
                QQQ collar call-strike is relatively high, currently
            </h2>

            <p>
                For comparing collar prices historically, and between different ETFs, we look at the strike of the
                6-month call that needs to be sold to offset the cost of a 6-month put that is 10% out of the money.
                The higher the
                call-strike, the more upside potential the investor retains in the ETF.
            </p>
            <p>
                As of October 22nd, the 7.3% upside provided by the QQQ collar is at the 91st percentile based on the
                past
                five years.
            </p>
            <p>
                In contrast, the call-strike of the SPY 6-month collar with a 90% put is 4.5%. SPY collars have
                historically provided less upside than QQQ. However, the additional upside of 2.8%
                (7.3% - 4.5%) is at the 98th percentile.
            </p>
            <p>
                The call-strike of the zero-cost collar is based on the supply and demand of puts and calls. With QQQ having
                outperformed broader equities, the option market appears positioned for a continuation of this trend.
                For investors with the view that further significant gains in QQQ are unlikely, a QQQ collar could be an
                efficient hedge.
            </p>
            <div
                className={'card bg-light'}>
                <div
                    className={'card-body'}>
                    <QQQCollarStrikeChartContainer/>
                </div>
            </div>
        </React.Fragment>
    )
}


function QQQCollarPostBody(props) {
    return (
        <React.Fragment>
            <section className="pt-6 pt-md-8">
                <ReceiveUpdatesModal/>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-12 col-lg-10 col-xl-9">
                            <Summary/>
                            <LargeCapGrowthRally/>
                            <UsingACollar/>
                            <QQQvsSPY/>
                            <BacktestingSourceDisclaimer/>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}


export default function TLT_Overwriting_20200412(props) {
    const projectId = 'ab5b35a8-c22d-46d0-a16f-28c0828ef26a'

    useEffect(() => {
        mixpanel.track('QQQPuts post mounted')
        document.title = "Clarivor Blog: Nasdaq-100 ETF Hedge"
    }, [])

    const headerProps = {
        title: 'Contrarian Hedge Using Nasdaq-100 ETF',
        subtitle: "The market's bullishness on tech stocks could present an interesting hedging opportunity",
        avatarImage: headshot,
        author: 'Jon Spiegel, CFA',
        publishedOnText: 'October 23, 2020',
    }

    return (
        <React.Fragment>
            <HeroSection/>
            <HeaderSection {...headerProps} />
            <QQQCollarProjectContainer projectId={projectId}>
                <QQQCollarPostBody/>
            </QQQCollarProjectContainer>
            <RequestDemoSection/>
            <BlogSignupSection fromLocation={'Blog Post QQQ Collars 20201022'}/>
        </React.Fragment>
    )
}



export function RichViewQQQCollar(props) {
    return (
        <section className="pt-7 pt-md-10">
            <div className="container">
                <div className="row">
                    <div className="col-12">

                        <div className="card card-row shadow-light-lg mb-6 lift lift-lg">
                            <div className="row no-gutters">
                                <div className="col-12">

                            <span className="badge badge-pill badge-light badge-float badge-float-inside">
                    <span className="h6 text-uppercase">Hedging</span>
                  </span>

                                </div>
                                <Link className="col-12 col-md-6 order-md-2 bg-cover card-img-right"
                                      style={{backgroundImage: "url(" + QQQCollar202010Image + ")"}}
                                      to={POST_PATH}>

                                    <img src="assets/img/photos/photo-27.jpg" alt="..."
                                         className="img-fluid d-md-none invisible"/>

                                    <div
                                        className="shape shape-left shape-fluid-y svg-shim text-white d-none d-md-block">
                                        <svg viewBox="0 0 112 690" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 0h62.759v172C38.62 384 112 517 112 517v173H0V0z"
                                                  fill="currentColor"></path>
                                        </svg>
                                    </div>

                                </Link>
                                <div className="col-12 col-md-6 order-md-1">

                                    <Link className="card-body" to={POST_PATH}>

                                        <h3>
                                            Contrarian Hedge Using Nasdaq-100 ETF
                                        </h3>

                                        <p className="mb-0 text-muted">
                                            The market's apparent bullishness on Nasdaq-100 stocks could present an
                                            interesting hedging opportunity for wary investors
                                        </p>

                                    </Link>

                                    <a className="card-meta" href="#!">

                                        <hr className="card-meta-divider"/>

                                        <div className="avatar avatar-sm mr-2">
                                            <img src={jonAvatar} alt="..."
                                                 className="avatar-img rounded-circle"/>
                                        </div>

                                        <h6 className="text-uppercase text-muted mr-2 mb-0">
                                            Jon Spiegel
                                        </h6>

                                        <p className="h6 text-uppercase text-muted mb-0 ml-auto">
                                            <time dateTime="2020-10-23">Oct 23</time>
                                        </p>

                                    </a>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>

    )
}
