import React, {useContext, useEffect, useState} from "react"
import headshot from 'images/blog/avatar-100.jpg'
import RequestDemoModal from "../../../pages/common/RequestDemoModal"
import {globalAuthManager, globalServices} from "../../../../services/GlobalServices"
import {apiGet} from "../../../../services/api-call-helpers"
import * as keys from 'keys'
// import {mixpanel} from "../../../../services/mixpanel"
import ReceiveUpdatesModal from "../../../pages/common/ReceiveUpdatesModal"
import {Link} from "react-router-dom"
import {useAlert} from "../../../common/alerts/AlertsManager";
import BlogSignupSection from "../../common/BlogSignupSection"

import BannerImage from 'images/blog/Blog_20220922_CalendarCollar/banner.jpg'
import BannerImage500 from 'images/blog/Blog_20220922_CalendarCollar/banner500.jpg'

import chart2021 from 'images/blog/Blog_20220922_CalendarCollar/chart2021.png'
import chart2020 from 'images/blog/Blog_20220922_CalendarCollar/chart2020.png'
import chart2018 from 'images/blog/Blog_20220922_CalendarCollar/chart2018.png'
import longTermPerformance from 'images/blog/Blog_20220922_CalendarCollar/longTermPerformance.png'

import jonAvatar from 'images/blog/avatar-100.jpg'
import HypotheticalPerformanceAlert from "../../../common/alerts/HypotheticalPerformanceAlert";

const ProjectContext = React.createContext({})

const documentTitle = "Clarivor Blog"
const postTitle = 'Calendar Collar Overlay'
const postSubtitle = 'A variation on the traditional collar that could reduce hedging costs'
const postAuthor = 'Jon Spiegel, CFA'
const publishedOnText = 'September 22, 2022'
const publishedOnShort = 'Sep 22'

export const path = 'calendar-collar-20220922'

const HeroSection = (props) => (
    <section
        style={{
            backgroundImage: ["url(" + BannerImage + ")"],
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            position: 'relative',
            zIndex: 0,
            backgroundSize: 'cover',
            height: '35vh'
        }}>
    </section>
)

const HeaderSection = (props) => (
    <section className="pt-8 pt-md-11">
        <RequestDemoModal/>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 col-md-12 col-lg-10 col-xl-10">


                    <h1 className="display-4 text-center">
                        {props.title}
                    </h1>


                    {/*<p className="lead mb-7 text-center text-muted">*/}
                    <p className="lead px-10 mb-7 text-center text-muted">
                        {props.subtitle}
                    </p>


                    <div className="row align-items-center py-5 border-top border-bottom">
                        <div className="col-auto">


                            <div className="avatar avatar-lg">
                                <img src={props.avatarImage} alt="..."
                                     className="avatar-img rounded-circle"/>
                            </div>

                        </div>
                        <div className="col ml-n5">
                            <h6 className="text-uppercase mb-0">
                                {props.author}
                            </h6>
                        </div>
                        <div className="col-auto">
                            <time className="font-size-sm text-muted" dateTime="2019-05-20">
                                {props.publishedOnText}
                            </time>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
)


function Summary(props) {
    return (
        <React.Fragment>
            <h3 className={'font-weight-bold mt-6'}>
                Summary
            </h3>
            <ul>
                <li className='my-2'>
                    With bonds providing less diversification since the beginning of this year, investors may
                    increasingly look to protect
                    equity portfolios against severe losses.
                </li>
                <li className='my-2'>
                    Meanwhile, a well-known tail risk hedging strategy, the collar, can significantly
                    under-perform in years when equities have large gains, like in 2021.
                </li>
                <li className='my-2'>
                    A variation on the traditional collar, the calendar collar, can provide portfolio protection with
                    reduced risk of missing a sustained equity rally.
                </li>
            </ul>
        </React.Fragment>
    )
}


function Section1(props) {
    const putRef = React.createRef()
    const psRef = React.createRef()
    const collarRef = React.createRef()
    const pscRef = React.createRef()


    return (
        <React.Fragment>
            <h3 className={'font-weight-bold mt-6'}>
                Equity Puts and Traditional Collars
            </h3>
            <p>
                For clients who cannot tolerate severe equity market sell-offs, investment managers either need to
                allocate to safe assets or implement solutions to protect equity portfolios. Now that bonds have been
                providing less diversification than they have in the past several decades, the need for protective
                solutions is more important than ever.
            </p>
            <p>
                Put options on equity indices or equity ETFs can act like insurance on equity portfolios. However,
                investors who need protection often don't want to pay what the market charges for puts. One way to
                offset the upfront cost of a put is to pay for it using the proceeds from selling a call option, setting
                up a collar overlay.
            </p>
            <p>
                In a traditional collar overlay strategy, an investor may sell a 12-month, out-of-the-money (OTM) call
                to purchase a 12-month OTM put. If the put has a strike that is initially 80% of the initial price of
                the underlying security, the put generally provides insurance against the market declining
                by more than 20%. A significant risk of this strategy is that selling the call option can create
                a large liability in the case where the equity market rallies substantially over the 12-month life
                of the trade.
            </p>
            <h3 className={'font-weight-bold mt-6'}>
                Calendar Collar
            </h3>
            <p>
                A variation of the collar that can reduce this risk involves selling call options with one month
                maturity, on a monthly basis, rather than selling 12-month calls annually.
                This strategy, sometimes called a “calendar collar,” is
                similar to combining a short-term
                covered call strategy with a longer-term purchased put. With a calendar collar, since each month the
                strike of the new short call option can be higher, there is less time for the market to rally
                significantly past
                the strike. Short-dated option strategies can also generate more yield than longer-dated
                strategies with similar strikes.
            </p>
            <p>
                The risk of the traditional collar was exemplified in 2021, when U.S. large-cap equities (SPY ETF) had a
                sustained rally and returned 27.0%. Based on Clarivor’s back-testing, SPY hedged with a collar could
                have returned only 7.5% in 2021, giving up 19.5% in gains. Meanwhile, a calendar collar strategy would
                have returned 22.1%, and a portfolio hedged with a 12-month put would have returned 22.4% </p>
            <div className='card mx-auto my-6' style={{maxWidth: '90%'}}>
                <div className='card-body p-2  bg-white'>
                    <h3 className='text-center'>
                        Collar vs. Calendar Collar in 2021
                    </h3>
                    <img src={chart2021} className='img-fluid'/>
                </div>
            </div>
            <p>
                Like the traditional collar, the calendar collar can offset the premium of the purchased puts, resulting
                in less drag than other hedging strategies. This was
                exhibited in 2020. Clarivor’s back-testing shows that in 2020 a calendar collar overlay could have added
                1.2% to the return of SPY, while the outright put strategy would have subtracted -1.3% and the traditional
                collar would have subtracted -6.1%.
            </p>
            <div className='card mx-auto my-6' style={{maxWidth: '90%'}}>
                <div className='card-body p-2  bg-white'>
                    <h3 className='text-center'>
                        Collar vs. Calendar Collar in 2020
                    </h3>
                    <img src={chart2020} className='img-fluid'/>
                </div>
            </div>
            <h3 className={'font-weight-bold mt-6'}>
                Risk Impacting the Calendar Collar
            </h3>
            <p>
                While the calendar collar may reduce the risk of under-performance in a rally, the calendar collar comes
                with other potential risks. For example, the strategy could be negatively impacted by a market
                exhibiting high volatility and directional changes. This occurred in 2018, when the market was
                choppy and finished the year losing a modest -5%. In 2018 the calendar collar would have
                under-performed both the traditional collar and the outright put:
            </p>

            <div className='card mx-auto my-6' style={{maxWidth: '90%'}}>
                <div className='card-body p-2  bg-white'>
                    <h3 className='text-center'>
                        Collar vs. Calendar Collar in 2018
                    </h3>
                    <img src={chart2018} className='img-fluid'/>
                </div>
            </div>

            <h3 className={'font-weight-bold mt-6'}>
                Long-Term Performance
            </h3>
            <p>
                Despite major sell-offs in 2008 and 2020, the overall significant gains in equity markets have resulted
                in most hedging strategies contributing negatively to portfolios. However when compared to the collar
                and
                outright put, the calendar collar could have provided protection with less cost since 2006.
            </p>
            <div className='card mx-auto my-6' style={{maxWidth: '90%'}}>
                <div className='card-body p-2  bg-white'>
                    <h3 className='text-center'>
                        Long-Term Back-test
                    </h3>
                    <img src={longTermPerformance} className='img-fluid'/>
                </div>
            </div>
            <h3 className={'font-weight-bold mt-6'}>
                Final Thoughts
            </h3>
            <p>
                With many investment managers investors viewing the market at a transition point, hedging strategies may
                be more useful than ever. Investment managers should consider the risks and benefits of different
                strategies
                that provide tail risk protection. Calendar collars could provide benefits when some other strategies
                struggle.
            </p>

        </React.Fragment>
    )
}

function BufferPerformanceProjectContainer(props) {
    const [projectObj, setProjectObj] = useState(null)

    useEffect(() => {
        apiGet(keys.API_ENDPOINT.PROJECTS, {id: props.projectId}, globalAuthManager).then(
            res => {
                const [project] = res.data
                setProjectObj(project)
            }
        )
    }, [])

    const contextValue = {
        projectObj: projectObj,
        projectId: props.projectId
    }

    return (
        <ProjectContext.Provider value={contextValue}>
            {props.children}
        </ProjectContext.Provider>
    )
}


function DisclosuresLink(props) {
    return (
        <a data-toggle="modal" href="#disclaimerModal"
           onClick={() => null}>Disclosures</a>
// onClick={() => mixpanel.track('Disclosure Clicked')}>Disclosures</a>
    )
}

function TermsOfUseLink(props) {
    return (
        <Link to={'/terms-of-use'} target={'_blank'}
              onClick={() => null}>Terms of Use</Link>
// onClick={() => mixpanel.track('Terms of Use Clicked')}>Terms of Use</Link>
    )
}

function BacktestingSourceDisclaimer(props) {
    return (
        <div>
            <hr/>
            <p className='font-italic text-muted'>
                Strategy Details: <br/><br/>
                The calendar collar strategy back-tested above buys 12-month puts, 20%
                out-of-the-money (OTM) on the SPY
                ETF. Each month, a 1-month call is sold with strike closest to 1/12th of the value of a 12-month, 20%
                OTM put. The 12-month put is rolled 7 days prior to maturity, while the short calls are rolled 3 days
                prior to maturity. <br/><br/>
                Similarly, the traditonal collar strategy buys a 12-month 20% OTM put and sells a 12-month call of
                approximately equal value.<br/><br/>
                The weights of the option overlays and SPY are rebalanced quarterly.<br/><br/>
                Each option strategy back-test above includes an annual deduction of 0.39% to reflect management fees
                and commissions.<br/><br/>

                SPY (TR) has dividends immediately reinvested, shown with no additional management fee.
            </p>
            <p className='font-italic text-muted'>
                Source: Clarivor LLC. Option prices are observed at 3:45pm NY time.<br/><br/>


                {/*Strikes and maturities are estimated from listed strikes and tenors for easier comparison.*/}
                {/*All buffers above refer to a SPY 12-month buffer traded on 12/18/2021, providing 10% downside*/}
                {/*protection, collateralized with cash.*/}
                {/*Options can entail complex risks and may not be suitable for all investors.<br/>*/}
                {/*Strikes are selected from the listed market.*/}
                {/*Options are rolled seven days prior to expiration at bid or offer.*/}
                Back-tested results may not be indicative of future returns.  Changes in strategy parameters,
                such as maturity, strike, or roll timing, can have a significant impact on results.
                Options can entail complex risks and may not be suitable for all investors. Nothing in this post should
                be construed as investment advice. <DisclosuresLink/> and <TermsOfUseLink/> apply.
                <br/>
            </p>
        </div>
    )
}


function PostBody(props) {
    return (
        <React.Fragment>
            <section className="pt-0 pt-md-0">
                <ReceiveUpdatesModal/>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-12 col-lg-10 col-xl-9">
                            <Summary/>
                            <Section1/>
                            <BacktestingSourceDisclaimer/>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}


export default function Blog_20220922_CalendarCollar(props) {
    const projectId = null  // this is a static blog post

    useEffect(() => {
        document.title = documentTitle
    }, [])

    const headerProps = {
        title: postTitle,
        subtitle: postSubtitle,
        avatarImage: headshot,
        author: postAuthor,
        publishedOnText: publishedOnText,
    }

    // useAlert(HypotheticalPerformanceAlert, () => true, 3)

    return (
        <React.Fragment>
            <HeroSection/>
            <HeaderSection {...headerProps} />
            <BufferPerformanceProjectContainer projectId={projectId}>
                <PostBody/>
            </BufferPerformanceProjectContainer>
            <div className='container'>
                <HypotheticalPerformanceAlert/>
            </div>
            <BlogSignupSection fromLocation={'Blog Post TLT Overwriting'}/>
        </React.Fragment>
    )
}


export function RichCalendarCollar20220922(props) {
    return (
        <section className="pt-7 pt-md-10">
            <div className="container">
                <div className="row">
                    <div className="col-12">

                        <div className="card card-row shadow-light-lg mb-6 lift lift-lg">
                            <div className="row no-gutters">
                                <div className="col-12">

<span className="badge badge-pill badge-light badge-float badge-float-inside">
<span className="h6 text-uppercase">hedging</span>
</span>
                                </div>
                                <Link className="col-12 col-md-6 order-md-2 bg-cover card-img-right"
                                      style={{backgroundImage: "url(" + BannerImage500 + ")"}}
                                      to={props.blogPath + path}>

                                    <img src="assets/img/photos/photo-27.jpg" alt="..."
                                         className="img-fluid d-md-none invisible"/>

                                    <div
                                        className="shape shape-left shape-fluid-y svg-shim text-white d-none d-md-block">
                                        <svg viewBox="0 0 112 690" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 0h62.759v172C38.62 384 112 517 112 517v173H0V0z"
                                                  fill="currentColor"></path>
                                        </svg>
                                    </div>

                                </Link>
                                <div className="col-12 col-md-6 order-md-1">

                                    <Link className="card-body" to={props.blogPath + path}>

                                        <h3>
                                            {postTitle}
                                        </h3>

                                        <p className="mb-0 text-muted">
                                            {postSubtitle}
                                        </p>

                                    </Link>

                                    <a className="card-meta" href="#!">

                                        <hr className="card-meta-divider"/>

                                        <div className="avatar avatar-sm mr-2">
                                            <img src={jonAvatar} alt="..."
                                                 className="avatar-img rounded-circle"/>
                                        </div>

                                        <h6 className="text-uppercase text-muted mr-2 mb-0">
                                            Jon Spiegel
                                        </h6>

                                        <p className="h6 text-uppercase text-muted mb-0 ml-auto">
                                            <time dateTime="2020-10-23">{publishedOnShort}</time>
                                        </p>

                                    </a>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>

    )
}
