import {nominalTypeHack} from "prop-types";
import axios from 'axios'


export default class StrategyTSManager {
    constructor(apiURL, authManager, projectId) {
        this.url = apiURL
        this.authManager = authManager
        this.dict = {}
        this.projectId = projectId
    }

    getStrategyTS(strategy_id) {
        let i = this.indexStrategyId[strategy_id]
        return this.tsList[i].timeseries
    }

    // retrieveTSList(uuids, callback) {
    //     let seriesOptions = []
    //     let nReceived = 0
    //
    //     console.log('retrieveTSList', uuids)
    //
    //     for (let i = 0; i < uuids.length; i++) {
    //
    //         // if (uuids[i] in this.dict) {
    //         //     seriesOptions[i] = this.dict[uuids[i]]
    //         //     nReceived++
    //         //     } else {
    //         const config = {
    //             headers: {'Authorization': "Bearer " + this.authManager.accessToken},
    //             params: {'uuid': uuids[i]}
    //         }
    //         axios.get(this.url, config)
    //
    //             .then(res => {
    //                     console.log('retrieveTSList', res.data);
    //                     this.dict[uuids[i]] = res.data[0]
    //                     seriesOptions[i] = {
    //                         name: res.data[0].name,
    //                         data: res.data[0].timeseries
    //                     }
    //                     console.log('seriesOptions', seriesOptions)
    //                     nReceived++
    //                     if (nReceived == uuids.length) {
    //                         callback(seriesOptions)
    //                     }
    //                 }
    //             );
    //         // }
    //     }
    // }

    async retrieveTSListAsync(uuids) {
        const seriesOptions = Promise.all(uuids.map(async uuid => {
                const config = {
                    headers: {'Authorization': "Bearer " + this.authManager.accessToken},
                    params: {
                        uuid: uuid,
                        columns: JSON.stringify(['level', 'notional', 'trading_pl', 'bid_offer']),
                        project: this.projectId
                    },
                    // paramsSerializer: params => {
                    //     return JSON.stringify(params)
                    // }
                }

                const res = await axios.get(this.url, config)
                this.dict[uuids] = res.data[0]

                // let test = JSON.parse(res.data[0].timeseries)
                // console.log(test.filter(x => x[3] !== 0))
                return {
                    name: res.data[0].name,
                    data: JSON.parse(res.data[0].timeseries)
                }
            }
            )
        )
        return seriesOptions
    }

    async retrieveStockHistoryAsync(symbols) {
        const seriesOptions = Promise.all(symbols.map(async symbol => {
                const config = {
                    headers: {'Authorization': "Bearer " + this.authManager.accessToken},
                    params: {'symbol': symbol}
                }
                const res = await axios.get(this.url, config)
                this.dict[symbols] = res.data[0]
                return {
                    name: res.data[0].name,
                    data: JSON.parse(res.data[0].timeseries)
                }
            }
            )
        )
        return seriesOptions
    }
}


