import Highcharts from 'highcharts'
import React, {useContext, useEffect, useState} from "react"
import headshot from 'images/blog/avatar-100.jpg'
import RequestDemoModal from "../../../pages/common/RequestDemoModal"
import {globalAuthManager} from "../../../../services/GlobalServices"
import {apiGet} from "../../../../services/api-call-helpers"
import * as keys from 'keys'
import heroImage from "images/blog/Income-2020-12/feet-maldives.jpg"
import {mixpanel} from "../../../../services/mixpanel"
import ReceiveUpdatesModal from "../../../pages/common/ReceiveUpdatesModal"
import {Link} from "react-router-dom"
import IndicatorTSManager from "../../../../services/IndicatorTSManager"
import StrategyTSManager from "../../../../services/StrategyTSManager"
import BlogSignupSection from "../../common/BlogSignupSection";
import PercentHighVsStrikeChart from "./PercentHighVsStrikeChart";
import EquityPerformanceVsOptionPremiumChart from "./EquityPerformanceVsOptionPremiumChart";
import {track_ux_event} from "../../../../services/clarivor-tracking";
import income202012Image from "images/blog/Income-2020-12/feet-maldives-500.jpg"
import jonAvatar from 'images/blog/avatar-100.jpg'

const IncomePostContext = React.createContext({})

const POST_TITLE = 'Income From Options Remains High'
const POST_SUBTITLE = 'Despite equities at record prices, option income strategies are still offering elevated yields'

export function RichViewIncome20201209(props) {
    return (
        <section className="pt-7 pt-md-10">
            <div className="container">
                <div className="row">
                    <div className="col-12">

                        <div className="card card-row shadow-light-lg mb-6 lift lift-lg">
                            <div className="row no-gutters">
                                <div className="col-12">

                            <span className="badge badge-pill badge-light badge-float badge-float-inside">
                    <span className="h6 text-uppercase">Income</span>
                  </span>

                                </div>
                                <Link className="col-12 col-md-6 order-md-2 bg-cover card-img-right"
                                      style={{backgroundImage: "url(" + income202012Image + ")"}}
                                      to="/blog/Income_20201209">

                                    {/*<img src="assets/img/photos/photo-27.jpg" alt="..."*/}
                                    {/*     className="img-fluid d-md-none invisible"/>*/}

                                    <div
                                        className="shape shape-left shape-fluid-y svg-shim text-white d-none d-md-block">
                                        <svg viewBox="0 0 112 690" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 0h62.759v172C38.62 384 112 517 112 517v173H0V0z"
                                                  fill="currentColor"></path>
                                        </svg>
                                    </div>

                                </Link>
                                <div className="col-12 col-md-6 order-md-1">

                                    <Link className="card-body" to="/blog/Income_20201209">

                                        <h3>
                                            {POST_TITLE}
                                        </h3>

                                        <p className="mb-0 text-muted">
                                            {POST_SUBTITLE}
                                        </p>

                                    </Link>

                                    <a className="card-meta" href="#!">

                                        <hr className="card-meta-divider"/>

                                        <div className="avatar avatar-sm mr-2">
                                            <img src={jonAvatar} alt="..."
                                                 className="avatar-img rounded-circle"/>
                                        </div>

                                        <h6 className="text-uppercase text-muted mr-2 mb-0">
                                            Jon Spiegel
                                        </h6>

                                        <p className="h6 text-uppercase text-muted mb-0 ml-auto">
                                            <time dateTime="2020-12-09">Dec 10</time>
                                        </p>

                                    </a>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>

    )
}


const HeroSection = (props) => (
    <section
        style={{
            backgroundImage: ["url(" + heroImage + ")"],
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            position: 'relative',
            zIndex: 0,
            backgroundSize: 'cover',
            height: '50vh'
        }}>
    </section>
)

const HeaderSection = (props) => (
    <section className="pt-8 pt-md-11">
        <RequestDemoModal/>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 col-md-12 col-lg-10 col-xl-10">


                    <h1 className="display-4 text-center">
                        {props.title}
                    </h1>


                    <p className="lead mb-7 text-center text-muted">
                        {props.subtitle}
                    </p>


                    <div className="row align-items-center py-5 border-top border-bottom">
                        <div className="col-auto">


                            <div className="avatar avatar-lg">
                                <img src={props.avatarImage} alt="..."
                                     className="avatar-img rounded-circle"/>
                            </div>

                        </div>
                        <div className="col ml-n5">
                            <h6 className="text-uppercase mb-0">
                                {props.author}
                            </h6>
                        </div>
                        <div className="col-auto">
                            <time className="font-size-sm text-muted" dateTime="2019-05-20">
                                {props.publishedOnText}
                            </time>
                        </div>
                    </div>

                </div>
            </div>

        </div>

    </section>
)


const beaLink = "https://www.bea.gov/news/2020/gross-domestic-product-state-2nd-quarter-2020"


function Summary(props) {
    return (
        <React.Fragment>
            <h2 className={'font-weight-bold mt-6'}>
                Summary
            </h2>
            <ul>
                <li className='my-2'>
                    The outperformance of large-cap tech stocks has occurred at a faster pace this year, as QQQ, an ETF
                    tracking the Nasdaq-100, has returned 32% year-to-date, compared with
                    7% for U.S. large-cap stocks more broadly (SPY).
                </li>
                <li className='my-2'>
                    The option market appears to price in further gains on QQQ, compared with SPY.
                </li>
                <li className='my-2'>
                    Investors who are skeptical about the potential for further gains in the Nasdaq-100 can set up a
                    potentially cost-effective hedge by selling QQQ calls to buy QQQ puts.
                </li>
            </ul>
        </React.Fragment>
    )
}


function createSeriesList(percentHighTS, strikeTS) {

}


function PercentHighVsStrikeChartContainer(props) {
    const context = useContext(IncomePostContext)

    const defaultTicker = 'SPY'
    const defaultTenor = 3
    const defaultIncome = .06

    const [ticker, setTicker] = useState(defaultTicker)
    const [tenor, setTenor] = useState(defaultTenor)
    const [income, setIncome] = useState(defaultIncome)

    const indicatorTSManager = new IndicatorTSManager(keys.API_ENDPOINT.PROJECT_INDICATOR_TIMESERIES_LIST, globalAuthManager, context.projectId)
    const [allTuples, setAllTuples] = useState([])
    const [loadingData, setLoadingData] = useState(false)

    let initialized = false
    useEffect(() => {
        if (context.projectObj != null) {
            if (initialized) {
                track_ux_event(localStorage.uuid, 'Change Strategy',
                    {
                        source: {
                            type: 'blog',
                            post: 'Income_20201209',
                        },
                        params: {
                            ticker: ticker,
                            tenor: tenor,
                            income: income
                        }
                    },
                    globalAuthManager)
            } else {
                initialized = true
            }

            const percentSpotIndicators = context.projectObj.user_indicators.filter(ui => ui.class_name.includes('PercentOfHigh'))
            const strikeIndicators = context.projectObj.user_indicators.filter(ui => ui.class_name.includes('Strike'))

            const [percentOfHighIndicator] = percentSpotIndicators.filter(ui => ui.params.ticker === ticker)

            const targetPremium = income * tenor / 12

            const [callStrikeIndicator] = strikeIndicators.filter(ui => ui.params.ticker === ticker &&
                ui.params.tenor_mo === tenor &&
                Math.abs(ui.params.premium - targetPremium) < .0001)

            setLoadingData(true)
            indicatorTSManager.retrieveTSListAsync([percentOfHighIndicator.id, callStrikeIndicator.id]).then(
                indicatorDataObjs => {
                    let pchTS = indicatorDataObjs.filter(obj => obj.uuid === percentOfHighIndicator.id)[0].data
                    let csTS = indicatorDataObjs.filter(obj => obj.uuid === callStrikeIndicator.id)[0].data

                    pchTS = pchTS.filter(tuple => tuple[0] >= csTS[0][0])
                    let hashPH = {}
                    pchTS.map(
                        phTuple => {
                            hashPH[phTuple[0]] = Math.round(10000 * phTuple[1]) / 10000
                        }
                    )

                    let tuples = []

                    csTS.map((cs, i) => {
                        tuples[i] = [cs[0], Math.round(10000 * (hashPH[cs[0]] - 1)) / 10000,
                            Math.round(10000 * (cs[1] - 1)) / 10000]
                    })

                    setAllTuples(tuples)
                }
            ).finally(() => {
                setLoadingData(false)
            })
        }
    }, [context.projectObj, ticker, tenor, income])

    const epochCutoff = +new Date(2020, 0, 1)

    let tupleLists = [[], [], []]
    if (allTuples.length > 0) {
        const recentTuples = allTuples.filter((tuple, i) => tuple[0] >= epochCutoff && i < allTuples.length - 1)
        const oldTuples = allTuples.filter((tuple, i) => tuple[0] <= epochCutoff)
        const lastTuple = [allTuples[allTuples.length - 2]]

        tupleLists = [oldTuples.map(x => [x[1], x[2]]),
            recentTuples.map(x => [x[1], x[2]]),
            lastTuple.map(x => [x[1], x[2]])]

        // let oldHighTuples = oldTuples.filter(tuple => tuple[1] === 0)
        // let total = 0
        // let oldHighStrikes = oldHighTuples.map(tuple => {total += tuple[2]})
        // window.alert(total/oldHighStrikes.length)

        // console.log(oldHighStrikes)
    }

    function changeTickerAttempted() {
        track_ux_event(localStorage.uuid,
            'Unavailable Strategy',
            {
                source: {
                    type: 'blog',
                    post: 'Income_20201209',
                },
                params: {
                    ticker: 'SPY',
                }
            },
            globalAuthManager
        )
        window.alert('Please contact us for access to more strategies.')
    }

    return (
        <div className='card bg-light'>
            <div className='card-body'>
                <form>
                    <div className='row justify-content-center' style={{height: '20px'}}>
                        {
                            loadingData ?
                                <span className="spinner-border spinner-border-sm" role="status"
                                      aria-hidden="true"></span>
                                : null
                        }
                    </div>
                    <div className='row justify-content-center my-6'>
                        <div className={'col text-center'} onClick={changeTickerAttempted}>
                            <label>ETF</label>
                            <select className='form-control'
                                    defaultValue={defaultTicker}
                                    disabled={true}
                            >
                                <option key={0} value={'SPY'}> {'SPY'} </option>
                                <option key={2} value={'IWM'}> {'IWM'} </option>
                            </select>
                        </div>
                        <div className={'col text-center'}>
                            <label>Option Maturity</label>
                            <select className='form-control px-2'
                                    defaultValue={defaultTenor}
                                    onChange={event => setTenor(parseInt(event.target.value))}>
                                <option key={0} value={1}> {'1-month'} </option>
                                <option key={0} value={3}> {'3-month'} </option>
                                <option key={1} value={6}> {'6-month'} </option>
                            </select>
                        </div>
                        <div className={'col text-center'}>
                            <label>Annual Income</label>
                            <select className='form-control px-2'
                                    defaultValue={defaultIncome}
                                    onChange={event => setIncome(parseFloat(event.target.value))}>
                                <option key={0} value={.04}> {'4.0%'} </option>
                                <option key={1} value={.06}> {'6.0%'} </option>
                            </select>
                        </div>
                    </div>
                </form>
                <PercentHighVsStrikeChart tupleLists={tupleLists}/>
            </div>
        </div>
    )
}


function EquityAndOptionPremiumChartContainer(props) {
    const [optionPriceSeries, setOptionPriceSeries] = useState([])
    const [equitySeries, setEquitySeries] = useState([])
    const context = useContext(IncomePostContext)

    const indicatorTSManager = new IndicatorTSManager(keys.API_ENDPOINT.PROJECT_INDICATOR_TIMESERIES_LIST, globalAuthManager, context.projectId)
    const strategyTSManager = new StrategyTSManager(keys.API_ENDPOINT.PROJECT_STRATEGY_TIMESERIES_LIST, globalAuthManager, context.projectId)

    useEffect(() => {
        if (context.projectObj != null) {
            const [uiCall] = context.projectObj.user_indicators.filter(ui => ui.class_name === 'OptionPriceIndicator')
            indicatorTSManager.retrieveTSListAsync([uiCall.id]).then(
                res => {
                    setOptionPriceSeries(res[0].data)
                }
            )

            const [usEquity] = context.projectObj.user_strategies.filter(us => us.class_name === 'EquityBasketStrategy')
            strategyTSManager.retrieveTSListAsync([usEquity.id]).then(
                res => {
                    setEquitySeries(res[0].data)
                }
            )
        }
    }, context.projectObj)

    useEffect(() => {
        // without this line, when chart loads it is too wide for the parent div.  It gets fixed when window is resized
        const chart = Highcharts.charts[0]
        if (chart) {
            chart.reflow()
        }
    })  // doesn't work if deps == []!


    if (context.projectObj == null) {
        return null
    }

    let tupleLists = [
        equitySeries,
        optionPriceSeries
    ]

    return (
        <EquityPerformanceVsOptionPremiumChart tupleLists={tupleLists}/>
    )
}


function Section1(props) {
    return (
        <React.Fragment>
            <h3 className={'font-weight-bold mt-6'}>
                Low rates and high asset prices
            </h3>
            <p>
                For investors seeking income, market conditions over the past decade have created challenges,
                and finding sources of yield appears to be getting even more difficult. The downtrend in interest
                rates has coincided with lower corporate bond yields and dividend yields. As U.S. equities have recently
                reached new highs at the end of a turbulent year, tolerance for risk generally appears to have
                normalized, with asset prices more reflective of the low-rate environment.
            </p>
            <h3 className={'font-weight-bold mt-6'}>
                Option income is atypically high
            </h3>
            <p>
                When interest rates are low and equity markets are at their highs, we usually expect the yield available
                from writing call options to be commensurately low. However in the market currently, given the level
                of equities, some investors may find that the income available from writing options is relatively
                attractive.
            </p>
            <div className='card bg-light'>
                <div className='card-body'>
                    <EquityAndOptionPremiumChartContainer/>
                </div>
            </div>
            <h3 className={'font-weight-bold mt-6'}>
                Targeting a level of income
            </h3>
            <p>
                When writing a covered call on a stock, ETF or index, the investor receives the price of the call
                ("premium")
                in exchange for capping gains at the call option’s strike. In addition to the premium income, the
                investor may receive dividends from holding the underlying stock or index investment.
            </p>
            <p>
                By adjusting the strike of the call sold, an investor can choose to receive a desired amount of option
                premium. For example, an investor may sell a 3-month call on an ETF that is worth 1.5% of the price of
                the
                ETF. On an annual basis this strategy targets income of 6% in excess of the dividend yield.
            </p>

            <h3 className={'font-weight-bold mt-6'}>
                Quantifying income strategy upside, currently and historically
            </h3>
            <p>
                The below chart illustrates how option income strategies typically provide less upside
                when equities are close to their highs. Points to the left represent trading days when
                the ETF was far below its last high, while points to the right represent trading days when the ETF was
                closer to (or at) its high.
            </p>
            <p>
                We can see that on December 8th, with SPY at its all-time high, a covered-call strategy targeting income
                of 6% offered approximately 4.1% upside over the next 3 months. For comparison, from 2010 to 2019, when
                SPY was at its high, the average upside of the strategy was approximately 1.3%.
            </p>
            <PercentHighVsStrikeChartContainer/>
        </React.Fragment>
    )
}


const WrittenSection = (props) => {

    const handleToolScroll = (event) => {
        event.preventDefault()
        props.scrollToTool()
    }

    return (
        <section>
            <hr className="hr-md"/>
        </section>
    )
}


function SelectorsWrapper(props) {
    return (
        <div className='card mt-4'>
            <div className='card-header bg-gray-300 text-secondary p-4'>
                Compare Put Strategies
            </div>
            <div className='card-body bg-light p-4'>
                {props.children}
                <div className="form-check">
                    {/*<ShowSPYCheckbox/>*/}
                </div>
            </div>
        </div>
    )
}


function ChartWrapper(props) {
    return (
        <div className='card mt-4 px-0'>
            <div className='card-body border px-0'>
                {props.children}
            </div>
        </div>
    )

}

function ProjectContainer(props) {
    const [projectObj, setProjectObj] = useState(null)

    useEffect(() => {
        apiGet(keys.API_ENDPOINT.PROJECTS, {id: props.projectId}, globalAuthManager).then(
            res => {
                const [project] = res.data
                setProjectObj(project)
            }
        )
    }, [])

    const contextValue = {
        projectObj: projectObj,
        projectId: props.projectId
    }

    return (
        <IncomePostContext.Provider value={contextValue}>
            {props.children}
        </IncomePostContext.Provider>
    )
}


function RequestDemoSection(props) {
    return (
        <section>
            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-12 col-md-8 col-lg-6'>
                        <div className='card border border-primary'>
                            <div className='card-body'>
                                <div className='row justify-content-center'>
                                    <h3 className={'text-center'}>
                                        Get in touch with Clarivor for more analysis on income and other option
                                        strategies
                                    </h3>
                                </div>
                                <div className='row justify-content-center'>
                                    <a className='mx-auto' data-toggle="modal" href="#requestDemoModal"
                                       style={{textDecoration: 'none'}}
                                       onClick={() => {
                                           mixpanel.track('Request Demo clicked')
                                       }}>
                                        <button className="btn btn-success lift mt-2 d-flex">
                                            <span className={'my-auto'}>Request Demo</span>
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

function DisclosuresLink(props) {
    return (
        <a data-toggle="modal" href="#disclaimerModal"
           onClick={() => mixpanel.track('Disclosure Clicked')}>Disclosures</a>
    )
}

function TermsOfUseLink(props) {
    return (
        <Link to={'/terms-of-use'} target={'_blank'}
              onClick={() => mixpanel.track('Terms of Use Clicked')}>Terms of Use</Link>
    )
}

function BacktestingSourceDisclaimer(props) {
    return (
        <div className='card'>
            <div className='card-body font-italic text-muted'>
                Source: Clarivor LLC. Any applicable fees, commissions or taxes are not reflected. Option prices
                are mid-market, observed at 3:45pm NY time. Option price measurements entail
                interpolations between expirations. Some data points may be outside the plotted range.
                These results are back-tested and may not be indicative of future performance. Option strategies can
                pose risk of material loss.
                NOTHING IN THIS POST SHOULD BE CONSIDERED INVESTMENT
                ADVICE. <DisclosuresLink/> and <TermsOfUseLink/> apply.
            </div>
        </div>
    )
}

function PostBody(props) {
    return (
        <React.Fragment>
            <section className="pt-6 pt-md-8">
                <ReceiveUpdatesModal/>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-12 col-lg-10 col-xl-9">
                            {/*<Summary/>*/}
                            <Section1/>
                            {/*<UsingABuyWrite/>*/}
                            {/*<MaturitySelection/>*/}
                            <BacktestingSourceDisclaimer/>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}


export default function Income_20201209(props) {
    const projectId = 'd75fdb17-c532-432c-b950-b4e009fd4fde'

    useEffect(() => {
        document.title = "Clarivor Blog: " + POST_TITLE
    }, [])

    const headerProps = {
        title: POST_TITLE,
        subtitle: POST_SUBTITLE,
        avatarImage: headshot,
        author: 'Jon Spiegel, CFA',
        publishedOnText: 'December 10, 2020',
    }

    return (
        <React.Fragment>
            <HeroSection/>
            <HeaderSection {...headerProps} />
            <ProjectContainer projectId={projectId}>
                <PostBody/>
            </ProjectContainer>
            {/*<RequestDemoSection/>*/}
            <BlogSignupSection fromLocation={'Blog Post Income 20201209'}/>
        </React.Fragment>
    )
}

