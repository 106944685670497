
export function mapClasses(module, classStr) {
    if (module == null) {
        return classStr
    }

    let strings = classStr.split(' ')

    let new_strings = []
    for (let i = 0; i < strings.length; i++) {
        new_strings.push(module[strings[i]])
    }

    return new_strings.join(' ')
}