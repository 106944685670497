import React, {useContext, useState} from "react"
import {Link} from 'react-router-dom'
import {AccountManagementContext, AppContext} from "../../contexts"
import * as keys from 'keys'
import {apiPost} from "../../../services/api-call-helpers"
import {globalAuthManager} from "../../../services/GlobalServices"
import BreadcrumbNav from "../../common/BreadcrumbNav"


function passwordErrorText(s) {
    if (s.length < 8) {
        return 'Password needs to be at least 8 characters.'
    }

    if (!(new RegExp('[a-z]').test(s))) {
        return 'Password must have at least one lowercase letter.'
    }

    if (!(new RegExp('[A-Z]').test(s))) {
        return 'Password must have at least one uppercase letter.'
    }

    if (!(new RegExp('[0-9]').test(s))) {
        return 'Password must have at least one number.'
    }

    return null
}

function ChangePasswordForm(props) {
    const signupContext = useContext(AccountManagementContext)
    const appContext = useContext(AppContext)
    const [submitting, setSubmitting] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState(null)

    const handleTextChange = (event) => {
        let kvp = {}
        kvp[event.target.name] = event.target.value
        signupContext.setSignupKVPair(kvp)
    }

    const handleClick = async (event) => {
        event.preventDefault()

        setSubmitting(true)
        const params = {
            currentPassword: signupContext.signupState.currentPassword,
            newPassword: signupContext.signupState.newPassword,
            product: signupContext.product,
        }

        try {
            setSubmitting(true)
            await apiPost(keys.API_ENDPOINT.CHANGE_PASSWORD, params, globalAuthManager)
            props.setPasswordChanged(true)
        } catch (error) {
            if (error.response == null) {
                setErrorMessage('Could not sign up.  Connectivity issue.')
            } else if (error.response.data.message) {
                setErrorMessage(error.response.data.message)
            } else {
                setErrorMessage(`Could not sign up.  Error code: ${error.response.status}.`)
            }
        } finally {
            setSubmitting(false)
        }
    }


    let passwordError = passwordErrorText(signupContext.signupState.newPassword || "")

    if (passwordError == null) {
        var passwordsMatch = signupContext.signupState.newPassword === signupContext.signupState.confirmNewPassword
    }

    return (
        <div className={'card'}>
            <div className={'card-body text-left'}>
                <form>
                    <div className="row justify-content-center">
                        {
                            errorMessage ?
                                <span className='text-danger my-3'>{errorMessage}</span> :
                                null
                        }
                    </div>

                    <div className="form-label-group">
                        <input type="password" className="form-control" name="currentPassword"
                               placeholder={"Current Password"}
                               onChange={handleTextChange}/>
                        <label>Current Password</label>
                    </div>
                    <div className="form-label-group">
                        <input type="password" className="form-control" name="newPassword" placeholder={"New Password"}
                               onChange={handleTextChange}/>
                        <label>New Password</label>
                    </div>
                    <div className="form-label-group">
                        <input type="password" className="form-control" name="confirmNewPassword"
                               placeholder={"Confirm New Password"}
                               onChange={handleTextChange}/>
                        <label>Confirm New Password</label>
                    </div>
                    <div className="mt-6">
                        {
                            submitting ?
                                <button className="btn btn-block btn-success disabled" type="submit"
                                        onClick={() => null}>
                                    <span className="spinner-border spinner-border-sm" role="status"
                                          aria-hidden="true"></span>
                                </button>
                                :
                                passwordError ?
                                    <button className="btn btn-block btn-success disabled" type="submit"
                                            onClick={() => null}>
                                        {passwordError}
                                    </button> :
                                    passwordsMatch ?
                                        <button className="btn btn-block btn-success lift" type="submit"
                                                onClick={handleClick}>
                                            Change Password
                                        </button> :
                                        <button className="btn btn-block btn-success disabled " type="submit"
                                                onClick={handleClick}>
                                            The passwords do not match.
                                        </button>
                        }
                    </div>
                </form>
            </div>
        </div>
    )
}


function PleaseSignOut(props) {
    return (
        <React.Fragment>
            <h3 className="font-weight-bold text-dark">
                Please sign out before creating a new account.
            </h3>
            Or <Link to={'/account/user-info'}>edit</Link> account information.
        </React.Fragment>
    )
}

function ChangePasswordBody(props) {
    const [passwordChanged, setPasswordChanged] = useState(false)

    if (passwordChanged) {
        return (
            <React.Fragment>
                <h2 className="font-weight-bold text-dark">
                    Your password has been changed.
                </h2>
                <a href='/app' className='btn btn-primary mx-10'>Go to app</a>
            </React.Fragment>
        )
    } else return (
        <React.Fragment>
            <h2 className="font-weight-bold text-dark">
                Change your password below.
            </h2>

            <ChangePasswordForm setPasswordChanged={setPasswordChanged}/>
        </React.Fragment>
    )
}

export default function ChangePasswordPage(props) {
    const appContext = useContext(AppContext)

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    document.title = 'Change Clarivor Password'

    return (
        <React.Fragment>
            <BreadcrumbNav pathList={['Account', 'Change Password']}/>
            <section className="pt-8 pt-md-11 pb-8 pb-md-14 bg-light" style={{minHeight: '70vh'}}>
                <div className="container">
                    <div className='row justify-content-center my-5'>
                        <div className='col-12 col-md-8 text-center'>
                            {
                                <ChangePasswordBody/>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}