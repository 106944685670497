import axios from 'axios'

export default class InvestmentAccountsManager
{
    constructor(investment_accounts_url)
    {
        this.investment_accounts_url = investment_accounts_url
        this.investmentAccountObjs = null
    }

    async retrieveInvestmentAccountObjsAsync(authManager) {
        const config = {
            headers: {'Authorization': "Bearer " + authManager.accessToken}
        };

        try {
            let res = await axios.get(this.investment_accounts_url, config)

            this.investmentAccountObjs = res.data
        }
        catch (e) {
            console.error(e)
        }

        return this.investmentAccountObjs
    }
}

