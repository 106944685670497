import React, {useContext, useEffect, useState} from "react"
import RequestDemoModal from "components/pages/common/RequestDemoModal"
import {globalAuthManager} from "services/GlobalServices"
import {apiGet} from "services/api-call-helpers"
import * as keys from 'keys'
import ReceiveUpdatesModal from "components/pages/common/ReceiveUpdatesModal"
import {Link} from "react-router-dom"
import {track_ux_event} from "services/clarivor-tracking"
import {interpolateColors} from "../../util/colors"
import NavBarDashkitBlog from "../common/NavBarDashkitBlog"
import Footer from "../common/FooterDashkit"
import DashkitSignupSection from "./DashkitSignupSection"
import {setDKBody} from "../../dkcss"

const IncomeMonitorContext = React.createContext({})

const HeaderSection = (props) => {
    const context = useContext(IncomeMonitorContext)
    const statObjs = context.indicatorStats
    let asOfDateStr = ''

    if (statObjs != null) {
        let calc_dates = statObjs.map(obj => obj.calc_date)
        calc_dates.sort()
        asOfDateStr = calc_dates[0]
    }

    return (
        <section>
            <RequestDemoModal source='IncomeMonitor'/>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-12 col-lg-10 col-xl-9">
                        <div className="header-body">
                            <div className="row align-items-end">
                                <div className="col">
                                    <h6 className="header-pretitle">
                                    </h6>

                                    <h1 className="header-title">
                                        Option Income Monitor - Target Yield Strategies
                                    </h1>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </section>
    )
}


function ProjectContainer(props) {
    const [projectObj, setProjectObj] = useState(null)
    const [indicatorStats, setIndicatorStats] = useState(null)
    const [indicatorHistoryDays, setIndicatorHistoryDays] = useState(null)

    useEffect(() => {
        apiGet(keys.API_ENDPOINT.PROJECTS, {id: props.projectId}, globalAuthManager).then(
            res => {
                const [project] = res.data
                setProjectObj(project)
            }
        )

        apiGet(keys.API_ENDPOINT.PROJECT_INDICATOR_STATS_LIST, {project: props.projectId}, globalAuthManager).then(
            res => {
                setIndicatorStats(res.data)
            }
        )
    }, [])

    useEffect(() => {
        if (indicatorStats) {
            let dateStrs = indicatorStats.map(obj => obj.calc_date)
            dateStrs.sort()

            const hdParams = {
                project: props.projectId,
                start_date: dateStrs[0],
            }

            apiGet(keys.API_ENDPOINT.PROJECT_INDICATOR_HISTORY_DAYS, hdParams, globalAuthManager).then(
                res => {
                    setIndicatorHistoryDays(res.data)
                }
            )
        }
    }, [indicatorStats])

    const contextValue = {
        projectObj: projectObj,
        projectId: props.projectId,
        indicatorStats: indicatorStats,
        indicatorHistoryDays: indicatorHistoryDays,
    }

    return (
        <IncomeMonitorContext.Provider value={contextValue}>
            {props.children}
        </IncomeMonitorContext.Provider>
    )
}


function DisclosuresLink(props) {
    return (
        <a data-toggle="modal" href="#disclaimerModal"
           onClick={
               () => track_ux_event(localStorage.uuid, 'Disclosures Clicked', {source: 'Income Monitor'}, globalAuthManager)
           }
        >Disclosures</a>
    )
}

function TermsOfUseLink(props) {
    return (
        <Link to={'/terms-of-use'} target={'_blank'}
              onClick={
                  () => track_ux_event(localStorage.uuid, 'Terms of Use Clicked', {source: 'Income Monitor'}, globalAuthManager)
              }
        >Terms of Use</Link>
    )
}

function BacktestingSourceDisclaimer(props) {
    return (
        <div className='card'>
            <div className='card-body font-italic text-muted'>
                Source: Clarivor LLC. NOTHING ON THIS PAGE SHOULD BE CONSIDERED INVESTMENT
                ADVICE. Any applicable fees, commissions or taxes are not reflected. Strikes are based on option prices
                observed at 3:45pm NY time. Option price measurements entail
                interpolations between expirations and strikes. Prices may not be indicative of future
                performance. Option strategies can pose risk of material
                loss. <DisclosuresLink/> and <TermsOfUseLink/> apply.
            </div>
        </div>
    )
}


function Definitions(props) {
    return (
        <div className='card border border-light'>
            <div className='card-body text-dark'>
                <p className='font-weight-bold'>Definitions: </p>
                <div>
                    <p><strong>Target Option Annual Income</strong> means the total cash received from writing
                        options annually.
                        For
                        example, writing 1-month calls to target 6% annual income target means writing calls worth 0.5%
                        of the ETF price each month for a year. An investor will additionally receive any dividends from
                        holding the ETF.
                    </p>
                    <p><strong>Upside Cap</strong> means the strike of the call option that an investor needs to sell to
                        achieve
                        the target income, expressed as a percentage of the current ETF price.
                    </p>
                    <p><strong>Score</strong> is the 5-year percentile of the upside cap. For example, if the score
                        for a strategy is 90, then the current upside cap is higher than it was on 90% of the trading
                        days over the past 5 years.
                    </p>
                    <p><strong>1-month Change</strong> is the difference between the strategy's current Score and the
                        Score a month ago..
                    </p>
                </div>
            </div>
        </div>
    )
}

// green: "#00D97E",
// yellow: "#F6C343",
// orange: "#fd7e14",

const vega = {
    1: .11,
    3: .18,
    6: .27
}

const delta = {
    1: .3,
    3: .3,
    6: .3
}

function IncomeTableCardView(props) {
    let statObjs = JSON.parse(JSON.stringify(props.statObjs))
    let ihdObjs = props.ihds ? JSON.parse(JSON.stringify(props.ihds)) : null

    const symbolOrder = ['SPY', 'IWM', 'QQQ', 'EFA', 'EEM']

    const i = (val) => (symbolOrder.findIndex(e => e === val))

    statObjs.sort((a, b) => (
        i(a.params.ticker) < i(b.params.ticker) ? -1 :
            i(a.params.ticker) > i(b.params.ticker) ? 1 :
                0
    ))

    const bgColor = (percentile) => {
        if (percentile > 50) {
            return interpolateColors('#FFFFFF', '#00d97e', (percentile - 50) / 50)
        } else {
            return interpolateColors('#fd7e14', '#FFFFFF', percentile / 50)
        }
    }

    const IndicatorRow = (props) => {
        let strikeStr = null
        if (props.ihdObj != null) {
            strikeStr = ((props.ihdObj.data.strikes[0] / props.ihdObj.data.spot - 1) * 100).toFixed(1) + '%'
        }
        return (
            <tr>
                <th scope="row">{props.statObj.params.ticker}</th>
                <td className='font-weight-bold'>{strikeStr}</td>
                <td bgcolor={bgColor(props.statObj.stats.percentile5y)}>{props.statObj.stats.percentile5y.toFixed(0)}</td>
                <td className='text-muted'>{props.statObj.stats.percentile5yChange1m.toFixed(0)}%</td>
            </tr>
        )
    }

    return (
        <div className='card card-light'>
            <div className='card-header bg-secondary text-white'>
                Writing {props.tenor_mo}-month Calls
            </div>
            <div className='card-body'>
                <table className="table text-center">
                    <thead>
                    <tr>
                        <th scope="col" style={{width: '10%'}}></th>
                        <th scope="col" style={{width: '30%'}}>Upside Cap<sup></sup></th>
                        <th scope="col" style={{width: '30%'}}>Score<sup></sup></th>
                        <th scope="col" style={{width: '30%'}}>1-month Change<sup></sup></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        statObjs.map(statObj => {
                                let ihdObj = null
                                if (ihdObjs) {
                                    [ihdObj] = ihdObjs.filter(ihd => ihd.id === statObj.user_indicator)
                                }
                                return <IndicatorRow statObj={statObj} ihdObj={ihdObj}/>
                            }
                        )
                    }
                    </tbody>
                </table>
            </div>
        </div>
    )
}


//{"stats":{"lastLevel":1.0495,"percentile5y":82.5,"percentile5yChange1m":1.9841269841269877},"name":"SPY 6m Call StriPke (3.00% premium)","user_indicator":"fd6d4afb-44ab-4bff-8b35-3078e0a66505","indicator_class":"FixedPremiumOptionStrike","params":{"c_or_p":"c","ticker":"SPY","premium":0.03,"tenor_mo":6,"premium_type":"percent"}}

function MonitorBody(props) {
    const defaultAnnualIncome = .04
    const context = useContext(IncomeMonitorContext)
    const [annualIncome, setAnnualIncome] = useState(defaultAnnualIncome)

    let useStats = context.indicatorStats
    let useIHDs = context.indicatorHistoryDays

    if (useStats != null) {
        useStats = useStats.filter(obj => Math.abs(obj.params.premium * (12 / obj.params.tenor_mo) - annualIncome) < .0001)
    } else {
        useStats = []
    }

    let stats1m = useStats.filter(obj => obj.params.tenor_mo === 1)
    let stats3m = useStats.filter(obj => obj.params.tenor_mo === 3)
    let stats6m = useStats.filter(obj => obj.params.tenor_mo === 6)

    let asOfDateStr = ''

    if (useStats != null) {
        let calc_dates = useStats.map(obj => obj.calc_date)
        calc_dates.sort()
        asOfDateStr = calc_dates[0]
    }


    return (
        <React.Fragment>
            <section className="">
                <ReceiveUpdatesModal/>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-12 col-lg-10 col-xl-9">
                            <div className='card bg-primary-soft'>
                                <div className='card-body'>
                                    <div className='row justify-content-center'>
                                        <div className='col-12 col-md-8 col-lg-6 text-center'>
                                            Select target option annual income<sup></sup>:
                                        </div>
                                    </div>
                                    <div className='row justify-content-center'>
                                        <div className='col-12 col-md-6 text-center pt-4'>
                                            <div className="btn-group-toggle" data-toggle="buttons">
                                                <label className="btn btn-white mx-2">
                                                    <input type="radio" name="options" id="option1"
                                                           checked={true}
                                                           onClick={(event) => {
                                                               setAnnualIncome(.04)
                                                               track_ux_event(localStorage.uuid, 'Changed Income %', {source: 'Income Monitor'}, globalAuthManager)
                                                           }}
                                                    />4.0%
                                                </label>
                                                <label className="btn btn-white mx-2">
                                                    <input type="radio" name="options" id="option2"
                                                           onClick={(event) => {
                                                               setAnnualIncome(.06)
                                                               track_ux_event(localStorage.uuid, 'Changed Income %', {source: 'Income Monitor'}, globalAuthManager)
                                                           }}
                                                    />6.0%
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row justify-content-center pt-4'>
                                        <div className='col-12 col-md-8 col-lg-6 text-center'>
                                            (Scroll down to see definitions)
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row align-items-end">
                                <div className="col">
                                </div>
                                <div className="col-auto text-muted">
                                    As of: {asOfDateStr}
                                </div>
                            </div>

                            <IncomeTableCardView statObjs={stats1m} ihds={useIHDs} tenor_mo={1}/>
                            <IncomeTableCardView statObjs={stats3m} ihds={useIHDs} tenor_mo={3}/>
                            <IncomeTableCardView statObjs={stats6m} ihds={useIHDs} tenor_mo={6}/>
                            <Definitions/>
                            <BacktestingSourceDisclaimer/>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}


function CommentarySection(props) {
    return (
        <section className="">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-12 col-lg-10 col-xl-9">
                        <div className='card border border-light'>
                            <div className='card-body text-secondary'>
                                <p>
                                    Commentary (14-Jan):
                                </p>
                                <p>
                                    In a market environment where most asset prices have rallied, and yields are low,
                                    options strategies continue to provide more income than might otherwise be expected.
                                    (For further discussion on the relationship of equity prices and income
                                    see <Link to={'/blog/Income_20201209'}>our post from December</Link>).
                                </p>
                                <p>
                                    Over the past month, the upside available from option income strategies has
                                    generally increased. Notably, call-writing strategies on EEM are offering higher
                                    caps across all maturities, coinciding with a surge in emerging market equities.
                                </p>
                                <p>
                                    IWM, which has been a very strong performer since November, is at the highest
                                    percentiles recently. Apparently the market may anticipate a continuation of
                                    small-cap out-performance.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default function IncomeMonitorPage(props) {
    const projectId = 'cf6fd69a-6a91-487c-b461-70985af5eb9f'

    useEffect(() => {
        setDKBody()
    }, [])

    return (
        <React.Fragment>
            <NavBarDashkitBlog/>
            <ProjectContainer projectId={projectId}>
                <HeaderSection/>
                {/*<CommentarySection/>*/}
                <MonitorBody/>
                <DashkitSignupSection fromLocation={'Income Monitor'}/>
            </ProjectContainer>
            <Footer/>
        </React.Fragment>
    )
}

