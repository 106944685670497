import React, {createContext} from 'react'

export const AppContext = React.createContext({mixpanel: null, authManager: null, privileges: null})

export const FavoriteStrategiesContext = React.createContext({
    setConfigAsFavorite: null,

})

export const AccountPortfolioContext = React.createContext({state: null})

// Use this context for actions that apply to any account.
//  For example, may want to roll a basket from a screen that includes baskets from different accounts.
export const MasterPortfolioContext = React.createContext({})

export const InvestmentAppContext = React.createContext({
        userStrategies: [],
        investmentAccounts: [],
        userIndicators: []
    }
)

export const TradeHubAppContext = React.createContext({})

export const GlobalServicesContext = React.createContext({globalServices: null})


export const AccountManagementContext = React.createContext({})

export const BacktestingContext = React.createContext({})

export const BacktestAllocateModalContext = React.createContext({})

export const AmendBasketModalContext = React.createContext({})

export const MarketMonitorContext = React.createContext({})

export const AllocationsViewContext = createContext({})

export const ModelPortfolioManagerContext = createContext({})

export const ModalManagerContext = createContext({})

export const PortfolioManagementAppletContext = createContext({})