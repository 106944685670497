import React, {useEffect} from 'react'
import NavBar from 'components/common/NavBarDefault'
import RequestDemoModal from "../common/RequestDemoModal"
import Footer from 'components/common/Footer'
import {setLKBody} from 'lkcss'
import institutionHeroImage from '../../../images/traders-at-desk.png'
import coupleOfAnalysisImage from '../../../images/team-of-stockbrokers-are-having-a-conversation.png'
import planScreenshot from '../../../images/plan-screenshot.png'
import {Check} from "react-feather"
import macBook from 'lk/dist/assets/img/devices/macbook.svg'
import AdvisorsPricingSection from "./InstitutionsPricingSection"
import {Fade} from "react-reveal"
import BreadcrumbNav from "../../common/BreadcrumbNav"
import {track_ux_event} from "../../../services/clarivor-tracking"
import {globalAuthManager} from "../../../services/GlobalServices"

function BellCurveSVG(props) {
    return (
        <svg height={props.heightStr} width={props.widthStr} fill={props.fillStr} data-name="Layer 1"
             viewBox="0 0 100 100" x="0px" y="0px"><title>bellcuves</title>
            <path
                d="M91.14,91.77H86c-13.17,0-20.49-18.17-25.84-31.43-3.66-9.06-6.3-15.61-9.79-15.61h0c-2.29,0-4.5,3-7,9.24C30.81,86.1,21.86,91.06,15.47,91.77H9.64a.5.5,0,0,0-.5.5.51.51,0,0,0,.5.5h81.5a.5.5,0,0,0,.5-.5A.5.5,0,0,0,91.14,91.77Zm-66.35,0H19.41a19.93,19.93,0,0,0,5.38-3.42Zm12.58,0H25.79V87.43c3.85-3.7,7.67-9.37,11.58-17.19Zm12.47,0H38.37V68.19q2.93-6.1,6-13.85c2-5.13,3.86-8,5.51-8.51Zm12.69-23v23H50.84v-46c2.67.68,5.34,7.3,8.41,14.92,1,2.55,2.13,5.28,3.33,8ZM75,91.77H63.53V70.87c3,6.67,6.73,13.21,11.47,17.38Zm1,0V89.08a18.2,18.2,0,0,0,4.66,2.69Z"></path>
        </svg>
    )
}

function InstitutionHeroSection(props) {
    return (
        <section className="py-10 bg-white bg-gradient-light">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-8 col-lg-6 px-0">
                        {/*<Fade left>*/}
                        <div
                            className="img-fluid mb-6 rounded py-6 px-6 d-flex shadow-dark-lg"
                            alt="..."
                            style={{
                                backgroundImage: ['linear-gradient(180deg, rgba(38, 32, 60, .6) 0%, rgba(38, 32, 60, .6) 100%)', "url(" + institutionHeroImage + ")"],
                                backgroundRepeat: 'no-repeat',
                                position: 'relative',
                                zIndex: 0,
                                backgroundSize: 'cover',
                                minHeight: '350px'
                            }}
                        >
                            <h1 className="display-3 text-md-left text-white align-self-center">
                                Managing Option Strategies Is No Longer a Full-Time Job
                            </h1>
                        </div>
                        {/*</Fade>*/}
                    </div>
                    <Fade right>
                        <div className="col-12 col-md-8 col-lg-6 d-flex px-8">
                            <div className="my-auto">
                                <hr className="hr-md"/>
                                <p className="lead text-center align-self-center">
                                    With Clarivor's technology, you can screen and execute strategies with
                                    the click of a button, leaving you more time to focus on value-added investment
                                    decisions
                                </p>
                                <hr className="hr-md"/>
                            </div>
                        </div>
                    </Fade>
                </div>


                <Fade bottom>
                    <div className="row justify-content-center mt-6">
                        <a data-toggle="modal" href="#requestDemoModal" style={{textDecoration: 'none'}}>
                            <button className="btn btn-success lift mt-2 d-flex"
                                    onClick={
                                        () => track_ux_event(localStorage.uuid,
                                            'Request Demo Clicked', {source: 'Institution Solutions 1'}, globalAuthManager)
                                    }
                            >
                                <span className={'my-auto'}>Request Demo</span>
                            </button>
                        </a>
                    </div>
                </Fade>
            </div>
        </section>
    )
}


function LightbulbSVG(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height={props.heightStr} width={props.widthStr}
             fill={props.fillStr} version="1.1" x="0px" y="0px" viewBox="0 0 100 100"
             enable-background="new 0 0 100 100">
            <path
                d="M50,23.587c-16.27,0-22.799,12.574-22.799,21.417c0,12.917,10.117,22.451,12.436,32.471h20.726  c2.32-10.02,12.436-19.554,12.436-32.471C72.799,36.161,66.271,23.587,50,23.587z"/>
            <path
                d="M39.637,87.161c0,3.001,1.18,4.181,4.181,4.181h0.374h0.052l0.41,1.231C45.278,94.449,46.042,95,48.019,95h3.963  c1.978,0,2.74-0.551,3.365-2.427l0.409-1.231h0.052h0.375c3.002,0,4.18-1.18,4.18-4.181V80.91H39.637V87.161z"/>
            <path
                d="M50,18.265c1.26,0,2.072-0.814,2.072-2.073v-9.12C52.072,5.813,51.26,5,50,5c-1.259,0-2.072,0.813-2.072,2.073v9.12  C47.928,17.452,48.741,18.265,50,18.265z"/>
            <path
                d="M68.313,23.727c0.994,0.774,2.135,0.634,2.91-0.357l5.614-7.187c0.776-0.992,0.636-2.135-0.356-2.909  c-0.992-0.776-2.135-0.636-2.91,0.357l-5.613,7.186C67.18,21.81,67.322,22.952,68.313,23.727z"/>
            <path
                d="M91.157,36.373c-0.306-1.222-1.291-1.815-2.513-1.51l-8.85,2.207c-1.222,0.305-1.814,1.29-1.51,2.512  c0.305,1.223,1.291,1.814,2.513,1.51l8.849-2.206C90.869,38.581,91.462,37.595,91.157,36.373z"/>
            <path
                d="M86.757,60.48l-8.331-3.709c-1.15-0.512-2.225-0.099-2.736,1.052c-0.512,1.151-0.1,2.224,1.051,2.737l8.33,3.707  c1.15,0.514,2.225,0.101,2.736-1.05C88.32,62.068,87.907,60.994,86.757,60.48z"/>
            <path
                d="M28.779,23.37c0.775,0.992,1.917,1.131,2.909,0.357c0.992-0.776,1.132-1.917,0.357-2.91l-5.615-7.186  c-0.775-0.992-1.917-1.132-2.909-0.357s-1.131,1.917-0.356,2.909L28.779,23.37z"/>
            <path
                d="M21.715,39.583c0.305-1.223-0.288-2.208-1.51-2.513l-8.849-2.207c-1.222-0.303-2.208,0.289-2.513,1.511  c-0.303,1.222,0.288,2.207,1.511,2.512l8.848,2.206C20.424,41.396,21.41,40.805,21.715,39.583z"/>
            <path
                d="M21.575,56.771l-8.331,3.711c-1.151,0.511-1.563,1.586-1.05,2.735c0.511,1.151,1.586,1.563,2.736,1.052l8.331-3.711  c1.151-0.511,1.563-1.586,1.05-2.735C23.799,56.673,22.726,56.261,21.575,56.771z"/>
        </svg>)
}

function WarningSVG(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height={props.heightStr} width={props.widthStr}
             fill={props.fillStr} version="1.1" x="0px" y="0px" viewBox="0 0 100 100">
            <g>
                <path
                    d="M16.2,84.6c1.5,0.4,3.1,0.4,4.7,0.4c9.7,0,19.4,0,29.1,0c9.7,0,19.4,0,29.1,0c1.6,0,3.2,0,4.7-0.4c6-1.6,8.7-8.3,5.4-13.8   C78.9,53.7,68.5,36.6,58.1,19.5c-1.9-3.2-5-4.7-8.1-4.8c-3.1,0-6.1,1.6-8.1,4.8c-10.5,17-20.8,34.1-31.1,51.3   C7.5,76.3,10.2,83,16.2,84.6z M50,75.7C50,75.7,50,75.7,50,75.7C50,75.7,50,75.7,50,75.7c-2.5,0-4.5-2-4.6-4.5c0-2.5,2-4.5,4.6-4.5   c2.5,0,4.6,2,4.6,4.5C54.5,73.7,52.5,75.6,50,75.7z M45,38c0.1-2.9,2.1-4.9,4.9-5c0,0,0,0,0.1,0s0,0,0.1,0c2.9,0,4.9,2.1,4.9,5   c0.1,3.1,0.1,15.1,0,18c-0.1,3.1-2,4.7-5,4.7c-3,0-4.9-1.6-5-4.7C44.9,53.1,44.9,41.1,45,38z"/>
            </g>
        </svg>
    )
}

function PlanSection(props) {
    return (
        <section className="py-10 bg-gradient-light">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8 text-center">
<span className="badge badge-pill badge-primary-soft mb-3">
<span className="h6 text-uppercase">Investment Process</span>
</span>
                        <h1>
                            How Clarivor works
                        </h1>
                    </div>
                </div>

                <div className="row align-items-center mt-4">
                    <div className="col-12 col-md-6 col-lg-7">
                        <div className="device device-macbook">
                            <div className="device device-macbook">
                                <img src={planScreenshot} className="device-screen" alt="..."/>
                                <img src={macBook} className="img-fluid" alt="..."/>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-5">
                        {/*<div className="d-flex">*/}
                        {/*    <div className="badge badge-lg badge-rounded-circle badge-primary-soft mt-1">*/}
                        {/*        <span>1</span>*/}
                        {/*    </div>*/}
                        {/*    <div className="ml-5">*/}
                        {/*        <h3>*/}
                        {/*            Sign up*/}
                        {/*        </h3>*/}
                        {/*        <p className="text-gray-700 mb-6">*/}
                        {/*            Your firm becomes a client of Clarivor, and you open end-client brokerage*/}
                        {/*            accounts*/}
                        {/*            at*/}
                        {/*            a supported custodian*/}
                        {/*        </p>*/}

                        {/*    </div>*/}

                        {/*</div>*/}
                        <div className="d-flex">


                            <div className="badge badge-lg badge-rounded-circle badge-primary-soft mt-1">
                                <span>1</span>
                            </div>


                            <div className="ml-5">


                                <h3>
                                    Screen
                                </h3>


                                <p className="text-gray-700 mb-6">
                                    Use automated screens to select strategies based on investment objectives and market
                                    conditions
                                </p>

                            </div>

                        </div>
                        <div className="d-flex">
                            <div className="badge badge-lg badge-rounded-circle badge-primary-soft mt-1">
                                <span>2</span>
                            </div>


                            <div className="ml-5">


                                <h3>
                                    Invest
                                </h3>


                                <p className="text-gray-700 mb-6">
                                    Instruct Clarivor to execute strategies in your custodial accounts
                                </p>

                            </div>

                        </div>
                        <div className="d-flex">
                            <div className="badge badge-lg badge-rounded-circle badge-primary-soft mt-1">
                                <span>3</span>
                            </div>


                            <div className="ml-5">


                                <h3>
                                    Manage Risk
                                </h3>


                                <p className="text-gray-700 mb-0">
                                    Access real-time risk reports and perform "what-if" scenario analysis
                                </p>

                            </div>

                        </div>

                    </div>
                </div>

                <div className="row justify-content-center my-4 my-md-7">
                    <a data-toggle="modal" href="#requestDemoModal" style={{textDecoration: 'none'}}>
                        <button className="btn btn-success lift mt-2 d-flex"
                                onClick={
                                    () => track_ux_event(localStorage.uuid,
                                        'Request Demo Clicked', {source: 'Institution Solutions 2'}, globalAuthManager)
                                }
                        >
                            <span className={'my-auto'}>Request Demo</span>
                        </button>
                    </a>
                </div>
            </div>
        </section>
    )
}


function ValuePropositionSection(props) {
    return (
        <section className="py-10 bg-gradient-light">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8 text-center text-dark">
                        <div className="mb-md-4">
                            <BellCurveSVG fillStr="#95AAC9" widthStr={'120px'} heightStr={'120px'}/>
                        </div>
                        <h1>
                            Automated access to institutional option strategies:
                        </h1>
                    </div>
                </div>
                <div className="row justify-content-center my-2 my-md-6 ">
                    <div className="col-10 col-sm-8 col-md-7">
                        <div className="card shadow mb-6 text-white rounded bg-dark">
                            <div
                                className="card-body position-relative text-center font-weight-bold justify-content-center">
                                <div className="h3 font-weight-bold d-flex align-items-center mh-100">
                                    <span className="my-auto text-center w-100">Hedging tail risk</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-10 col-sm-8 col-md-7">
                        <div className="card shadow mb-6 text-white rounded bg-dark">
                            <div className="card-body position-relative text-center font-weight-bold">
                                <h3 className="font-weight-bold">
                                    Harvesting the volatility risk premium
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-10 col-sm-8 col-md-7">
                        <div className="card shadow mb-6 text-white rounded bg-dark">
                            <div className="card-body position-relative text-center font-weight-bold">
                                <h3 className="font-weight-bold">
                                    Targeting relative value opportunities
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center my-4 my-md-7">
                    <a data-toggle="modal" href="#requestDemoModal" style={{textDecoration: 'none'}}>
                        <button className="btn btn-success lift mt-2 d-flex"
                                onClick={
                                    () => track_ux_event(localStorage.uuid,
                                        'Request Demo Clicked', {source: 'Institution Solutions 3'}, globalAuthManager)
                                }
                        >
                            <span className={'my-auto'}>Request Demo</span>
                        </button>
                    </a>
                </div>
            </div>
        </section>
    )
}


function ChallengesSection(props) {
    return (
        <section className="py-10 bg-gradient-light">
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-12 col-md-8 col-lg-6 px-0">
                        <div
                            className="img-fluid mw-md-150 mw-lg-130 mb-6 `mb-md-0 rounded py-6 px-6 d-flex"
                            alt="..."
                            style={{
                                backgroundImage: ['linear-gradient(180deg, rgba(38, 32, 60, .4) 0%, rgba(38, 32, 60, .4) 100%)', "url(" + coupleOfAnalysisImage + ")"],
                                backgroundRepeat: 'no-repeat',
                                position: 'relative',
                                zIndex: 0,
                                backgroundSize: 'cover',
                                minHeight: '350px'
                            }}
                        >
                            <h1 className="display-3 text-md-left text-white align-self-center">
                                Option strategies require specialized tools
                            </h1>
                        </div>
                    </div>
                    <div className="col-12 col-md-8 col-lg-6">
                        <div className="row justify-content-center">
                            <div className="col-12 text-center">
                                <h6 className="text-muted mb-8 badge badge-pill badge-danger-soft">
                                    FREQUENT PAIN-POINTS
                                </h6>
                                <h3 className="text-secondary">
                                    Strategy backtesting and analysis
                                </h3>
                                <hr className="hr-md"/>
                                <h3 className="text-secondary">
                                    Instrument selection, execution, and reconciliation
                                </h3>
                                <hr className="hr-md"/>
                                <h3 className="text-secondary">
                                    Risk management and reporting
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}


// Automated platform, save you time
// Automated, institutional grade platform

// Institutional grade
// Founder managed risk and firms like Merrill Lynch, Bank of America and Deutsche Bank
// Solutions for pensions, insurers, hedge funds, swf's
// 12 years experience
function GuideSection(props) {
    return (
        <section className="py-10 bg-gradient-light">
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-12 col-md-10 text-center h1 mb-4 mb-md-8">
                        Built for Institutions That Need to Manage Time and Resources Efficiently
                        {/*  alt: Clarivor understands how institutional investors need more efficient investment solutions*/}
                    </div>
                </div>
                <div className="row align-items-center justify-content-center">
                    <div className="col-12">
                        <div className="row pt-6 pt-md-0 justify-content-center">
                            <div className="col-12 col-md-8 text-center">
                                <div className="card bg-gray-200 mb-6">
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <div className="badge badge-rounded-circle badge-primary mt-1 mr-4">
                                                <Check className={'mt-n1'}/>
                                            </div>
                                            <p className="h3 text-left">
                                                Clarivor's founder spent 12+ years at major banks, managing risk
                                                and developing investment solutions with derivatives
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card bg-gray-200 mb-6">
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <div className="badge badge-rounded-circle badge-primary mt-1 mr-4">
                                                <Check className={'mt-n1'}/>
                                            </div>
                                            <p className="h3 text-left">
                                                Clarivor's platform solves challenges facing major pensions,
                                                hedge funds, insurance companies, and family offices
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}


//
// Explanatory paragraph
// ...products with retail fees that are difficult to risk-manage
export default function InstitutionsPage(props) {
    let aNewWayRef = React.createRef()
    let pricingRef = React.createRef()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const scrollToANewWay = () => {
        aNewWayRef.current.scrollIntoView({behavior: 'smooth'})
    }

    const scrollToPricing = () => {
        pricingRef.current.scrollIntoView({behavior: 'smooth'})
    }

    setLKBody()


    return (
        <React.Fragment>
            <NavBar {...props}/>
            <RequestDemoModal source="AdvisorsPage"/>
            <BreadcrumbNav pathList={['Solutions', 'Institutions']}/>
            <InstitutionHeroSection {...props} handleLearnMoreClicked={scrollToANewWay}/>
            <Fade clear>
                <ValuePropositionSection/>
            </Fade>
            <Fade clear>
                <ChallengesSection/>
            </Fade>
            <Fade clear>
                <GuideSection/>
            </Fade>
            <Fade clear>
                <PlanSection/>
            </Fade>
            {/*<AdvisorsPricingSection/>*/}

            <a id={'learnMore'}></a>

            {/*<PricingSection sectionRef={pricingRef}/>*/}
            {/*<SignupFormSection {...props}/>*/}
            <Footer {...props}/>
        </React.Fragment>
    )
}


