import React, {useContext, useEffect, useState} from "react"
import headshot from 'images/blog/avatar-100.jpg'
import RequestDemoModal from "../../../pages/common/RequestDemoModal"
import {globalAuthManager, globalServices} from "../../../../services/GlobalServices"
import {apiGet} from "../../../../services/api-call-helpers"
import * as keys from 'keys'
// import {mixpanel} from "../../../../services/mixpanel"
import ReceiveUpdatesModal from "../../../pages/common/ReceiveUpdatesModal"
import {Link} from "react-router-dom"
import BlogSignupSection from "../../common/BlogSignupSection"

import BannerImage from "images/blog/Blog_20211215_BufferPerformance/stock-chart-banner.jpg"
import BannerImage500 from "images/blog/Blog_20211215_BufferPerformance/stock-chart-banner-500.jpg"
import bufferPerfChart from "images/blog/Blog_20211215_BufferPerformance/buffer-performance-chart.png"
import bufferBRPerfChart from "images/blog/Blog_20211215_BufferPerformance/buffer-bond-replacement-chart.png"
import jonAvatar from 'images/blog/avatar-100.jpg'
import HypotheticalPerformanceAlert from "../../../common/alerts/HypotheticalPerformanceAlert";

const TLTOverwriteContext = React.createContext({})

const documentTitle = "Clarivor Blog"
const postTitle = 'Buffers: Beware the Benchmark'
const postSubtitle = 'In 2021 buffers appeared to under-perform traditional equity, but is that the right comparison?'
const postAuthor = 'Jon Spiegel, CFA'
const publishedOnText = 'December 15, 2021'
const publishedOnShort = 'Dec 15, 2021'
export const path = '/blog/buffer-performance-20211215'

const HeroSection = (props) => (
    <section
        style={{
            backgroundImage: ["url(" + BannerImage + ")"],
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            position: 'relative',
            zIndex: 0,
            backgroundSize: 'cover',
            height: '35vh'
        }}>
    </section>
)

const HeaderSection = (props) => (
    <section className="pt-8 pt-md-11">
        <RequestDemoModal/>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 col-md-12 col-lg-10 col-xl-10">


                    <h1 className="display-4 text-center">
                        {props.title}
                    </h1>


                    {/*<p className="lead mb-7 text-center text-muted">*/}
                    <p className="lead px-10 mb-7 text-center text-muted">
                        {props.subtitle}
                    </p>


                    <div className="row align-items-center py-5 border-top border-bottom">
                        <div className="col-auto">


                            <div className="avatar avatar-lg">
                                <img src={props.avatarImage} alt="..."
                                     className="avatar-img rounded-circle"/>
                            </div>

                        </div>
                        <div className="col ml-n5">
                            <h6 className="text-uppercase mb-0">
                                {props.author}
                            </h6>
                        </div>
                        <div className="col-auto">
                            <time className="font-size-sm text-muted" dateTime="2019-05-20">
                                {props.publishedOnText}
                            </time>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
)


function Summary(props) {
    return (
        <React.Fragment>
            <h3 className={'font-weight-bold mt-6'}>
                Summary
            </h3>
            <ul>
                <li className='my-2'>
                    Over the past twelve months, a SPY buffer option strategy returned an estimated 15% less
                    than
                    holding the SPY ETF directly.
                </li>
                <li className='my-2'>
                    A buy-and-hold investor benchmarking the buffer to the ETF would likely view this as a significant
                    under-performance.
                </li>
                <li className='my-2'>
                    However, due to the nature of buffers, equity benchmarks may not
                    always be appropriate for assessing relative performance of buffers against investment objectives.
                </li>
            </ul>
        </React.Fragment>
    )
}


function Section1(props) {
    return (
        <React.Fragment>
            <h3 className={'font-weight-bold mt-6'}>
                Overview
            </h3>
            <p>
                Over the past twelve months, large-cap U.S. equities (SPY ETF) have returned approximately 27%.
                Meanwhile, a SPY option buffer strategy, targeting 10% protection, traded last December would have
                returned approximately 12%. Buffers usually provide protection against an initial price decline (e.g.
                10%) in
                exchange for giving up price appreciation past a cap. With the continued equity rally in 2021, the
                change in price of SPY has been significantly higher than the cap.
            </p>
            <p>
                The 15% difference in returns is likely relevant for a buy-and-hold investor using the buffer as a
                static equity replacement. However in many cases the underlying equity ETF may not be the right
                benchmark for assessing the performance of the buffer relative to investment objectives.
            </p>
            <p>
                Below are three reasons why it might not make sense to compare the buffer return to the ETF return over
                the past year.
            </p>

            <h3 className={'font-weight-bold mt-6'}>
                1. Changing market exposure
            </h3>
            <p>
                The average market exposure of a buffer traded last December has been approximately 32%. That means
                that on average, if the price of SPY changed by 1%, an investor could expect the buffer's value
                to change by 0.3%.
            </p>
            <p>
                The exposure started at approximately 58%, and has declined to almost zero due to the passage of time
                and SPY's price increasing past the level of the cap. Even though buffers are designed to provide equity
                upside at maturity, it may not make sense to hold one as an equity replacement if the exposure has
                declined.
            </p>
            <p>
                An investor seeking to track equities more closely could add equity exposure, for
                example by rolling into a buffer with more exposure or by replacing some of the buffer allocation with
                the ETF itself.
            </p>

            <div className='card bg-light mb-3'>
                <div className='card-body p-2'>
                    <img style={{width: "100%"}} src={bufferPerfChart}/>
                </div>
            </div>
            <h3 className={'font-weight-bold mt-6'}>
                2. Bond replacement strategies
            </h3>
            <p>
                In a low-rate environment like the one that has prevailed in recent years, investors may have
                used buffers as a way of reducing exposure to bonds with the potential to capture additional upside to
                equities. With bonds having a negative total return over the past year (AGG -3%), the SPY buffer
                outperformed bonds.
            </p>
            <p>
                A portfolio of 50% SPY, 20% Buffer, and 30% bonds
                performed similarly to a traditional 60-40 portfolio, even before rebalancing for the
                diminished market exposure embedded in the buffer.
            </p>

            <div className='card bg-light mb-3'>
                <div className='card-body p-2'>
                    <img style={{width: "100%"}} src={bufferBRPerfChart}/>
                </div>
            </div>
            <h3 className={'font-weight-bold mt-6'}>
                3. Considering pricing and volatility
            </h3>
            <p>
                The return of the buffers over the past year, while impacted by the cap, was relatively high for
                the strategy in general. This is because at the time of trading in December 2020 the option market
                was pricing in higher than average volatility. The cap provided by the buffer was higher than it had
                been 85% of the time over the previous 5 years.
            </p>
            <p>
                Furthermore, the volatility of SPY in 2021 was generally lower than what the market was pricing last
                December. While the volatility may not impact the final value of a static buffer position, lower
                volatility can be
                favorable for strategies that rebalance as the exposure of the buffer changes.
            </p>


            <h3 className={'font-weight-bold mt-6'}>
                Conclusion
            </h3>
            <p>
                In short, buffers are option strategies that can exhibit risk characteristics different from
                traditional equity investments. Investors can incorporate buffers in their portfolios in various ways,
                for example by using them as equity or bond replacements. Investors should use buffers consistently
                with their investment objectives and assess their performance accordingly.
            </p>

            <h3 className={'font-weight-bold mt-6'}>
                More on buffers
            </h3>
            <p>
                See the following posts for more of Clarivor's work on buffer strategies.
            </p>
            <ul>
                <li>
                    <Link to='/blog/Blog_20210525_BufferDelta'>Are You Actively Managing Your Buffers?
                        (2021-05-25)</Link>
                </li>
                <li>
                    <Link to='/blog/BufferBondReplacement_20210624'>Buffers as Bond Replacements (2021-06-24)</Link>
                </li>
                <li>
                    <Link to='/blog/BufferPerformance_20210930'>Buffer Performance in September 2021 (2021-09-30)</Link>
                </li>
                <li>
                    <Link to='/blog/SepBuffer_20211012'>When a Buffer Rolls in a Choppy Market (2021-10-13)</Link>
                </li>
            </ul>


        </React.Fragment>
    )
}

function BufferPerformanceProjectContainer(props) {
    const [projectObj, setProjectObj] = useState(null)

    useEffect(() => {
        apiGet(keys.API_ENDPOINT.PROJECTS, {id: props.projectId}, globalAuthManager).then(
            res => {
                const [project] = res.data
                setProjectObj(project)
            }
        )
    }, [])

    const contextValue = {
        projectObj: projectObj,
        projectId: props.projectId
    }

    return (
        <TLTOverwriteContext.Provider value={contextValue}>
            {props.children}
        </TLTOverwriteContext.Provider>
    )
}


function DisclosuresLink(props) {
    return (
        <a data-toggle="modal" href="#disclaimerModal"
           onClick={() => null}>Disclosures</a>
// onClick={() => mixpanel.track('Disclosure Clicked')}>Disclosures</a>
    )
}

function TermsOfUseLink(props) {
    return (
        <Link to={'/terms-of-use'} target={'_blank'}
              onClick={() => null}>Terms of Use</Link>
// onClick={() => mixpanel.track('Terms of Use Clicked')}>Terms of Use</Link>
    )
}

function BacktestingSourceDisclaimer(props) {
    return (
        <div>
            <hr/>
            <p className='font-italic text-muted'>
                Source: Clarivor LLC. These results are back-tested and may not be indicative of future performance.
                All buffers above refer to a SPY 12-month buffer traded on 12/18/2021, providing 10% downside
                protection, collateralized with cash.  Changes to strategy parameters, such as the buffer level, cap,
                or roll timing, can have a material impact on results.
                Options can entail complex risks and may not be suitable for all investors.<br/>
                Each option strategy back-test above includes an annual deduction of 0.49% to reflect management fees
                and commissions. SPY (TR) and AGG (TR) have dividends immediately reinvested, shown with no additional
                management fee.
                Strikes are selected from the listed market.
                {/*Options are rolled seven days prior to expiration at bid or offer.*/}
                Option prices are observed at 3:45pm NY time.
                <br/>
                <br/>
                NOTHING IN THIS POST SHOULD BE CONSTRUED AS INVESTMENT ADVICE. <br/>
                <DisclosuresLink/> and <TermsOfUseLink/> apply.
                <br/>
            </p>
        </div>
    )
}


function PostBody(props) {
    return (
        <React.Fragment>
            <section className="pt-0 pt-md-0">
                <ReceiveUpdatesModal/>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-12 col-lg-10 col-xl-9">
                            <Summary/>
                            <Section1/>
                            <BacktestingSourceDisclaimer/>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}


export default function BufferPerformance_20210803(props) {
    const projectId = null  // this is a static blog post

    useEffect(() => {
        document.title = documentTitle
    }, [])

    const headerProps = {
        title: postTitle,
        subtitle: postSubtitle,
        avatarImage: headshot,
        author: postAuthor,
        publishedOnText: publishedOnShort,
    }

    return (
        <React.Fragment>
            <HeroSection/>
            <HeaderSection {...headerProps} />
            <BufferPerformanceProjectContainer projectId={projectId}>
                <PostBody/>
            </BufferPerformanceProjectContainer>
            <BlogSignupSection fromLocation={'Blog Post TLT Overwriting'}/>
            <div className='container'>
                <HypotheticalPerformanceAlert/>
            </div>
        </React.Fragment>
    )
}


export function RichBufferPerformance20211215(props) {
    return (
        <section className="pt-7 pt-md-10">
            <div className="container">
                <div className="row">
                    <div className="col-12">

                        <div className="card card-row shadow-light-lg mb-6 lift lift-lg">
                            <div className="row no-gutters">
                                <div className="col-12">

<span className="badge badge-pill badge-light badge-float badge-float-inside">
<span className="h6 text-uppercase">buffers</span>
</span>
                                </div>
                                <Link className="col-12 col-md-6 order-md-2 bg-cover card-img-right"
                                      style={{backgroundImage: "url(" + BannerImage500 + ")"}}
                                      to={path}>

                                    <img src="assets/img/photos/photo-27.jpg" alt="..."
                                         className="img-fluid d-md-none invisible"/>

                                    <div
                                        className="shape shape-left shape-fluid-y svg-shim text-white d-none d-md-block">
                                        <svg viewBox="0 0 112 690" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 0h62.759v172C38.62 384 112 517 112 517v173H0V0z"
                                                  fill="currentColor"></path>
                                        </svg>
                                    </div>

                                </Link>
                                <div className="col-12 col-md-6 order-md-1">

                                    <Link className="card-body" to={path}>

                                        <h3>
                                            {postTitle}
                                        </h3>

                                        <p className="mb-0 text-muted">
                                            {postSubtitle}
                                        </p>

                                    </Link>

                                    <a className="card-meta" href="#!">

                                        <hr className="card-meta-divider"/>

                                        <div className="avatar avatar-sm mr-2">
                                            <img src={jonAvatar} alt="..."
                                                 className="avatar-img rounded-circle"/>
                                        </div>

                                        <h6 className="text-uppercase text-muted mr-2 mb-0">
                                            Jon Spiegel
                                        </h6>

                                        <p className="h6 text-uppercase text-muted mb-0 ml-auto">
                                            <time dateTime="2020-10-23">{publishedOnShort}</time>
                                        </p>

                                    </a>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>

    )
}
