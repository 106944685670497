import React, {useContext, useEffect, useState} from "react"
import {Link} from 'react-router-dom'
import {AccountManagementContext, AppContext} from "../../contexts"
import * as keys from 'keys'
import {apiGet, apiPost} from "../../../services/api-call-helpers"
import {globalAuthManager} from "../../../services/GlobalServices"
import {
    IndividualQuestionnaireForm,
    InstitutionQuestionnaireForm,
    AdviserQuestionnaireForm
} from "./ClientQuestionnaireForms"
import ContactInfoForm from "./ContactInfoForm"
import {QuestionnaireContext} from "./ClientQuestionnaireForms"

const NoClientType = (props) => (
    <div className={'card'}>
        <div className={'card-body text-center'}>
            <h3 className={'text-info'}>Loading Questionnaire</h3>
        </div>
    </div>

)

const QuestionnaireWrapper = (props) => {
    const clientType = props.clientType == null ? null : props.clientType.toLowerCase()
    let description = "."

    const individualDescriptionComponent = <p className="font-size-lg mb-7 mb-md-9 text-muted">
        As part of our fiduciary duty we need to understand each client's financial situation,
        objectives and risk tolerance, so that we are able to recommend suitable investment strategies.<br/>
        Please answer the following questions.
    </p>

    const institutionalDescriptionComponent = <p className="font-size-lg mb-7 mb-md-9 text-muted">
        As part of our fiduciary duty we need to understand each client's financial situation,
        objectives and risk tolerance, so that we are able to recommend suitable investment strategies.<br/>
        Please answer the following questions.
    </p>

    const adviserDescriptionComponent = <p className="font-size-lg mb-7 mb-md-9 text-muted">
        Please answer the following questions about your advisory firm and your clients.
    </p>

    let descriptionComponent = null
    if (clientType === keys.CLIENT_TYPES.ADVISER.toLowerCase()) {
        descriptionComponent = adviserDescriptionComponent
    } else if (clientType === keys.CLIENT_TYPES.INSTITUTION.toLowerCase()) {
        descriptionComponent = institutionalDescriptionComponent
    } else if (clientType === keys.CLIENT_TYPES.INDIVIDUAL.toLowerCase()) {
        descriptionComponent = individualDescriptionComponent
    }

    return (
        <section className="pt-8 pt-md-11 pb-8 pb-md-14 bg-light">
            <div className="container">
                <div className='row justify-content-center my-5'>
                    <div className='col-12 col-md-10 text-center'>
                        <h2 className="text-dark font-weight-bold">
                            Client Questionnaire
                        </h2>
                        {descriptionComponent}
                        {props.children}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default function ClientQuestionnaireContainer(props) {
    const appContext = useContext(AppContext)
    const accountMgmtContext = useContext(AccountManagementContext)
    const [errorMessage, setErrorMessage] = React.useState(null)
    const [formFields, setFormFields] = React.useState({})
    const [defaultFormFields, setDefaultFormFields] = useState({})
    const [clientInfo, setClientInfo] = useState(null)

    useEffect(() => {
        if (appContext.isLoggedIn === true) {
            window.scroll(0, 0)
            apiGet(keys.API_ENDPOINT.ADVISORY_QUESTIONNAIRE, {}, globalAuthManager).then(res => {
                    if (res.data.questionnaire != null) {
                        const copyFF = JSON.parse(JSON.stringify(res.data.questionnaire))
                        setDefaultFormFields(res.data.questionnaire)
                        setFormFields(copyFF)
                    }
                }
            )

            apiGet(keys.API_ENDPOINT.ADVISORY_CLIENT, {}, globalAuthManager).then(res => {
                setClientInfo(res.data.client_info)
                // const typeStr = res.data.type
                //
                // if (typeStr != null) {
                //     if (res.data.type.toLowerCase() === 'individual') {
                //         setIsIndividual(true)
                //     } else if (res.data.type.toLowerCase() === 'institution') {
                //         setIsIndividual(false)
                //         setClientName(res.data.name)
                //     }
                // }
            })
        }
    }, [appContext.isLoggedIn])

    const setFieldValue = (k, v) => {
        let newFormFields = JSON.parse(JSON.stringify(formFields))

        newFormFields[k] = v

        if (k === 'isAccredited' && v === true) {
            newFormFields.netWorth = null
            newFormFields.householdIncome = null
        }

        if (k === 'noShortOrMediumTermObligations' && v === true) {
            newFormFields.obligationsDescription = null
        }

        setFormFields(newFormFields)
    }

    const handleSubmit = async (event) => {
        event.preventDefault()

        const data = {
            questionnaire: formFields
        }

        let submitted = false
        try {
            await apiPost(keys.API_ENDPOINT.ADVISORY_QUESTIONNAIRE, data, globalAuthManager)
            submitted = true
        } catch (error) {
            if (error.response == null) {
                setErrorMessage('Could not submit.  Connectivity issue.')
            } else if (error.response.data.message) {
                setErrorMessage(error.response.data.message)
            } else {
                setErrorMessage(`Could not submit.  Error code: ${error.response.status}.`)
            }
        }

        if (submitted) {
            accountMgmtContext.newQuestionnaireSubmitted()
        }
    }

    let questionnaire = <NoClientType/>

    let clientType = null
    if (clientInfo != null) {
        clientType = clientInfo.clientType
    }

    if (clientType === keys.CLIENT_TYPES.INDIVIDUAL) {
        questionnaire = (<IndividualQuestionnaireForm submit={handleSubmit}
                                                      errorMessage={errorMessage}/>)
    } else if (clientType === keys.CLIENT_TYPES.INSTITUTION) {
        questionnaire = (<InstitutionQuestionnaireForm submit={handleSubmit}
                                                       errorMessage={errorMessage}
        />)
    } else if (clientType === keys.CLIENT_TYPES.ADVISER) {
        questionnaire = (<AdviserQuestionnaireForm submit={handleSubmit}
                                                   errorMessage={errorMessage}
        />)
    }

    return (
        <QuestionnaireContext.Provider value={{
            defaultFormFields: defaultFormFields,
            formFields: formFields,
            setFieldValue: setFieldValue,
            clientInfo: clientInfo
        }}>
            <QuestionnaireWrapper clientType={clientType}>
                {questionnaire}
            </QuestionnaireWrapper>
        </QuestionnaireContext.Provider>
    )
}