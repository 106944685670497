import Modal from "../../common/Modal"
import React from "react"
// import lk from 'lk/dist/assets/css/theme.module.css'

const advUrl = "https://adviserinfo.sec.gov/firm/summary/309994"
const crsUrl = "https://reports.adviserinfo.sec.gov/crs/crs_309994.pdf"

export default function DisclaimerDiv(props) {
    return (
        <div>
            <div className="row align-items-center">
                <div className="col-12 col-md">
                    <h1 className="display5 mb-2">
                        Disclosures
                    </h1>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <hr/>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    {/*<p className="text-gray-800 mb-6 mb-md-8">*/}
                    <p>
                        Clarivor LLC (“Clarivor”) is an investment advisor registered with the SEC under the
                        Investment Advisers Act of 1940. More information about Clarivor is available on the SEC’s
                        Website <a href={advUrl}
                                   className={'font-weight-bold'} target={"_blank"}>by clicking here</a>.
                    </p>
                    <p>
                        Retail investors should review Clarivor's <a href={crsUrl}
                                                                     className={'font-weight-bold'} target={"_blank"}>Client
                        Relationship Summary</a>.
                    </p>
                    <p>
                        Investment advice is only available to Clients, where "Client" means an individual or entity
                        who is party to a Client Advisory Agreement with Clarivor. However,
                        parts of the website available at clarivor.com (the “Website”), emails from Clarivor, and other
                        materials and media may contain software tools, analysis or discussions related to investments
                        (“Informational Content”) that are available to users who are not Clients.
                    </p>
                    <p>
                        NOT INVESTMENT ADVICE: Informational Content is provided for educational purposes only and
                        should not be considered as an investment recommendation or solicitation of action. You are
                        solely responsible for any judgments as to any securities and other related financial
                        instruments in which you transact based to any extent on Informational Content.
                    </p>
                    <p>
                        Additionally, no content provided by Clarivor should be considered as an offer to buy or
                        sell securities, or as tax or legal advice.
                    </p>
                    <p>
                        Investing in securities and trading options can entail significant risk of material loss.
                        Clarivor makes no representations about the suitability of any investments discussed by
                        Informational Content, either for typical users or any specific type of user. Clarivor also
                        makes no representations about the likelihood of any investment strategy meeting its
                        objective.</p>
                    <p>
                        Clarivor occasionally uses hypothetical performance to illustrate the
                        potential benefits and risks of various investment strategies. The results presented may not
                        have been achieved by any portfolio that Clarivor manages. This includes, but is not limited
                        to, model performance, back-tested performance, and targeted or projected returns.
                        Access to hypthetical performance is
                        intended for professional investors and investment advisors with the resources and
                        expertise necessary to independently assess the information
                        and its inherent risks.
                    </p>
                    <p>
                        THE SERVICES, INFORMATIONAL CONTENT, DOCUMENTATION, AND ANY AND ALL OTHER MATERIAL PROVIDED BY
                        CLARIVOR ARE
                        PROVIDED TO YOU “AS IS” WITHOUT WARRANTY OF ANY KIND. CLARIVOR AND ITS
                        LICENSORS AND SUPPLIERS HEREBY DISCLAIM ANY AND ALL WARRANTIES, EXPRESS, IMPLIED, OR STATUTORY
                        WITH RESPECT TO THE SERVICES, INCLUDING BUT NOT LIMITED TO ANY IMPLIED WARRANTIES OF
                        MERCHANTABILITY, NON-INFRINGEMENT, TITLE OR FITNESS FOR A PARTICULAR USE OR PURPOSE. NEITHER
                        CLARIVOR NOR ITS LICENSORS OR SUPPLIERS WARRANT THAT THE SERVICES WILL FUNCTION WITHOUT
                        INTERRUPTION OR THAT IT IS ERROR-FREE. TO THE EXTENT PERMITTED BY LAW YOU BEAR THE ENTIRE RISK
                        AS TO THE OPERATION OF THE SERVICES. IF YOU ARE A CLIENT, NOTHING IN THIS DISCLAIMER OF WARRANTY
                        SHALL AFFECT ANY RIGHTS YOU MAY HAVE UNDER COMMON LAW OR FEDERAL AND STATE SECURITIES LAWS.
                    </p>
                    <p>
                        Your use of the Website is further subject to the Terms of Service and Privacy Policy.
                    </p>
                </div>
            </div>
        </div>
    )
}
