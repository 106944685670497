import React, {useContext, useEffect, useState} from "react"
import headshot from 'images/blog/avatar-100.jpg'
import RequestDemoModal from "../../../pages/common/RequestDemoModal"
import {globalAuthManager, globalServices} from "../../../../services/GlobalServices"
import {apiGet} from "../../../../services/api-call-helpers"
import * as keys from 'keys'
// import {mixpanel} from "../../../../services/mixpanel"
import ReceiveUpdatesModal from "../../../pages/common/ReceiveUpdatesModal"
import {Link} from "react-router-dom"
import BlogSignupSection from "../../common/BlogSignupSection"

// import BannerImage from 'images/blog/Blog_20230203_SPYPuts/banner.jpg'
import BannerImage500 from 'images/blog/Blog_20230621_DeltaStrikeCC/scale-500.jpg'

import percentStrikeTable from 'images/blog/Blog_20230621_DeltaStrikeCC/percent-strike-table.jpg'
import targetYieldTable from 'images/blog/Blog_20230621_DeltaStrikeCC/target-yield-table.jpg'
import deltaStrikeTable from 'images/blog/Blog_20230621_DeltaStrikeCC/delta-strike-table.jpg'
import annualizedIncomeChart from 'images/blog/Blog_20230621_DeltaStrikeCC/annualized-income-chart.jpg'
import selectedStrikeChart from 'images/blog/Blog_20230621_DeltaStrikeCC/selected-strike-chart.jpg'

import jonAvatar from 'images/blog/avatar-100.jpg'

const ProjectContext = React.createContext({})

const documentTitle = "Clarivor Blog"
const postTitle = 'Covered Calls: Striking the Right Balance'
const postSubtitle = 'Leveraging Delta-Based Strike Selection to Navigate the Trade-off Between Income and Upside Potential'
const postAuthor = 'Jon Spiegel, CFA'
const publishedOnText = 'June 21, 2023'
const publishedOnShort = 'Jun 21'
const topic = 'income'
export const path = 'covered-call-delta-strikes-20230621'

const HeroSection = (props) => (
    <section
        className='bg-dark text-white'
        style={{
            position: 'relative',
            zIndex: 0,
            minHeight: '35vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start', // This line has been modified to left align the text.
            backgroundColor: '#f0f0f0', // You can adjust the color as you wish
            color: '#000', // You can adjust the color as you wish
            padding: '20px'
        }}>

        <div className='container bg-dark text-white'>
            <div className={'row justify-content-center'}>
                <h1 style={{
                    fontSize: '3.0em',
                    fontWeight: 'bold',
                    textAlign: 'center' // This line has been modified to left align the text.
                }}>
                    {postTitle}
                </h1>
                <div className="px-md-12 mt-6">
                    <h2 style={{
                        fontSize: '1.5em',
                        textAlign: 'center' // This line has been modified to left align the text.
                    }}>
                        {postSubtitle}
                    </h2>
                    <p style={{
                        fontSize: '1em',
                        textAlign: 'center'
                    }}>
                        {publishedOnText}
                    </p>
                </div>
            </div>
        </div>

    </section>)

const HeaderSection = (props) => (
    <section className="pt-8 pt-md-11">
        <RequestDemoModal/>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 col-md-12 col-lg-10 col-xl-10">


                    <h1 className="display-4 text-center">
                        {props.title}
                    </h1>


                    {/*<p className="lead mb-7 text-center text-muted">*/}
                    <p className="lead px-10 mb-7 text-center text-muted">
                        {props.subtitle}
                    </p>


                    <div className="row align-items-center py-5 border-top border-bottom">
                        <div className="col-auto">


                            <div className="avatar avatar-lg">
                                <img src={props.avatarImage} alt="..."
                                     className="avatar-img rounded-circle"/>
                            </div>

                        </div>
                        <div className="col ml-n5">
                            <h6 className="text-uppercase mb-0">
                                {props.author}
                            </h6>
                        </div>
                        <div className="col-auto">
                            <time className="font-size-sm text-muted" dateTime="2019-05-20">
                                {props.publishedOnText}
                            </time>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
)


function Summary(props) {
    return (
        <React.Fragment>
            {/*<h3 className={'font-weight-bold mt-6'}>*/}
            {/*    Summary*/}
            {/*</h3>*/}
            {/*<ul>*/}
            {/*    <li className='my-2'>*/}
            {/*        Over the past year inflation and rates have steadily trended higher. While the trend has reversed*/}
            {/*        over the past couple weeks, some investors may believe the market is still in a long-term*/}
            {/*        transition to a higher-rate regime.*/}
            {/*    </li>*/}
            {/*    <li className='my-2'>*/}
            {/*        The recent pull-back could provide an opportunity to investors who want to hedge against higher*/}
            {/*        rates and protect their portfolios from the potential impact across asset classes.*/}
            {/*    </li>*/}
            {/*    <li className='my-2'>*/}
            {/*        In order to hedge rates with no upfront costs and limited downside risk, an investor can*/}
            {/*        buy a put on the TLT ETF with funds raised from selling a TLT call-spread.*/}
            {/*    </li>*/}
            {/*</ul>*/}
        </React.Fragment>
    )
}


function Section1(props) {
    const putRef = React.createRef()
    const psRef = React.createRef()
    const collarRef = React.createRef()
    const pscRef = React.createRef()


    return (
        <React.Fragment>
            <h3 className={'font-weight-bold mt-6'}>
                Summary
            </h3>
            <ul>
                <li className='my-2'>
                    Covered call strategies often seek to balance yield generation and price appreciation,
                    but simple strike selection methods, like targeting a percent-of-spot or yield level, can lead to
                    risk
                    inconsistencies.
                </li>
                <li>
                    A more adaptive approach uses the option "delta" to systematically target the desired balance.
                </li>
                <li>
                    Compared to other methods, the delta-strike selection may adjust better across underlyings and
                    through different market environments.
                </li>
            </ul>
            <h3 className={'font-weight-bold mt-6'}>
                Introduction
            </h3>
            <p>
                Selling covered calls presents an inherent trade-off between generating yield and retaining potential
                for price appreciation. Certain strategies focus on yield, limiting exposure to price appreciation—for
                instance, those that sell at-the-money calls. However, when investment managers wish to maintain more
                exposure to price appreciation, they often seek a balance between yield and upside.
            </p><p>
            Although this trade-off can appear straightforward, maintaining the right balance between income and price
            appreciation can be challenging with covered call strategies, given the variability of option prices across
            different underlying securities and over time.
        </p><p>
            Some specialized managers may focus their efforts on predicting target stock prices, either based on
            fundamentals or technical indicators. For these managers, strike selection may be highly discretionary.
            However, many investors seeking to generate yield from covered calls may benefit from the discipline and
            consistency offered by a systematic approach.
        </p><p>
            Implementing a systematic call overwriting strategy aimed at balancing income and price appreciation can
            falter if the strike selection approach is overly simplistic, leading to inconsistencies in the risk profile
            of the strategy. A method to more consistently achieve the desired balance involves selecting strikes based
            on "delta".
        </p><p>
            While quantitatively oriented managers often use delta-based strikes, a significant portion of assets
            tracking covered call strategies may not incorporate any option models into their decision-making. However,
            a quick delta calculation can aid both investment managers and their clients in choosing strikes.
        </p>
            <p>
                Moving forward, I'll highlight some of the pitfalls of simplistic strategies and contrast them with the
                robust solutions presented by the delta-strike selection method.
            </p>

            <h3 className={'font-weight-bold mt-6'}>
                Targeting a Percent-of-spot
            </h3>

            <p>
                Arguably the simplest way to systematically select option strikes is by targeting a percentage of the
                underlying price. For example, a strategy might sell 3-month calls 10% out-of-the-money. If the price a
                stock was $50 when rolling the strategy, the listed strike closest to $55 might be selected.
            </p><p>
            While this strike percentage might be appropriate in certain market conditions for a stock with certain
            volatility, it may not provide sufficient yield for a lower-volatility, higher-dividend stock.
            Conversely, for a highly volatile stock, a 10% price appreciation cap may be too low, despite the higher
            option premium offered by the market. The following table illustrates the inconsistency across three
            different underlying stocks.
        </p>
            <div className='card mx-auto my-6'>
                <div className='card-body p-2  bg-white'>
                    <h3 className='text-center'>
                        3-month, 10% out-of-the-money calls
                    </h3>
                    <img src={percentStrikeTable} className='img-fluid'/>
                    <p className='text-center mt-3' style={{fontSize: '0.8rem'}}>
                        As of 5/19/2023<sup>1</sup>
                    </p></div>
            </div>
            <p>
                In this case, a manager seeking to sell covered calls on BAC might view a target strike 10%
                out-of-the-money as achieving a balanced yield-price appreciation trade-off. However, for TSLA, the same
                strike could be considered too low due to the stock's volatility. For PEP, the annualized yield at the
                nearest listed strike might be too low, making a lower strike call more consistent with the strategy’s
                objective.
            </p><p>
            Just as the fixed-percentage-strike might not consistently meet objectives for different underlying
            securities on a given day, the same fixed-percentage strike might not pick the best strikes over time for
            the same stock. For example, in a market with relatively high volatility, selling 3-month calls on a given
            stock 15% out-of-the money may generate the right tradeoff between upside and yield. However, when
            volatility and option prices are lower, the sweet spot might be a lower strike.
        </p>
            <h3 className={'font-weight-bold mt-6'}>
                Targeting a Yield Level
            </h3>

            <p>
                An alternative to the fixed-percentage strike methodology would be to target a consistent annualized
                yield from the premium raised, as a percent of the underlying price, determining the strike that targets
                the same yield each month. For example, a strategy that targets 10% annualized premium selling 3-month
                calls would select the strike each quarter corresponding to the call option worth 2.5% of the underlying
                stock price.
            </p>
            <p>
                This approach would adjust the strikes of lower volatility stocks to be closer to the money, while
                strikes for higher volatility stocks would be further out-of-the-money, such that each stock generates a
                similar yield. However, for a diverse set of underlyings, a single yield level could create
                inconsistencies in risk profiles.
            </p>
            <p>
                For instance, if a manager decided to target a 10% annual option premium yield for each stock in the
                portfolio, that yield might be too high for some stocks, as achieving such a yield could result in some
                calculated strikes being in-the-money. Conversely, for a high-volatility stock, the same yield level may
                not provide sufficient compensation for the risk of forgoing a substantial gain, despite the higher cap.
            </p>

            <div className='card mx-auto my-6'>
                <div className='card-body p-2  bg-white'>
                    <h3 className='text-center'>
                        3-month Calls Targeting 10% Annual Premium Yield
                    </h3>
                    <img src={targetYieldTable} className='img-fluid'/>
                    <p className='text-center mt-3' style={{fontSize: '0.8rem'}}>
                        As of 5/19/2023<sup>1</sup>
                    </p></div>
            </div>
            <p>
                In this example, targeting 10% annual option premium for BAC might be consistent with an objective to
                balance option yield and potential price appreciation. However, a manager seeking such a balance might
                want to target a lower yield for PEP and a higher yield for TSLA.
            </p>

            <h3 className={'font-weight-bold mt-6'}>
                A More Adaptive Approach with Delta-Strikes
            </h3>

            <p>
                When considering a more effective and balanced approach, a more refined methodology for selecting strike
                prices in covered call strategies involves using the option "delta." The delta of an option provides a
                measure of the sensitivity of the option's price to changes in the price of the underlying asset. It can
                be interpreted as the expected change in the option's price for a $1 change in the underlying asset's
                price.
            </p>
            <p>
                For a more balanced approach to writing covered calls, the delta of the option can play a pivotal role.
                By considering the delta, investment managers can systematically target desired balance in their strike
                price selection. Should they want to tilt the strategy to more income, they can select a higher delta.
                Conversely, if their intent is to preserve more upside, they can select a lower delta. They can then
                apply that delta across the entire portfolio of covered calls systematically, creating a similar balance
                between income and upside for each underlying security.
            </p>
            <p>
                A call option with a 50% delta, typically at-the-money or very near, might be fitting for investment
                managers aiming for more income. On the other hand, a call option with a delta of 30%, typically
                out-of-the-money, can offer more potential upside, which can be more suitable for investment managers
                seeking greater price appreciation.
            </p>

            <p>
                The following table illustrates the results of targeting a 30% delta across TSLA, BAC and PEP.
            </p>

            <div className='card mx-auto my-6'>
                <div className='card-body p-2  bg-white'>
                    <h3 className='text-center'>
                        3-month Calls Targeting 30% Delta
                    </h3>
                    <img src={targetYieldTable} className='img-fluid'/>
                    <p className='text-center mt-3' style={{fontSize: '0.8rem'}}>
                        As of 5/19/2023<sup>1</sup>
                    </p></div>
            </div>

            <p>
                Compared with the fixed-percentage strike and target-yield approaches, these strikes more consistently
                balance yield and upside for each underlying, based on the individual option prices.
            </p>
            <p>
                Further, as market conditions change, a delta-based strike selection strategy naturally adapts to target
                a consistent balance between yield and potential price appreciation. When market volatility increases,
                using delta-strike selection will often yield higher premiums (thus more income) and increase the cap on
                potential price appreciation, as out-of-the-money options become more expensive. On the other hand, when
                market volatility decreases, the same delta-strike strategy will adjust, selecting strikes that offer a
                balanced combination of lower yield and lower potential price appreciation.
            </p>
            <p>
                While selecting a strike based on a constant delta may not always result in the same yield, it may
                maintain
                a consistent trade-off between yield and price appreciation, both over time and across different
                underlying
                stocks. The level of yield will generally be proportional to the underlying's volatility, leading to a
                higher
                yield
                when volatility is high and vice versa. This consistency can help in reducing risk and providing more
                predictability for the strategy over time.
            </p>
            <p>
                However, this is not to say that it is a one-size-fits-all solution. Just as with any other strategy,
                careful consideration and regular adjustment are required. Investment managers must choose the delta
                that
                aligns with their strategy's specific objectives and risk tolerance. Regardless, the introduction of
                option
                pricing models like delta into the decision-making process for strike selection can indeed add value to
                a
                covered call strategy.
            </p>

            <h3 className={'font-weight-bold mt-6'}>
                Historical Illustration of Strike Selection Methods
            </h3>
            <p>
                To illustrate the behavior of different strike selection methods over time, we'll compare the strike
                selection methods over time historically. As an example, we will take 3-month calls on Bank of America
                Corporation (BAC).
            </p><p>
            The chart below shows the resulting percent-strikes of the selected options over time. As we can
            see, the target-yield and delta-strikes fluctuate over time. This is because they adapt
            to changing market conditions. Target-yield, focused solely on achieving a consistent yield, adjusts more
            dramatically as it seeks to maintain a constant annualized yield from the premium raised.
        </p><p>
            The delta-strike, on the other hand, adjusts more gradually, moving up or down as volatility increases or
            decreases. The changes in delta-strike are not as large as those in target-yield because it maintains a
            balance between yield and potential price appreciation, rather than being solely focused on yield.
        </p>
            <div className='card mx-auto my-6'>
                <div className='card-body p-2  bg-white'>
                    <h3 className='text-center'>
                        Evolution of Percent-Strike Over Time
                    </h3>
                    <img src={selectedStrikeChart} className='img-fluid'/>
                </div>
            </div>
            <p>
                Next, let's look at the annualized yield for the 3-month BAC calls. In this case, the percent-strike
                strategy's annualized premium
                varies significantly as volatility rises and falls. When
                volatility is high, the yield can be high as well, but it drops when volatility decreases. As a result,
                the
                income generated from the percent-strike strategy can be inconsistent, fluctuating with changes in
                market
                volatility.
            </p><p>
            The delta-strike strategy's yield also varies, albeit in a more controlled manner. This is
            because as volatility rises, the delta-strike strategy allows for some increase in upside-cap, as well as
            in annualized premium yield. This provides a balance between yield and potential
            price appreciation, potentially making it a more adaptive approach to strike selection over time.
        </p>
            <div className='card mx-auto my-6'>
                <div className='card-body p-2  bg-white'>
                    <h3 className='text-center'>
                        Historical Annualized Yield for 3-Month BAC Calls
                    </h3>
                    <img src={annualizedIncomeChart} className='img-fluid'/>
                </div>
            </div>
            {/*<p>*/}
            {/*    Although there is no one-size-fits-all approach to strike selection in covered call*/}
            {/*    strategies, the delta-strike selection method offers a balanced, adaptive, and systematic approach. This*/}
            {/*    strategy adjusts to market conditions, maintains a balance between yield and potential price*/}
            {/*    appreciation, and ultimately, brings consistency to the risk profile*/}
            {/*</p>*/}

            {/*<p>*/}
            {/*    In the next section, we will present some illustrative examples to show how delta-strike selection*/}
            {/*    can*/}
            {/*    provide a more consistent balance of yield and potential price appreciation. The examples will shed*/}
            {/*    light on*/}
            {/*    how delta-based strike selection can adjust with the market conditions to help achieve the intended*/}
            {/*    balance*/}
            {/*    in a covered call strategy. Stay tuned.*/}
            {/*</p>*/}


            <h3 className={'font-weight-bold mt-6'}>
                Practical Implications of Delta-Strikes in Portfolio Management
            </h3>

            <p>
                Having explored the potential benefits of a delta-based strategy for covered call writing, it is useful
                to consider the practical implications of adopting this approach. In essence, the delta-strike
                selection method
                provides an efficient, adaptable framework for managing covered call strategies across a diverse
                portfolio, under various market conditions.
            </p>
            <p>
                Implementing a delta-based strategy has several notable implications:
            </p>

            <p className='pl-5'>
                1. Consistency Across the Portfolio: By setting a consistent delta target, investment managers
                can
                ensure a
                coherent balance between yield and upside potential across all securities in the portfolio. This
                consistency
                can result in a more predictable risk profile, regardless of the differences in the volatility or
                other
                characteristics of the underlying securities.
            </p>

            <p className='pl-5'>
                2. Flexibility in Market Views: A potential advantage of a delta-based approach is its adaptability.
                An
                investment
                manager can modify the chosen delta based on changes in market views or the risk appetite of
                their
                clients.
                For instance, during periods of heightened market uncertainty, the manager might opt for a lower
                delta
                to
                favor more potential upside. In more stable periods, a higher delta could be chosen to generate
                a higher
                yield. This flexibility allows the strategy to dynamically adjust to prevailing market
                conditions.
            </p>

            <p className='pl-5'>
                3. Facilitates Risk Management: Using delta to select strike prices facilitates better risk
                management.
                By
                using the same delta across the portfolio, the manager can maintain a consistent trade-off
                between yield
                and
                upside potential, potentially mitigating the risk of creating exposures inconsistent with objectives.
            </p>

            <p className='pl-5'>
                4. Operational Efficiency: A delta-strike approach simplifies the process of strike selection.
                Rather
                than
                attempting to predict price movements or manually adjust strike prices, investment managers can
                use a
                straightforward, systematic approach. This operational efficiency can free up time and resources
                to
                focus on
                other aspects of portfolio management.
            </p>

            <p className='pl-5'>
                5. Alignment with Investment Objectives: Investment managers can choose a delta that aligns with
                their
                investment strategy's specific objectives and risk tolerance. For example, a conservative
                covered call
                strategy might employ a lower delta, leaving more room for potential price appreciation. In
                contrast, a
                strategy aiming for higher income might use a higher delta to maximize yield.
            </p>

            <p className='pl-5'>
                While a delta-based approach does involve a certain level of complexity, the potential benefits
                described here
                can outweigh these challenges. As
                with
                any investment strategy, it requires careful implementation and ongoing management. Still, the
                discipline
                and flexibility it offers can be a valuable addition to the toolkit of any investment manager
                running a
                systematic covered call strategy.
            </p>
        </React.Fragment>
    )
}

function BufferPerformanceProjectContainer(props) {
    const [projectObj, setProjectObj] = useState(null)

    useEffect(() => {
        apiGet(keys.API_ENDPOINT.PROJECTS, {id: props.projectId}, globalAuthManager).then(
            res => {
                const [project] = res.data
                setProjectObj(project)
            }
        )
    }, [])

    const contextValue = {
        projectObj: projectObj,
        projectId: props.projectId
    }

    return (
        <ProjectContext.Provider value={contextValue}>
            {props.children}
        </ProjectContext.Provider>
    )
}


function DisclosuresLink(props) {
    return (
        <a data-toggle="modal" href="#disclaimerModal"
           onClick={() => null}>Disclosures</a>
// onClick={() => mixpanel.track('Disclosure Clicked')}>Disclosures</a>
    )
}

function TermsOfUseLink(props) {
    return (
        <Link to={'/terms-of-use'} target={'_blank'}
              onClick={() => null}>Terms of Use</Link>
// onClick={() => mixpanel.track('Terms of Use Clicked')}>Terms of Use</Link>
    )
}

function BacktestingSourceDisclaimer(props) {
    return (
        <div>
            <hr/>
            {/*<p className='font-italic text-muted'>*/}
            {/*    Strategy Details: <br/><br/>*/}
            {/*    The calendar collar strategy back-tested above buys 12-month puts, 20%*/}
            {/*    out-of-the-money (OTM) on the SPY*/}
            {/*    ETF. Each month, a 1-month call is sold with strike closest to 1/12th of the value of a 12-month, 20%*/}
            {/*    OTM put. The 12-month put is rolled 7 days prior to maturity, while the short calls are rolled 3 days*/}
            {/*    prior to maturity. <br/><br/>*/}
            {/*    Similarly, the traditonal collar strategy buys a 12-month 20% OTM put and sells a 12-month call of*/}
            {/*    approximately equal value.<br/><br/>*/}
            {/*    The weights of the option overlays and SPY are rebalanced quarterly.<br/><br/>*/}
            {/*    Each option strategy back-test above includes an annual deduction of 0.39% to reflect management fees*/}
            {/*    and commissions.<br/><br/>*/}

            {/*    SPY (TR) has dividends immediately reinvested, shown with no additional management fee.*/}
            {/*</p>*/}
            <p className='font-italic text-muted'>
                Source: Clarivor LLC.<br/>
                <sup>1</sup>5/19/2023 selected as a representative date for illustrative purposes.<br/>
                Intended for professional investors - options can entail complex risks and may not be suitable for
                particular situations. <br/>
                {/*Listed strikes and maturities are rounded for comparison.<br/>*/}
                {/*Strikes and maturities are estimated from listed strikes and tenors for easier comparison.*/}
                {/*All buffers above refer to a SPY 12-month buffer traded on 12/18/2021, providing 10% downside*/}
                {/*protection, collateralized with cash.*/}
                {/*Options can entail complex risks and may not be suitable for all investors.<br/>*/}
                {/*Strikes are selected from the listed market.*/}
                {/*Options are rolled seven days prior to expiration at bid or offer.*/}
                {/*Back-tested results may not be indicative of future returns.*/}
                {/*Options can entail complex risks and may not be suitable for all investors. */}
                Nothing in this post should
                be construed as investment advice.<br/>
                <DisclosuresLink/> and <TermsOfUseLink/> apply.<br/>
            </p>
        </div>
    )
}


function PostBody(props) {
    return (
        <React.Fragment>
            <section className="pt-0 pt-md-0">
                <ReceiveUpdatesModal/>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-12 col-lg-10 col-xl-9">
                            {/*<Summary/>*/}
                            <Section1/>
                            <BacktestingSourceDisclaimer/>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}


export default function Blog_20230621_DeltaStrikeCC(props) {
    const projectId = null  // this is a static blog post

    useEffect(() => {
        document.title = documentTitle
    }, [])

    const headerProps = {
        title: postTitle,
        subtitle: postSubtitle,
        avatarImage: headshot,
        author: postAuthor,
        publishedOnText: publishedOnText,
    }

    return (
        <React.Fragment>
            <HeroSection/>
            {/*<HeaderSection {...headerProps} />*/}
            <BufferPerformanceProjectContainer projectId={projectId}>
                <PostBody/>
            </BufferPerformanceProjectContainer>
            <BlogSignupSection fromLocation={'Blog Post TLT Overwriting'}/>
        </React.Fragment>
    )
}


export function RichDeltaStrikeCC20230621(props) {
    return (
        <section className="pt-7 pt-md-10">
            <div className="container">
                <div className="row">
                    <div className="col-12">

                        <div className="card card-row shadow-light-lg mb-6 lift lift-lg">
                            <div className="row no-gutters">
                                <div className="col-12">

<span className="badge badge-pill badge-light badge-float badge-float-inside">
<span className="h6 text-uppercase">{topic}</span>
</span>
                                </div>
                                <Link className="col-12 col-md-6 order-md-2 bg-cover card-img-right"
                                      style={{backgroundImage: "url(" + BannerImage500 + ")"}}
                                      to={props.blogPath + path}>

                                    <img src="assets/img/photos/photo-27.jpg" alt="..."
                                         className="img-fluid d-md-none invisible"/>

                                    <div
                                        className="shape shape-left shape-fluid-y svg-shim text-white d-none d-md-block">
                                        <svg viewBox="0 0 112 690" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 0h62.759v172C38.62 384 112 517 112 517v173H0V0z"
                                                  fill="currentColor"></path>
                                        </svg>
                                    </div>

                                </Link>
                                <div className="col-12 col-md-6 order-md-1">

                                    <Link className="card-body" to={props.blogPath + path}>

                                        <h3>
                                            {postTitle}
                                        </h3>

                                        <p className="mb-0 text-muted">
                                            {postSubtitle}
                                        </p>

                                    </Link>

                                    <a className="card-meta" href="#!">

                                        <hr className="card-meta-divider"/>

                                        <div className="avatar avatar-sm mr-2">
                                            <img src={jonAvatar} alt="..."
                                                 className="avatar-img rounded-circle"/>
                                        </div>

                                        <h6 className="text-uppercase text-muted mr-2 mb-0">
                                            Jon Spiegel
                                        </h6>

                                        <p className="h6 text-uppercase text-muted mb-0 ml-auto">
                                            <time dateTime="2020-10-23">{publishedOnShort}</time>
                                        </p>

                                    </a>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>

    )
}
