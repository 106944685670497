
class MixPanelDummy {
    init() {
        return null
    }

    track() {
        return null
    }

    identify() {
        return null
    }

    alias() {
        return null
    }
}

// export var mixpanel = require('mixpanel-browser');
export var mixpanel = new MixPanelDummy();
