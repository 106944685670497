import axios from 'axios'
import * as keys from 'keys'

//  App states:
//    start isLoggedIn = null
//    if there no refresh token, isLoggedIn = false.
//    if there is a refresh token, try it.
//      if succeeds, isLoggedIn = true
//      if fails, isLoggedIn = false

export class AuthorizationManager {
    constructor(tokenUrl, tokenRefreshUrl, setStateCallback) {
        this.tokenUrl = tokenUrl
        this.tokenRefreshUrl = tokenRefreshUrl
        this.setStateCallback = setStateCallback

        this.username = ''
        this.accessToken = null
    }

    haveStoredRefreshToken() {
        return localStorage.refreshToken ? true : false
    }

    // returns true or false.
    async tryLoginWithRefreshTokenAsync() {

        let result = null

        // if (localStorage.refreshToken) {
        const payload = {
            refresh: localStorage.refreshToken
        }

        try {
            let res = await axios.post(this.tokenRefreshUrl, payload)

            if (res.data.access) {
                this.accessToken = res.data.access
                this.username = localStorage.username

                const seconds = 4 * 60
                window.setInterval(this.refreshAccessToken, seconds * 1000)
                result = true
                this.setStateCallback(true)
            }
        } catch (e) {
            result = false
        }
        // }

        return result
    }


    async logout() {
        localStorage.refreshToken = null

        try {

            const config = {
                headers: {'Authorization': "Bearer " + this.accessToken}
            };
            await axios.post(keys.API_ENDPOINT.LOGOUT, config)

        } catch (e) {
            console.error(e)
        }

        this.setStateCallback(false)
        this.accessToken = null
    }

    async loginAsync(username, password) {
        const payload = {

            username: username,
            password: password
        }

        let result = null

        try {
            const res = await axios.post(this.tokenUrl, payload)

            if (res.data.access) {
                this.accessToken = res.data.access
                this.username = username
                localStorage.username = username

                result = true
                let seconds = 4 * 60
                window.setInterval(this.refreshAccessToken, seconds * 1000)
            }

            if (res.data.refresh) {
                this.refreshToken = res.data.refresh
                localStorage.refreshToken = res.data.refresh
            }

            this.setStateCallback(true)


        } catch (e) {
            result = false
            console.error(e)
        }

        console.log(result)
        return result
    }

    refreshAccessToken = () => {
        const config = {
            refresh: localStorage.refreshToken
        }

        axios.post(keys.API_ENDPOINT.TOKENREFRESH, config)
            .then(res => {
                    if (res.data.access) {
                        console.log('new access token received  ', res.data.access)
                        this.accessToken = res.data.access
                    } else {
                        this.handleRefreshFailure(config, () => {
                        });
                    }
                }
            )
    }
}

export default AuthorizationManager
