import React, {lazy, Suspense} from "react"
import {Route, Switch, Redirect} from "react-router-dom"
import BlogMainPage from "./blog/BlogMainPage"
import NavBar from "./common/NavBarDefault"
import Footer from './common/Footer'
import {
    path as path_bufferPerformance20211215
} from './blog/posts/Blog_20211215_BufferPerformance/Blog_20211215_BufferPerformance'
import {path as path_tltHedges20220120} from './blog/posts/Blog_20220120_TLTHedges/Blog_20220120_TLTHedges'
import {path as path_spyPutSpreads20220308} from './blog/posts/Blog_20220308_PutSpreads/Blog_20220308_PutSpreads'
import {path as path_20220421} from './blog/posts/Blog_20220421_ShortPuts/Blog_20220421_ShortPuts'
import {path as path_20220615} from './blog/posts/Blog_20220615_Jun13Outlier/Blog_20220615_Jun13Outlier'
import {path as path_20220722} from './blog/posts/Blog_20220722_SPYCollar/Blog_20220722_SPYCollar'
import {path as path_20220922} from './blog/posts/Blog_20220922_CalendarCollar/Blog_20220922_CalendarCollar'
import {path as path_20221122} from './blog/posts/Blog_20221122_RatesTailHedge/Blog_20221122_RatesTailHedge'
import {path as path_20230203} from './blog/posts/Blog_20230203_SPYPuts/Blog_20230203_SPYPuts'
import {path as path_20230621} from './blog/posts/Blog_20230621_DeltaStrikeCC/Blog_20230621_DeltaStrikeCC'
import {path as path_20230815} from './blog/posts/Blog_20230815_LongCallSpreads/Blog_20230815_LongCallSpreads'
import {track_ux_event} from "../services/clarivor-tracking";
import {globalAuthManager} from "../services/GlobalServices";
import BlogPageNotFound from "./blog/common/BlogPageNotFound";


const SPYPuts_202014 = lazy(() => import('./blog/posts/SPYPuts_20201014/SPYPuts_20201014'))
const QQQCollar_20201023 = lazy(() => import('./blog/posts/QQQCollar_20201023/QQQCollar_20201023'))
const Income_20201209 = lazy(() => import('./blog/posts/Income_20201209/Income_20201209'))
const AYearSincePreCovid_20210216 = lazy(() => import('./blog/posts/AYearSincePreCovid_20210216/AYearSincePreCovid_20210216'))
const TLTOverwriting_202104 = lazy(() => import('./blog/posts/TLT_Overwriting_20200412/TLT_Overwriting_20200412'))
const Blog_20210525_BufferDelta = lazy(() => import('./blog/posts/Blog_20210525_BufferDelta/Blog_20210525_BufferDelta'))
const Blog_20210624_BufferBondReplacement = lazy(() => import('./blog/posts/Blog_20210624_BufferBondReplacement/Blog_20210624_BufferBondReplacement'))
const Blog_20210803_BuyWriteDiversification = lazy(() => import('./blog/posts/Blog_20210803_BuyWriteDiversification/Blog_20210803_BuyWriteDiversification'))
const Blog_20210930_BufferPerformance = lazy(() => import('./blog/posts/Blog_20210930_BufferPerformance/Blog_20210930_BufferPerformance'))
const Blog_20211012_SepBuffer = lazy(() => import('./blog/posts/Blog_20211012_SepBuffer/Blog_20211012_SepBuffer'))
const Blog_20211103_SellTLTPuts = lazy(() => import('./blog/posts/Blog_20211103_SellTLTPuts/Blog_20211103_SellTLTPuts'))
const Blog_20211118_SPY3mAccelerator = lazy(() => import('./blog/posts/Blog_20211118_SPY3mAcceleratedReturn/Blog_20211118_SPY3mAcceleratedReturn'))
const Blog_20211215_BufferPerformance = lazy(() => import('./blog/posts/Blog_20211215_BufferPerformance/Blog_20211215_BufferPerformance'))
const Blog_20220120_TLTHedges = lazy(() => import('./blog/posts/Blog_20220120_TLTHedges/Blog_20220120_TLTHedges'))
const Blog_20220308_SPYPutSpreads = lazy(() => import('./blog/posts/Blog_20220308_PutSpreads/Blog_20220308_PutSpreads'))
const Blog_20220422_ShortPuts = lazy(() => import('./blog/posts/Blog_20220421_ShortPuts/Blog_20220421_ShortPuts'))
const Blog_20220615_Outlier = lazy(() => import('./blog/posts/Blog_20220615_Jun13Outlier/Blog_20220615_Jun13Outlier'))
const Blog_20220722_SPYCollar = lazy(() => import('./blog/posts/Blog_20220722_SPYCollar/Blog_20220722_SPYCollar'))
const Blog_20220922_CalendarCollar = lazy(() => import('./blog/posts/Blog_20220922_CalendarCollar/Blog_20220922_CalendarCollar'))
const Blog_20221122_RatesTailHedge = lazy(() => import('./blog/posts/Blog_20221122_RatesTailHedge/Blog_20221122_RatesTailHedge'))
const Blog_20230203_SPYPuts = lazy(() => import('./blog/posts/Blog_20230203_SPYPuts/Blog_20230203_SPYPuts'))
const Blog_20230621_DeltaStrikeCC = lazy(() => import('./blog/posts/Blog_20230621_DeltaStrikeCC/Blog_20230621_DeltaStrikeCC'))
const Blog_20230815_LongCallSpreads = lazy(() => import('./blog/posts/Blog_20230815_LongCallSpreads/Blog_20230815_LongCallSpreads'))

function BlogPostLoading(props) {
    return (
        <section style={{minHeight: '100vh'}}>
            <div className='container'>
                <div className='alert alert-light'>
                    Loading...
                </div>
            </div>
        </section>
    )
}


function BlogPostWrapper(props) {
    return (
        <Suspense fallback={<BlogPostLoading/>}>
            <NavBar/>
            {props.children}
            <Footer/>
        </Suspense>
    )
}

/*
*       clarivor.com/blog/blogPostName
*       clarivor.com/blog
*/
export default function BlogContainer(props) {
    const [tracked, setTracked] = React.useState(false)
    let isLoggedIn = props.isLoggedIn

    return (
        <Switch>
            <Route
                exact path={(props.match.path + '/' + path_20230815).replace(/([^:]\/)\/+/g, "$1")}
                render={(props) => {
                    return (
                        <BlogPostWrapper>
                            <Blog_20230815_LongCallSpreads/>
                        </BlogPostWrapper>
                    )
                }}
            />
            <Route
                exact path={(props.match.path + '/' + path_20230621).replace(/([^:]\/)\/+/g, "$1")}
                render={(props) => {
                    return (
                        <BlogPostWrapper>
                            <Blog_20230621_DeltaStrikeCC/>
                        </BlogPostWrapper>
                    )
                }}
            />
            <Route
                exact path={(props.match.path + '/' + path_20230203).replace(/([^:]\/)\/+/g, "$1")}
                render={(props) => {
                    return (
                        <BlogPostWrapper>
                            <Blog_20230203_SPYPuts/>
                        </BlogPostWrapper>
                    )
                }}
            />
            <Route
                exact path={(props.match.path + '/' + path_20221122).replace(/([^:]\/)\/+/g, "$1")}
                render={(props) => {
                    return (
                        <BlogPostWrapper>
                            <Blog_20221122_RatesTailHedge/>
                        </BlogPostWrapper>
                    )
                }}
            />
            <Route
                exact path={(props.match.path + '/' + path_20220922).replace(/([^:]\/)\/+/g, "$1")}
                render={(props) => {
                    return (
                        <BlogPostWrapper>
                            <Blog_20220922_CalendarCollar/>
                        </BlogPostWrapper>
                    )
                }}
            />
            <Route
                exact path={(props.match.path + '/' + path_20220722).replace(/([^:]\/)\/+/g, "$1")}
                render={(props) => {
                    return (
                        <BlogPostWrapper>
                            <Blog_20220722_SPYCollar/>
                        </BlogPostWrapper>
                    )
                }}
            />
            <Route
                exact path={(props.match.path + '/' + path_20220615).replace(/([^:]\/)\/+/g, "$1")}
                render={(props) => {
                    return (
                        <BlogPostWrapper>
                            <Blog_20220615_Outlier/>
                        </BlogPostWrapper>
                    )
                }}
            />
            <Route
                exact path={(props.match.path + '/' + path_20220421).replace(/([^:]\/)\/+/g, "$1")}
                render={(props) => {
                    return (
                        <BlogPostWrapper>
                            <Blog_20220422_ShortPuts/>
                        </BlogPostWrapper>
                    )
                }}
            />
            <Route
                exact path={(props.match.path + '/' + path_spyPutSpreads20220308).replace(/([^:]\/)\/+/g, "$1")}
                render={(props) => {
                    return (
                        <BlogPostWrapper>
                            <Blog_20220308_SPYPutSpreads/>
                        </BlogPostWrapper>
                    )
                }}
            />
            <Route
                exact path={(props.match.path + '/' + path_tltHedges20220120).replace(/([^:]\/)\/+/g, "$1")}
                render={(props) => {
                    return (
                        <BlogPostWrapper>
                            <Blog_20220120_TLTHedges/>
                        </BlogPostWrapper>
                    )
                }}
            />
            <Route
                exact path={path_bufferPerformance20211215}
                render={(props) => {
                    return (
                        <BlogPostWrapper>
                            <Blog_20211215_BufferPerformance/>
                        </BlogPostWrapper>
                    )
                }}
            />
            <Route
                exact path={props.match.path + "/spy-accelerator-20121118"}
                render={(props) => {
                    return (
                        <BlogPostWrapper>
                            <Blog_20211118_SPY3mAccelerator/>
                        </BlogPostWrapper>
                    )
                }}
            />
            <Route
                exact path={props.match.path + "/selling-tlt-puts-20211104"}
                render={(props) => {
                    return (
                        <BlogPostWrapper>
                            <Blog_20211103_SellTLTPuts/>
                        </BlogPostWrapper>
                    )
                }}
            />
            <Route
                exact path={props.match.path + "/SepBuffer_20211012"}
                render={(props) => {
                    return (
                        <BlogPostWrapper>
                            <Blog_20211012_SepBuffer/>
                        </BlogPostWrapper>
                    )
                }}
            />
            <Route
                exact path={props.match.path + "/BufferPerformance_20210930"}
                render={(props) => {
                    return (
                        <BlogPostWrapper>
                            <Blog_20210930_BufferPerformance/>
                        </BlogPostWrapper>
                    )
                }}
            />
            <Route
                exact path={props.match.path + "/BuyWriteDiversification_20210803"}
                render={(props) => {
                    return (
                        <BlogPostWrapper>
                            <Blog_20210803_BuyWriteDiversification/>
                        </BlogPostWrapper>
                    )
                }}
            />
            <Route
                exact path={props.match.path + "/BufferBondReplacement_20210624"}
                render={(props) => {
                    return (
                        <BlogPostWrapper>
                            <Blog_20210624_BufferBondReplacement/>
                        </BlogPostWrapper>
                    )
                }}
            />
            <Route
                exact path={props.match.path + "/Blog_20210525_BufferDelta"}
                render={(props) => {
                    return (
                        <BlogPostWrapper>
                            <Blog_20210525_BufferDelta/>
                        </BlogPostWrapper>
                    )
                }}
            />
            <Route
                exact path={props.match.path + "/TLT_Overwriting_20210413"}
                render={(props) => {
                    return (
                        <BlogPostWrapper>
                            <TLTOverwriting_202104/>
                        </BlogPostWrapper>
                    )
                }}
            />
            <Route
                exact path={props.match.path + "/AYearSincePreCovid_20210216"}
                render={(props) => {
                    return (
                        <BlogPostWrapper>
                            <AYearSincePreCovid_20210216/>
                        </BlogPostWrapper>
                    )
                }}
            />
            <Route
                exact path={props.match.path + "/SPYPuts_20201014"}
                render={(props) => {
                    return (
                        <BlogPostWrapper>
                            <SPYPuts_202014/>
                        </BlogPostWrapper>
                    )
                }}
            />
            <Route
                exact path={props.match.path + "/QQQCollar_20201023"}
                render={(props) => {
                    return (
                        <BlogPostWrapper>
                            <QQQCollar_20201023/>
                        </BlogPostWrapper>)
                }}
            />
            <Route
                exact path={props.match.path + "/Income_20201209"}
                render={(props) => {
                    return (
                        <BlogPostWrapper>
                            <Income_20201209/>
                        </BlogPostWrapper>)
                }}
            />
            <Route
                exact path={props.match.path + "/"}
                render={(props) => {
                    return (<BlogMainPage {...props} isLoggedIn={isLoggedIn}/>)
                }}
            />
            <Route
                // path={props.match.path + "/"}
                render={(props) => {
                    return <BlogPostWrapper>
                        <BlogPageNotFound {...props}/>
                    </BlogPostWrapper>
                }}
            />
            {/*<Route*/}
            {/*    path={[props.match.path + "/e:emailId/:postId", props.match.path + "/e/:postId"]}*/}
            {/*    render={(props) => {*/}
            {/*        let emailId = ''*/}
            {/*        if (props.match.params.emailId != null) {*/}
            {/*            emailId = props.match.params.emailId*/}
            {/*        }*/}

            {/*        const redirectTo = props.location.pathname.replace("e" + emailId + "/", "")*/}

            {/*        if (!tracked) {*/}
            {/*            mixpanel.track('Routing Blog Email Link', {pathname: redirectTo, emailId: emailId})*/}
            {/*            setTracked(true)*/}
            {/*        }*/}

            {/*        return (*/}
            {/*            // /!*<Redirect to={redirectTo}/>*!/*/}
            {/*            <Blog postId={props.match.params.postId} {...props} isLoggedIn={isLoggedIn}/>*/}
            {/*        )*/}
            {/*    }}*/}
            {/*/>*/}
        </Switch>
    )
}