import React, {Component} from 'react';
import {Link} from 'react-router-dom'

import NavBar from 'components/common/NavBar'
import Footer from 'components/common/Footer'
import SignInModal from 'components/common/SignInModal'

// import 'lkcss'
// import lk from 'dk/src/assets/css/theme.module.css'
// import {mapClasses} from 'util/cssModule'


export default function TermsOfUseSection(props) {
    return (
        <section className="">
            <div className="container" style={{maxWidth: '760px'}}>
                <div className="row align-items-center">
                    <div className="col-12 col-md">
                        <h1 className="display-4 mb-2">
                            Terms of Use
                        </h1>
                        <p className="font-size-lg text-gray-700 mb-md-0">
                            Last Revised: 2024-06-21
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">

                        <hr className="my-6 my-md-8"/>

                    </div>
                </div>
                <div className="row">
                    <div className="col-12">

                        <p className="text-gray-800">
            <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4 font-weight-bold font-size-large">
                1.
            </span>
                            {/*<span className="font-weight-bold text-uppercase" >Introduction</span>*/}
                            <span className="font-weight-bold text-uppercase text-uppercase">Introduction</span>
                        </p>

                        <p className="text-gray-800">
                            CLARIVOR LLC (“Clarivor”) provides investment advisory and investment management services
                            over the internet, software services and an informational website accessible at clarivor.com
                            (collectively, “the Services”). THIS TERMS OF USE AGREEMENT (“Agreement”) sets forth the
                            terms and conditions by which you are permitted to access the Services and accompanying
                            documentation (“Documentation”) that is provided to you hereunder by “Clarivor”.
                        </p>
                        <p>
                            If you choose to become a become a “Client,” the terms of such services will be further
                            subject to a required Client Agreement, which you will need to agree to when creating a
                            Client account. In cases of inconsistency between the Client Agreement and this Agreement,
                            the terms of the Client Agreement shall apply. Only Clients can have access to Clarivor’s
                            investment advisory and investment management services (“Client Services”).
                        </p>
                        <p>
                            Your use of the Services is also subject to Clarivor’s privacy policy available at <Link
                            to='/privacy-policy'>https://www.clarivor.com/privacy-policy.</Link>
                        </p><p>
                        For purposes of this Agreement, “you” and “your” means you as the user of the Services. If you
                        use the Services on behalf of a company or other entity then “you” includes you and that entity,
                        and you represent and warrant that (a) you are an authorized representative of the entity with
                        the authority to bind the entity to these Terms, and (b) you agree to these Terms on the
                        entity's behalf. BY CLICKING ON THE APPLICABLE “I AGREE” BUTTON DURING SIGNUP, OR BY ACCESSING
                        OR USING THE SERVICES, YOU AGREE THAT THESE TERMS AND CONDITIONS APPLY TO YOU. IF YOU DO NOT
                        AGREE TO THESE TERMS, YOU ARE NOT LICENSED OR PERMITTED TO ACCESS OR USE THE SERVICES.
                    </p>


                        <p className="text-gray-800">
                            <span
                                className="badge badge-rounded-circle badge-success-soft mt-1 mr-4 font-weight-bold text-uppercase font-size-large">
                                2.
                            </span>
                            <span className="font-weight-bold text-uppercase"
                            >Use of Services
                            </span>
                        </p>

                        <div className={'pl-6'}>

                            <p className="text-gray-800">
                                <span
                                    className="badge badge-rounded-circle badge-success-soft mt-1 mr-4 font-weight-bold text-uppercase font-size-large">
                                    2.1
                                </span>
                                <span className="font-weight-bold text-uppercase"
                                >Provision of Access.  </span>
                                Subject to the terms of this Agreement, as well as the terms of the applicable Client
                                Agreement required for Client Services, Clarivor grants you a nonexclusive,
                                nontransferable right to access the Services solely for your personal or internal
                                business use, or as permitted under a separate written agreement. All rights not
                                specifically granted to you herein are retained by Clarivor. Clarivor may deny access,
                                in its sole discretion, to any of the Services for any reason.
                            </p>
                            <p className="text-gray-800">
                                <span
                                    className="badge badge-rounded-circle badge-success-soft mt-1 mr-4 font-weight-bold text-uppercase font-size-large">
                                    2.2
                                </span>
                                <span className="font-weight-bold text-uppercase"
                                >Certain Restrictions.  </span>
                                Except as otherwise specifically permitted under Section 2.1, you shall not use, copy,
                                modify, create derivative works of, distribute, sell, assign, sublicense, lease, loan,
                                rent, provide access to, or transfer to a third party the Services, or any information
                                produced by the Services, nor permit any third party to do any of the foregoing. You may
                                not (i) derive or attempt to derive the source code of all or any portion of the
                                Services that is provided to you in object code form, (ii) permit any third party to
                                derive or attempt to derive such source code, (iii) reverse engineer, decompile,
                                disassemble, or translate the Services or any part thereof, (iv) access any components
                                of the Services accept through interfaces deliberately provided by Clarivor. You also
                                agree not to remove, obscure, or alter any proprietary rights notice affixed to, or
                                contained within, the Services or Documentation. You shall: (a) have sole responsibility
                                for the accuracy, legality, appropriateness and other aspects of all data and
                                information you input to or store via the Services; (b) prevent unauthorized access to,
                                or use of, the Services, and notify Clarivor promptly of any such unauthorized use; and
                                (c) comply with all applicable laws in using the Services. </p>
                            <p className="text-gray-800">
                                <span
                                    className="badge badge-rounded-circle badge-success-soft mt-1 mr-4 font-weight-bold text-uppercase font-size-large">
                                    2.3
                                </span>
                                <span className="font-weight-bold text-uppercase"
                                >Ownership and Feedback.  </span>
                                Clarivor and its licensors own and shall retain all intellectual property rights and
                                other rights in and to the Services, the Documentation, and any changes, modifications
                                or corrections to the foregoing. You may report or otherwise disclose orally or in
                                writing errors, problems, defects, or suggestions for changes and improvements to the
                                Services (collectively, “Feedback”) to Clarivor. You hereby assign to Clarivor all
                                right, title and interest, including all related intellectual property rights, in and to
                                all such Feedback.
                            </p>
                            <p className="text-gray-800">
                                <span
                                    className="badge badge-rounded-circle badge-success-soft mt-1 mr-4 font-weight-bold text-uppercase font-size-large">
                                    2.4
                                </span>
                                <span className="font-weight-bold text-uppercase"
                                >Term and Termination.  </span>
                                Either party may terminate this Agreement with immediate effect, for any reason or no
                                reason, upon written notice to the other party. Upon termination of this Agreement for
                                any reason, you agree to, cease any use of the Services and destroy any Documentation.
                                All rights to use the Services shall terminate upon any termination or expiration of
                                this Agreement. The following provisions will survive the expiration or earlier
                                termination of this Agreement: Sections 2.2 through 10.
                            </p>
                        </div>
                        {/* end indent */}
                        <p className="text-gray-800">
                                <span
                                    className="badge badge-rounded-circle badge-success-soft mt-1 mr-4 font-weight-bold text-uppercase font-size-large">
                                    3.
                                </span>
                            <span className="font-weight-bold text-uppercase"
                            >No Warranty.  </span>
                            <span className={'text-uppercase'}>
                                The Services, Documentation, and any and all other material provided by Clarivor to you under this Agreement are provided to you “AS IS” without warranty of any kind. Clarivor and its licensors and suppliers hereby disclaim any and all warranties, express, implied, or statutory with respect to the Services, including but not limited to any implied warranties of merchantability, non-infringement, title or fitness for a particular use or purpose. Neither Clarivor nor its licensors or suppliers warrant that the Services will function without interruption or that it is error-free.  To the extent permitted by law you bear the entire risk as to the operation of the Services.  If you are a Client,
                                nothing in this disclaimer of warranty shall affect any rights you may have under common law or Federal and State securities laws.
                            </span>
                        </p>
                        <p className="text-gray-800">
                                <span
                                    className="badge badge-rounded-circle badge-success-soft mt-1 mr-4 font-weight-bold text-uppercase font-size-large">
                                    4.
                                </span>
                            <span className="font-weight-bold text-uppercase"
                            >Limitation of Liability.  </span>
                            <span className={'text-uppercase'}>
                                In no event will Clarivor or its licensors and suppliers be liable for any damages, whether
                                in contract or tort (including negligence), including but not limited to direct, indirect,
                                consequential, incidental, punitive, special or exemplary damages or for loss of profits or
                                revenues or loss of data, arising out of or in connection with this Agreement, or your use
                                of, or the results obtained from, the Services, Documentation, or any other material
                                provided by Clarivor to you under this Agreement.  If you are a Client, nothing in this
                                limitation of liability shall affect any rights you may have under common law or Federal and State securities laws.
                                You acknowledge and agree that the
                                disclaimer of warranties, limitations on liability and limited remedies contained in this
                                Agreement are fundamental parts of the basis of Clarivor’s bargain hereunder, and Clarivor
                                would not provide the Services to you absent such limitations.
                            </span>
                        </p>
                        <p className="text-gray-800">
                                <span
                                    className="badge badge-rounded-circle badge-success-soft mt-1 mr-4 font-weight-bold text-uppercase font-size-large">
                                    5.
                                </span>
                            <span className="font-weight-bold text-uppercase"
                            >Indemnification.  </span>
                            You agree to hold Clarivor, its members, employees, contractors, and agents harmless from
                            all damages, costs, or losses (collectively, “Losses”) arising directly or indirectly out of
                            your use of the Services; provided, however Clarivor shall not be indemnified for any Losses
                            to the extent such Losses are caused by Clarivor’s own gross negligence, bad faith or
                            willful malfeasance.
                        </p>
                        <p className="text-gray-800">
                                <span
                                    className="badge badge-rounded-circle badge-success-soft mt-1 mr-4 font-weight-bold text-uppercase font-size-large">
                                    6.
                                </span>
                            <span className="font-weight-bold text-uppercase"
                            >Informational Content Not Investment Advice.  </span>
                            For services made available to users who have not agreed to a Client Agreement, you
                            acknowledge and agree that (i) services are provided for informational purposes only, (ii)
                            Clarivor is not serving as an investment advisor or fiduciary or making any recommendations
                            or soliciting any action based on the analyses provided by Clarivor hereunder, and (iii) you
                            will be solely responsible for any judgments as to any securities and other related
                            financial instruments in which you transact. Accordingly, Clarivor will not be responsible
                            nor have any liability for any conclusions or decisions made by you with respect to any
                            matter, whether or not based to any extent on the information provided through the Services.
                            Further, if you are party to a Client Agreement, you acknowledge that Clarivor’s software
                            allows analysis and management of investment strategies that may be inconsistent with
                            Clarivor’s investment recommendations, and your use of such functionality is subject to this
                            Section 6.
                        </p>
                        <p className="text-gray-800">
                                <span
                                    className="badge badge-rounded-circle badge-success-soft mt-1 mr-4 font-weight-bold text-uppercase font-size-large">
                                    7.
                                </span>
                            <span className="font-weight-bold text-uppercase"
                            >Access to Hypothetical Performance Information.  </span>
                            Clarivor occasionally uses hypothetical performance on its website to illustrate the
                            potential benefits and risks of various investment strategies. The results presented may not
                            have been achieved by any portfolio that Clarivor manages. This includes, but is not limited
                            to, model performance, back-tested performance, and targeted or projected returns.
                            Access to hypthetical performance is
                            restricted to professional investors and investment advisors with the resources and
                            expertise necessary to independently assess the information
                            and its inherent risks. Visitors are required to attest to meeting these criteria prior to
                            accessing hypothetical performance information. Unauthorized access or non-compliance with
                            these conditions is a
                            breach of these Terms of Use.
                        </p>
                        <p className="text-gray-800">
                                <span
                                    className="badge badge-rounded-circle badge-success-soft mt-1 mr-4 font-weight-bold text-uppercase font-size-large">
                                    8.
                                </span>
                            <span className="font-weight-bold text-uppercase"
                            >Confidential Information.  </span>
                            You hereby acknowledge and agree that any information you receive from Clarivor related to
                            your use or evaluation of the Services, including Documentation and any other information
                            regarding the operation or use of the Services, that a reasonable user would consider
                            confidential, is the proprietary and confidential information of Clarivor (collectively,
                            “Confidential Information”). You hereby agree not to use the Confidential Information except
                            as authorized by this Agreement and further agree to hold the Confidential Information in
                            strict confidence and protect such Confidential Information from disclosure using the same
                            care you use to protect your own confidential information of like importance, but not less
                            than reasonable care. If Confidential Information is required to be disclosed by law or
                            governmental authority, including pursuant to a subpoena or court order, such Confidential
                            Information may be disclosed, provided that you: (i) promptly notify Clarivor of the
                            disclosure requirement; (ii) cooperate with Clarivor’s reasonable efforts to resist or
                            narrow the scope of disclosure and to obtain an order or other reliable assurance that
                            confidential treatment will be accorded the Confidential Information; and (iii) furnish only
                            such Confidential Information as you are legally compelled to disclose according to advice
                            of your legal counsel. Upon written request, or at the expiration or termination of this
                            Agreement, you will return or destroy all Confidential Information (and all copies thereof),
                            with written certification thereof.
                        </p>
                        <p className="text-gray-800">
                                <span
                                    className="badge badge-rounded-circle badge-success-soft mt-1 mr-4 font-weight-bold text-uppercase font-size-large">
                                    9.
                                </span>
                            <span className="font-weight-bold text-uppercase"
                            >Third Party Websites and Links.  </span>
                            The Services may contain links to other sites operated by third parties. Clarivor does not
                            control such other sites and is not responsible for their content or their privacy policies.
                            Clarivor’s inclusion of such links does not, by itself, imply any endorsement of the content
                            on such sites or of their owners or operators except as disclosed on the Services. Clarivor
                            expressly disclaims any and all liability for the actions of third parties. You use all
                            third-party websites at your own risk. </p>
                        <p className="text-gray-800">
                                <span
                                    className="badge badge-rounded-circle badge-success-soft mt-1 mr-4 font-weight-bold text-uppercase font-size-large">
                                    10.
                                </span>
                            <span className="font-weight-bold text-uppercase"
                            >Updating This Agreement  </span>
                            Clarivor may modify this Agreement from time to time in which case Clarivor will update the
                            “Last Revised” date at the top of this Agreement. If Clarivor make changes that are
                            material, Clarivor will use reasonable efforts to attempt to provide notice to you and,
                            where required by applicable law, Clarivor will obtain your consent. Notice may be by email
                            to you at the last email address you provided us, by posting notice of such changes on the
                            Services, or by other means, consistent with applicable law. However, it is your sole
                            responsibility to review this Agreement from time to time to view any such changes. The
                            updated Agreement will be effective as of the time of posting, or such later date as may be
                            specified in the updated Privacy Policy. IF YOU DO NOT ACCEPT AND AGREE TO THESE TERMS AND
                            CONDITIONS, INCLUDING ANY CHANGES THERETO, THEN YOU MUST NOT ACCESS OR USE THE SERVICES.
                        </p>
                        <p className="text-gray-800">
                                <span
                                    className="badge badge-rounded-circle badge-success-soft mt-1 mr-4 font-weight-bold text-uppercase font-size-large">
                                    11.
                                </span>
                            <span className="font-weight-bold text-uppercase"
                            >General.  </span>
                            This Agreement will be governed by and construed in accordance with the laws of the State of
                            Delaware, regardless of its conflicts of laws principles. Neither this Agreement nor any
                            rights or obligations under this Agreement may be assigned or delegated by you, by operation
                            of law or otherwise, without the prior written consent of Clarivor. Any attempted or
                            purported assignment or delegation by you in violation of the previous sentence will be null
                            and void. You will not export, directly or indirectly, the Services or any technical data of
                            Clarivor to any country for which the U.S. Government requires an export license or other
                            governmental approval without first obtaining such license or approval. If any provision of
                            this Agreement will be held invalid or unenforceable by a court, the remaining provisions of
                            this Agreement will remain in full force and effect, and the provision or portion thereof
                            affected will be construed so as to be enforceable to the maximum extent permissible by law.
                            This Agreement, together with any Client Agreement and Clarivor’s privacy policy available
                            at <Link to='/privacy-policy'> https://www.clarivor.com/privacy-policy.</Link>, is the
                            complete and exclusive agreement of the parties with
                            respect to the subject matter hereof and supersedes and merges all prior discussions between
                            them.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}


