import React, {useContext, useEffect, useState} from "react"
import headshot from 'images/blog/avatar-100.jpg'
import RequestDemoModal from "../../../pages/common/RequestDemoModal"
import {globalAuthManager, globalServices} from "../../../../services/GlobalServices"
import {apiGet} from "../../../../services/api-call-helpers"
import * as keys from 'keys'
// import {mixpanel} from "../../../../services/mixpanel"
import ReceiveUpdatesModal from "../../../pages/common/ReceiveUpdatesModal"
import {Link} from "react-router-dom"
import BlogSignupSection from "../../common/BlogSignupSection"

import BannerImage from 'images/blog/Blog_20220615_OutlierReturn/banner.jpg'
import BannerImage500 from 'images/blog/Blog_20220615_OutlierReturn/banner-500.jpg'

import plot from 'images/blog/Blog_20220615_OutlierReturn/2022Correlation.png'

import prosAndCons from 'images/blog/Blog_20220120_TLTHedges/ProsAndCons.png'

import jonAvatar from 'images/blog/avatar-100.jpg'

const ProjectContext = React.createContext({})

const documentTitle = "Clarivor Blog"
const postTitle = 'Outlier Asset Returns'
const postSubtitle = "Equity-bond price action on June 13th was unlike anything we've seen in decades."
const postAuthor = 'Jon Spiegel, CFA'
const publishedOnText = 'Jun 15, 2022'
const publishedOnShort = 'Jun 15'
export const path = 'outlier-2022-jun-13'

const HeroSection = (props) => (
    <section
        style={{
            backgroundImage: ["url(" + BannerImage + ")"],
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            position: 'relative',
            zIndex: 0,
            backgroundSize: 'cover',
            height: '35vh'
        }}>
    </section>
)

const HeaderSection = (props) => (
    <section className="pt-8 pt-md-11">
        <RequestDemoModal/>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 col-md-12 col-lg-10 col-xl-10">


                    <h1 className="display-4 text-center">
                        {props.title}
                    </h1>


                    {/*<p className="lead mb-7 text-center text-muted">*/}
                    <p className="lead px-10 mb-7 text-center text-muted">
                        {props.subtitle}
                    </p>


                    <div className="row align-items-center py-5 border-top border-bottom">
                        <div className="col-auto">


                            <div className="avatar avatar-lg">
                                <img src={props.avatarImage} alt="..."
                                     className="avatar-img rounded-circle"/>
                            </div>

                        </div>
                        <div className="col ml-n5">
                            <h6 className="text-uppercase mb-0">
                                {props.author}
                            </h6>
                        </div>
                        <div className="col-auto">
                            <time className="font-size-sm text-muted" dateTime="2019-05-20">
                                {props.publishedOnText}
                            </time>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
)


function Summary(props) {
    return (
        <React.Fragment>
            <h2 className={'font-weight-bold mt-6'}>
                Summary
            </h2>
            <ul>
                <li className='my-2'>
                    With the potential for lower returns from both equities and bonds, asset allocators may seek
                    alternative sources of returns.
                </li>
                <li className='my-2'>
                    Selling equity put options can provide a higher return than bond strategies, with reduced risk
                    compared to owning stocks directly.
                </li>
                <li className='my-2'>
                    Prudently incorporating short equity puts into portfolios could enhance returns with comparable
                    downside risk in typical market scenarios.
                </li>
            </ul>
        </React.Fragment>
    )
}


function Section1(props) {
    const putRef = React.createRef()
    const psRef = React.createRef()
    const collarRef = React.createRef()
    const pscRef = React.createRef()


    return (
        <React.Fragment>
            <h3 className={'font-weight-bold mt-6'}>
                {/*Overview*/}
            </h3>
            <p>
                The market's move on Monday, June 13th was even more extreme than you might realize.
            </p>

            <p>
                The +0.28% change in the 10-year Treasury rate was only part of the story.
            </p>

            <p>
                Yes, that represents the 29th largest one-day rate jump since 1978.
            </p>

            <p>
                However, simultaneously with rates higher, the S&P 500 fell 3.9%.
            </p>

            <p>
                The last time the S&P 500 fell that much on a large rate jump (>0.20%) was September 11, 1986.
            </p>

            <p>
                The weakest days for equities don't typically occur when rates are going higher, potentially because
                higher
                rates usually coincide with strong demand and growth.
            </p>

            <p>
                But in 2022, if inflation and higher rates are caused more by supply constraints than overheated demand,
                we
                could see more anomalous, strongly correlated, large moves.
            </p>

            <div className='card mx-auto my-6' style={{maxWidth: '90%'}}>
                <div className='card-body p-2  bg-white'>
                    {/*<h3 className='text-center'>*/}
                    {/*    Short Put Payoff Illustration*/}
                    {/*</h3>*/}
                    <img src={plot} className='img-fluid'/>
                </div>
            </div>

        </React.Fragment>
    )
}

function BufferPerformanceProjectContainer(props) {
    const [projectObj, setProjectObj] = useState(null)

    useEffect(() => {
        apiGet(keys.API_ENDPOINT.PROJECTS, {id: props.projectId}, globalAuthManager).then(
            res => {
                const [project] = res.data
                setProjectObj(project)
            }
        )
    }, [])

    const contextValue = {
        projectObj: projectObj,
        projectId: props.projectId
    }

    return (
        <ProjectContext.Provider value={contextValue}>
            {props.children}
        </ProjectContext.Provider>
    )
}


function DisclosuresLink(props) {
    return (
        <a data-toggle="modal" href="#disclaimerModal"
           onClick={() => null}>Disclosures</a>
// onClick={() => mixpanel.track('Disclosure Clicked')}>Disclosures</a>
    )
}

function TermsOfUseLink(props) {
    return (
        <Link to={'/terms-of-use'} target={'_blank'}
              onClick={() => null}>Terms of Use</Link>
// onClick={() => mixpanel.track('Terms of Use Clicked')}>Terms of Use</Link>
    )
}

function BacktestingSourceDisclaimer(props) {
    return (
        <div>
            <hr/>
            <p className='font-italic text-muted'>
                {/*Source: Clarivor LLC. Strikes and maturities are estimated from listed strikes and tenors for easier*/}
                {/*comparison.*/}

                {/*All buffers above refer to a SPY 12-month buffer traded on 12/18/2021, providing 10% downside*/}
                {/*protection, collateralized with cash.*/}
                {/*Options can entail complex risks and may not be suitable for all investors.<br/>*/}
                {/*Each option strategy back-test above includes an annual deduction of 0.49% to reflect management fees*/}
                {/*and commissions. SPY (TR) and AGG (TR) have dividends immediately reinvested, shown with no additional*/}
                {/*management fee.*/}
                {/*Strikes are selected from the listed market.*/}
                {/*Options are rolled seven days prior to expiration at bid or offer.*/}
                {/*Option prices are observed at 3:45pm NY time.*/}
                <br/>
                <br/>
                NOTHING IN THIS POST SHOULD BE CONSTRUED AS INVESTMENT ADVICE. <br/>
                <DisclosuresLink/> and <TermsOfUseLink/> apply.
                <br/>
            </p>
        </div>
    )
}


function PostBody(props) {
    return (
        <React.Fragment>
            <section className="pt-0 pt-md-0">
                <ReceiveUpdatesModal/>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-12 col-lg-10 col-xl-9">
                            {/*<Summary/>*/}
                            <Section1/>
                            <BacktestingSourceDisclaimer/>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}


export default function Outlier2022Jun13(props) {
    const projectId = null  // this is a static blog post

    useEffect(() => {
        document.title = documentTitle
    }, [])

    const headerProps = {
        title: postTitle,
        subtitle: postSubtitle,
        avatarImage: headshot,
        author: postAuthor,
        publishedOnText: publishedOnText,
    }

    return (
        <React.Fragment>
            <HeroSection/>
            <HeaderSection {...headerProps} />
            <BufferPerformanceProjectContainer projectId={projectId}>
                <PostBody/>
            </BufferPerformanceProjectContainer>
            <BlogSignupSection fromLocation={'Blog Post TLT Overwriting'}/>
        </React.Fragment>
    )
}


export function RichJun13Outlier(props) {
    return (
        <section className="pt-7 pt-md-10">
            <div className="container">
                <div className="row">
                    <div className="col-12">

                        <div className="card card-row shadow-light-lg mb-6 lift lift-lg">
                            <div className="row no-gutters">
                                <div className="col-12">

                            <span className="badge badge-pill badge-light badge-float badge-float-inside">
                            <span className="h6 text-uppercase">Diversification</span>
                            </span>
                                </div>
                                <Link className="col-12 col-md-6 order-md-2 bg-cover card-img-right"
                                      style={{backgroundImage: "url(" + BannerImage500 + ")"}}
                                      to={props.blogPath + path}>

                                    <img src="assets/img/photos/photo-27.jpg" alt="..."
                                         className="img-fluid d-md-none invisible"/>

                                    <div
                                        className="shape shape-left shape-fluid-y svg-shim text-white d-none d-md-block">
                                        <svg viewBox="0 0 112 690" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 0h62.759v172C38.62 384 112 517 112 517v173H0V0z"
                                                  fill="currentColor"></path>
                                        </svg>
                                    </div>

                                </Link>
                                <div className="col-12 col-md-6 order-md-1">

                                    <Link className="card-body" to={props.blogPath + path}>

                                        <h3>
                                            {postTitle}
                                        </h3>

                                        <p className="mb-0 text-muted">
                                            {postSubtitle}
                                        </p>

                                    </Link>

                                    <a className="card-meta" href="#!">

                                        <hr className="card-meta-divider"/>

                                        <div className="avatar avatar-sm mr-2">
                                            <img src={jonAvatar} alt="..."
                                                 className="avatar-img rounded-circle"/>
                                        </div>

                                        <h6 className="text-uppercase text-muted mr-2 mb-0">
                                            Jon Spiegel
                                        </h6>

                                        <p className="h6 text-uppercase text-muted mb-0 ml-auto">
                                            <time dateTime="2020-10-23">{publishedOnShort}</time>
                                        </p>

                                    </a>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>

    )
}
