import React, {useState, useEffect, useContext} from "react"
import ReactBootstrapSlider from 'react-bootstrap-slider'

export default function BidAskSlider(props) {
    const setTargetSpreadCallback = props.setTargetSpreadCallback
    const marketMidAskPercentOfSpot = props.marketBidAskPercentOfSpot / 2
    const annualizedSpread = props.annualizedBidAskPercentOfSpot / 2
    const rowKey = props.rowKey
    const initialTargetSpread = props.initialTargetSpread

    let nChoices = 50
    let increment = marketMidAskPercentOfSpot / nChoices
    let precision = 2
    if (marketMidAskPercentOfSpot <= .0001) {
        precision = 4
    } else if (marketMidAskPercentOfSpot <= .001) {
        increment = .00001
        precision = 2
    }
    //   const nChoices = Math.round(Math.abs(.5 * marketBidAskPercentOfSpot / increment))

    const [indexSelected, setIndexSelected] = useState(Math.round(nChoices * initialTargetSpread))

    let spreadChoices = [], spreadChoiceStrings = []
    if (nChoices && nChoices > 0) {
        spreadChoices = [...Array(nChoices).keys()].map(i => i * increment)
        spreadChoiceStrings = spreadChoices.map(val => isNaN(val) || !isFinite(val) ? "" : (val * 100).toFixed(precision) + "%")
    }

    function htmlWrapSpreadString(midAskSpread) {
        let style = "text-primary"
        if (annualizedSpread > .015) {
            style = "text-danger"
        } else if (annualizedSpread > .01) {
            style = "text-warning"
        }

        return `<span class=${style}>${midAskSpread}</span>`
    }

    const maxSpreadString = htmlWrapSpreadString(spreadChoiceStrings[nChoices - 1])

    return <div style={{maxWidth: '150px'}} className='mx-auto'>
        <ReactBootstrapSlider
            key={rowKey}
            value={indexSelected}
            slideStop={(event) => {
                setTargetSpreadCallback(event.target.value / spreadChoices.length)
                setIndexSelected(event.target.value)
            }}
            step={1}
            // max={ticks[ticks.length - 1]}   overwridden by ticks.
            // min={ticks[0]}
            ticks={[0, nChoices - 1]}
            ticks_labels={["Mid", maxSpreadString]}
            formatter={(i) => spreadChoiceStrings[i]}
            // change={() => null}
            // disabled="disabled"/>
            // orientation="vertical"
            // reversed={true}
        />
    </div>
}

