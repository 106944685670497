import React, {useContext} from "react"
import {Link} from 'react-router-dom'
import {AccountManagementContext, AppContext} from "../../contexts"
import * as keys from 'keys'
import {apiPost} from "../../../services/api-call-helpers"
import {globalAuthManager} from "../../../services/GlobalServices"

function CreateAccountForm(props) {
    const signupContext = useContext(AccountManagementContext)
    const appContext = useContext(AppContext)
    const [creating, setCreating] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState(null)

    const handleTextChange = (event) => {
        let kvp = {}
        kvp[event.target.name] = event.target.value
        signupContext.setSignupKVPair(kvp)
    }

    const handleClick = async (event) => {
        event.preventDefault()

        setCreating(true)
        const params = {
            email: signupContext.signupState.email,
            password: signupContext.signupState.password,
            product: signupContext.product,
        }

        let userCreated = false
        try {
            await apiPost(keys.API_ENDPOINT.CREATE_USER, params, globalAuthManager)
            userCreated = true
        } catch (error) {
            if (error.response == null) {
                setErrorMessage('Could not sign up.  Connectivity issue.')
            } else if (error.response.data.message) {
                setErrorMessage(error.response.data.message)
            } else {
                setErrorMessage(`Could not sign up.  Error code: ${error.response.status}.`)
            }
        }

        if (userCreated) {
            try {
                const signedIn = await globalAuthManager.loginAsync(params.email, params.password)

                if (signedIn) {
                    appContext.setLoggedInState(true)
                }
                signupContext.newAccountSignedIn()
            } catch (error) {
                setErrorMessage('Error signing into new account')
            }
        }

        setCreating(false)
    }

    return (
        <div className={'card'}>
            <div className={'card-body text-left'}>
                <form>
                    <div className="row justify-content-center">
                        {
                            errorMessage ?
                                <span className='text-danger my-3'>{errorMessage}</span> :
                                null
                        }
                    </div>
                    <div className="form-label-group">
                        <input type="email" className="form-control form-control-flush" name="email"
                               placeholder="Work Email" onChange={handleTextChange}/>
                        <label>Work Email</label>
                    </div>
                    <div className="form-label-group">
                        <input type="password" className="form-control form-control-flush" name="password"
                               placeholder="New Password" onChange={handleTextChange}/>
                        <label>New Password</label>
                    </div>
                    <div className="mt-6">
                        {
                            creating ?
                                <button className="btn btn-block btn-success lift disabled" type="submit"
                                        onClick={() => null}>
                                    <span className="spinner-border spinner-border-sm" role="status"
                                          aria-hidden="true"></span>
                                </button>
                                :
                                <button className="btn btn-block btn-success lift" type="submit"
                                        onClick={handleClick}>
                                    Submit
                                </button>
                        }
                    </div>
                </form>
            </div>
        </div>
    )
}


function PleaseSignOut(props) {
    return (
        <React.Fragment>
            <h3 className="font-weight-bold text-dark">
                Please sign out before creating a new account.
            </h3>
            Or <Link to={'/account/user-info'}>edit</Link> account information.
        </React.Fragment>
    )
}

function SignupBody(props) {
    return (
        <React.Fragment>
            <h2 className="font-weight-bold text-dark">
                Welcome to Clarivor.
            </h2>

            <p className="font-size-lg text-primary mb-7 mb-md-9 text-muted">
                Please provide your work email address and password to use on Clarivor's website.
            </p>

            <CreateAccountForm setSignupState={props.setSignupState}/>
        </React.Fragment>
    )
}

export default function CreateAccountPage(props) {
    const appContext = useContext(AppContext)
    const loggedIn = appContext.isLoggedIn

    React.useEffect( () => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <section className="pt-8 pt-md-11 pb-8 pb-md-14 bg-light" style={{minHeight: '70vh'}}>
            <div className="container">
                <div className='row justify-content-center my-5'>
                    <div className='col-12 col-md-8 text-center'>

                        {
                            loggedIn === true ? <PleaseSignOut/>
                                : loggedIn === null ? null : <SignupBody setSignupState={props.setSignupState}/>
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}