import {callPrice, putPrice} from 'util/analytics/blackscholes'
import {formatDate} from "./dates"

export function calcSPXForward(spot, T) {
    return spot * Math.pow(1.05 - .013, T)
}

export function nameInstrument(instrumentDict) {
    let name = null

    switch (instrumentDict.class_name) {
        case 'Stock':
        case 'MutualFund':
        case 'Bond':
        case 'OtherSecurity':
            name = instrumentDict.ticker
            break;
        case 'StockOption':
            name = instrumentDict.ticker + ' ' + instrumentDict.expiration + ' ' + instrumentDict.strike
            if (instrumentDict.type.toUpperCase() === 'C') {
                name += ' Call'
            } else if (instrumentDict.type.toUpperCase() === 'P') {
                name += ' Put'
            } else {
                // error!
            }

            break;
        case 'CashUnit':
            name = 'USD Cash'
    }

    return name
}

export function instantiateInstrument(instrumentDict) {
    if (instrumentDict.class_name === 'Stock') {
        return new Stock(instrumentDict)
    } else if (instrumentDict.class_name === 'StockOption') {
        return new StockOption(instrumentDict)
    } else if (instrumentDict.class_name === 'MutualFund') {
        return new MutualFund(instrumentDict)
    } else if (instrumentDict.class_name === 'Bond') {
        return new Bond(instrumentDict)
    } else if (instrumentDict.class_name === 'OtherSecurity') {
        return new OtherSecurity(instrumentDict)
    } else if (instrumentDict.class_name === 'CashUnit') {
        return new CashUnit(instrumentDict)
    }

    return null
}


export class Instrument {
    constructor(dict) {
        this.dict = dict

        this.multiplier = 1
    }

    getMultiplier() {
        return this.multiplier
    }

    getName() {
        let name = null

        switch (this.dict.class_name) {
            case 'Stock':
                name = this.dict.ticker
                break
            case 'StockOption':
                name = this.dict.ticker + ' ' + this.dict.expiration + ' ' + this.dict.strike
                if (this.dict.type.toUpperCase() == 'C') {
                    name += ' Call'
                } else if (this.dict.type.toUpperCase() == 'P') {
                    name += ' Put'
                } else {
                    // error!
                }
                break
            case 'CashUnit':
                name = 'USD Cash'
        }

        return name
    }

    getTicker() {
        return this.ticker
    }

    isSameInstrument(otherInstrument) {
        return null
    }

    isCash() {
        return this.dict.class_name === 'CashUnit'
    }
}


export class StockOption extends Instrument {
    constructor(dict) {
        super(dict)

        const parts = dict.expiration.split("-")

        this.strike = dict.strike
        this.expiration = new Date(parts[0], parts[1] - 1, parts[2])
        this.multiplier = dict.multiplier
        this.ticker = dict.ticker
        this.c_or_p = dict.type.toLowerCase() === 'c' ? 'c' : 'p'
    }

    calcEquityScenarioPL(shift, price) {
        window.alert('not supported equity scenario function')
    }

    calcPriceScenario(marketDataObj, asOfDateObj) {
        const [volObj] = marketDataObj.equity.vols.filter(obj => obj.ticker === this.ticker)
        const [expObj] = volObj.expirations.filter(obj => obj.expiration === formatDate(this.expiration))

        const [strikeObj] = expObj.strikes.filter(obj => obj.strike === this.strike)
        const [spotObj] = marketDataObj.equity.spotPrices.filter(obj => obj.ticker === this.ticker)

        const vol = strikeObj.vol
        const spot = spotObj.spotPrice

        let T = (this.expiration - asOfDateObj) / (365.25 * 24 * 60 * 60 * 1000)
        let df = Math.pow(.95, T)
        let fwd = calcSPXForward(spot, T)

        if (this.c_or_p === 'c') {
            const px = callPrice(fwd, this.strike, T, vol, df)
            return px
        } else {
            return putPrice(fwd, this.strike, T, vol, df)
        }
    }

    isSameInstrument(otherInstrument) {
        if (this.constructor.name !== otherInstrument.constructor.name) {
            return false
        } else if (this.strike !== otherInstrument.strike) {
            return false
        } else if (this.expiration.getDate() !== otherInstrument.expiration.getDate()) {
            return false
        } else if (this.expiration.getMonth() !== otherInstrument.expiration.getMonth()) {
            return false
        } else if (this.expiration.getFullYear() !== otherInstrument.expiration.getFullYear()) {
            return false
        // kluge to fix issue where some multipliers are stored as strings.  may be fixed on backend
        // } else if (this.multiplier !== otherInstrument.multiplier) {
        //     return false
        } else if (this.ticker !== otherInstrument.ticker) {
            return false
        } else if (this.c_or_p !== otherInstrument.c_or_p) {
            return false
        }

        return true
    }
}

export class Stock extends Instrument {
    constructor(dict) {
        super(dict)

        this.ticker = dict.ticker
        this.multiplier = 1
    }

    calcEquityScenarioPL(shift, price) {
        return shift * price
    }

    calcPriceScenario(marketDataObj, asOfDateObj) {
        const [spotObj] = marketDataObj.equity.spotPrices.filter(sp => sp.ticker === this.getTicker())
        return spotObj.spotPrice
    }

    isSameInstrument(otherInstrument) {
        if (this.constructor.name !== otherInstrument.constructor.name) {
            return false
        }
        return (this.ticker === otherInstrument.ticker)
    }
}

export class MutualFund extends Instrument {
    constructor(dict) {
        super(dict)

        this.ticker = dict.ticker
        this.multiplier = 1
    }

    calcEquityScenarioPL(shift, price) {
        return shift * price
    }

    calcPriceScenario(marketDataObj, asOfDateObj) {
        const [spotObj] = marketDataObj.equity.spotPrices.filter(sp => sp.ticker === this.getTicker())
        return spotObj.spotPrice
    }

    isSameInstrument(otherInstrument) {
        if (this.constructor.name !== otherInstrument.constructor.name) {
            return false
        }
        return (this.ticker === otherInstrument.ticker)
    }
}

export class Bond extends Instrument {
    constructor(dict) {
        super(dict)

        this.ticker = dict.ticker
        this.multiplier = 1
    }

    calcEquityScenarioPL(shift, price) {
        return shift * price
    }

    calcPriceScenario(marketDataObj, asOfDateObj) {
        const [spotObj] = marketDataObj.equity.spotPrices.filter(sp => sp.ticker === this.getTicker())
        return spotObj.spotPrice
    }

    isSameInstrument(otherInstrument) {
        if (this.constructor.name !== otherInstrument.constructor.name) {
            return false
        }

        return (this.ticker === otherInstrument.ticker)
    }
}

export class OtherSecurity extends Instrument {
    constructor(dict) {
        super(dict)

        this.ticker = dict.ticker
        this.multiplier = 1
    }

    calcEquityScenarioPL(shift, price) {
        return shift * price
    }

    calcPriceScenario(marketDataObj, asOfDateObj) {
        const [spotObj] = marketDataObj.equity.spotPrices.filter(sp => sp.ticker === this.getTicker())
        return spotObj.spotPrice
    }

    isSameInstrument(otherInstrument) {
        if (this.constructor.name !== otherInstrument.constructor.name) {
            return false
        }
        return (this.ticker === otherInstrument.ticker)
    }
}

export class CashUnit extends Instrument {
    constructor(dict) {
        super(dict)

        this.multiplier = 1
    }

    calcEquityScenarioPL(shift, price) {
        return 0
    }

    calcPriceScenario(marketDataObj, asOfDateObj) {
        return 1
    }

    isSameInstrument(otherInstrument) {
        return this.constructor.name === otherInstrument.constructor.name
    }
}