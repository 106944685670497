import React, {useContext, useEffect, useState} from "react"
import headshot from 'images/blog/avatar-100.jpg'
import RequestDemoModal from "../../../pages/common/RequestDemoModal"
import {globalAuthManager, globalServices} from "../../../../services/GlobalServices"
import {apiGet} from "../../../../services/api-call-helpers"
import * as keys from 'keys'
// import {mixpanel} from "../../../../services/mixpanel"
import ReceiveUpdatesModal from "../../../pages/common/ReceiveUpdatesModal"
import {Link} from "react-router-dom"
import BlogSignupSection from "../../common/BlogSignupSection"

import BannerImage from 'images/blog/Blog_20220421_ShortPuts/banner.jpg'
import BannerImage500 from 'images/blog/Blog_20220421_ShortPuts/banner-500.jpg'

import putPayoffChart from 'images/blog/Blog_20220421_ShortPuts/PutPayoffChart.png'

import prosAndCons from 'images/blog/Blog_20220120_TLTHedges/ProsAndCons.png'

import jonAvatar from 'images/blog/avatar-100.jpg'

const ProjectContext = React.createContext({})

const documentTitle = "Clarivor Blog"
const postTitle = 'Selling Equity Puts'
const postSubtitle = 'An alternative source of return that could enhance portfolios going forward'
const postAuthor = 'Jon Spiegel, CFA'
const publishedOnText = 'April 21, 2022'
const publishedOnShort = 'Apr 21'
export const path = 'selling-equity-puts-20220421'

const HeroSection = (props) => (
    <section
        style={{
            backgroundImage: ["url(" + BannerImage + ")"],
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            position: 'relative',
            zIndex: 0,
            backgroundSize: 'cover',
            height: '35vh'
        }}>
    </section>
)

const HeaderSection = (props) => (
    <section className="pt-8 pt-md-11">
        <RequestDemoModal/>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 col-md-12 col-lg-10 col-xl-10">


                    <h1 className="display-4 text-center">
                        {props.title}
                    </h1>


                    {/*<p className="lead mb-7 text-center text-muted">*/}
                    <p className="lead px-10 mb-7 text-center text-muted">
                        {props.subtitle}
                    </p>


                    <div className="row align-items-center py-5 border-top border-bottom">
                        <div className="col-auto">


                            <div className="avatar avatar-lg">
                                <img src={props.avatarImage} alt="..."
                                     className="avatar-img rounded-circle"/>
                            </div>

                        </div>
                        <div className="col ml-n5">
                            <h6 className="text-uppercase mb-0">
                                {props.author}
                            </h6>
                        </div>
                        <div className="col-auto">
                            <time className="font-size-sm text-muted" dateTime="2019-05-20">
                                {props.publishedOnText}
                            </time>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
)


function Summary(props) {
    return (
        <React.Fragment>
            <h2 className={'font-weight-bold mt-6'}>
                Summary
            </h2>
            <ul>
                <li className='my-2'>
                    With the potential for lower returns from both equities and bonds, asset allocators may seek
                    alternative sources of returns.
                </li>
                <li className='my-2'>
                    Selling equity put options can provide a higher return than bond strategies, with reduced risk
                    compared to owning stocks directly.
                </li>
                <li className='my-2'>
                    Prudently incorporating short equity puts into portfolios could enhance returns with comparable
                    downside risk in typical market scenarios.
                </li>
            </ul>
        </React.Fragment>
    )
}


function Section1(props) {
    const putRef = React.createRef()
    const psRef = React.createRef()
    const collarRef = React.createRef()
    const pscRef = React.createRef()


    return (
        <React.Fragment>
            <h3 className={'font-weight-bold mt-6'}>
                Overview
            </h3>
            <p>
                Some asset allocators believe that diversifying sources of return have become more important in the
                current investing environment. With many asset prices still relatively high, and the potential for
                higher interest rates to continue reversing a multi-decade bull market in bonds, traditional portfolios
                could suffer in the coming years. In recent history, bonds have provided valuable diversification to
                equities,
                and that could change if stock prices and bond prices move in the same direction.
            </p>
            <p>
                One alternative strategy that allocators should consider is selling equity put options. So-called
                put "writing"
                strategies have historically served institutional investors as "equity replacements," and the strategy
                could provide a needed source of returns going forward. While writing
                equity puts is not a hedging strategy, it can behave more defensively than holding stocks, and it can
                provide higher a yield than many fixed income strategies. Compared to some other alternative strategies,
                put-writing strategies often have the added benefits of being liquid, transparent and relatively simple
                to implement.
            </p>

            <h3 className={'font-weight-bold mt-6'}>
                Why sell put options
            </h3>
            <p>
                Some market participants consider a put option as a form of insurance on the price of an asset. When
                selling a put option, an investor effectively receives a cash payment for accepting downside exposure to
                an underlying stock, ETF or index. Similar to other forms of insurance, the price received for the put
                can exceed the expected value of the protection it provides, resulting in a risk premium that can
                enhance portfolio returns.
            </p>
            <p>
                Alternatively, value-oriented investors may view selling puts from a somewhat different perspective. If
                the underlying asset is below the put’s strike price at expiration, the put seller will be obligated to
                purchase the asset at the strike price when the put is exercised. Some value investors may seek to shift
                capital into equities when prices decline. By selling puts with strikes at their desired entry points,
                these investors can receive a yield while waiting for equity prices to decline to their buy prices.
            </p>

            <h3 className={'font-weight-bold mt-6'}>
                Selling puts vs. owning stocks:
            </h3>

            <p>
                Since an investor receives cash up front for selling a put, when the underlying asset’s price is
                unchanged and the put expires worthless, the put-writing strategy typically outperforms.
            </p>
            <p>
                A put selling strategy also tends to outperform the underlying asset when the asset decreases in price,
                as the received yield serves as a cushion against downside risk. Additionally, the short put may only
                have exposure after the underlying asset has fallen by a significant amount, which is the case for an
                out-of-the-money put.
            </p>
            <p>
                On the other hand, a short put will generally under-perform an investment in the asset itself when the
                asset’s total return (including its dividend) is higher than the yield from the short-put strategy over
                the life of the put. Therefore a short put trade may have less upside potential than holding the stock
                itself.
            </p>

            <div className='card mx-auto my-6' style={{maxWidth: '90%'}}>
                <div className='card-body p-2  bg-white'>
                    <h3 className='text-center'>
                        Short Put Payoff Illustration
                    </h3>
                    <img src={putPayoffChart} className='img-fluid'/>
                </div>
            </div>

            <h3 className={'font-weight-bold mt-6'}>
                Allocating to short puts
            </h3>

            <p>
                One challenge with incorporating option strategies is that they may not fit neatly into simple asset
                allocation models. In the case of short equity puts, using them strictly as an equity replacement can
                result in under-performance when equities rally significantly. On the other hand, using short equity
                puts
                strictly as a bond replacement can significantly alter the exposure of the portfolio to
                equity market stress scenarios.
            </p>
            <p>
                The approach that might make the most sense, therefore, is to view short equity puts as a hybrid asset
                class combining some characteristics of both equities and bonds. Using short puts to replace allocations
                of both equities and bonds can add the diversifying benefits of short puts to the portfolio, while
                keeping overall downside risk similar in most market conditions. For example, a portfolio of 50%
                equities, 30% bonds and 20% short equity puts, relative to a traditional 60-40 portfolio, could
                incorporate a diversifying source of returns, and reduce exposure to bonds, with similar equity downside
                exposure in most market scenarios.
            </p>


            <h3 className={'font-weight-bold mt-6'}>
                Implementation considerations
            </h3>

            <p>
                While short put strategies collectively may have similar broad objectives, the behavior of a specific
                strategy will be influenced by its construction. The following variables will impact the characteristics
                of a short put strategy:
            </p>
            <p>
                <u>Underlying security</u>: Selling puts on stocks, ETFs or indices with higher volatility will tend to
                generate higher yields as compensation for potentially higher downside risk exposure. Additionally, all
                else equal, securities with higher dividends will tend to generate somewhat higher yields as well.
            </p>
            <p>
                <u>Tenor</u> (time to maturity): Longer dated put options are generally more expensive than shorter
                dated ones.
                However, the total premium generated from selling multiple shorter dated options is usually higher than
                the premium from selling a single longer dated option over the same period. For example, selling a
                3-month put might generate 12% annual yield (3% over 3 months), while a 12-month option with the same
                security and same strike might only generate 6% annual yield. The higher total yield from shorter dated
                options reflects compensation for higher exposure to the realized volatility of the underlying security.
            </p>
            <p>
                <u>Strike</u>: All else equal, the lower the strike of the put sold (further out-of-the-money), the less
                likely
                selling the put will result in a liability on expiration. Therefore strategies using lower strikes will
                usually have lower yields. Additionally these strategies can also have lower volatility in many market
                conditions. However, while the performance of “deep out of the money” strategies can appear very smooth
                in some markets, the strategies are still subject to significant tail risk exposure.
            </p>
            <p>
                <u>Margin</u>: Since selling a put option can result in the liability to buy the underlying security at
                the
                strike price, the associated investment account will need to have enough cash or securities to fulfill
                such obligation. Many investors may want to hold cash to meet this obligation, while more sophisticated
                investors may want to use a margin account to hold securities other than cash against the short put
                liability. The latter approach can result in significant leverage and risk, with the equity exposure
                from the put adding risk in addition to the other securities in the portfolio.
            </p>
            <h3 className={'font-weight-bold mt-6'}>
                Final thoughts
            </h3>
            <p>
                When equity markets perform like they did in 2019 - 2021, and bonds provide diversification like they
                did during the temporary March 2020 sell-off, it's hard to improve on holding a traditional portfolio.
                However, due to a range of factors such positive returns may not persist in coming years, and investors
                with may look to boost performance with alternative strategies. Short puts may be a valuable tool to
                consider, given their potential to enhance portfolio returns in a range of market scenarios.
            </p>
        </React.Fragment>
    )
}

function BufferPerformanceProjectContainer(props) {
    const [projectObj, setProjectObj] = useState(null)

    useEffect(() => {
        apiGet(keys.API_ENDPOINT.PROJECTS, {id: props.projectId}, globalAuthManager).then(
            res => {
                const [project] = res.data
                setProjectObj(project)
            }
        )
    }, [])

    const contextValue = {
        projectObj: projectObj,
        projectId: props.projectId
    }

    return (
        <ProjectContext.Provider value={contextValue}>
            {props.children}
        </ProjectContext.Provider>
    )
}


function DisclosuresLink(props) {
    return (
        <a data-toggle="modal" href="#disclaimerModal"
           onClick={() => null}>Disclosures</a>
// onClick={() => mixpanel.track('Disclosure Clicked')}>Disclosures</a>
    )
}

function TermsOfUseLink(props) {
    return (
        <Link to={'/terms-of-use'} target={'_blank'}
              onClick={() => null}>Terms of Use</Link>
// onClick={() => mixpanel.track('Terms of Use Clicked')}>Terms of Use</Link>
    )
}

function BacktestingSourceDisclaimer(props) {
    return (
        <div>
            <hr/>
            <p className='font-italic text-muted'>
                {/*Source: Clarivor LLC. Strikes and maturities are estimated from listed strikes and tenors for easier*/}
                {/*comparison.*/}

                {/*All buffers above refer to a SPY 12-month buffer traded on 12/18/2021, providing 10% downside*/}
                {/*protection, collateralized with cash.*/}
                {/*Options can entail complex risks and may not be suitable for all investors.<br/>*/}
                {/*Each option strategy back-test above includes an annual deduction of 0.49% to reflect management fees*/}
                {/*and commissions. SPY (TR) and AGG (TR) have dividends immediately reinvested, shown with no additional*/}
                {/*management fee.*/}
                {/*Strikes are selected from the listed market.*/}
                {/*Options are rolled seven days prior to expiration at bid or offer.*/}
                {/*Option prices are observed at 3:45pm NY time.*/}
                <br/>
                <br/>
                NOTHING IN THIS POST SHOULD BE CONSTRUED AS INVESTMENT ADVICE. <br/>
                <DisclosuresLink/> and <TermsOfUseLink/> apply.
                <br/>
            </p>
        </div>
    )
}


function PostBody(props) {
    return (
        <React.Fragment>
            <section className="pt-0 pt-md-0">
                <ReceiveUpdatesModal/>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-12 col-lg-10 col-xl-9">
                            <Summary/>
                            <Section1/>
                            <BacktestingSourceDisclaimer/>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}


export default function BufferPerformance_20210803(props) {
    const projectId = null  // this is a static blog post

    useEffect(() => {
        document.title = documentTitle
    }, [])

    const headerProps = {
        title: postTitle,
        subtitle: postSubtitle,
        avatarImage: headshot,
        author: postAuthor,
        publishedOnText: publishedOnText,
    }

    return (
        <React.Fragment>
            <HeroSection/>
            <HeaderSection {...headerProps} />
            <BufferPerformanceProjectContainer projectId={projectId}>
                <PostBody/>
            </BufferPerformanceProjectContainer>
            <BlogSignupSection fromLocation={'Blog Post TLT Overwriting'}/>
        </React.Fragment>
    )
}


export function RichShortPuts20220421(props) {
    return (
        <section className="pt-7 pt-md-10">
            <div className="container">
                <div className="row">
                    <div className="col-12">

                        <div className="card card-row shadow-light-lg mb-6 lift lift-lg">
                            <div className="row no-gutters">
                                <div className="col-12">

<span className="badge badge-pill badge-light badge-float badge-float-inside">
<span className="h6 text-uppercase">yield</span>
</span>
                                </div>
                                <Link className="col-12 col-md-6 order-md-2 bg-cover card-img-right"
                                      style={{backgroundImage: "url(" + BannerImage500 + ")"}}
                                      to={props.blogPath + path}>

                                    <img src="assets/img/photos/photo-27.jpg" alt="..."
                                         className="img-fluid d-md-none invisible"/>

                                    <div
                                        className="shape shape-left shape-fluid-y svg-shim text-white d-none d-md-block">
                                        <svg viewBox="0 0 112 690" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 0h62.759v172C38.62 384 112 517 112 517v173H0V0z"
                                                  fill="currentColor"></path>
                                        </svg>
                                    </div>

                                </Link>
                                <div className="col-12 col-md-6 order-md-1">

                                    <Link className="card-body" to={props.blogPath + path}>

                                        <h3>
                                            {postTitle}
                                        </h3>

                                        <p className="mb-0 text-muted">
                                            {postSubtitle}
                                        </p>

                                    </Link>

                                    <a className="card-meta" href="#!">

                                        <hr className="card-meta-divider"/>

                                        <div className="avatar avatar-sm mr-2">
                                            <img src={jonAvatar} alt="..."
                                                 className="avatar-img rounded-circle"/>
                                        </div>

                                        <h6 className="text-uppercase text-muted mr-2 mb-0">
                                            Jon Spiegel
                                        </h6>

                                        <p className="h6 text-uppercase text-muted mb-0 ml-auto">
                                            <time dateTime="2020-10-23">{publishedOnShort}</time>
                                        </p>

                                    </a>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>

    )
}
