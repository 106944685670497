import React, {Component} from 'react';

// // import 'dkcss'
// import {dk} from 'dkDummy'
import {mapClasses} from 'util/cssModule'


export function ModalToggleButton(props) {
    return (
        <button type={props.type} className={props.className} data-toggle="modal" data-target={"#modal" + props.modalID}
                name={props.name}>
            {props.children}
        </button>
    );
}

export default function Modal(props) {
    const modalID = props.modalID
    const modalLabel = modalID + 'Label'

    let modalSize = ""
    if (props.modalSize != null) {
        modalSize = props.modalSize
    }

    let modalClassName = 'modal fade'

    if (props.show) {
        modalClassName = 'modal show'
    }

    return (
        <React.Fragment>
            <div className={modalClassName} id={modalID} tabIndex="-1" role="dialog" aria-labelledby={modalLabel}>
                 // aria-hidden="true">
                <div className={"modal-dialog " + modalSize} role="document">
                    <div className={mapClasses(props.cssModule, "modal-content")}>
                        <div className={mapClasses(props.cssModule, "modal-header bg-secondary text-white")}>
                            <h5 className={mapClasses(props.cssModule, "modal-title")}
                                id={modalLabel}>{props.modalTitle}</h5>
                            <button type="button" className={mapClasses(props.cssModule, "close")}
                                    data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className={mapClasses(props.cssModule, "modal-body")}>
                            {props.bodyComponent}
                        </div>
                        {
                            props.footerComponent ?
                                <div className={mapClasses(props.cssModule, "modal-footer")}>
                                    {props.footerComponent}
                                </div> : null
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
