import React from "react"
import {Link} from "react-router-dom";

export default function ClientInfoSubmittedPage(props) {
    React.useEffect(()=>{
        window.scrollTo(0, 0)
    }, [])

    return (
        <section className="pt-8 pt-md-11 pb-8 pb-md-14 bg-light" style={{minHeight: '70vh'}}>
            <div className="container">
                <div className='row justify-content-center my-5'>
                    <div className='col-12 col-md-8 text-center'>
                        <div className={'card'}>
                            <div className={'card-body text-left'}>
                                <h1 className='text-info'>Thank you.</h1>
                                <h2>We will review your information for approval and contact you shortly.</h2>
                                {/*<h3>In the meantime, feel free to visit Clarivor's <Link to={'/blog'}>blog</Link>.</h3>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}



export function ActiveClientInfoSubmittedPage(props) {
    React.useEffect(()=>{
        window.scrollTo(0, 0)
    }, [])

    return (
        <section className="pt-8 pt-md-11 pb-8 pb-md-14 bg-light" style={{minHeight: '70vh'}}>
            <div className="container">
                <div className='row justify-content-center my-5'>
                    <div className='col-12 col-md-8 text-center'>
                        <div className={'card'}>
                            <div className={'card-body text-left'}>
                                <h1 className='text-info'>Thank you!</h1>
                                <h2>We have received your information.</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

