import 'popper.js'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
// // import '../node_modules/bootstrap/dist/css/bootstrap-theme.min.css' // optional
import '../node_modules/jquery/dist/jquery.min.js'
import '../node_modules/bootstrap/dist/js/bootstrap.min.js'

export function setLKBody() {
    document.body.style.fontFamily = "HKGroteskPro"
    document.body.style.fontSize ="1.0625rem"
    document.body.style.margin = 0
    document.body.style.fontWeight = 400
    document.body.style.lineHeight = 1.6
    document.body.style.color = "#161C2D"
    document.body.style.textAlign = 'left'
    document.body.style.backgroundColor = '#FFFFFF'
}




// import 'lk/dist/assets/fonts/Feather/feather.css'
// import 'lk/dist/assets/libs/flickity/dist/flickity.min.css'
// import 'lk/dist/assets/libs/flickity-fade/flickity-fade.css'
// import 'lk/dist/assets/libs/aos/dist/aos.css'
// // import 'lk/dist/assets/libs/jarallax/dist/jarallax.css'
// import 'lk/dist/assets/libs/highlightjs/styles/vs2015.css'
// import 'lk/dist/assets/libs/@fancyapps/fancybox/dist/jquery.fancybox.min.css'

// // import 'https://api.mapbox.com/mapbox-gl-js/v0.53.0/mapbox-gl.css'

// import 'lk/dist/assets/css/theme.min.css'
// import lk from 'lk/dist/assets/css/theme.module.css'

// import 'dk/dist/assets/css/theme.min.css'
// import 'dk/dist/assets/fonts/feather/feather.min.css'
// import 'dk/dist/assets/libs/flatpickr/dist/flatpickr.min.css'
// import 'dk/dist/assets/libs/quill/dist/quill.core.css'
// import 'dk/dist/assets/libs/select2/dist/css/select2.min.css'
