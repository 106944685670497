import React, {Component, Suspense} from "react";
import {Link} from 'react-router-dom'
import NavBar from "../common/NavBarDefault";
import Footer from "../common/Footer";
import SPYPuts202010Image from "images/blog/SPY-Puts-2020-10/paragliding-500.jpg"
import {RichViewQQQCollar} from "./posts/QQQCollar_20201023/QQQCollar_20201023";
import jonAvatar from 'images/blog/avatar-100.jpg'
import BlogSignupSection from "./common/BlogSignupSection";
import {RichViewIncome20201209} from "./posts/Income_20201209/Income_20201209";
import {RichViewIncomeMonitor} from "./posts/Income_20201209/IncomeMonitorForBlog";
import {RichViewOneYearSincePreCovid} from "./posts/AYearSincePreCovid_20210216/AYearSincePreCovid_20210216";
import {RichViewTLTOverwrite} from "./posts/TLT_Overwriting_20200412/TLT_Overwriting_20200412";
import {RichBufferDelta202105} from "./posts/Blog_20210525_BufferDelta/Blog_20210525_BufferDelta";
import {
    RichBufferBondReplacement202106
} from "./posts/Blog_20210624_BufferBondReplacement/Blog_20210624_BufferBondReplacement";
import BreadcrumbNav from "../common/BreadcrumbNav";
import {
    RichBuyWriteDiversification202108
} from "./posts/Blog_20210803_BuyWriteDiversification/Blog_20210803_BuyWriteDiversification"
import {RichBufferPerformance202109} from "./posts/Blog_20210930_BufferPerformance/Blog_20210930_BufferPerformance"
import {RichSepBuffer202110} from "./posts/Blog_20211012_SepBuffer/Blog_20211012_SepBuffer";
import {RichSellTLTPuts202111} from "./posts/Blog_20211103_SellTLTPuts/Blog_20211103_SellTLTPuts";
import {RichBufferPerformance20211215} from "./posts/Blog_20211215_BufferPerformance/Blog_20211215_BufferPerformance";
import {RichTLTHedges20220120} from "./posts/Blog_20220120_TLTHedges/Blog_20220120_TLTHedges"
import {RichSPYPutSpreads20220308} from "./posts/Blog_20220308_PutSpreads/Blog_20220308_PutSpreads"
import {RichShortPuts20220421} from "./posts/Blog_20220421_ShortPuts/Blog_20220421_ShortPuts"
import {RichJun13Outlier} from "./posts/Blog_20220615_Jun13Outlier/Blog_20220615_Jun13Outlier"
import {RichSPYCollar20220722} from "./posts/Blog_20220722_SPYCollar/Blog_20220722_SPYCollar"
import {RichCalendarCollar20220922} from "./posts/Blog_20220922_CalendarCollar/Blog_20220922_CalendarCollar";
import {RichRatesTailHedge20221122} from "./posts/Blog_20221122_RatesTailHedge/Blog_20221122_RatesTailHedge";
import {RichSPYPuts20230203} from "./posts/Blog_20230203_SPYPuts/Blog_20230203_SPYPuts";
import {RichDeltaStrikeCC20230621} from "./posts/Blog_20230621_DeltaStrikeCC/Blog_20230621_DeltaStrikeCC";
import {RichLongCallSpreads20230815} from "./posts/Blog_20230815_LongCallSpreads/Blog_20230815_LongCallSpreads";

function BlogHeaderSection(props) {
    return (
        <section className="pt-4 pt-md-11">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-7 order-md-1">
                        <h2 className="display-4 text-center">
                            Practical insights for using options efficiently
                        </h2>
                        <p className='text-muted text-center'>
                            Keep up with Clarivor's research, commentary and tools
                        </p>
                    </div>
                </div>

            </div>

        </section>
    )
}


function RichViewSPYPuts(props) {
    return (
        <section className="pt-7 pt-md-10">
            <div className="container">
                <div className="row">
                    <div className="col-12">

                        <div className="card card-row shadow-light-lg mb-6 lift lift-lg">
                            <div className="row no-gutters">
                                <div className="col-12">

                            <span className="badge badge-pill badge-light badge-float badge-float-inside">
                    <span className="h6 text-uppercase">Hedging</span>
                  </span>

                                </div>
                                <Link className="col-12 col-md-6 order-md-2 bg-cover card-img-right"
                                      style={{backgroundImage: "url(" + SPYPuts202010Image + ")"}}
                                      to="blog/SPYPuts_20201014">

                                    <img src="assets/img/photos/photo-27.jpg" alt="..."
                                         className="img-fluid d-md-none invisible"/>

                                    <div
                                        className="shape shape-left shape-fluid-y svg-shim text-white d-none d-md-block">
                                        <svg viewBox="0 0 112 690" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 0h62.759v172C38.62 384 112 517 112 517v173H0V0z"
                                                  fill="currentColor"></path>
                                        </svg>
                                    </div>

                                </Link>
                                <div className="col-12 col-md-6 order-md-1">

                                    <Link className="card-body" to="/blog/SPYPuts_20201014">

                                        <h3>
                                            Hedging a Market Correction with Put Options
                                        </h3>

                                        <p className="mb-0 text-muted">
                                            When considering long puts, investors should carefully select maturities and
                                            strikes that fit their objectives
                                        </p>

                                    </Link>

                                    <a className="card-meta" href="#!">

                                        <hr className="card-meta-divider"/>

                                        <div className="avatar avatar-sm mr-2">
                                            <img src={jonAvatar} alt="..."
                                                 className="avatar-img rounded-circle"/>
                                        </div>

                                        <h6 className="text-uppercase text-muted mr-2 mb-0">
                                            Jon Spiegel
                                        </h6>

                                        <p className="h6 text-uppercase text-muted mb-0 ml-auto">
                                            <time dateTime="2020-10-14">Oct 14</time>
                                        </p>

                                    </a>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>

    )
}


export default function BlogMainPage(props) {

    React.useEffect(() => {
        window.scrollTo(0, 0)
    })

    return (
        <React.Fragment>
            <NavBar {...props}/>
            <BreadcrumbNav pathList={['Blog']}/>
            <BlogHeaderSection/>
            <div className={'container'}>
                <RichLongCallSpreads20230815 blogPath={props.match.path}/>
                <RichDeltaStrikeCC20230621 blogPath={props.match.path}/>
                <RichSPYPuts20230203 blogPath={props.match.path}/>
                <RichRatesTailHedge20221122 blogPath={props.match.path}/>
                <RichCalendarCollar20220922 blogPath={props.match.path}/>
                <RichSPYCollar20220722 blogPath={props.match.path}/>
                <RichJun13Outlier blogPath={props.match.path}/>
                <RichShortPuts20220421 blogPath={props.match.path}/>
                <RichSPYPutSpreads20220308 blogPath={props.match.path}/>
                <RichTLTHedges20220120 blogPath={props.match.path}/>
                <RichBufferPerformance20211215/>
                <RichSellTLTPuts202111/>
                <RichSepBuffer202110/>
                <RichBufferPerformance202109/>
                <RichBuyWriteDiversification202108/>
                <RichBufferBondReplacement202106/>
                <RichBufferDelta202105/>
                <RichViewTLTOverwrite/>
                <RichViewOneYearSincePreCovid/>
                <RichViewIncomeMonitor/>
                <RichViewIncome20201209/>
                <RichViewQQQCollar/>
                <RichViewSPYPuts/>
                <BlogSignupSection fromLocation={'Blog Main'}/>
            </div>
            <Footer/>
        </React.Fragment>
    )
}


