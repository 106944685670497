import React, {useState, useEffect} from "react"
import {globalAuthManager} from "../../../../services/GlobalServices"
import {apiGet, apiPost} from "../../../../services/api-call-helpers"
import * as keys from 'keys'
import {instantiateInstrument, nameInstrument} from "util/instruments"
import {InvestmentAppContext} from "../../../contexts"
import {parseDate} from "../../../../util/dates"
import BidAskSlider from "./BidAskSlider"
import {v4 as uuidv4} from 'uuid'

export function describeTradeLeg(rtl) {
    let s = ''
    if (rtl.units < 1) {
        s = 'Sell ' + Math.abs(rtl.units) + ' '
    } else {
        s = 'Buy ' + Math.abs(rtl.units) + ' '
    }

    s += nameInstrument(rtl.instrument)

    return s
}

function ResolvedTradesView(props) {
    let resolvedTrades = props.resolvedTrades

    resolvedTrades.sort((t1, t2) => {
        if (t1.instrument.strike == null) {
            return -1
        } else if (t2.instrument.strike == null) {
            return 1
        } else {
            return t1.instrument.strike < t2.instrument.strike ? -1 : 1
        }
    })

    return (
        <React.Fragment>
            {
                resolvedTrades.map((rtl, i) => <h4 key={i}>{describeTradeLeg(rtl)}</h4>)
            }
        </React.Fragment>
    )
}

function ExcessTradesView(props) {
    let instrumentDictList = props.instrumentDictList
    let unitsList = props.unitsList

    let trades = instrumentDictList.map((instDict, i) => ({
        instrument: instDict,
        units: unitsList[i]
    }))

    trades.sort((t1, t2) => {
        if (t1.instrument.strike == null) {
            return -1
        } else if (t2.instrument.strike == null) {
            return 1
        } else {
            return t1.instrument.strike < t2.instrument.strike ? -1 : 1
        }
    })

    return (
        <React.Fragment>
            {
                trades.map(rtl => <h4>{describeTradeLeg(rtl)}</h4>)
            }
        </React.Fragment>
    )
}


export function calcComboBidAskSpreadPerShare(instrumentObjs, unitsList, bidAskSpreads) {
    let totalSpread = 0
    const sharesList = unitsList.map((u, i) => u * instrumentObjs[i].getMultiplier())

    for (let i = 0; i < instrumentObjs.length; i++) {
        totalSpread += Math.abs(sharesList[i] * bidAskSpreads[i])
    }

    const absShares = sharesList.map(shares => Math.abs(shares))
    const minShares = Math.min(...absShares)

    return totalSpread / minShares
}

export function calcComboBidAskSpreadPercentAnnualized(instrumentObjs, unitsList, bidAskSpreads, stockPrice) {
    let totalAnnualizedSpread = 0

    const sharesList = unitsList.map((u, i) => u * instrumentObjs[i].getMultiplier())

    for (let i = 0; i < instrumentObjs.length; i++) {
        if (instrumentObjs[i].expiration) {
            const dToday = new Date()
            const days = (instrumentObjs[i].expiration.getTime() - dToday.getTime()) / (1000 * 60 * 60 * 24)
            totalAnnualizedSpread += (365 / days) * Math.abs(sharesList[i] * bidAskSpreads[i]) / stockPrice
        }
    }

    const absShares = sharesList.map(shares => Math.abs(shares))
    const minShares = Math.min(...absShares)

    return totalAnnualizedSpread / minShares
}

// may be called with Resolved Trades or Pending Trade Combo
export function PlaceOrderModalBodyAndFooterWithPreview(props) {
    const defaultPercentBidAsk = props.defaultPercentBidAsk
    const pendingTradeCombo = props.pendingTradeCombo
    const instrumentDicts = props.instruments
    const unitsList = props.unitsList
    const closeModal = props.closeModal
    const placeOrderForPTC = props.placeOrderForPTC
    const placeOrderForResolvedTrades = props.placeOrderForResolvedTrades
    const placeOrderForInstruments = props.placeOrderForInstruments
    let investmentAccountId = props.investmentAccountId
    let resolvePTCsEndpoint = props.resolvePTCsAPIEndpoint

    const [resolvedTradeObjs, setResolvedTradeObjs] = useState(null)
    const [instrumentQuotes, setInstrumentQuotes] = useState(null)
    const [resolvingTrades, setResolvingTrades] = useState(false)
    const [underlyingPrice, setUnderlyingPrice] = useState(null)
    const [bidAskSpreadPerShare, setBidAskSpreadPerShare] = useState(null)
    const [midPricePerShare, setMidPricePerShare] = useState(null)
    const [targetTCost, setTargetTCost] = useState(.75)

    const invAppContext = React.useContext(InvestmentAppContext)
    if (investmentAccountId == null && pendingTradeCombo != null) {
        investmentAccountId = pendingTradeCombo.investment_account
    }
    const [investmentAccountObj] = invAppContext.investmentAccounts.filter(obj => obj.id === investmentAccountId)
    let setLimitPrice = true
    let alwaysPreviewTrades = true

    // if (investmentAccountObj != null && investmentAccountObj.broker != null) {
    //     setLimitPrice = !investmentAccountObj.broker.name.toLowerCase().includes("schwab")
    // }

    useEffect(() => {
        if (instrumentDicts == null || instrumentDicts === []) {
            previewTrades()
        }
    }, [pendingTradeCombo])

    function handleChange(event) {
        localStorage.percentOfBidAsk = event.target.value
    }

    function handlePreviewClick(event) {
        previewTrades()
    }

    function previewTrades() {
        // is there a pending trade combo?
        if (pendingTradeCombo != null) {
            setResolvingTrades(true)

            const params = {
                pending_trade_combos: JSON.stringify([pendingTradeCombo.id])
            }
            apiGet(resolvePTCsEndpoint || keys.API_ENDPOINT.RESOLVE_PENDING_TRADE_COMBOS, params, globalAuthManager).then(
                res => {
                    setResolvedTradeObjs(res.data.trade_legs)
                    setMidPricePerShare(res.data.mid)
                }
            ).finally(() => {
                setResolvingTrades(false)
            })
        } // are these excess positions?
        else if (instrumentDicts != null) {
            setResolvingTrades(true)

            const params = {
                instruments: JSON.stringify(instrumentDicts),
                investment_account: investmentAccountId
            }
            apiPost(keys.API_ENDPOINT.QUOTE_INSTRUMENTS, params, globalAuthManager).then(
                res => {
                    setInstrumentQuotes(res.data)
                }
            ).finally(() => {
                setResolvingTrades(false)
            })
        }
    }

    function handleCancel() {
        closeModal()
    }

    useEffect(() => {
        if (resolvedTradeObjs != null && resolvedTradeObjs.length > 0) {
            const instrumentObjs = resolvedTradeObjs.map(rtl => instantiateInstrument(rtl.instrument))
            const unitsList = resolvedTradeObjs.map(rtl => rtl.units)
            const bidAskSpreads = resolvedTradeObjs.map(rtl => rtl.bid_ask_spread)

            const bas = calcComboBidAskSpreadPerShare(instrumentObjs, unitsList, bidAskSpreads)
            setBidAskSpreadPerShare(bas)

            if (instrumentObjs.every(iObj => iObj.getTicker() === instrumentObjs[0].getTicker())) {
                const ticker = instrumentObjs[0].getTicker()
                const params = {symbols: JSON.stringify([ticker])}
                apiGet(keys.API_ENDPOINT.STOCK_MID_PRICES, params, globalAuthManager).then(
                    res => {
                        const priceDict = res.data
                        const px = priceDict[ticker]
                        setUnderlyingPrice(px)
                    }
                )
            }

        }
    }, [resolvedTradeObjs])

    useEffect(() => {
        if (instrumentQuotes != null && instrumentQuotes.length > 0) {
            const instrumentObjs = instrumentDicts.map(instDict => instantiateInstrument(instDict))

            // only supporting one instrument currently
            setBidAskSpreadPerShare(instrumentQuotes[0].bid_ask_spread)

            if (instrumentObjs.every(iObj => iObj.getTicker() === instrumentObjs[0].getTicker())) {
                const ticker = instrumentObjs[0].getTicker()
                const params = {symbols: JSON.stringify([ticker])}
                apiGet(keys.API_ENDPOINT.STOCK_MID_PRICES, params, globalAuthManager).then(
                    res => {
                        const priceDict = res.data
                        const px = priceDict[ticker]
                        setUnderlyingPrice(px)
                    }
                )
            }

        }
    }, [instrumentQuotes])


    function handleSubmit() {
        if (resolvedTradeObjs != null && resolvedTradeObjs.length > 0) {
            placeOrderForResolvedTrades(resolvedTradeObjs, pendingTradeCombo, targetTCost)
            closeModal()
        } else {
            if (pendingTradeCombo) {
                placeOrderForPTC(pendingTradeCombo, targetTCost)
            } else if (instrumentDicts) {
                placeOrderForInstruments(targetTCost)
            }
            closeModal()
        }
    }

    let midAskStr = ""
    if (bidAskSpreadPerShare != null && underlyingPrice != null) {
        midAskStr = (.5 * bidAskSpreadPerShare / underlyingPrice * 100).toFixed(2) + '%'
    } else if (bidAskSpreadPerShare != null) {
        midAskStr = (.5 * bidAskSpreadPerShare).toFixed(2)
    }


    let midPriceStr = null
    if (midPricePerShare != null && underlyingPrice != null) {
        midPriceStr = (midPricePerShare / underlyingPrice * 100).toFixed(2) + '%'
    } else if (midPricePerShare != null) {
        midPriceStr = midPricePerShare.toFixed(2)
    }

    let previewTradesButtonText = alwaysPreviewTrades ? "Refresh" : "Preview Trades"

    const instrumentObjs = resolvedTradeObjs == null ? null : resolvedTradeObjs.map(rtl => instantiateInstrument(rtl.instrument))
    const bidAskSpreads = resolvedTradeObjs == null ? null : resolvedTradeObjs.map(rtl => rtl.bid_ask_spread)

    let annualizedBidAsk = 99
    if (instrumentObjs != null && bidAskSpreads != null && underlyingPrice != null) {
        annualizedBidAsk = calcComboBidAskSpreadPercentAnnualized(
            instrumentObjs, resolvedTradeObjs.map(rtl => rtl.units), bidAskSpreads, underlyingPrice
        )
    }

    if (false) {
        return (
            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col'>
                        <h3 className='text-center'>
                            <span className="spinner-border spinner-border-sm" role="status"
                                  aria-hidden="true"></span>
                        </h3>
                    </div>
                </div>
                <div className='row justify-content-center'>
                    <div className='col'>
                        <h3 className='text-center'>
                            Calculating instruments
                        </h3>
                    </div>
                </div>
            </div>
        )
    } else return (
        <React.Fragment>
            <div className="modal-body">
                {
                    resolvedTradeObjs == null ? null : <ResolvedTradesView resolvedTrades={resolvedTradeObjs}/>
                }
                {
                    instrumentDicts == null ? null :
                        <ExcessTradesView instrumentDictList={instrumentDicts} unitsList={unitsList}/>
                }
                {
                    resolvedTradeObjs != null && instrumentDicts != null ? null :
                        <div className='row justify-content-center my-4'>
                            <div className='col text-center'>
                                {
                                    resolvingTrades ?
                                        <span className="spinner-border spinner-border-sm" role="status"
                                              aria-hidden="true"></span>
                                        :
                                        pendingTradeCombo != null ?
                                            <button className='btn btn-sm btn-outline-primary'
                                                    onClick={handlePreviewClick}>
                                                {previewTradesButtonText}
                                            </button> :
                                            <button className='btn btn-sm btn-outline-primary'
                                                    onClick={handlePreviewClick}>
                                                Check Mid Ask
                                            </button>
                                }
                            </div>
                        </div>
                }
                <hr/>
                {
                    resolvedTradeObjs ?
                        <div className='row justify-content-center'>
                            <div className='col'>
                                {
                                    midPriceStr == null ? null :
                                        <h3 className='text-center text-info'>
                                            Mid: {midPriceStr}<br/>
                                        </h3>
                                }
                                <h3 className='text-center text-info'>
                                    Mid-ask: {midAskStr}<br/>
                                </h3>
                            </div>
                        </div>
                        :
                        null
                }
                {
                    instrumentDicts ?
                        <div className='row justify-content-center'>
                            <div className='col'>
                                {
                                    midPriceStr == null ? null :
                                        <h3 className='text-center text-info'>
                                            Mid: {midPriceStr}<br/>
                                        </h3>
                                }
                                <h3 className='text-center text-info'>
                                    Mid-ask: {midAskStr}<br/>
                                </h3>
                            </div>
                        </div>
                        :
                        null
                }
                <hr/>
                {
                    !setLimitPrice ? null :
                        <div className='row justify-content-center'>
                            <div className='col'>
                                <BidAskSlider midPercentOfSpot={midPricePerShare / underlyingPrice}
                                              marketBidAskPercentOfSpot={bidAskSpreadPerShare / underlyingPrice}
                                              setTargetSpreadCallback={(spread) => {
                                                  setTargetTCost(spread)
                                              }}
                                              rowKey={uuidv4()}
                                              annualizedBidAskPercentOfSpot={annualizedBidAsk}
                                              initialTargetSpread={targetTCost}
                                />
                            </div>
                        </div>

                    // <React.Fragment>
                    //     <div className='row justify-content-center'>
                    //         <div className='col'>
                    //             <h3 className='text-center'>
                    //                 Set limit price <br/>
                    //             </h3>
                    //         </div>
                    //     </div>
                    //     <div className='row justify-content-center'>
                    //         <div className='col'>
                    //             <h3 className='text-muted text-center'>
                    //                 0 = mid, 100 = bid or ask
                    //             </h3>
                    //         </div>
                    //     </div>
                    //     <div className='row justify-content-center'>
                    //         <div className='col-6'>
                    //             <input className='form-control text-center' type='number' step={5} min={-25}
                    //                    max={125}
                    //                    onChange={handleChange} defaultValue={defaultPercentBidAsk}
                    //             />
                    //         </div>
                    //     </div>
                    // </React.Fragment>
                }
            </div>
            <div className='modal-footer'>
                <div className='row justify-content-center'>
                    <div className='col'>
                    </div>
                    <div className='col-auto'>
                        <button className='btn btn-light' onClick={handleCancel}>Cancel</button>
                    </div>
                    <div className='col-auto'>
                        <button className='btn btn-primary' onClick={handleSubmit}>Submit</button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}


export function PlaceOrderModalBody(props) {
    const defaultPercentBidAsk = props.defaultPercentBidAsk

    // If we want to resolve the trades, we need to change this code, bcause the modal is also used for excess positions with no PTC.
    // const pendingTradeCombo = props.pendingTradeCombo
    // const [resolvedTrades, setResolvedTrades] = useState(null)
    //
    // React.useEffect(() => {
    //     const params = {
    //         pending_trade_combos: JSON.stringify([pendingTradeCombo.id])
    //     }
    //     apiGet(keys.API_ENDPOINT.RESOLVE_PENDING_TRADE_COMBOS, params, globalAuthManager).then(
    //         res => setResolvedTrades(res.data)
    //     )
    // }, [])

    function handleChange(event) {
        localStorage.percentOfBidAsk = event.target.value
    }

    // if (resolvedTrades == null) {
    if (false) {
        return (
            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col'>
                        <h3 className='text-center'>
                            <span className="spinner-border spinner-border-sm" role="status"
                                  aria-hidden="true"></span>
                        </h3>
                    </div>
                </div>
                <div className='row justify-content-center'>
                    <div className='col'>
                        <h3 className='text-center'>
                            Calculating instruments
                        </h3>
                    </div>
                </div>
            </div>
        )
    } else return (
        <div className='container'>
            {/*<ResolvedTradesView resolvedTrades={resolvedTrades}/>*/}
            {/*<hr/>*/}
            <div className='row justify-content-center'>
                <div className='col'>
                    <h3 className='text-center'>
                        Set limit price <br/>
                    </h3>
                </div>
            </div>
            <div className='row justify-content-center'>
                <div className='col'>
                    <h3 className='text-muted'>
                        0 = mid, 100 = full bid-ask
                    </h3>
                </div>
            </div>
            <div className='row justify-content-center'>
                <div className='col-6'>
                    <input className='form-control text-center' type='number' step={5} min={-25} max={125}
                           onChange={handleChange} defaultValue={defaultPercentBidAsk}
                    />
                </div>
            </div>
        </div>
    )
}


export function PlaceOrderModalFooter(props) {
    return (
        <div className='row justify-content-center'>
            <div className='col'>
            </div>
            <div className='col-auto'>
                <button className='btn btn-light' onClick={props.handleCancel}>Cancel</button>
            </div>
            <div className='col-auto'>
                <button className='btn btn-primary' onClick={props.handleSubmit}>Submit</button>
            </div>
        </div>
    )
}

