import React, {createContext, useState, useContext, useEffect} from 'react';

// Alert Context
const AlertContext = createContext({
    alerts: [],
    addAlert: () => {
    }
});

// Alert Provider
function AlertProvider({children}) {
    const [alerts, setAlerts] = useState([]);

    const addAlert = (component, shouldDisplay, priority) => {
        setAlerts(prevAlerts => [
            ...prevAlerts,
            {component, shouldDisplay, priority}
        ]);
    };

    return (
        <AlertContext.Provider value={{alerts, addAlert}}>
            {children}
        </AlertContext.Provider>
    );
}

// Custom Hook
function useAlert(component, shouldDisplay, priority) {
    const {addAlert} = useContext(AlertContext);

    useEffect(() => {
        addAlert(component, shouldDisplay, priority);
    }, []);
}

// Alert Manager
function AlertManager() {
    const {alerts} = useContext(AlertContext);

    console.log(alerts)

    const highestPriorityAlert = alerts
        .filter(alert => alert.shouldDisplay())
        .sort((a, b) => b.priority - a.priority)[0];

    console.log(highestPriorityAlert)


    if (highestPriorityAlert) {
        return <highestPriorityAlert.component/>;
    }

    return null;
}

export {AlertProvider, useAlert, AlertManager};
