import Modal from "../../common/Modal"
import React, {useContext, useState} from "react"
import brandLogo from 'images/clarivor-p-500.png'
import axios from "axios"
import {API_ENDPOINT} from "../../../keys"
import {mixpanel} from "../../../services/mixpanel"

const RequestDemoContext = React.createContext(null)

function DemoRequestForm(props) {
    const [formData, setFormData] = useState({})
    const context = useContext(RequestDemoContext)

    const handleSubmit = async (event) => {
        event.preventDefault()

        formData.source = context.source

        const params = {
            form_data: formData,
            form_name: 'Request Demo',
        }

        try {
            await axios.post(API_ENDPOINT.CONTACT_FORM, params)
            mixpanel.alias(formData.email)
            props.setSubmitted(true)
        } catch (error) {
            {
                window.alert('An error occurred.  Please email contact@clarivor.com')
            }
        }

        return false
    }

    const handleFieldChange = (event) => {
        let newFormData = JSON.parse(JSON.stringify(formData))

        newFormData[event.target.name] = event.target.value

        setFormData(newFormData)
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row justify-content-center">
                <div className="col-12 col-md-10">
                    <div className={'row justify-content-center my-6'}>
                        <img src={brandLogo} style={{width: '50%'}}/>
                    </div>
                    <h4 className="text-dark px-4 mb-5">
                        {/*Please enter your contact information below, or call <span*/}
                        {/*className={'text-info'}>1-212-810-1187</span>*/}
                        Please enter your contact information below to schedule a brief Zoom.
                    </h4>
                </div>
            </div>

            < div
                className="row justify-content-center">
                < div
                    className="col-12 col-md-10">
                    <div
                        className="form-group mb-5">
                        <input
                            name='fullName'
                            type="text"
                            required
                            className="form-control"
                            id="fullName"
                            onChange={handleFieldChange}
                            placeholder="Full Name"/>
                    </div>
                    <div className="form-group mb-5">
                        <input name='email' type="email" required className="form-control"
                               id="email"
                               onChange={handleFieldChange}
                               placeholder="Business Email"/>
                    </div>
                    {/*<div*/}
                    {/*    className="form-group mb-5">*/}
                    {/*    <input*/}
                    {/*        name='company'*/}
                    {/*        type="text"*/}
                    {/*        className="form-control"*/}
                    {/*        id="company"*/}
                    {/*        onChange={handleFieldChange}*/}
                    {/*        required*/}
                    {/*        placeholder="Company"/>*/}
                    {/*</div>*/}
                    <div className="form-group mb-5">
                        <button type="submit" className="btn btn-block btn-outline-success lift">
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </form>
    )
}

function RequestUpdateSubmittedForm(props) {
    return (
        <React.Fragment>
            <div className="row justify-content-center">
                <div className="col-12">
                    <div className={'row justify-content-center my-6'}>
                        <img src={brandLogo} style={{width: '50%'}}/>
                    </div>
                    <h4 className="text-dark px-4 mb-5">
                        Thank you. We will be in touch shortly.
                    </h4>
                    <div className='col-12 d-flex'>
                        <button className='btn btn-success mx-auto' data-dismiss='modal'>OK</button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}


function RequestModalBody(props) {
    const [submitted, setSubmitted] = useState(false)

    if (!submitted) {
        return <DemoRequestForm setSubmitted={setSubmitted}/>
    } else {
        return <RequestUpdateSubmittedForm/>
    }
}


export default function RequestDemoModal(props) {


    let modalBody = <RequestModalBody/>

    return (
        <RequestDemoContext.Provider value={{source: props.source}}>
            <Modal modalID={'requestDemoModal'} type='' cssModule={null} modalTitle="Learn More"
                   bodyComponent={modalBody}/>
        </RequestDemoContext.Provider>
    )
}
