import React, {Component} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const getHCOptions = (seriesOptions, colors) => (
    {
        series: seriesOptions,

        legend: {
            // enabled: true,
            enabled: false,
            itemStyle: {
                "color": "#333333",
                "cursor": "pointer",
                "fontSize": "12px",
                "fontWeight": "normal",
                "textOverflow": "ellipsis"
            }
        },

        rangeSelector: {
            selected: 4
        },

        title: {
            text: 'Collar Hedge Illustration'
        },

        credits: {
            enabled: false
        },

        xAxis: {
            title: {
                text: 'ETF return as of option expiration'
            },
            labels: {
                enabled: true,
                formatter: function () {
                    return (this.value > 0 ? '+' : '') + this.value * 100 + '%';
                }
            }
            // labels: {
            //     formatter: function () {
            //         return this.value*100 + '%';
            //     }
            // },
        },

        yAxis: {
            title: {
                text: 'Collar expiration value (% of initial ETF value)'
            },

            labels: {
                formatter: function () {
                    return (this.value > 0 ? '+' : '') + this.value * 100 + '%';
                }
            },
            plotLines: [{
                value: 0,
                width: 2,
                color: 'silver'
            }],

            max:.2,
            min:-.25,

        },

        chart: {
                backgroundColor: 'transparent',
        },

        tooltip: {
            pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> <br/>',
            valueDecimals: 2,
            split: true
        },

        colors: ['#888888'],

        exporting: {enabled: false},

    }
)

export default function CollarPayoffDiagram(props) {
    // values -.3 to +.3 increments of .005:
    let xVals = []
    for (let i = -.3; i <= .3; i += .005) {
        xVals.push(i)
    }

    let kPut = .9
    let kCall = 1.075
    let yVals = xVals.map(x => (
        Math.max(0, kPut - (1 + x)) - Math.max(0, 1 + x - kCall)
    ))

    let tuples = xVals.map((x, i) => [x, yVals[i]])

    let seriesOptions = {
        name: 'Collar Payoff',
        data: tuples
    }

    return (
        <HighchartsReact highcharts={Highcharts} options={getHCOptions([seriesOptions])}/>
    )
}
