// props.pathList is a list of strings
import {ChevronRight} from "react-feather";
import React from "react";

export default function BreadcrumbNav(props)
{
    return (
        <nav className="bg-gray-200">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-muted">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item align-items-center">
                                {props.pathList[0]}<ChevronRight size={12} className='mx-2'/>{props.pathList[1]}
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </nav>
    )
}