import React, {Component} from 'react'
import footerLogo from 'images/clarivor-p-500-inverted.png'
import {Link} from 'react-router-dom'
// import lk from 'lk/dist/assets/css/theme.module.css'
import {mapClasses} from 'util/cssModule'
import CookieConsentAlert from "./alerts/CookieConsentAlert"
import DisclaimerDiv from "../pages/legal/DisclaimerDiv"
import Modal from "./Modal"
import {mixpanel} from "../../services/mixpanel"
import {AlertManager} from "./alerts/AlertsManager";

// const mapClasses = (module, classstr) => module == null ? classstr : module[classstr]


function DisclaimerModal(props) {
    let modalBody = <DisclaimerDiv/>

    return (
        <Modal modalID={'disclaimerModal'} type='' cssModule={null} modalTitle="Clarivor Disclosures"
               bodyComponent={modalBody} footerComponent={null} modalSize={'modal-lg'}/>
    )
}

class Footer extends Component {
    render() {
        return (
            <div>
                <AlertManager/>
                <DisclaimerModal/>
                <div className={mapClasses(null, "position-relative")}>
                    <div className={mapClasses(null, "shape shape-bottom shape-fluid-x svg-shim text-dark")}>
                        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                        </svg>
                    </div>
                </div>
                <footer className={mapClasses(null, "py-8 py-md-11 mt-6 bg-dark")}>
                    <div className={mapClasses(null, "container")}>
                        <div className={mapClasses(null, "row justify-content-center")}>
                            <div className={mapClasses(null, "col-12 col-md-6 pb-6" )}>
                                <img src={footerLogo} style={{maxWidth: '200px'}} alt="..."
                                     className={mapClasses(null, "img-fluid mb-2")}/>
                                <p className={mapClasses(null, "text-gray-700 mb-5 h4")}>
                                    1345 Avenue of the Americas<br/>
                                    2nd Floor 2-062<br/>
                                    New York, NY 10105
                                </p>
                                <p className={mapClasses(null, "text-gray-700 my-5 h4")}>
                                    Copyright © 2022 Clarivor LLC. All Rights Reserved.
                                </p>
                            </div>

                            <div className={mapClasses(null, "col-12 col-sm-4 col-md-2 h5 mb-4")}>
                                { /* Heading */}
                                <h6 className={mapClasses(null, "font-weight-bold text-uppercase text-gray-700")}>
                                    Solutions
                                </h6>

                                { /* List */}
                                <ul className={mapClasses(null, "list-unstyled text-muted mb-0")}>
                                    <li className={mapClasses(null, "mb-3")}>
                                        <Link to={'/advisors'} className={mapClasses(null, "text-reset")}>
                                            Advisors
                                        </Link>
                                    </li>
                                </ul>
                                <ul className={mapClasses(null, "list-unstyled text-muted mb-0")}>
                                    <li className={mapClasses(null, "mb-3")}>
                                        <Link to={'/institutions'} className={mapClasses(null, "text-reset")}>
                                            Institutions
                                        </Link>
                                    </li>
                                </ul>
                            </div>


                            <div className={mapClasses(null, "col-12 col-sm-4 col-md-2 h5 mb-4")}>
                                { /* Heading */}
                                <h6 className={mapClasses(null, "font-weight-bold text-uppercase text-gray-700")}>
                                    Company
                                </h6>

                                { /* List */}
                                <ul className={mapClasses(null, "list-unstyled text-muted mb-0")}>
                                    <li className={mapClasses(null, "mb-3")}>
                                        <Link to={'/about'} className={mapClasses(null, "text-reset")}>
                                            About
                                        </Link>
                                    </li>
                                </ul>
                                <ul className={mapClasses(null, "list-unstyled text-muted mb-0")}>
                                    <li className={mapClasses(null, "mb-3")}>
                                        <Link to={'/blog'} className={mapClasses(null, "text-reset")}>
                                            Blog
                                        </Link>
                                    </li>
                                </ul>
                                <ul className={mapClasses(null, "list-unstyled text-muted mb-0")}>
                                    <li className={mapClasses(null, "mb-3")}>
                                        <Link to={'/jobs'} className={mapClasses(null, "text-reset")}>
                                            Jobs
                                        </Link>
                                    </li>
                                </ul>
                                <ul className={mapClasses(null, "list-unstyled text-muted mb-0")}>
                                    <li className={mapClasses(null, "mb-3")}>
                                        <Link to={'/contact'} className={mapClasses(null, "text-reset")}>
                                            Contact
                                        </Link>
                                    </li>
                                </ul>
                            </div>


                            <div className={mapClasses(null, "col-12 col-sm-4 col-md-2 px-4")}>
                                { /* Heading */}
                                <h6 className={mapClasses(null, "font-weight-bold text-uppercase text-gray-700")}>
                                    Legal
                                </h6>

                                { /* List */}
                                <ul className={mapClasses(null, "list-unstyled text-muted mb-0")}>
                                    <li className={mapClasses(null, "mb-3")}>
                                        <a data-toggle="modal" href="#disclaimerModal"
                                           className={mapClasses(null, "text-reset")}
                                           onClick={() => mixpanel.track('Disclosure Clicked')}>Disclosures</a>
                                    </li>
                                </ul>
                                <ul className={mapClasses(null, "list-unstyled text-muted mb-0")}>
                                    <li className={mapClasses(null, "mb-3")}>
                                        <Link to={'/terms-of-use'} target={'_blank'} className={'text-reset'}
                                              onClick={() => mixpanel.track('Terms of Use Clicked')}>Terms of Use</Link>
                                    </li>
                                </ul>
                                <ul className={mapClasses(null, "list-unstyled text-muted mb-0")}>
                                    <li className={mapClasses(null, "mb-3")}>
                                        <Link to={'/privacy-policy'} target={'_blank'}
                                              className={mapClasses(null, "text-reset")}
                                              onClick={() => mixpanel.track('Privacy Policy Clicked')}>
                                            Privacy Policy
                                        </Link>
                                    </li>
                                </ul>
                            </div>

                        </div>

                    </div>
                    { /* / .container */}
                </footer>
            </div>
        )
    }
}

export default Footer