import React, {lazy, Suspense} from 'react'
import {Link} from "react-router-dom";
import NavBar from 'components/common/NavBarDefault'
import Footer from 'components/common/Footer'
import AutomatedWorkflowSection from "./AutomatedWorkflowSection"
import {setLKBody} from 'lkcss'
import {ParallaxProvider} from "react-scroll-parallax"
import {ArrowDown} from "react-feather";
import RequestDemoModal from "../common/RequestDemoModal";
import Fade from "react-reveal/Fade";
import HeroSection from "./HeroSection";

const mapClasses = (modStr, className) => (className)

function DownButton(props) {
    return (
        <div className="row py-2 py-md-4 ">
            <div className="col-12 text-center">
                <button
                    className="btn btn-white btn-rounded-circle shadow mt-n7 mt-md-n9"
                    onClick={props.scroll}>
                    <ArrowDown className={'my-n1'}/>
                </button>
            </div>
        </div>)
}

const ANewWayOfInvesting = (props) => (
    <div ref={props.sectionRef} className="row justify-content-center">
        <div className="col-12 col-md-10 col-lg-8 mt-n4 text-center">
            <hr className="hr-md my-6 my-md-8 border-gray-400"/>
            <h1>
                The first automated advisor for institutional option strategies
            </h1>
            <hr className="hr-md my-6 my-md-8 border-gray-400"/>
            <div className={'my-8'}></div>
        </div>
    </div>
)

const arrowNextStr = 'url("data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' fill=\'%23888888\' viewBox=\'0 0 8 8\'%3e%3cpath d=\'M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z\'/%3e%3c/svg%3e")'
const arrowPrevStr = 'url("data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' fill=\'%23888888\' viewBox=\'0 0 8 8\'%3e%3cpath d=\'M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z\'/%3e%3c/svg%3e")';

function BlogCarousel(props) {
    return (
        <div id="selectedChartsCarousel" className="carousel slide bg-transparent" data-ride="carousel">
            <div className="carousel-inner">
                {
                    React.Children.map(props.children, (child, i) => {
                            return (
                                <div className={"carousel-item" + (i == 0 ? ' active' : '')}>
                                    {child}
                                </div>
                            )
                        }
                    )
                }
            </div>
            <a className="carousel-control-prev" href="#selectedChartsCarousel" role="button"
               data-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"
                                  style={{backgroundImage: arrowPrevStr}}></span>
                <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" href="#selectedChartsCarousel" role="button"
               data-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"
                                  style={{backgroundImage: arrowNextStr}}></span>
                <span className="sr-only">Next</span>
            </a>
        </div>)
}

function TargetAudienceSection(props) {
    const thisSectionRef = React.createRef()

    function scrollToThisSection() {
        thisSectionRef.current.scrollIntoView({behavior: 'smooth'})
    }

    return (
        <section ref={thisSectionRef} className='bg-gradient-light'>
            <DownButton scroll={scrollToThisSection}/>
            <div className={mapClasses(null, "container  py-10")}>
                <div className={mapClasses(null, "row")}>
                    <div
                        className={mapClasses(null, "col-12 col-md-6 col-lg-6 mx-auto text-center px-3 px-lg-5")}>

                        <Fade up duration={1000}>
                            <div className="card card-border border-secondary shadow-lg mb-5 h3">

                                <div className={mapClasses(null, "card-body")}>
                                    {/* <!-- Heading --> */}
                                    <div className='d-flex' style={{minHeight: '2.75em'}}>
                                        <h2 className='text-primary my-auto mx-auto font-weight-bold'>
                                            For Institutions:
                                        </h2>
                                    </div>

                                    <hr/>

                                    <div className={mapClasses(null, "d-flex my-3")}
                                         style={{minHeight: '5em'}}>
                                        <span className={'my-auto mx-auto'}>
                                              Implement your own option strategies with less manual work
                                      </span>
                                    </div>
                                    <hr/>
                                    <div className={mapClasses(null, "d-flex my-3")}
                                         style={{minHeight: '5em'}}>
                                        <span className={'my-auto mx-auto'}>
                                            Obtain new insights with  integrated analysis and full transparency
                                        </span>
                                    </div>
                                    <hr/>
                                    <div className={mapClasses(null, "d-flex my-3")}
                                         style={{minHeight: '5em'}}>
                                        <span className={'my-auto mx-auto'}>Stop spending valuable time processing data
                                        </span>
                                    </div>
                                    <hr/>
                                    <div className="d-flex my-3 justify-content-center pt-2"
                                         style={{minHeight: '5em'}}>
                                        <Link to='/institutions'>
                                            <button
                                                className='btn btn-primary align-self-center lift'>Institutions - Learn
                                                More
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                    </div>
                    <div
                        className={mapClasses(null, "col-12 col-md-6 col-lg-6 mx-auto text-center px-3 px-lg-5")}>

                        <Fade up duration={1000} delay={200}>

                            <div
                                // className={mapClasses(null, "card card-border border-secondary shadow-lg mb-5 h3")}>
                                className={mapClasses(null, "card card-border border-secondary shadow-lg mb-5 h3")}>

                                <div className={mapClasses(null, "card-body")}>

                                    <div className='d-flex' style={{minHeight: '2.75em'}}>
                                        <h2 className='text-primary my-auto mx-auto font-weight-bold'>
                                            For Advisors:
                                        </h2>
                                    </div>

                                    <hr/>

                                    {/* <!-- Text --> */}
                                    <div className={mapClasses(null, "d-flex my-3")}
                                         style={{minHeight: '5em'}}>
                                        <span className={'my-auto mx-auto'}>
                                            Grow your business using differentiated investment solutions
                                        </span>
                                    </div>
                                    <hr/>
                                    <div className={mapClasses(null, "d-flex my-3")}
                                         style={{minHeight: '5em'}}>
                                        <span className={'my-auto mx-auto'}>
                                            Diversify client portfolios with strategies for income and protection
                                        </span>
                                    </div>
                                    <hr/>
                                    <div className={mapClasses(null, "d-flex my-3")}
                                         style={{minHeight: '5em'}}>
                                        <span className={'my-auto mx-auto'}>
                                            Use listed options without the manual burden
                                        </span>
                                    </div>
                                    <hr/>
                                    <div className="d-flex my-3 justify-content-center  pt-2"
                                         style={{minHeight: '5em'}}>
                                        <Link to='/advisors'>
                                            <button
                                                className='btn btn-primary align-self-center lift'>Advisors - Learn
                                                More
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
                {/*                    <div className={mapClasses(null, "row justify-content-center my-6")}>*/}
                {/*                        <Link to={'/pricing'}>*/}
                {/*                            <button className={mapClasses(null, 'btn btn-success lift')} align='center'*/}
                {/*                                    onClick={(event) => {*/}
                {/*                                        // this.props.scrollOnClick(event)*/}
                {/*//                                        mixpanel.track('View Pricing Target Audience Clicked')*/}
                {/*                                    }}>View Pricing*/}
                {/*                            </button>*/}
                {/*                        </Link>*/}
                {/*                    </div>*/}
            </div>
        </section>
    )
}

function Homepage(props) {
    let aNewWayRef = React.createRef()
    let pricingRef = React.createRef()
    let blogRef = React.createRef()
    let valuePropRef = React.createRef()

    const scrollToBlog = () => {
        blogRef.current.scrollIntoView({behavior: 'smooth'})
    }

    const scrollToANewWay = () => {
        aNewWayRef.current.scrollIntoView({behavior: 'smooth'})
    }

    const scrollToPricing = () => {
        pricingRef.current.scrollIntoView({behavior: 'smooth'})
    }

    setLKBody()

    return (
        <ParallaxProvider>
            <NavBar {...props}/>
            <RequestDemoModal/>
            <HeroSection {...props} handleLearnMoreClicked={scrollToANewWay}/>
            {/*<DownButton scroll={scrollToANewWay}/>*/}

            {/*<a id={'learnMore'}></a>*/}
            {/*<ANewWayOfInvesting sectionRef={aNewWayRef}/>*/}
            <TargetAudienceSection {...props} scrollOnClick={scrollToPricing}/>

            <AutomatedWorkflowSection {...props} scrollOnClick={scrollToPricing}/>

            {/*<PricingSection sectionRef={pricingRef}/>*/}
            {/*<SignupFormSection {...props}/>*/}
            <Footer {...props}/>
        </ParallaxProvider>
    )
}

export default Homepage

