import * as keys from "../keys"
import AuthorizationManager from "./AuthorizationManager"
import StockHistoryManager from "./StockHistoryManager"
import IndicatorStatsManager from "./IndicatorStatsManager"
import AccountStrategyAllocationsManager from "./AccountStrategyAllocationsManager"
import StrategyLibraryManager from 'services/StrategyLibraryManager'
import StrategyTSManager from 'services/StrategyTSManager'
import IndicatorTSManager from 'services/IndicatorTSManager'
import AccountHoldingsHistoryManager from 'services/account-holdings/AccountHoldingsHistoryManager'
import InvestmentAccountsManager from 'services/InvestmentAccountsManager'

export var globalAuthManager = null
export var globalServices = null

export function initGlobalAuthManager(tokenUrl, refreshUrl, setLoggedInState) {
    globalAuthManager = new AuthorizationManager(tokenUrl, refreshUrl, setLoggedInState)
    return globalAuthManager
}


export default class GlobalServices {
    constructor() {
        this.authManager = null
        this.indicatorStatsManager = null
        this.indicatorTSManager = null
        this.strategyLibrary = null
        this.strategyTSManager = null
        this.stockHistoryManager = null
        this.holdingsHistoryManager = null
        this.strategyAllocationsManager = null
    }
}

export function initGlobalServices(authManager) {
    globalServices = new GlobalServices()

    globalServices.authManager = authManager
    globalServices.strategyLibrary = new StrategyLibraryManager(keys.API_ENDPOINT.USER_STRATEGIES, authManager)
    globalServices.strategyTSManager = new StrategyTSManager(keys.API_ENDPOINT.STRATEGY_TIMESERIES_LIST, authManager)
    globalServices.stockHistoryManager = new StockHistoryManager(keys.API_ENDPOINT.STOCK_HISTORY_LIST, authManager)
    globalServices.indicatorTSManager = new IndicatorTSManager(keys.API_ENDPOINT.INDICATOR_TIMESERIES_LIST, authManager)
    globalServices.holdingsHistoryManager = new AccountHoldingsHistoryManager(keys.API_ENDPOINT.ACCOUNT_HOLDINGS_HISTORIES, authManager)
    globalServices.strategyAllocationsManager = new AccountStrategyAllocationsManager(keys.API_ENDPOINT.ACCOUNT_STRATEGY_ALLOCATIONS, authManager)
    globalServices.indicatorStatsManager = new IndicatorStatsManager(keys.API_ENDPOINT.INDICATOR_STATS_LIST, authManager)
    globalServices.investmentAccountsManager = new InvestmentAccountsManager(keys.API_ENDPOINT.USER_INVESTMENT_ACCOUNTS, authManager)

    return globalServices
}