import React, {useContext, useState, useEffect} from "react"

export const QuestionnaireContext = React.createContext({})

const MultipleChoiceQuestion = (props) => {
    const qContext = useContext(QuestionnaireContext)

    let defaultValue = null
    try {
        defaultValue = qContext.formFields[props.questionName].value
    } catch {
        //pass
    }

    return (
        <React.Fragment>
            <div className={'row'}>
                <div className={'col'}>
                    {props.question}
                </div>
            </div>
            <div className={'row'}>
                <div className={'col ml-6 my-3'}>
                    {
                        props.answers.map(([answerText, answerVal]) => {
                            return (
                                <div className="form-check my-2">
                                    <input className="form-check-input" type="radio" name={props.questionName}
                                           id={answerVal}
                                           value={answerVal}
                                           onChange={() => {
                                               qContext.setFieldValue(props.questionName,
                                                   {
                                                       question: props.question,
                                                       answer: answerText,
                                                       value: answerVal
                                                   })
                                           }}
                                           required={props.required}
                                           checked={defaultValue === answerVal}
                                    />
                                    <label className="form-check-label" htmlFor={answerVal}>{answerText}</label>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </React.Fragment>
    )
}

const EquityWeightQuestion = (props) => {

    const answers = [
        ['Unspecified', null],
        ['100% Equities', 1],
        ['80% Equities, 20% Bonds', .8],
        ['60% Equities, 40% Bonds', .6],
        ['50% Equities, 50% Bonds', .5],
        ['30% Equities, 70% Bonds', .3],
    ]

    const question = "Optional:  Which of the following represents the best approximate risk guideline for your assets\n" +
        "managed using Clarivor's services?"

    const questionName = 'equityWeightGuideline'

    const qContext = useContext(QuestionnaireContext)

    return (
        <MultipleChoiceQuestion question={question} answers={answers} questionName={questionName}
                                required={true}/>
    )
}

const RiskToleranceQuestion = (props) => {

    const answers = [
        ['Preservation: I want to preserve my capital', "preservation"],
        ['Blend: I want to balance growth and capital preservation taking measured risks', 'blend'],
        ['Growth: I want to grow my capital and understand that growth returns entail risks', 'growth'],
    ]

    const question = "Which of the following best represents your approach to investment risk?"

    const questionName = 'riskToleranceGuideline'

    const qContext = useContext(QuestionnaireContext)

    return (
        <MultipleChoiceQuestion question={question} answers={answers} questionName={questionName}
                                required={true}/>
    )
}

const ObjectiveGoalQuestion = (props) => {

    const answers = [
        ["I am most interested in generating total return out-performance in typical market conditions", 'returns'],
        ["I am most interested in generating income in typical market conditions", 'income'],
        ["I am most interested in protecting against significant market declines", 'protection'],
        ["I am a tactical investor seeking opportunities in various market conditions", 'opportunistic'],
    ]

    const question = "Which of the following best describes your investment goals?"

    const questionName = 'investmentObjective'

    return (
        <MultipleChoiceQuestion question={question} answers={answers} questionName={questionName}
                                required={true}/>
    )
}


const StrategyCheckBox = (props) => {
    const qContext = useContext(QuestionnaireContext)
    let checked = null

    try {
        checked = qContext.formFields.optionStrategyFamiliarity[props.name]
    } catch {
        // pass
    }

    return (
        <div className="form-check my-3">
            <input className="form-check-input" type="checkbox" name={props.name} id={props.name}
                   onChange={props.handleCheckChange}
                   checked={checked}
            />
            <label className="form-check-label" htmlFor={props.name}>{props.name}</label>
        </div>
    )
}

const OptionStrategyFamiliarityQuestion = (props) => {
    const [KVPairs, setKVPairs] = useState({})
    const qContext = useContext(QuestionnaireContext)

    const handleCheckChange = (event) => {
        let newKVPairs = JSON.parse(JSON.stringify(KVPairs))
        newKVPairs[event.target.name] = event.target.checked
        qContext.setFieldValue('optionStrategyFamiliarity', newKVPairs)
        setKVPairs(newKVPairs)
    }

    const optionStrategies = [
        "Long Options",
        "Short Options",
        "Covered Calls",
        "Protective Puts",
        "Buffers",
        "Spreads",
        "Collars",
        "VIX or VXX Options"
    ]

    // run once:
    React.useEffect(() => {
        let kvp = {}
        optionStrategies.map(os => {
            kvp[os] = false
        })
        setKVPairs(kvp)
    }, [])

    return (
        <React.Fragment>
            <div className={'row'}>
                <div className={'col'}>
                    I am familiar with the following option strategies:
                </div>
            </div>
            <div className={'row'}>
                <div className={'col ml-6 my-3'}>
                    {
                        optionStrategies.map(name => (
                            <StrategyCheckBox name={name} handleCheckChange={handleCheckChange}/>
                        ))
                    }
                </div>
            </div>
        </React.Fragment>
    )
}

function FinancialSituationQuestionsIndividual(props) {
    const qContext = useContext(QuestionnaireContext)

    const accreditedInvestorURL = 'https://www.investor.gov/introduction-investing/general-resources/news-alerts/alerts-bulletins/investor-bulletins/updated-3'
    const accreditedInvestorLink = <a href={accreditedInvestorURL} target={'_blank'}>accredited investor</a>

    const [isAccredited, setIsAccredited] = useState(true)
    const [noObligations, setNoObligations] = useState(true)


    useEffect(() => {
        const accredited = qContext.defaultFormFields.isAccredited == null ? true : qContext.defaultFormFields.isAccredited
        setIsAccredited(accredited)

        const noObs = qContext.defaultFormFields.noShortOrMediumTermObligations == null ? true : qContext.defaultFormFields.noShortOrMediumTermObligations
        setNoObligations(noObs)
    }, [qContext.defaultFormFields.noShortOrMediumTermObligations, qContext.defaultFormFields.noShortOrMediumTermObligations])

    React.useEffect(() => {
            qContext.setFieldValue('isAccredited', true)
            qContext.setFieldValue('noShortOrMediumTermObligations', true)
        }, []
    )

    const handleIsAccreditedChange = (event) => {
        const boolVal = event.target.checked
        setIsAccredited(boolVal)
        qContext.setFieldValue('isAccredited', boolVal)
    }

    const handleObligationsChange = (event) => {
        const boolVal = event.target.checked
        setNoObligations(boolVal)
        qContext.setFieldValue('noShortOrMediumTermObligations', boolVal)
    }

    const handleFloatChange = (event) => {
        const value = parseFloat(event.target.value)
        qContext.setFieldValue(event.target.name, value)
    }

    const handleTextChange = (event) => {
        qContext.setFieldValue(event.target.name, event.target.value)
    }

    return (
        <React.Fragment>
            <div className="form-check my-6">
                <input type="checkbox" className="form-check-input mt-2" name="isAccredited" checked={isAccredited}
                       onChange={handleIsAccreditedChange}/>
                <label className="form-check-label ml-4" htmlFor="exampleCheck1">I meet the
                    qualifications of an {accreditedInvestorLink}
                </label>
            </div>
            <hr/>
            {
                isAccredited ? null :
                    <React.Fragment>
                        <div className="form-label-group">
                            <input type="number" className="form-control form-control-flush" name="netWorth"
                                   id={'netWorth'}
                                   placeholder="Net Worth" required={true} onChange={handleFloatChange}
                                   value={qContext.formFields.netWorth}
                            />
                            <label htmlFor="netWorth">Please enter household net worth (USD)</label>
                        </div>
                        <div className="form-label-group">
                            <input type="number" className="form-control form-control-flush" name="householdIncome"
                                   placeholder="Annual Income" required={true} onChange={handleFloatChange}
                                   value={qContext.formFields.householdIncome}
                            />
                            <label htmlFor="cardName">Please enter household annual income (USD)</label>
                        </div>
                    </React.Fragment>
            }
            <div className="form-check my-6">
                <input type="checkbox" className="form-check-input mt-2" name="noShortOrMediumTermObligations"
                       checked={noObligations} onChange={handleObligationsChange}/>
                <label className="form-check-label ml-4" htmlFor="exampleCheck1">I have no significant short or
                    medium term
                    financial obligations that I am planning to fund with assets managed using Clarivor's
                    services.
                </label>
            </div>
            <hr/>
            {
                noObligations ? null :
                    <div className="form-label-group">
                        <textarea className="form-control form-control-flush" name="obligationsDescription"
                                  placeholder="Description" required={true} onChange={handleTextChange}
                                  value={qContext.formFields.obligationsDescription}
                        />
                        <label htmlFor="cardName">Please describe such financial obligations</label>
                    </div>
            }
            <div className="form-label-group my-6">
                <input type="text" className="form-control form-control-flush" name="dob" id="dob"
                       placeholder="DOB" onChange={handleTextChange} required={true}
                       value={qContext.formFields.dob}/>
                <label htmlFor="dob">Please enter your date of birth</label>
            </div>
        </React.Fragment>
    )
}


function FinancialSituationQuestionsInstitution(props) {
    const qContext = useContext(QuestionnaireContext)

    const accreditedInvestorURL = 'https://www.investor.gov/introduction-investing/general-resources/news-alerts/alerts-bulletins/investor-bulletins/updated-3'
    const accreditedInvestorLink = <a href={accreditedInvestorURL} target={'_blank'}>accredited investor</a>

    const [isAccredited, setIsAccredited] = useState(true)
    const [noObligations, setNoObligations] = useState(true)


    useEffect(() => {
        const accredited = qContext.defaultFormFields.isAccredited == null ? true : qContext.defaultFormFields.isAccredited
        setIsAccredited(accredited)

        const noObs = qContext.defaultFormFields.noShortOrMediumTermObligations == null ? true : qContext.defaultFormFields.noShortOrMediumTermObligations
        setNoObligations(noObs)
    }, [qContext.defaultFormFields.noShortOrMediumTermObligations, qContext.defaultFormFields.noShortOrMediumTermObligations])

    React.useEffect(() => {
            qContext.setFieldValue('isAccredited', true)
            qContext.setFieldValue('noShortOrMediumTermObligations', true)
        }, []
    )

    const handleIsAccreditedChange = (event) => {
        const boolVal = event.target.checked
        setIsAccredited(boolVal)
        qContext.setFieldValue('isAccredited', boolVal)
    }

    const handleObligationsChange = (event) => {
        const boolVal = event.target.checked
        setNoObligations(boolVal)
        qContext.setFieldValue('noShortOrMediumTermObligations', boolVal)
    }

    const handleFloatChange = (event) => {
        const value = parseFloat(event.target.value)
        qContext.setFieldValue(event.target.name, value)
    }

    const handleTextChange = (event) => {
        qContext.setFieldValue(event.target.name, event.target.value)
    }

    return (
        <React.Fragment>
            <div className="form-check my-6">
                <input type="checkbox" className="form-check-input mt-2" name="isAccredited" checked={isAccredited}
                       onChange={handleIsAccreditedChange}/>
                <label className="form-check-label ml-4" htmlFor="exampleCheck1">{qContext.clientInfo.company} meets the
                    qualifications of an {accreditedInvestorLink}
                </label>
            </div>
            <hr/>
            {
                isAccredited ? null :
                    <React.Fragment>
                        <div className="form-label-group">
                            <input type="number" className="form-control form-control-flush" name="netWorth"
                                   id={'netWorth'}
                                   placeholder="Net Worth" required={true} onChange={handleFloatChange}
                                   value={qContext.formFields.netWorth}
                            />
                            <label htmlFor="netWorth">Please enter the USD value of {qContext.clientInfo.company}'s
                                invested
                                assets</label>
                        </div>
                    </React.Fragment>
            }
            <div className="form-check my-6">
                <input type="checkbox" className="form-check-input mt-2" name="noShortOrMediumTermObligations"
                       checked={noObligations} onChange={handleObligationsChange}/>
                <label className="form-check-label ml-4" htmlFor="exampleCheck1">{qContext.clientInfo.company} has no
                    significant short or
                    medium term financial obligations that it is planning to fund with assets managed using Clarivor's
                    services.
                </label>
            </div>
            <hr/>
            {
                noObligations ? null :
                    <div className="form-label-group">
                        <textarea className="form-control form-control-flush" name="obligationsDescription"
                                  placeholder="Description" required={true} onChange={handleTextChange}
                                  value={qContext.formFields.obligationsDescription}
                        />
                        <label htmlFor="cardName">Please describe such financial obligations</label>
                    </div>
            }
        </React.Fragment>
    )
}


function AdviserOptionKnowledgeQuestion(props) {
    const qContext = useContext(QuestionnaireContext)

    const [answerValues, setAnswerValues] = useState({})

    const checkBoxQuestions = [
        {key: 'knowsLongOptions', question: 'Long Calls and Puts:   Investor can lose entire option premium paid'},
        {
            key: 'knowsShortPuts',
            question: 'Short Puts:  Investor can lose value of underlying stock, depending on the strike'
        },
        {key: 'knowsShortCalls', question: 'Short Calls:  Investor can lose unlimited amount (unless covered)'},
    ]

    const handleCheckChanged = (event) => {
        const key = event.target.name
        const boolVal = event.target.checked
        let d = answerValues
        d[key] = boolVal

        // set local state and context
        setAnswerValues(d)
        qContext.setFieldValue(key, boolVal)
    }

    return (
        <React.Fragment>
            <div>
                I am familiar with the following option strategies and risks:
            </div>
            {
                checkBoxQuestions.map(cbq => {
                        let checked = qContext.formFields[cbq.key] ? qContext.formFields[cbq.key].value : false
                        return (<div className="form-check my-6 ml-6">
                            <input type="checkbox" className="form-check-input mt-2" name={cbq.key}
                                   checked={checked}
                                   onChange={(event) =>
                                       qContext.setFieldValue(cbq.key, {
                                           question: cbq.question,
                                           value: event.target.checked
                                       })}/>
                            <label className="form-check-label ml-4" htmlFor={cbq.key}>{cbq.question}</label>
                        </div>)
                    }
                )
            }
        </React.Fragment>
    )
}


function AdviserOptionApprovalQuestions(props) {
    const qContext = useContext(QuestionnaireContext)

    const answers = [
        ['Yes', 'yes'],
        ['Yes, with restrictions on the types of option trading', 'yesWithRestrictions'],
        ['No', 'no'],
    ]

    const question = "Does your firm currently have approval from a custodian to trade options in client accounts?"

    const questionName = 'hasOptionApproval'

    const showCustodianInput = qContext.formFields != null && qContext.formFields[questionName] != null &&
        qContext.formFields[questionName].value !== 'no'

    const showRestrictionInput = qContext.formFields != null && qContext.formFields[questionName] != null &&
        qContext.formFields[questionName].value === 'yesWithRestrictions'

    return (
        <div className='mb-8'>
            <MultipleChoiceQuestion question={question} answers={answers} questionName={questionName}
                                    required={true}/>

            {
                showCustodianInput ? <OptionApprovalCustodianNameQuestion/> : null
            }
            {
                showRestrictionInput ? <OptionApprovalRestrictionDescription/> : null
            }
        </div>
    )
}

function OptionApprovalCustodianNameQuestion(props) {
    const qContext = useContext(QuestionnaireContext)

    const questionName = 'optionApprovalCustodianName'
    const question = 'Approving Custodian Name'

    function handleChange(event) {
        qContext.setFieldValue(questionName, {
            question: question,
            answer: event.target.value,
            value: event.target.value
        })
    }

    let defaultValue = ""
    if (qContext.formFields[questionName]) {
        defaultValue = qContext.formFields[questionName].value
    }

    return (<div className="form-label-group ml-6">
            <input type="text" className="form-control form-control-flush" name={questionName}
                   placeholder={question} onChange={handleChange} required={true}
                   defaultValue={defaultValue}
            />

            <label>Approving Custodian Name</label>
        </div>
    )
}


function OptionApprovalRestrictionDescription(props) {
    const qContext = useContext(QuestionnaireContext)

    const questionName = 'optionApprovalRestriction'
    const question = 'Describe Option Trading Restrictions'

    function handleChange(event) {
        qContext.setFieldValue(questionName, {
            question: question,
            answer: event.target.value,
            value: event.target.value
        })
    }

    let defaultValue = ""
    if (qContext.formFields[questionName]) {
        defaultValue = qContext.formFields[questionName].value
    }

    return (<div className="form-label-group ml-6">
            <input type="text" className="form-control form-control-flush" name={questionName}
                   placeholder={question} onChange={handleChange} required={true}
                   defaultValue={defaultValue}
            />

            <label>Describe Option Trading Restrictions</label>
        </div>
    )
}

export function IndividualQuestionnaireForm(props) {
    return (
        <div className={'card'}>
            <div className={'card-body text-left'}>
                <form onSubmit={props.submit}>
                    <FinancialSituationQuestionsIndividual/>
                    <RiskToleranceQuestion/>
                    <hr/>
                    <EquityWeightQuestion/>
                    <hr/>
                    <ObjectiveGoalQuestion/>
                    <hr/>
                    <OptionStrategyFamiliarityQuestion/>
                    <hr/>
                    <div className="mt-6">
                        <button type="submit" className="btn btn-block btn-success lift">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export function InstitutionQuestionnaireForm(props) {
    return (
        <div className={'card'}>
            <div className={'card-body text-left'}>
                <form onSubmit={props.submit}>
                    <FinancialSituationQuestionsInstitution/>
                    <RiskToleranceQuestion/>
                    <hr/>
                    <EquityWeightQuestion/>
                    <hr/>
                    <ObjectiveGoalQuestion/>
                    <hr/>
                    <OptionStrategyFamiliarityQuestion/>
                    <hr/>
                    <div className="mt-6">
                        <button type="submit" className="btn btn-block btn-success lift">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export function AdviserQuestionnaireForm(props) {

    return (
        <div className={'card'}>
            <div className={'card-body text-left'}>
                <form onSubmit={props.submit}>
                    <AdviserOptionApprovalQuestions/>
                    <AdviserOptionKnowledgeQuestion/>
                    <div className="mt-6">
                        <button type="submit" className="btn btn-block btn-success lift">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    )
}
