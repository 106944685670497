import axios from 'axios'
import {apiGet} from "./api-call-helpers";
import * as keys from 'keys'

export default class StrategyLibraryManager {
    constructor(api_url, auth) {
        this.url = api_url
        this.auth = auth
        this.userStrategies = null
        this.indexStrategyId = null
    }

    setIndices() {
        let n = this.userStrategies.length;
        let dict1 = {};
        let dict2 = {};
        for (let i = 0; i < n; i++) {
            dict1[this.userStrategies[i].strategy_instance_id] = i;
            dict2[this.userStrategies[i].id] = i;
        }
        this.indexStrategyId = dict1
        this.indexUserStrategyId = dict2
    }

    refreshFromServer(callback, authManager) {
        const config = {
            headers: {'Authorization': "Bearer " + authManager.accessToken}
        };
        axios.get(this.url, config)
            .then(res => {
                    this.userStrategies = res.data

                    this.setIndices()

                    callback(this)
                }
            );
    }

    async retrieveUserStrategiesAsync(authManager, userSpecific = false) {
        const params = {
            user_specific_only: userSpecific
        }

        const config = {
            headers: {'Authorization': "Bearer " + authManager.accessToken}
        };

        try {
            const res = await apiGet(this.url, params, authManager)
            // const res = await axios.get(this.url, config)

            if (userSpecific) {
                const userIdsReceived = Array.from(new Set(res.data.map(us => us.user)))
                this.userStrategies = this.userStrategies.filter(us => !userIdsReceived.includes(us.user))
                this.userStrategies.push(...res.data)
            } else {
                this.userStrategies = res.data
            }
            this.setIndices()

        } catch (err) {
            console.error(err)
        }

        return this.userStrategies
    }


    getStrategyName(strategy_id) {
        if (strategy_id == 0 || (typeof (strategy_id) == 'string' && strategy_id.toUpperCase() == 'EXCESS')) {
            return 'Excess'
        }

        let i = this.indexStrategyId[strategy_id]

        return this.userStrategies[i].name
    }

    getUserStrategyName(uSuuid) {
        let i = this.indexUserStrategyId[uSuuid]
        return this.userStrategies[i].name
    }

    getUserStrategyObjByUUID(uuid) {
        let i = this.indexUserStrategyId[uuid]
        return this.userStrategies[i]
    }

    getUserStrategyObjByInstanceId(id) {
        let i = this.indexStrategyId[id]
        return this.userStrategies[i]
    }

    getUserStrategiesByClass(className) {
        let results = []

        for (let i = 0; i < this.userStrategies.length; i++) {
            if (this.userStrategies[i].class_name == className) {
                results.push(this.userStrategies[i])
            }
        }
        return results
    }

    getStrategyClasses(userStrategies = null) {
        let list = []

        let strategies = userStrategies
        if (strategies === null) {
            strategies = this.userStrategies
        }
        for (let i = 0; i < strategies.length; i++) {
            list.push(strategies[i].class_name)
        }
        return Array.from(new Set(list))
    }

    static isSameStrategy(className, params, userStrategy) {
        let found = false
        let paramsCopy = JSON.parse(JSON.stringify(params))

        if (className === userStrategy.class_name) {
            switch (className) {
                case 'SingleOptionLegStrategy':
                    if (paramsCopy.first_trade_date != null &&
                        paramsCopy.first_trade_date != 'default' &&
                        paramsCopy.first_trade_date != userStrategy.params.first_trade_date) {
                        return false
                    }
                    if (paramsCopy.roll_days_before_expiration == null) {
                        paramsCopy.roll_days_before_expiration = 7  //  just in case.
                    }
                    if (paramsCopy.ticker === userStrategy.params.ticker &&
                        paramsCopy.call_put === userStrategy.params.call_put &&
                        paramsCopy.strike === userStrategy.params.strike &&
                        paramsCopy.tenor_mo === userStrategy.params.tenor_mo &&
                        paramsCopy.roll_days_before_expiration === userStrategy.params.roll_days_before_expiration) {
                        return true
                    } else return false
                case 'OptionSpreadStrategy':
                    if (paramsCopy.first_trade_date != null &&
                        paramsCopy.first_trade_date != 'default' &&
                        paramsCopy.first_trade_date != userStrategy.params.first_trade_date) {
                        return false
                    }
                    if (paramsCopy.roll_days_before_expiration == null) {
                        paramsCopy.roll_days_before_expiration = 7  //  just in case.
                    }
                    if (paramsCopy.ticker === userStrategy.params.ticker &&
                        paramsCopy.call_put === userStrategy.params.call_put &&
                        paramsCopy.tenor_mo === userStrategy.params.tenor_mo &&
                        paramsCopy.premium_target === userStrategy.params.premium_target &&
                        paramsCopy.max_payoff === userStrategy.params.max_payoff &&
                        paramsCopy.roll_days_before_expiration === userStrategy.params.roll_days_before_expiration) {
                        return true
                    } else return false
                case 'EquityBasketStrategy': {
                    if (params.tickers.length === userStrategy.params.tickers.length) {
                        let sortedTickers1 = params.tickers
                        sortedTickers1.sort()
                        let sortedTickers2 = userStrategy.params.tickers
                        sortedTickers2.sort()

                        for (let i = 0; i < sortedTickers1.length; i++) {
                            if (sortedTickers1[i] != sortedTickers2[i]) {
                                return false
                            }
                        }
                        return true
                    } else {
                        return false
                    }
                }
                case 'SellCallsStrategy':
                case 'BuyPutsStrategy':
                    if (params.ticker == userStrategy.params.ticker &&
                        params.strike == userStrategy.params.strike &&
                        params.tenor_mo == userStrategy.params.tenor_mo) {
                        return true
                    } else return false
                case 'VanillaBufferCashOverlayStrategy':
                    // If we add different short & long strikes, need to fix StrategySelector to be like PSC.
                    if (params.ticker === userStrategy.params.ticker &&
                        parseFloat(params.buffer) === parseFloat(userStrategy.params.short_put_strike) &&
                        parseInt(params.tenor_mo) === parseInt(userStrategy.params.tenor_mo)) {
                        return true
                    } else return false
                case 'VanillaBooster2xCashOverlayStrategy':
                    if (params.ticker === userStrategy.params.ticker &&
                        parseInt(params.tenor_mo) === parseInt(userStrategy.params.tenor_mo)) {
                        return true
                    } else return false
                case 'PSCSolveForCallStrikeStrategy':
                    if (params.ticker === userStrategy.params.ticker &&
                        parseInt(params.tenor_mo) === parseInt(userStrategy.params.tenor_mo) &&
                        params.short_put_strike === userStrategy.params.short_put_strike &&
                        params.long_put_strike === userStrategy.params.long_put_strike
                    ) {
                        return true
                    } else return false
                case 'CollarSolveForCallStrikeStrategy':
                    if (params.ticker === userStrategy.params.ticker &&
                        parseInt(params.tenor_mo) === parseInt(userStrategy.params.tenor_mo) &&
                        params.long_put_strike === userStrategy.params.long_put_strike
                    ) {
                        return true
                    } else return false
            }
        }
        return false
    }

    static getUserStrategyFromParams(className, params, userStrategies) {
        const classStrategies = userStrategies.filter(us => us.class_name === className)

        let matchStrategies = classStrategies.filter(us => StrategyLibraryManager.isSameStrategy(className, params, us))

        return matchStrategies[0]
    }

    isLoaded() {
        return (this.userStrategies != null)
    }

    getUserStrategyObjByName(name) {
        const index = this.userStrategies.findIndex(elem => (elem.name === name))
        return this.userStrategies[index]
    }
}
