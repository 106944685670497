import React, {Component} from 'react';
import footerLogo from 'images/clarivor-p-500-inverted.png'
import {Link} from 'react-router-dom'
import {AppContext} from "../../contexts";
import {mixpanel} from "../../../services/mixpanel"
import {createCookie, readCookie} from "util/cookies";

// import lk from 'lk/dist/assets/css/theme.module.css'
// import {mapClasses} from 'util/cssModule'


function acceptedCookieMessage() {
    createCookie('accepted-cookie-message', 'yes', 90, '/');
}

export default class CookieConsentAlert extends Component {
    state = {
        accepted: false
    }

    constructor(props) {
        super(props)

        let cookie = readCookie('accepted-cookie-message');
        if (cookie != null && cookie === 'yes') {
            this.state.accepted = true
        }
    }


    handleClick = () => {
        mixpanel.track('CookieConsent clicked')
        acceptedCookieMessage()
    }

    render() {
        return (
            this.state.accepted ? null :
                <div
                    className="alert alert-secondary alert-dismissible text-gray-500 fade show fixed-bottom mx-1 mx-md-6 mx-lg-12"
                    role="alert">
                    This website uses cookies for various purposes, as described <Link className='text-gray-300'
                                                                                       to={'/privacy-policy'}>here</Link>.
                    By continuing to use this website you agree to our use of cookies and our <Link
                    className='text-gray-300' to={'/privacy-policy'}>privacy policy</Link>.

                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true" onClick={this.handleClick}>×</span>
                    </button>
                </div>
        );
    }
}

