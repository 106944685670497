import React, {useState, useContext, useEffect} from "react"
import {Switch, Route, Redirect} from 'react-router-dom'
import {AccountManagementContext} from "../../contexts"
import CreateAccountPage from "./CreateAccountPage"
import ChangePasswordPage from "./ChangePasswordPage";
import UserContactInfoContainer from "./UserContactInfoContainer"
import ClientQuestionnaireContainer from "./ClientQuestionnaireContainer"
import ClientInfoSubmittedPage, {ActiveClientInfoSubmittedPage} from './ClientInfoSubmittedPage'
import {AppContext} from "../../contexts"
import {apiGet} from "../../../services/api-call-helpers"
import {globalAuthManager} from "../../../services/GlobalServices"
import * as keys from 'keys'
import AgreeToTermsPage from "./AgreeToTermsPage"


// this is the component for /account.
// If not logged in --> pricing page.
// If logged in but no client info --> client info.
// If client info but no questionnaire --> questionnaire.
// If questionnaire but not approved --> "approval in progress"
function AccountRootPage(props) {
    const appContext = useContext(AppContext)
    const [clientCreated, setClientCreated] = useState(null)
    const [questionnaireSubmitted, setQuestionnaireSubmitted] = useState(null)

    React.useEffect(() => {
        if (appContext.isLoggedIn === true) {
            window.scroll(0, 0)

            apiGet(keys.API_ENDPOINT.ADVISORY_CLIENT, {}, globalAuthManager).then(res => {
                    if (res.data != null && res.data.client_info != null) {
                        setClientCreated(true)
                    } else {
                        setClientCreated(false)
                    }
                }
            )

            apiGet(keys.API_ENDPOINT.ADVISORY_QUESTIONNAIRE, {}, globalAuthManager).then(res => {
                    if (res.data != null && res.data.questionnaire != null) {
                        setQuestionnaireSubmitted(true)
                    } else {
                        setQuestionnaireSubmitted(false)
                    }
                }
            )
        }
    }, [appContext.isLoggedIn])

    if (appContext.isLoggedIn == null || appContext.agreedToTerms == null || appContext.approved == null) {
        return null
    } else if (appContext.isLoggedIn === false) {
        return <Redirect to={'/'}/>
    } else if (clientCreated === false) {
        return <UserContactInfoContainer/>
    } else if (questionnaireSubmitted === false) {
        return <ClientQuestionnaireContainer/>
    } else if (appContext.agreedToTerms === false) {
        return <AgreeToTermsPage/>
    } else if (appContext.approved === false) {
        return <ClientInfoSubmittedPage/>
    } else if (clientCreated && questionnaireSubmitted) {
        return <UserContactInfoContainer/>
    } else {
        return null
    }
}

export default function AccountManagementContainer(props) {
    const appContext = useContext(AppContext)
    const [signupState, dispatch] = React.useReducer(setField, {})
    const [profileProduct, setProfileProduct] = useState(null)

    // retrieve the assigned product from the profile.
    useEffect(() => {
        apiGet(keys.API_ENDPOINT.USER_PROFILE, {}, globalAuthManager).then(res => {
                if (res.data != null) {
                    setProfileProduct(res.data.product)
                }
            }
        )
    }, [appContext.isLoggedIn])

    let product = profileProduct
    try {
        if (product == null && props.location.state != null && props.location.state.product != null) {
            product = props.location.state.product
        } else if (props.location.pathname.includes('create/basic')) {
            product = keys.PRODUCT_IDS.basicTool
        }
    } catch (err) {
        window.alert('Error finding product')
    }

    function setField(kvPairs, kvPair) {
        const [key, value] = Object.entries(kvPair)[0]
        let newKVPairs = JSON.parse(JSON.stringify(kvPairs))
        newKVPairs[key] = value
        return newKVPairs
    }

    const newAccountSignedIn = () => {
        const path = props.match.path + "/client-info"
        props.history.push({pathname: path, state: {product: product}})
    }

    const newAccountContactInfoSubmitted = (isAdvisor) => {
        let path = null
        if (product === keys.PRODUCT_IDS.basicTool) {
            path = props.match.path + "/approval-pending"
        } else {
            path = props.match.path + "/client-questionnaire"
        }
        props.history.push({pathname: path, state: {product: product, isAdvisor: isAdvisor},})
    }

    const newQuestionnaireSubmitted = () => {
        // const path = props.match.path + "/agree-to-terms"
        const path = props.match.path + "/questionnaire-submitted"
        props.history.push({pathname: path})
    }

    const passContext = {
        product: product,
        signupState: signupState,
        setSignupKVPair: dispatch,
        newAccountSignedIn: newAccountSignedIn,
        newAccountContactInfoSubmitted: newAccountContactInfoSubmitted,
        newQuestionnaireSubmitted: newQuestionnaireSubmitted,
        history: props.history
    }

    return (
        <AccountManagementContext.Provider value={passContext}>
            <Switch>
                <Route
                    exact path={props.match.path + "/create/basic"}
                    render={(props) => (
                        <CreateAccountPage basic={true}/>
                    )
                    }
                />
                <Route
                    exact path={props.match.path + "/create/"}
                    render={(props) => (
                        <CreateAccountPage/>
                    )
                    }
                />
                <Route
                    exact path={[props.match.path + "/client-info/", props.match.path + "/user-info"]}
                    render={(props) => {
                        // if (appContext.isLoggedIn === true) {
                        return <UserContactInfoContainer/>
                        // // } else {
                        // //     return <Redirect to={"create/"}/>
                        // }
                    }}
                />
                <Route
                    exact path={props.match.path + "/client-questionnaire/"}
                    render={(props) => {
                        if (appContext.isLoggedIn === true) {
                            return <ClientQuestionnaireContainer/>
                        } else {
                            return <Redirect to={"create/"}/>
                        }
                    }
                    }
                />
                <Route
                    exact path={props.match.path + "/agree-to-terms/"}
                    render={(props) => {
                        if (appContext.isLoggedIn === true) {
                            return <AgreeToTermsPage/>
                        } else {
                            return <Redirect to={"create/"}/>
                        }
                    }
                    }
                />
                <Route
                    exact path={props.match.path + "/approval-pending/"}
                    render={(props) => (
                        <ClientInfoSubmittedPage/>
                    )
                    }
                />
                <Route
                    exact path={props.match.path + "/questionnaire-submitted/"}
                    render={(props) => {
                        if (appContext.isLoggedIn === true) {
                            if (appContext.agreedToTerms) {
                                return <ActiveClientInfoSubmittedPage />
                            } else {
                                return <AgreeToTermsPage/>
                            }
                        } else {
                            return <Redirect to={"create/"}/>
                        }
                    }
                    }
                />
                <Route
                    exact path={props.match.path + "/change-password/"}
                    render={(props) => {
                        if (appContext.isLoggedIn === null) {
                            return null
                        } else if (appContext.isLoggedIn === true) {
                            return <ChangePasswordPage/>
                        } else {
                            return <Redirect to={"/"}/>
                        }
                    }
                    }
                />
                <Route
                    exact path={props.match.path}
                    render={(props) => <AccountRootPage {...props} />}
                />

            </Switch>
        </AccountManagementContext.Provider>
    )
}
