import React, {useContext, useEffect, useState} from "react"
import headshot from 'images/blog/avatar-100.jpg'
import RequestDemoModal from "../../../pages/common/RequestDemoModal"
import {globalAuthManager, globalServices} from "../../../../services/GlobalServices"
import {apiGet} from "../../../../services/api-call-helpers"
import * as keys from 'keys'
import {mixpanel} from "../../../../services/mixpanel"
import ReceiveUpdatesModal from "../../../pages/common/ReceiveUpdatesModal"
import {Link} from "react-router-dom"
import BlogSignupSection from "../../common/BlogSignupSection"
import AdvisorImage500 from "images/blog/Blog_20210525_BufferDelta/buffer_advisor_500.jpg"
import AdvisorImage from "images/blog/Blog_20210525_BufferDelta/buffer_advisor.jpg"
import ScenariosImage from "images/blog/Blog_20210525_BufferDelta/buffer_scenarios.png"
import PayoffImage from "images/blog/Blog_20210525_BufferDelta/buffer_payoff_at_expiration.png"
import AverageDeltaImage from "images/blog/Blog_20210525_BufferDelta/average_historical_delta.png"
import jonAvatar from 'images/blog/avatar-100.jpg'

const TLTOverwriteContext = React.createContext({})

const documentTitle = "Clarivor Blog: Buffer Risk"
const postTitle = 'Are you actively managing your buffers?'
const postSubtitle = 'Buffers may have very low equity exposure currently'
const postAuthor = 'Jon Spiegel, CFA'
const publishedOnText = 'May 25, 2021'
const publishedOnShort = 'May 25'

const HeroSection = (props) => (
    <section
        style={{
            backgroundImage: ["url(" + AdvisorImage + ")"],
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            position: 'relative',
            zIndex: 0,
            backgroundSize: 'cover',
            height: '50vh'
        }}>
    </section>
)

const HeaderSection = (props) => (
    <section className="pt-8 pt-md-11">
        <RequestDemoModal/>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 col-md-12 col-lg-10 col-xl-10">


                    <h1 className="display-4 text-center">
                        {props.title}
                    </h1>


                    <p className="lead mb-7 text-center text-muted">
                        {props.subtitle}
                    </p>


                    <div className="row align-items-center py-5 border-top border-bottom">
                        <div className="col-auto">


                            <div className="avatar avatar-lg">
                                <img src={props.avatarImage} alt="..."
                                     className="avatar-img rounded-circle"/>
                            </div>

                        </div>
                        <div className="col ml-n5">
                            <h6 className="text-uppercase mb-0">
                                {props.author}
                            </h6>
                        </div>
                        <div className="col-auto">
                            <time className="font-size-sm text-muted" dateTime="2019-05-20">
                                {props.publishedOnText}
                            </time>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
)


function Summary(props) {
    return (
        <React.Fragment>
            <h3 className={'font-weight-bold mt-6'}>
                Summary
            </h3>
            <ul>
                <li className='my-2'>
                    Buffered investment strategies are a popular way of accessing equity market returns with less risk.
                </li>
                <li className='my-2'>
                    However, like most structured products, the risk exposure of buffered strategies changes over time,
                    and investors may therefore want to actively manage the associated risks.
                </li>
                <li className='my-2'>
                    Currently some buffered strategies have relatively low exposure to equity markets, potentially
                    causing investors to be underweight equities in a move higher.
                </li>
            </ul>
        </React.Fragment>
    )
}


function Section1(props) {
    return (
        <React.Fragment>
            <h3 className={'font-weight-bold mt-6'}>
                Review - What is a Buffer?
            </h3>
            <p>
                Buffered investment products are a common way for investors to access equity returns with reduced risk.
                Whether packaged as an annuity, ETF or structured note, a “buffer” typically refers to a bundle of
                embedded options that are set up to:
            </p>
            <ol>
                <li>
                    Provide the positive returns of an underlying (e.g. index, ETF or stock) up to a cap, and
                </li>
                <li>Protect against the underlying’s initial losses.
                </li>
            </ol>
            <p>
                For example, a 12-month buffer on the SPY ETF may provide exposure to the first 11% of upside while
                protecting against the first 10% of downside. Below is an illustration of this hypothetical payoff.
            </p>
            <p>
                <div className='card bg-light'>
                    <div className='card-body p-2'>
                        <img src={PayoffImage} style={{width: '100%'}}/>
                    </div>
                </div>
            </p>
            <h3 className={'font-weight-bold mt-6'}>
                Risk Management Questions
            </h3>
            <p>
                The risk management of the buffer becomes more nuanced when you consider the time between trading and
                expiration. For example, if six months into the trade SPY surpasses the cap, what do you do with this
                investment for the next six months? Conversely, if SPY sells off significantly, is the portfolio's risk
                still consistent with the investor's objectives?
            </p>
            <p>
                Several factors that might influence the answer to this question are:
            </p>
            <ol>
                <li>
                    The investor portfolio's benchmark equity allocation
                </li>
                <li>
                    Whether assets to purchase the buffer came from the equity allocation or bond allocation
                </li>
                <li>
                    The current and potential market exposure ("delta") of the buffer
                </li>
            </ol>

            <h3 className={'font-weight-bold mt-6'}>
                Changing Market Exposure
            </h3>
            <p>
                A primary risk characteristic of a buffer is that it will usually have a delta less than one.
                This means that if SPY gains 1%, the buffer will usually gain less than 1%. Conversely, if the SPY loses
                1%,
                the buffer will usually lose less than 1%. For example, if the delta at a given point in time is 60%,
                the buffer will be expected to gain or lose approximately 0.6% when SPY gains or loses 1%.
            </p>
            <p>
                Another extremely important characteristic is that the delta will usually get higher
                when the underlying asset sells off, and get lower as the underlying asset rises.
                As a hypothetical example, the delta may be 60% now, but if the SPY sells off 20%, the delta may be
                expected to reach 90%. Below is an illustration of this effect.
            </p>
            <p>
                <div className='card bg-light'>
                    <div className='card-body p-2'>
                        <img src={ScenariosImage} style={{width: '100%'}}/>
                    </div>
                </div>
            </p>

            <h3 className={'font-weight-bold mt-6'}>
                Buffer Risk in the Current Market
            </h3>
            <p>
                While each buffer may have a different underlying, expiration and strikes, given the market rally over
                the past year, we’d expect that deltas are relatively low.
            </p>
            <p>
                Clarivor back-tested estimated deltas for 12-month SPY option buffers (10% buffer, solve for cap) over
                the past 10 years.
                The following chart shows the blended deltas for buffers initiated each expiration March, June,
                September and December. We find that the blended delta has ranged between 14% and 87%. As of May 24th
                the average delta was estimated at 24%.
            </p>
            <p>
                Clarivor back-tested estimated deltas for 12-month SPY option buffers (10% buffer, solve for cap) over
                the past 10 years.
                The following chart shows the blended deltas for buffers initiated each expiration March, June,
                September and December. We find that the blended delta has ranged between 14% and 87%.
            </p>
            <p>
                <div className='card bg-light'>
                    <div className='card-body p-2'>
                        <img src={AverageDeltaImage} style={{width: '100%'}}/>
                    </div>
                </div>
            </p>
            <h3 className={'font-weight-bold mt-6'}>
                Considerations for Advisors
            </h3>
            <p>
                Advisors may want to assess the risk of their buffers in terms of current and potential market exposure.
                These exposures depend on the specific buffered investments held. For example, in our backtest we find
                that the June '21 buffer currently has a delta of 4%, while the March '22 buffer, more recently rolled,
                has a buffer of 45%.
            </p>
            <p>
                If investors or advisors find that the current equity exposure from buffers are resulting in undesirably
                small equity exposure, they may wish to actively rebalance their portfolios. For example, buffers can be
                partially replaced
                with equity ETFs, or buffers can be rolled into new buffers with desired exposures.
            </p>
        </React.Fragment>
    )
}

const WrittenSection = (props) => {

    const handleToolScroll = (event) => {
        event.preventDefault()
        props.scrollToTool()
    }

    return (
        <section>
            <hr className="hr-md"/>
        </section>
    )
}


function SelectorsWrapper(props) {
    return (
        <div className='card mt-4'>
            <div className='card-header bg-gray-300 text-secondary p-4'>
                Compare Put Strategies
            </div>
            <div className='card-body bg-light p-4'>
                {props.children}
                <div className="form-check">
                    {/*<ShowSPYCheckbox/>*/}
                </div>
            </div>
        </div>
    )
}


function ChartWrapper(props) {
    return (
        <div className='card mt-4 px-0'>
            <div className='card-body border px-0'>
                {props.children}
            </div>
        </div>
    )

}

function TLTOverwriteProjectContainer(props) {
    const [projectObj, setProjectObj] = useState(null)

    useEffect(() => {
        apiGet(keys.API_ENDPOINT.PROJECTS, {id: props.projectId}, globalAuthManager).then(
            res => {
                const [project] = res.data
                setProjectObj(project)
            }
        )
    }, [])

    const contextValue = {
        projectObj: projectObj,
        projectId: props.projectId
    }

    return (
        <TLTOverwriteContext.Provider value={contextValue}>
            {props.children}
        </TLTOverwriteContext.Provider>
    )
}


function RequestDemoSection(props) {
    return (
        <section>
            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-12 col-md-8 col-lg-6'>
                        <div className='card border border-primary'>
                            <div className='card-body'>
                                <div className='row justify-content-center'>
                                    <h3 className={'text-center'}>
                                        Get in touch with Clarivor for further analysis.
                                    </h3>
                                </div>
                                <div className='row justify-content-center'>
                                    <a className='mx-auto' data-toggle="modal" href="#requestDemoModal"
                                       style={{textDecoration: 'none'}}
                                       onClick={() => {
                                           mixpanel.track('Request Demo clicked')
                                       }}>
                                        <button className="btn btn-success lift mt-2 d-flex">
                                            <span className={'my-auto'}>Request Demo</span>
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

function DisclosuresLink(props) {
    return (
        <a data-toggle="modal" href="#disclaimerModal"
           onClick={() => mixpanel.track('Disclosure Clicked')}>Disclosures</a>
    )
}

function TermsOfUseLink(props) {
    return (
        <Link to={'/terms-of-use'} target={'_blank'}
              onClick={() => mixpanel.track('Terms of Use Clicked')}>Terms of Use</Link>
    )
}

function BacktestingSourceDisclaimer(props) {
    return (
        <div>
            <hr/>
            <p className='font-italic text-muted'>
                Source: Clarivor LLC. These results are backtested and may not be indicative of future
                performance. Options are rolled seven days prior to expiration at bid or offer.
                Option prices are observed at 3:45pm NY time.
                <br/>
                <br/>
                NOTHING IN THIS POST SHOULD BE CONSTRUED AS INVESTMENT
                ADVICE. <DisclosuresLink/> and <TermsOfUseLink/> apply.
                <br/>
            </p>
        </div>
    )
}


function TLTOverwritePostBody(props) {
    return (
        <React.Fragment>
            <section className="pt-0 pt-md-0">
                <ReceiveUpdatesModal/>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-12 col-lg-10 col-xl-9">
                            <Summary/>
                            <Section1/>
                            <BacktestingSourceDisclaimer/>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}


export default function TLT_Overwriting_20210412(props) {
    const projectId = null  // this is a static blog post

    useEffect(() => {
        document.title = documentTitle
    }, [])

    const headerProps = {
        title: postTitle,
        subtitle: postSubtitle,
        avatarImage: headshot,
        author: postAuthor,
        publishedOnText: publishedOnShort,
    }

    return (
        <React.Fragment>
            <HeroSection/>
            <HeaderSection {...headerProps} />
            <TLTOverwriteProjectContainer projectId={projectId}>
                <TLTOverwritePostBody/>
            </TLTOverwriteProjectContainer>
            <BlogSignupSection fromLocation={'Blog Post TLT Overwriting'}/>
        </React.Fragment>
    )
}


export function RichBufferDelta202105(props) {
    return (
        <section className="pt-7 pt-md-10">
            <div className="container">
                <div className="row">
                    <div className="col-12">

                        <div className="card card-row shadow-light-lg mb-6 lift lift-lg">
                            <div className="row no-gutters">
                                <div className="col-12">

                            <span className="badge badge-pill badge-light badge-float badge-float-inside">
                    <span className="h6 text-uppercase">Buffers</span>
                  </span>

                                </div>
                                <Link className="col-12 col-md-6 order-md-2 bg-cover card-img-right"
                                      style={{backgroundImage: "url(" + AdvisorImage500 + ")"}}
                                      to="/blog/Blog_20210525_BufferDelta">

                                    <img src="assets/img/photos/photo-27.jpg" alt="..."
                                         className="img-fluid d-md-none invisible"/>

                                    <div
                                        className="shape shape-left shape-fluid-y svg-shim text-white d-none d-md-block">
                                        <svg viewBox="0 0 112 690" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 0h62.759v172C38.62 384 112 517 112 517v173H0V0z"
                                                  fill="currentColor"></path>
                                        </svg>
                                    </div>

                                </Link>
                                <div className="col-12 col-md-6 order-md-1">

                                    <Link className="card-body" to="/blog/Blog_20210525_BufferDelta">

                                        <h3>
                                            {postTitle}
                                        </h3>

                                        <p className="mb-0 text-muted">
                                            {postSubtitle}
                                        </p>

                                    </Link>

                                    <a className="card-meta" href="#!">

                                        <hr className="card-meta-divider"/>

                                        <div className="avatar avatar-sm mr-2">
                                            <img src={jonAvatar} alt="..."
                                                 className="avatar-img rounded-circle"/>
                                        </div>

                                        <h6 className="text-uppercase text-muted mr-2 mb-0">
                                            Jon Spiegel
                                        </h6>

                                        <p className="h6 text-uppercase text-muted mb-0 ml-auto">
                                            <time dateTime="2020-10-23">{publishedOnShort}</time>
                                        </p>

                                    </a>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>

    )
}
