import React, {useContext, useState, useEffect} from "react"
import {Link} from 'react-router-dom'
import {AccountManagementContext, AppContext} from "../../contexts"
import * as keys from 'keys'
import {apiPost} from "../../../services/api-call-helpers"
import {globalAuthManager} from "../../../services/GlobalServices"
import ContactInfoForm from "./ContactInfoForm"
import {apiGet} from "../../../services/api-call-helpers"
import {mapClasses} from "../../../util/cssModule";

async function sleep(ms) {
    return await new Promise(resolve => setTimeout(resolve, ms))
}

function ContactInfoSignInForm(props) {
    const [username, setUsername] = useState(null)
    const [password, setPassword] = useState(null)

    function handleSubmit(event) {
        event.preventDefault();
        const signedIn = globalAuthManager.loginAsync(username, password)
    }

    return (
        <div className={"card"}>

            {/*<button type="button" className={"modal-close close text-muted"}*/}
            {/*        data-dismiss="modal" aria-label="Close">*/}
            {/*    <span aria-hidden="true">×</span>*/}
            {/*</button>*/}

            <div className={"position-relative"}>
                <div className="shape shape-bottom shape-fluid-x svg-shim text-white">
                </div>
            </div>

            {/* <!-- Body --> */}
            <div className={"card-body"}>

                {/* <!-- Heading --> */}
                <h2 className={"mb-5 font-weight-bold text-center"}
                    id="modalSigninVerticalTitle">
                    Sign In
                </h2>

                {/* <!-- Text --> */}
                {/*<p className={"mb-6 text-center text-muted"}>*/}
                {/*    Sign into the beta website to start using Clarivor*/}
                {/*</p>*/}

                {/* <!-- Form --> */}
                <form className={"mb-6"}>

                    {/* <!-- Email --> */}
                    <div className={"form-group"}>
                        <label className={"sr-only"} htmlFor="modalSigninVerticalEmail">
                            Your email
                        </label>
                        <input type="email" onChange={(event) => setUsername(event.target.value)}
                               className={"form-control"}
                               id="modalSigninVerticalEmail" name='username'
                               placeholder="Enter email address"/>
                    </div>

                    {/* <!-- Password --> */}
                    <div className={"form-group mb-5"}>
                        <label className={"sr-only"}
                               htmlFor="modalSigninVerticalPassword">
                            Your password
                        </label>
                        <input type="password" onChange={(event) => setPassword(event.target.value)}
                               className={"form-control"} name='password'
                               id="modalSigninVerticalPassword" placeholder="Enter password"/>
                    </div>

                    {/* <!-- Submit --> */}
                    <button className={"btn btn-block btn-primary"}
                            data-dismiss="modal" onClick={handleSubmit}>
                        Sign in
                    </button>

                </form>

                {/*<p className={"mb-0 font-size-sm text-center text-muted"}>*/}
                {/*    Don't have an account yet? <a href="/pricing">Sign up</a>.*/}
                {/*</p>*/}

            </div>
        </div>
    )
}


const NewClientPrompt = props => (
    <React.Fragment>
        <h2 className="font-weight-bold text-dark">
            New Client Setup
        </h2>

        <p className="font-size-lg mb-7 mb-md-9 text-muted">
            We need the following information to set up your account.<br/>
            We will never sell your information.
            For more details, please see our <Link className='font-weight-bold' target={'_blank'}
                                                   to={'/privacy-policy'}>Privacy
            Policy.</Link>
        </p>
    </React.Fragment>
)

const EditClientPrompt = props => (
    <React.Fragment>
        <h2 className="font-weight-bold text-dark">
            Contact Information
        </h2>

        <p className="font-size-lg mb-7 mb-md-9 text-muted">
            We will never sell your information.
            For more details, please see our <Link className='font-weight-bold' target={'_blank'}
                                                   to={'/privacy-policy'}>Privacy
            Policy.</Link>
        </p>
    </React.Fragment>
)


export default function UserContactInfoContainer(props) {
    const appContext = useContext(AppContext)
    const accountMgmtContext = useContext(AccountManagementContext)
    const [errorMessage, setErrorMessage] = useState(null)
    const [formFields, setFormFields] = useState({})
    const [defaultFormFields, setDefaultFormFields] = useState({})

    useEffect(() => {
        if (appContext.isLoggedIn === true) {
            window.scroll(0, 0)

            if (accountMgmtContext.product === keys.PRODUCT_IDS.basicTool) {
                apiGet(keys.API_ENDPOINT.NON_ADVISOR_USER_INFO, {}, globalAuthManager).then(res => {
                        if (res.data != null) {
                            setDefaultFormFields(res.data)
                            setFormFields(res.data)
                        }
                    }
                )
            } else {
                apiGet(keys.API_ENDPOINT.ADVISORY_CLIENT, {}, globalAuthManager).then(res => {
                        if (res.data.client_info != null) {
                            setDefaultFormFields(res.data.client_info)
                            setFormFields(res.data.client_info)
                        }
                    }
                )
            }
        }
    }, [appContext.isLoggedIn])

    const setFieldValue = (k, v) => {
        let newFormFields = JSON.parse(JSON.stringify(formFields))
        newFormFields[k] = v

        if (k === 'clientType' && v === 'individual') {
            newFormFields['company'] = ''
        }

        setFormFields(newFormFields)
    }

    const handleSubmit = async (event) => {
        event.preventDefault()

        let mi_str = ''
        let name = formFields.company
        if (formFields.clientType == keys.CLIENT_TYPES.INDIVIDUAL) {
            mi_str = formFields.middleInitial == null ? ' ' : ' ' + formFields.middleInitial + ' '
            name = formFields.firstName + mi_str + formFields.lastName
        }

        let submitted = false
        try {
            if (accountMgmtContext.product === keys.PRODUCT_IDS.basicTool) {
                await apiPost(keys.API_ENDPOINT.NON_ADVISOR_USER_INFO, {
                    user_info: formFields,
                    agreed_to_terms: formFields.termsOfUseCheck ? true : false   // actually form can't be submitted if not checked.
                }, globalAuthManager)
            } else {
                await apiPost(keys.API_ENDPOINT.ADVISORY_CLIENT, {
                    name: name,
                    client_info: formFields
                }, globalAuthManager)
            }
            submitted = true
        } catch (error) {
            if (error.response == null) {
                setErrorMessage('Could not submit.  Connectivity issue.')
            } else if (error.response.data.message) {
                setErrorMessage(error.response.data.message)
            } else {
                setErrorMessage(`Could not submit.  Error code: ${error.response.status}.`)
            }
        }

        if (submitted) {
            const isAdvisor = formFields.clientType.toLowerCase() === keys.CLIENT_TYPES.ADVISER.toLowerCase()
            accountMgmtContext.newAccountContactInfoSubmitted(isAdvisor)
        }
    }

    return (
        <section className="pt-8 pt-md-11 pb-8 pb-md-14 bg-light">
            <div className="container" style={{minHeight: '50vh'}}>
                <div className='row justify-content-center my-5'>
                    {
                        appContext.isLoggedIn == null ? null :
                            appContext.isLoggedIn === false ?
                                <div className='col-10 col-md-8 col-lg-6 text-center'>
                                    <ContactInfoSignInForm/>
                                </div>
                                :

                                <div className='col-12 col-md-10 text-center'>
                                    {
                                        defaultFormFields === {} ? <NewClientPrompt/> : <EditClientPrompt/>
                                    }
                                    <ContactInfoForm setFieldValue={setFieldValue} submit={handleSubmit}
                                                     errorMessage={errorMessage}
                                                     defaultFormFields={defaultFormFields}
                                                     formFields={formFields}
                                    />
                                </div>
                    }
                </div>
            </div>
        </section>
    )
}