import React, {Component, useContext, useState} from 'react'
import brandLogo from 'images/clarivor-p-500.png'
import {Link} from 'react-router-dom'
import {AppContext} from "../contexts"
import SignInModal from "./SignInModal"
import {globalAuthManager} from "../../services/GlobalServices"
import {mapClasses} from "../../util/cssModule"
import {X} from "react-feather"

const navBarStyle = {
    paddingRight: '1rem',
    paddingLeft: '1rem',
    paddingTop: '1.25rem',
    paddingBottom: '1.25rem',
}

const navBarBrandStyle = {
    paddingTop: '.15rem',
    paddingBottom: '.15rem',
    marginRight: '0',
    fontFamily: 'HKGroteskPro',
    fontSize: '1.0625rem',
}

const navBarButtonStyle = {
    paddingTop: '0.5625rem',
    paddingRight: '1rem',
    paddingBottom: '0.5625rem',
    paddingLeft: '1rem',
    fontWeight: '600',
    fontFamily: 'HKGroteskPro',
    fontSize: '1.0625rem',
}

const navBarToggleStyle = {
    color: '#506690',
    borderColor: 'transparent'
}

class SignInButton extends Component {

    render() {

        return (
            <button type='button' className="navbar-btn btn btn-sm btn-outline-dark lift ml-auto"
                    data-toggle="modal"
                    data-target="#modalSigninVertical"
                    style={navBarButtonStyle}>
                Sign in
            </button>
        )
    }

}

function SignOutButton(props) {
    const appContext = React.useContext(AppContext)

    const logout = () => {
        const sourcePath = props.location ? props.location.pathname : ''
        globalAuthManager.logout()
    }

    return (
        <button type='button' className="navbar-btn btn btn-sm btn-light ml-auto"
                style={navBarButtonStyle}
                onClick={logout}
                data-toggle="tooltip"
                data-placement="top"
                title={"Sign out " + appContext.username}
        >
            Sign out
        </button>
    )
}


class UserOrSignInButton extends Component {

    render() {
        switch (this.props.isLoggedIn) {
            case false:
                return <SignInButton {...this.props} />
            case true:
                return <SignOutButton {...this.props} />
            default:
                return (
                    <button type='button'
                            className="navbar-btn btn btn-sm btn-light ml-auto text-light"
                            style={navBarButtonStyle}>
                        Waiting
                    </button>
                )
        }
    }
}

const lkNavLinkStyle = {
    margin: '0px',
    fontFamily: 'HKGroteskPro',
    fontSize: '1.0625rem',
    lineHeight: '1.6',
    textAlign: 'left',
    backgroundColor: 'transparent',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    fontWeight: '600',
}

function NavBar(props) {

    return (
        <nav className="navbar navbar-expand-md  navbar-light bg-white">

            {/* Toggler  */}
            <button className="navbar-toggler" type="button" data-toggle="collapse"
                    data-target="#navbarCollapse"
                    aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            {/* Collapse  */}
            <div className="collapse navbar-collapse" id="navbarCollapse">

                {/* Toggler  */}
                <button className="navbar-toggler text-secondary my-auto" type="button"
                        data-toggle="collapse"
                        data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false"
                        aria-label="Toggle navigation">
                    x
                </button>

                {/* Navigation  */}
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" id="navbarSolutions" data-toggle="dropdown"
                           href="#" aria-haspopup="true" aria-expanded="false">
                            Solutions
                        </a>
                        <div className="dropdown-menu dropdown-menu-xs"
                             aria-labelledby="navbarSolutions">

                            {/* List */}
                            <Link className="dropdown-item mx-0" to="/advisors">
                                Advisors
                            </Link>
                            <Link className="dropdown-item mx-0" to="/institutions">
                                Institutions
                            </Link>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
    )
}


export default NavBar