import 'popper.js'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
// // // import '../node_modules/bootstrap/dist/css/bootstrap-theme.min.css' // optional
import '../node_modules/jquery/dist/jquery.min.js'
import '../node_modules/bootstrap/dist/js/bootstrap.min.js'

// import 'dk/dist/assets/css/theme.min.css'
// import 'dk/dist/assets/fonts/feather/feather.min.css'
import 'dk/dist/assets/fonts/feather/feather.css'
// import 'dk/dist/assets/libs/flatpickr/dist/flatpickr.min.css'
// import 'dk/dist/assets/libs/quill/dist/quill.core.css'
// import 'dk/dist/assets/libs/select2/dist/css/select2.min.css'
// import 'dk/dist/assets/fonts/feather/feather.min.css'


export function setDKBody() {
    document.body.style.margin = 0
    document.body.style.fontFamily = '"Cerebri Sans", sans-serif'
    document.body.style.fontSize ="0.9375rem"
    document.body.style.fontWeight = 400
    document.body.style.lineHeight = 1.5
    document.body.style.color = "#12263F"
    document.body.style.textAlign = 'left'
    document.body.style.backgroundColor = '#F9FBFD'
}


// body {
//     margin: 0;
//     font-family: , sans-serif;
//     font-size: 0.9375rem;
//     font-weight: 400;
//     line-height: 1.5;
//     color: #12263F;
//     text-align: left;
//     background-color: #F9FBFD;
// }