import React from "react";
import axios from "axios";
import {API_ENDPOINT} from "../../keys";


export default function BlogSignupSection(props) {
    const [email, setEmail] = React.useState(null)

    function handleEmailChange(event) {
        event.preventDefault()
        setEmail(event.target.value)
    }

    function handleSubmit(event) {
        event.preventDefault()

        let form_data = {
            email: email
        }

        if (props.fromLocation != null) {
            form_data.from_location = props.fromLocation
        }

        const params = {
            form_data: form_data,
            form_name: 'Blog Subscribe',
        }
``
        axios.post(API_ENDPOINT.CONTACT_FORM, params).then(
            window.alert('Thank you, we have received your request')
        ).catch(error => {
            window.alert('Sorry, an error occurred.  Please email contact@clarivor.com')
        })
    }

    return (
        <section className="">
            <div className="container py-1 py-md-2">
                <div className="row justify-content-center ">
                    <div className="col-12 col-lg-10 col-xl-9 border-top border-bottom border-gray-300">

                        <div className="row align-items-center py-4">
                            <div className="col-12 col-md-6">
                                <h3 className="mb-1">
                                    Receive our insights
                                </h3>
                                <p className="font-size-lg text-muted mb-0">
                                    Directly to your inbox
                                </p>
                            </div>
                            <div className="col-12 col-md-6">
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-12 col-md-7 my-2">
                                            <input type="email" className="form-control" placeholder="Enter your email"
                                                   required={true}
                                                   onChange={handleEmailChange}/>
                                        </div>
                                        <div className="col-12 col-md-5 justify-content-end my-2">
                                            <button className="btn btn-primary" type="submit">
                                                Subscribe
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

