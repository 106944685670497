import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import React from "react"
import * as keys from "../../../../keys"


function createChartOptions(tupleLists) {
    let options =
        {
            series: [
                {
                    compare: 'percent',
                    compareBase: 0,
                    name: 'U.S. Equities Total Return - SPY (Right Axis)',
                    yAxis: 0,
                    data: tupleLists[0],
                    tooltip: {
                        // pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.change}%)<br/>',
                        pointFormat: '<span style="color:{series.color}">{series.name}</span>: ({point.change}%)<br/>',
                        valueDecimals: 2,
                        split: true
                    },
                }, {
                    name: 'Price of SPY 3-month 105% Call (Left Axis)',
                    yAxis: 1,
                    data: tupleLists[1],
                    tooltip: {
                        pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> <br/>',
                        valueDecimals: 2,
                        split: true
                    },
                }
            ],

            rangeSelector: {
                enabled: false
            },

            navigator: {
                enabled: false
            },

            scrollbar: {
                enabled: false
            },

            chart: {
                backgroundColor: 'transparent',

                events: {
                    render: function (event) {

                    }
                },
                style: {
                    fontFamily: "Arial, Helvetica, sans-serif"
                }
            },

            legend: {
                enabled: true,
                itemStyle: {
                    "color": "#333333",
                    "cursor": "pointer",
                    "fontSize": "12px",
                    "fontWeight": "normal",
                    "textOverflow": "ellipsis"
                }
            },

            title: {
                text: 'U.S. Equities and Option Prices (2010 - Present)'
            },

            credits: {
                enabled: false
            },

            xAxis: {
                type: 'datetime'
            },

            yAxis: [
                {
                    title: {
                        text: ''
                    },

                    labels: {
                        formatter: function () {
                            return (Math.round(this.value) > 0 ? ' + ' : '') + this.value + '%';
                        }
                    },
                    plotLines: [{
                        value: 0,
                        width: 2,
                        color: 'silver'
                    }],
                    min: -.5,
                },
                {
                    title: {
                        text: ''
                    },

                    labels: {
                        formatter: function () {
                            return (Math.round(this.value) > 0 ? ' + ' : '') + this.value*100 + '%';
                        }
                    },
                    plotLines: [{
                        value: 0,
                        width: 2,
                        color: 'silver'
                    },],
                    opposite: false

                }],

            // plotOptions: {
            //     series: [
            //         {
            //             allowPointSelect: true,
            //             compare: 'percent',
            //             compareBase: 0,
            //             point: {
            //                 events: {
            //                     select: (event) => null
            //                 }
            //             }
            //         },
            //         {
            //             allowPointSelect: true,
            //             yAxis: 1,
            //             point: {
            //                 events: {
            //                     select: (event) => null
            //                 }
            //             }
            //         },]
            //
            // },


            exporting: {enabled: false},

            colors: Object.values(keys.Color)
        }

    return options
}


export default function PerformanceChart(props) {
    let tupleLists = props.tupleLists

    if (!tupleLists || tupleLists.length === 0) {
        return null
    }

    tupleLists = JSON.parse(JSON.stringify(tupleLists))

    return (
        <HighchartsReact highcharts={Highcharts} constructorType={'stockChart'}
                         options={createChartOptions(tupleLists)}/>
    )
}
