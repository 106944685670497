// yyyy-mm-dd
export function parseDate(dateStr) {
    const year = parseInt(dateStr.slice(0, 4))
    const month = parseInt(dateStr.slice(5, 7))
    const day = parseInt(dateStr.slice(8, 10))

    return new Date(year, month - 1, day)
}

export function formatDate(dateObj) {
    var d = new Date(dateObj),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
}

function padZero(s) {
    return s.length < 2 ? '0' + s : s
}

export function formatTime(dateObj, includeAMPM = false, includeSeconds = true) {
    let d = new Date(dateObj),
        hours = d.getHours(),
        minutes = '' + d.getMinutes(),
        seconds = '' + d.getSeconds(),
        ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = padZero(minutes);

    let timeStr = [padZero(hours), minutes].join(':')

    if (includeSeconds) {
        seconds = padZero(seconds);
        timeStr = [timeStr, seconds].join(':')
    }
    return timeStr + (includeAMPM ? ' ' + ampm : '');
}

export function sameDate(dt1, dt2) {
    if (dt1.getFullYear() != dt2.getFullYear())
        return false

    if (dt1.getFullMonth() != dt2.getFullMonth())
        return false

    if (dt1.getDate() != dt2.getDate())
        return false

    return true
}

function compareNumbers(n1, n2) {
    if (n1 === n2) {
        return 0
    } else if (n2 > n1) {
        return 1
    } else {
        return -1
    }
}

export function compareDates(dt1, dt2) {

    if (dt1.getFullYear() === dt2.getFullYear()) {
        if (dt1.getMonth() === dt2.getMonth()) {
            return compareNumbers(dt1.getDate(), dt2.getDate())
        } else {
            return compareNumbers(dt1.getMonth(), dt2.getMonth())
        }
    } else {
        return compareNumbers(dt1.getFullYear(), dt2.getFullYear())
    }
}

// No holidays
export function addBusinessDays(date, num) {
    let result = new Date(date);

    while (num > 0) {
        result.setDate(result.getDate() + 1);

        // If it's not a weekend, decrement the number of days to add
        if (result.getDay() !== 6 && result.getDay() !== 0) {
            num--;
        }
    }

    return result;
}

export function utcDateToLocal(dateObj) {
    return new Date(dateObj.getTime() - dateObj.getTimezoneOffset() * 60000);
}

// let dt1 = datetime1
// dt1.setHours(0, 0, 0, 0)

// let dt2 = datetime2
// dt2.setHours(0, 0, 0, 0)

// console.log('dt1, dt2', dt1, dt2)

// if (dt2 === dt1) {
//     console.log('returning 0')
//     return 0
// }
// else if (dt2 > dt1)  {
//     console.log('returning 1')
//     return 1
// }
// else {
//     console.log('returning -1')
//     return -1
// }


// datetime kluge for adjusting utc
// const dtobj = new Date(dt)
// // const dtobj = new Date(trade.execution_time)
//
// // this needs to be cleaned up - a kluge to fix timezone!
// let hour = '' + dtobj.getHours() - 5
// if (hour < 10) {
//     hour = '0' + hour
// }
//
// let minute = '' + dtobj.getMinutes()
// if (minute < 10) {
//     minute = '0' + minute
// }
//
// let second = '' + dtobj.getSeconds()
// if (second < 10) {
//     second = '0' + second
// }
//
// const time_str = hour + ':' + minute + ':' + second
//
