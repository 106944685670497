import React, {Component} from 'react'
import macBook from 'lk/dist/assets/img/devices/macbook.svg'
import screenShot from 'images/device-screenshot.png'
import Fade from 'react-reveal/Fade'

// import lk from 'lk/dist/assets/css/theme.module.css'
import {mapClasses} from 'util/cssModule'
import {mixpanel} from "../../../services/mixpanel"

// const mapClasses = (module, classstr) => module == null ? classstr : module[classstr]

class AutomatedWorkflowSection extends Component {
    render() {
        return (
            <section className='pt-8 pt-md-10 pb-11 pb-md-11 pb-lg-8 bg-gradient-light'>
                <div className={mapClasses(null, "container")}>
                    <div className={mapClasses(null, "row align-items-center justify-content-between mb-5")}>
                        <div className={mapClasses(null, "col-12 col-md-6")}>

                            <Fade up duration={1000}>
                                <div className={mapClasses(null, "device device-macbook")}>
                                    <img src={screenShot} className={mapClasses(null, "device-screen")} alt="..."/>
                                    <img src={macBook} className={mapClasses(null, "img-fluid")} alt="..."/>
                                </div>
                            </Fade>
                        </div>
                        <div className={mapClasses(null, "col-12 col-md-6 col-lg-5")}>

                            { /* Heading */}
                            <h1>
                                The first automated advisor for option strategies
                                <br/>
                            </h1>
                            {/*<p className="lead text-muted mb-6 mb-md-0">*/}
                            {/*    Focus on returns and risk*/}
                            {/*</p>*/}

                        </div>
                    </div>
                    { /* / .row */}
                </div>
                { /* / .container */}
                <div className={mapClasses(null, "container")}>
                    <div className={mapClasses(null, "row")}>
                        <div className={mapClasses(null, "col-12 col-md-4")}>
                            <div className={mapClasses(null, "row")}>
                                <div className={mapClasses(null, "col-auto col-md-12")}>

                                    {/* <!-- Step --> */}
                                    <div className={mapClasses(null, "row no-gutters align-items-center mb-md-5")}>
                                        <div className={mapClasses(null, "col-auto")}>

                                            <a href="#!"
                                               className={mapClasses(null, "btn btn-sm btn-rounded-circle btn-secondary disabled opacity-1")}>
                                                <span>1</span>
                                            </a>

                                        </div>
                                        <div className={mapClasses(null, "col")}>

                                            <hr className={mapClasses(null, "d-none d-md-block border-gray-700 w-130")}/>

                                        </div>
                                    </div>
                                    {/* <!-- / .row --> */}

                                </div>
                                <div className={mapClasses(null, "col col-md-12 ml-n5 ml-md-0")}>

                                    {/* <!-- Heading --> */}
                                    <h3 className='text-dark'>
                                        Screen
                                    </h3>
                                    <p className="text-muted mb-6 mb-md-0">
                                        Make better decisions in less time with automated analysis for
                                        custom option portfolios</p>
                                </div>
                            </div>
                            {/* <!-- / .row --> */}
                        </div>
                        <div className={mapClasses(null, "col-12 col-md-4")}>
                            <div className={mapClasses(null, "row")}>
                                <div className={mapClasses(null, "col-auto col-md-12")}>

                                    {/* <!-- Step --> */}
                                    <div className={mapClasses(null, "row no-gutters align-items-center mb-md-5")}>
                                        <div className={mapClasses(null, "col-auto")}>

                                            <a href="#!"
                                               className={mapClasses(null, "btn btn-sm btn-rounded-circle btn-secondary disabled opacity-1")}>
                                                <span>2</span>
                                            </a>

                                        </div>
                                        <div className={mapClasses(null, "col")}>

                                            <hr className={mapClasses(null, "d-none d-md-block w-130")}
                                                style={{borderTop: ['1px', 'solid', '#000']}}/>

                                        </div>
                                    </div>
                                    {/* <!-- / .row --> */}

                                </div>
                                <div className={mapClasses(null, "col col-md-12 ml-n5 ml-md-0")}>

                                    {/* <!-- Heading --> */}
                                    <h3 className='text-dark'>
                                        Invest
                                    </h3>
                                    <p className="text-muted mb-6 mb-md-0">
                                        Streamline strategy implementation with trade alerts and
                                        straight-through processing with supported brokers
                                    </p>
                                </div>
                            </div>
                            {/* <!-- / .row --> */}
                        </div>
                        <div className={mapClasses(null, "col-12 col-md-4")}>
                            <div className={mapClasses(null, "row")}>
                                <div className={mapClasses(null, "col-auto col-md-12")}>

                                    {/* <!-- Step --> */}
                                    <div className={mapClasses(null, "row no-gutters align-items-center mb-md-5")}>
                                        <div className={mapClasses(null, "col-auto")}>

                                            <a href="#!"
                                               className={mapClasses(null, "btn btn-sm btn-rounded-circle btn-secondary disabled opacity-1")}>
                                                <span>3</span>
                                            </a>

                                        </div>
                                    </div>
                                    {/* <!-- / .row --> */}

                                </div>
                                <div className={mapClasses(null, "col col-md-12 ml-n5 ml-md-0")}>

                                    {/* <!-- Heading --> */}
                                    <h3 className='text-dark'>
                                        Manage
                                    </h3>
                                    <p className="text-muted mb-6 mb-md-0">
                                        Seamlessly track performance and risk with a system
                                        tailored for option-based investments
                                    </p>

                                </div>
                            </div>
                            {/* <!-- / .row --> */}
                        </div>
                    </div>
                    {/* <!-- / .row --> */}
                </div>

                <div className={mapClasses(null, "row justify-content-center my-6")}>
                    <a data-toggle="modal" href="#requestDemoModal" style={{textDecoration: 'none'}}
                       onClick={() => {
                           mixpanel.track('Request Demo Automated Workflow Clicked')
                       }}>
                        <button className="btn btn-success lift mt-2 d-flex">
                            <span className={'my-auto'}>Request Demo</span>
                        </button>
                    </a>
                </div>

            </section>
        )
    }
}

export default AutomatedWorkflowSection