import React, {useEffect} from 'react'

import NavBar from 'components/common/NavBarDefault'
import Footer from 'components/common/Footer'
import AboutHeroSection from './AboutHero2'
import AboutPrinciplesSection from 'components/pages/about/AboutPrinciplesSection'
import BreadcrumbNav from "../../common/BreadcrumbNav";
// import {setLKBody} from "../../../lkcss"

export default function About(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <React.Fragment>
            <NavBar {...props}/>
            <BreadcrumbNav pathList={['Company', 'About']}/>

            <AboutHeroSection/>
            <AboutPrinciplesSection/>
            <Footer/>
        </React.Fragment>
    )
}
