import React, {Component} from 'react'

import NavBar from 'components/common/NavBarDefault'
import Footer from 'components/common/Footer'
import {mixpanel} from "../../../services/mixpanel"
import 'lkcss'
import axios from "axios"
import {API_ENDPOINT} from "../../../keys"
import {setLKBody} from "../../../lkcss"
import BreadcrumbNav from "../../common/BreadcrumbNav";

export default class ContactPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            submitted: false,
            formData: {}
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        let state = {}
        if (this.props.location && this.props.location.state) {
            state = this.props.location.state
            this.setState({topic: this.props.location.state.topic})
        }
//        mixpanel.track('Contact Page Loaded', state)
    }

    render() {
        setLKBody()

        return (
            <div>
                <NavBar {...this.props}/>
                <BreadcrumbNav pathList={['Company', 'Contact']}/>
                {this.renderBody()}
                <Footer isLoggedIn={this.props.isLoggedIn}/>
            </div>
        )
    }

    handleSubmit = async (event) => {
        event.preventDefault()

        mixpanel.identify(this.state.formData.email)
        mixpanel.people.set({
            '$email': this.state.formData.email,
            '$last_name': this.state.formData.fullName,
            'company': this.state.formData.company
        })
        mixpanel.track('Signed up for updates')

        const params = {
            form_data: this.state.formData,
            form_name: 'Contact',
            // 'g-recaptcha-response': rcValue
        }

        await axios.post(API_ENDPOINT.CONTACT_FORM, params)

        this.setState({submitted: true})
    }

    handleFieldChange = (event) => {
        let formData = this.state.formData
        formData[event.target.name] = event.target.value

        this.setState({
            formData: formData
        })
    }

    renderForm = () => (
        <form onSubmit={this.handleSubmit}>
            <div className="row justify-content-center">
                <div className="col-12 col-sm-10 col-md-7 border border-gray-300 p-6">
                    <div className="form-group mb-5">
                        <input name='fullName' type="text" required className="form-control"
                               id="contactName"
                               onChange={this.handleFieldChange} placeholder="Name"/>
                    </div>
                    <div className="form-group mb-5">
                        <input name='email' type="email" required className="form-control"
                               id="contactEmail"
                               onChange={this.handleFieldChange}
                               placeholder="Email"/>
                    </div>
                    <div className="form-group mb-5">
                        <input name='company' type="text" className="form-control"
                               id="contactCompany"
                               onChange={this.handleFieldChange}
                               placeholder="Company (Optional)"/>
                    </div>
                    <div className="form-group mb-5">
                        <select name='topic' type="text" className="form-control"
                                id="contactCompany"
                                onChange={this.handleFieldChange}
                                value={this.state.topic}>
                            <option value={''}>Select Topic</option>
                            <option value={'jobs'}>Jobs</option>
                            <option value={'signup-process'}>Signup Process</option>
                            <option value={'technical-support'}>Technical Support</option>
                            <option value={'privacy'}>Privacy</option>
                            <option>Other</option>
                        </select>
                    </div>
                    <div className="form-group mb-5">
                                            <textarea className='form-control' name={'comments'} rows={'4'}
                                                      placeholder={'Comments'} onChange={this.handleFieldChange}/>
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-block btn-primary lift">
                            Send Clarivor a message
                        </button>
                    </div>
                </div>
            </div>
        </form>
    )

    renderSubmittedMessage = () => (
        <div className='alert bg-secondary text-white justify-content-center'>
            Thank you for getting in touch. We will get back to you as soon as possible.
        </div>
    )

    renderBody() {
        return (
            <section className="pt-8 pt-md-11 pb-8 pb-md-10 bg-light" style={{minHeight: '70vh'}}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-8 text-center">
                            <h2 className="text-dark font-weight-bold">
                                Get in touch
                            </h2>

                            <p className="font-size-lg text-muted">
                                Please submit your inquiry below
                            </p>

                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-12 col-lg-10">
                            {
                                this.state.submitted ? this.renderSubmittedMessage() : this.renderForm()
                            }
                        </div>
                    </div>
                </div>
            </section>
        )

    }
}