import React, {useContext, useEffect, useState} from "react"
import headshot from 'images/blog/avatar-100.jpg'
import RequestDemoModal from "../../../pages/common/RequestDemoModal"
import {globalAuthManager, globalServices} from "../../../../services/GlobalServices"
import {apiGet} from "../../../../services/api-call-helpers"
import * as keys from 'keys'
import {mixpanel} from "../../../../services/mixpanel"
import ReceiveUpdatesModal from "../../../pages/common/ReceiveUpdatesModal"
import {Link} from "react-router-dom"
import BlogSignupSection from "../../common/BlogSignupSection"
import PayoffImage from "images/blog/Blog_20210624_BufferBondReplacement/buffer_payoff_at_expiration.png"
import PayoffComparisonImage from "images/blog/Blog_20210624_BufferBondReplacement/buffer_vs_traditional_scenarios.png"
import ScenarioTable from "images/blog/Blog_20210624_BufferBondReplacement/scenario_table.png"
import BacktestChart from "images/blog/Blog_20210624_BufferBondReplacement/backtest.png"

import BannerImage500 from "images/blog/Blog_20210624_BufferBondReplacement/coins_on_board_500.jpg"
import BannerImage from "images/blog/Blog_20210624_BufferBondReplacement/coins_on_board.jpg"
import jonAvatar from 'images/blog/avatar-100.jpg'

const TLTOverwriteContext = React.createContext({})

const documentTitle = "Clarivor Blog"
const postTitle = 'Buffers as Bond Replacements'
const postSubtitle = 'They can work in moderation, but investors should mind stress scenarios'
const postAuthor = 'Jon Spiegel, CFA'
const publishedOnText = 'June 24, 2021'
const publishedOnShort = 'Jun 24, 2021'
const path = '/blog/BufferBondReplacement_20210624'

const HeroSection = (props) => (
    <section
        style={{
            backgroundImage: ["url(" + BannerImage + ")"],
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            position: 'relative',
            zIndex: 0,
            backgroundSize: 'cover',
            height: '50vh'
        }}>
    </section>
)

const HeaderSection = (props) => (
    <section className="pt-8 pt-md-11">
        <RequestDemoModal/>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 col-md-12 col-lg-10 col-xl-10">


                    <h1 className="display-4 text-center">
                        {props.title}
                    </h1>


                    <p className="lead mb-7 text-center text-muted">
                        {props.subtitle}
                    </p>


                    <div className="row align-items-center py-5 border-top border-bottom">
                        <div className="col-auto">


                            <div className="avatar avatar-lg">
                                <img src={props.avatarImage} alt="..."
                                     className="avatar-img rounded-circle"/>
                            </div>

                        </div>
                        <div className="col ml-n5">
                            <h6 className="text-uppercase mb-0">
                                {props.author}
                            </h6>
                        </div>
                        <div className="col-auto">
                            <time className="font-size-sm text-muted" dateTime="2019-05-20">
                                {props.publishedOnText}
                            </time>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
)


function Summary(props) {
    return (
        <React.Fragment>
            <h3 className={'font-weight-bold mt-6'}>
                Summary
            </h3>
            <ul>
                <li className='my-2'>
                    Investors should be careful when considering investments as bond replacements, as some structured
                    products may not work for this purpose.
                </li>
                <li className='my-2'>
                    However, using “buffered strategies” for equity exposure can justify a higher overall equity
                    allocation for investors concerned about higher rates.
                </li>
                <li className='my-2'>
                    By managing market stress scenarios, investors can target downside risk similar to a traditional
                    portfolio, while potentially achieving higher equity upside.
                </li>
            </ul>
        </React.Fragment>
    )
}


function Section1(props) {
    return (
        <React.Fragment>
            <h3 className={'font-weight-bold mt-6'}>
                Buffer Review
            </h3>
            <p>
                Buffered strategies typically protect investors against a sell-off in equities down to a certain level.
                Below that
                level, buffers start participating in losses. In exchange for this partial protection, the investor
                gives up
                gains past a cap on returns.
            </p>
            <p>
                For example, a 12-month buffer on the SPY ETF may provide exposure to the first 8.5% of upside while
                protecting against the first 10% of downside. Below is an illustration of this hypothetical payoff.
            </p>
            <div className='card bg-light'>
                <div className='card-body p-2'>
                    <img src={PayoffImage} style={{width: '100%'}}/>
                </div>
            </div>

            <h3 className={'font-weight-bold mt-6'}>
                Using Buffers as Part of an Equity Allocation
            </h3>
            <p>
                Since buffers provide equity exposure with less downside risk, when using buffers as part of an equity
                allocation, the overall portfolio can potentially achieve more equity exposure for the same downside
                risk.
            </p>
            <p>
                For example, if equities lost 35% over 12 months, the return of the traditional 60-40 portfolio would be
                -21%.<sup><i>1</i></sup> If half of the equity allocation were replaced with buffers, then a 70-30
                portfolio
                using this equity allocation would also return approximately -21%.<sup><i>2</i></sup>
            </p>
            <p>
                However, if equities sold off more than 35%, then this 70-30 portfolio will likely under-perform the
                traditional 60-40 portfolio.
            </p>
            <div className='card bg-light'>
                <div className='card-body p-2'>
                    <img src={PayoffComparisonImage} style={{width: '100%'}}/>
                </div>
            </div>
            <br/>
            <p>
                Investors may want to understand the additional tail risk when increasing equity allocations in this
                manner.
                The investor can select an equity stress scenario at which the portfolio with buffers has similar risk
                to the traditional 60-40 portfolio. Depending on the size of the selected stress scenario, the investor
                can justify a different “overweight” to equities using buffers.<sup><i>3</i></sup></p>
            <br/>

            <div className='card bg-light'>
                <div className='card-body p-6 text-center'>
                    <img src={ScenarioTable} style={{width: '75%'}}/>
                </div>
            </div>

            <br/>
            <p>
                Asset returns of the past decade have been characterized by declining interest rates and strong equity
                returns.  Investors who believe interest rates are likely to rise may wish to substitute some bond
                exposure for equities. Buffers may enable such investors to do that while managing downside risk.
            </p>

            {/*<h3 className={'font-weight-bold mt-6'}>*/}
            {/*    Back-tested Performance*/}
            {/*</h3>*/}
            {/*<p>*/}
            {/*    The back-test below illustrates the potential benefit of increasing equity exposure with*/}
            {/*    buffers during periods of ris*/}
            {/*</p>*/}
            {/*<p>*/}
            {/*    Since 2013, a traditional 60-40 portfolio has generally outperformed comparable portfolios*/}
            {/*    containing buffers as equity performance has been strong and the*/}
            {/*    caps on buffers have limited their returns.*/}
            {/*</p>*/}
            {/*<p>*/}
            {/*    However, investors looking for bond replacements may not be anticipating*/}
            {/*</p>*/}
            {/*<p>*/}
            {/*    However, with interest rates at historically low levels currently, investors who are cautiously*/}
            {/*    optimistic on equities and bearish on bonds may see more upside by using buffers to partially reduce*/}
            {/*    bond exposure.*/}
            {/*</p>*/}
            {/*<p>*/}
            {/*    <div className='card bg-light'>*/}
            {/*        <div className='card-body p-2'>*/}
            {/*            <img src={BacktestChart} style={{width: '100%'}}/>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</p>*/}

        </React.Fragment>
    )
}

const WrittenSection = (props) => {

    const handleToolScroll = (event) => {
        event.preventDefault()
        props.scrollToTool()
    }

    return (
        <section>
            <hr className="hr-md"/>
        </section>
    )
}


function SelectorsWrapper(props) {
    return (
        <div className='card mt-4'>
            <div className='card-header bg-gray-300 text-secondary p-4'>
                Compare Put Strategies
            </div>
            <div className='card-body bg-light p-4'>
                {props.children}
                <div className="form-check">
                    {/*<ShowSPYCheckbox/>*/}
                </div>
            </div>
        </div>
    )
}


function ChartWrapper(props) {
    return (
        <div className='card mt-4 px-0'>
            <div className='card-body border px-0'>
                {props.children}
            </div>
        </div>
    )

}

function TLTOverwriteProjectContainer(props) {
    const [projectObj, setProjectObj] = useState(null)

    useEffect(() => {
        apiGet(keys.API_ENDPOINT.PROJECTS, {id: props.projectId}, globalAuthManager).then(
            res => {
                const [project] = res.data
                setProjectObj(project)
            }
        )
    }, [])

    const contextValue = {
        projectObj: projectObj,
        projectId: props.projectId
    }

    return (
        <TLTOverwriteContext.Provider value={contextValue}>
            {props.children}
        </TLTOverwriteContext.Provider>
    )
}


function RequestDemoSection(props) {
    return (
        <section>
            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-12 col-md-8 col-lg-6'>
                        <div className='card border border-primary'>
                            <div className='card-body'>
                                <div className='row justify-content-center'>
                                    <h3 className={'text-center'}>
                                        Get in touch with Clarivor for further analysis.
                                    </h3>
                                </div>
                                <div className='row justify-content-center'>
                                    <a className='mx-auto' data-toggle="modal" href="#requestDemoModal"
                                       style={{textDecoration: 'none'}}
                                       onClick={() => {
                                           mixpanel.track('Request Demo clicked')
                                       }}>
                                        <button className="btn btn-success lift mt-2 d-flex">
                                            <span className={'my-auto'}>Request Demo</span>
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

function DisclosuresLink(props) {
    return (
        <a data-toggle="modal" href="#disclaimerModal"
           onClick={() => mixpanel.track('Disclosure Clicked')}>Disclosures</a>
    )
}

function TermsOfUseLink(props) {
    return (
        <Link to={'/terms-of-use'} target={'_blank'}
              onClick={() => mixpanel.track('Terms of Use Clicked')}>Terms of Use</Link>
    )
}

function BacktestingSourceDisclaimer(props) {
    return (
        <div>
            <hr/>
            <p className='font-italic text-muted'>
                <sup>1</sup> When comparing equity returns and buffer returns, we mean the relevant equity return
                occurring from the buffer trade date to the expiration approximately 1 year later. Analysis presented
                in this post assumes bonds have zero return over the relevant period.
            </p>
            <p className='font-italic text-muted'>
                <sup>2</sup> All calculations refer to 1-year buffers protecting the first 10% downside.
            </p>
            <p className='font-italic text-muted'>
                <sup>3</sup> The example portfolio using buffers consists of 35% buffers with 10% protection, 35%
                equities and 30% bonds.
            </p>
            <p className='font-italic text-muted'>
                Source: Clarivor LLC.
                {/*These results are backtested and may not be indicative of future*/}
                {/*performance. Options are rolled seven days prior to expiration at bid or offer.*/}
                {/*Option prices are observed at 3:45pm NY time. Back-tested strategies include 0.47% annualized cost to*/}
                {/*reflect management fees and commissions.*/}
                <br/>
                <br/>
                NOTHING IN THIS POST SHOULD BE CONSTRUED AS INVESTMENT ADVICE. <br/>
                <DisclosuresLink/> and <TermsOfUseLink/> apply.
                <br/>
            </p>
        </div>
    )
}


function TLTOverwritePostBody(props) {
    return (
        <React.Fragment>
            <section className="pt-0 pt-md-0">
                <ReceiveUpdatesModal/>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-12 col-lg-10 col-xl-9">
                            <Summary/>
                            <Section1/>
                            <BacktestingSourceDisclaimer/>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}


export default function TLT_Overwriting_20210412(props) {
    const projectId = null  // this is a static blog post

    useEffect(() => {
        document.title = documentTitle
    }, [])

    const headerProps = {
        title: postTitle,
        subtitle: postSubtitle,
        avatarImage: headshot,
        author: postAuthor,
        publishedOnText: publishedOnShort,
    }

    return (
        <React.Fragment>
            <HeroSection/>
            <HeaderSection {...headerProps} />
            <TLTOverwriteProjectContainer projectId={projectId}>
                <TLTOverwritePostBody/>
            </TLTOverwriteProjectContainer>
            <BlogSignupSection fromLocation={'Blog Post TLT Overwriting'}/>
        </React.Fragment>
    )
}


export function RichBufferBondReplacement202106(props) {
    return (
        <section className="pt-7 pt-md-10">
            <div className="container">
                <div className="row">
                    <div className="col-12">

                        <div className="card card-row shadow-light-lg mb-6 lift lift-lg">
                            <div className="row no-gutters">
                                <div className="col-12">

                            <span className="badge badge-pill badge-light badge-float badge-float-inside">
                    <span className="h6 text-uppercase">Asset Allocation</span>
                  </span>

                                </div>
                                <Link className="col-12 col-md-6 order-md-2 bg-cover card-img-right"
                                      style={{backgroundImage: "url(" + BannerImage500 + ")"}}
                                      to={path}>

                                    <img src="assets/img/photos/photo-27.jpg" alt="..."
                                         className="img-fluid d-md-none invisible"/>

                                    <div
                                        className="shape shape-left shape-fluid-y svg-shim text-white d-none d-md-block">
                                        <svg viewBox="0 0 112 690" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 0h62.759v172C38.62 384 112 517 112 517v173H0V0z"
                                                  fill="currentColor"></path>
                                        </svg>
                                    </div>

                                </Link>
                                <div className="col-12 col-md-6 order-md-1">

                                    <Link className="card-body" to={path}>

                                        <h3>
                                            {postTitle}
                                        </h3>

                                        <p className="mb-0 text-muted">
                                            {postSubtitle}
                                        </p>

                                    </Link>

                                    <a className="card-meta" href="#!">

                                        <hr className="card-meta-divider"/>

                                        <div className="avatar avatar-sm mr-2">
                                            <img src={jonAvatar} alt="..."
                                                 className="avatar-img rounded-circle"/>
                                        </div>

                                        <h6 className="text-uppercase text-muted mr-2 mb-0">
                                            Jon Spiegel
                                        </h6>

                                        <p className="h6 text-uppercase text-muted mb-0 ml-auto">
                                            <time dateTime="2020-10-23">{publishedOnShort}</time>
                                        </p>

                                    </a>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>

    )
}
