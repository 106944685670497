import React from "react";
import axios from "axios";
import {API_ENDPOINT} from "../../../keys";

export default function BlogSignupSection(props) {
    const [email, setEmail] = React.useState(null)

    function handleEmailChange(event) {
        event.preventDefault()
        setEmail(event.target.value)
    }

    function handleSubmit(event) {
        event.preventDefault()

        let form_data = {
            email: email
        }

        if (props.fromLocation != null) {
            form_data.from_location = props.fromLocation
        }

        const params = {
            form_data: form_data,
            form_name: 'Blog Subscribe',
        }

        axios.post(API_ENDPOINT.CONTACT_FORM, params).then(
            window.alert('Thank you, we have received your request')
        ).catch(error => {
            window.alert('Sorry, an error occurred.  Please email contact@clarivor.com')
        })
    }

    return (
        <section className="pt-7 py-md-10">
            <div className="container py-6 py-md-8 border-top border-bottom border-gray-300">
                <div className="row align-items-center">
                    <div className="col-12 col-md">
                        <h3 className="mb-1 font-weight-bold">
                            Receive our insights
                        </h3>
                        <p className="font-size-lg text-muted mb-6 mb-md-0">
                            Directly to your inbox
                        </p>
                    </div>
                    <div className="col-12 col-md-5">
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col">
                                    <input type="email" className="form-control" placeholder="Enter your email"
                                           required={true}
                                           onChange={handleEmailChange}/>
                                </div>
                                <div className="col-auto ml-n5">
                                    <button className="btn btn-success" type="submit">
                                        Subscribe
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}

