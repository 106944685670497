import React, {useContext, useEffect, useState} from "react"
import headshot from 'images/blog/avatar-100.jpg'
import RequestDemoModal from "../../../pages/common/RequestDemoModal"
import {globalAuthManager, globalServices} from "../../../../services/GlobalServices"
import {apiGet} from "../../../../services/api-call-helpers"
import * as keys from 'keys'
// import {mixpanel} from "../../../../services/mixpanel"
import ReceiveUpdatesModal from "../../../pages/common/ReceiveUpdatesModal"
import {Link} from "react-router-dom"
import BlogSignupSection from "../../common/BlogSignupSection"

import BannerImage from 'images/blog/Blog_20230203_SPYPuts/banner.jpg'
import BannerImage500 from 'images/blog/Blog_20230203_SPYPuts/banner500.jpg'

import putPriceChart from 'images/blog/Blog_20230203_SPYPuts/put-price-chart.png'

import jonAvatar from 'images/blog/avatar-100.jpg'

const ProjectContext = React.createContext({})

const documentTitle = "Clarivor Blog"
const postTitle = 'Equity Puts for Rally Skeptics'
const postSubtitle = 'Rising equity prices have coincided with declining cost of protection'
const postAuthor = 'Jon Spiegel, CFA'
const publishedOnText = 'February 3, 2023'
const publishedOnShort = 'Feb 3'
export const path = 'equity-put-hedge-20230203'

const HeroSection = (props) => (
    <section
        style={{
            backgroundImage: ["url(" + BannerImage + ")"],
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            position: 'relative',
            zIndex: 0,
            backgroundSize: 'cover',
            height: '35vh'
        }}>
    </section>
)

const HeaderSection = (props) => (
    <section className="pt-8 pt-md-11">
        <RequestDemoModal/>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 col-md-12 col-lg-10 col-xl-10">


                    <h1 className="display-4 text-center">
                        {props.title}
                    </h1>


                    {/*<p className="lead mb-7 text-center text-muted">*/}
                    <p className="lead px-10 mb-7 text-center text-muted">
                        {props.subtitle}
                    </p>


                    <div className="row align-items-center py-5 border-top border-bottom">
                        <div className="col-auto">


                            <div className="avatar avatar-lg">
                                <img src={props.avatarImage} alt="..."
                                     className="avatar-img rounded-circle"/>
                            </div>

                        </div>
                        <div className="col ml-n5">
                            <h6 className="text-uppercase mb-0">
                                {props.author}
                            </h6>
                        </div>
                        <div className="col-auto">
                            <time className="font-size-sm text-muted" dateTime="2019-05-20">
                                {props.publishedOnText}
                            </time>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
)


function Summary(props) {
    return (
        <React.Fragment>
            {/*<h3 className={'font-weight-bold mt-6'}>*/}
            {/*    Summary*/}
            {/*</h3>*/}
            {/*<ul>*/}
            {/*    <li className='my-2'>*/}
            {/*        Over the past year inflation and rates have steadily trended higher. While the trend has reversed*/}
            {/*        over the past couple weeks, some investors may believe the market is still in a long-term*/}
            {/*        transition to a higher-rate regime.*/}
            {/*    </li>*/}
            {/*    <li className='my-2'>*/}
            {/*        The recent pull-back could provide an opportunity to investors who want to hedge against higher*/}
            {/*        rates and protect their portfolios from the potential impact across asset classes.*/}
            {/*    </li>*/}
            {/*    <li className='my-2'>*/}
            {/*        In order to hedge rates with no upfront costs and limited downside risk, an investor can*/}
            {/*        buy a put on the TLT ETF with funds raised from selling a TLT call-spread.*/}
            {/*    </li>*/}
            {/*</ul>*/}
        </React.Fragment>
    )
}


function Section1(props) {
    const putRef = React.createRef()
    const psRef = React.createRef()
    const collarRef = React.createRef()
    const pscRef = React.createRef()


    return (
        <React.Fragment>
            <h3 className={'font-weight-bold mt-6'}>
                Purchasing SPY Puts
            </h3>
            <p>
                Investors skeptical about the 2023 equity rally may not know whether to position defensively or reach
                for more upside.
            </p><p>
            With the costs of protecting equity portfolios down significantly, they might want to do both.
        </p><p>
            As equity markets have risen YTD, the cost of using puts on SPY to protect against significant sell-offs
            has declined to levels not seen since Feb '20.
        </p><p>
            For long-term, strategic protection, an investor can buy a 2-year put on SPY, 20% out-of-the-money, for
            approximately $16 (2% per year).
        </p><p>
            The cost for this level of protection, as a percent of portfolio size, is currently lower than it has
            been about 70% of the time since 2005.
        </p><p>
            For a more tactical hedge, with SPY at $416, an investor can buy a Sep ’23 put on SPY, 10%
            out-of-the-money, for about $7.70 (1.9% over 5 months).
        </p><p>
            Similarly, the cost for this level of protection is lower than it has been about 55% of the time since
            2005.
        </p><p>
            Some investors currently see many concerning macro risks in the background, despite the recent market
            rebound.
        </p><p>
            These option purchases could offer downside protection at reasonable cost, while allowing continued
            benefit from a further move higher.
        </p>
            <div className='card mx-auto my-6' style={{maxWidth: '90%'}}>
                <div className='card-body p-2  bg-white'>
                    <h3 className='text-center'>
                        Historical Cost of SPY Puts
                    </h3>
                    <img src={putPriceChart} className='img-fluid'/>
                </div>
            </div>
        </React.Fragment>
    )
}

function BufferPerformanceProjectContainer(props) {
    const [projectObj, setProjectObj] = useState(null)

    useEffect(() => {
        apiGet(keys.API_ENDPOINT.PROJECTS, {id: props.projectId}, globalAuthManager).then(
            res => {
                const [project] = res.data
                setProjectObj(project)
            }
        )
    }, [])

    const contextValue = {
        projectObj: projectObj,
        projectId: props.projectId
    }

    return (
        <ProjectContext.Provider value={contextValue}>
            {props.children}
        </ProjectContext.Provider>
    )
}


function DisclosuresLink(props) {
    return (
        <a data-toggle="modal" href="#disclaimerModal"
           onClick={() => null}>Disclosures</a>
// onClick={() => mixpanel.track('Disclosure Clicked')}>Disclosures</a>
    )
}

function TermsOfUseLink(props) {
    return (
        <Link to={'/terms-of-use'} target={'_blank'}
              onClick={() => null}>Terms of Use</Link>
// onClick={() => mixpanel.track('Terms of Use Clicked')}>Terms of Use</Link>
    )
}

function BacktestingSourceDisclaimer(props) {
    return (
        <div>
            <hr/>
            {/*<p className='font-italic text-muted'>*/}
            {/*    Strategy Details: <br/><br/>*/}
            {/*    The calendar collar strategy back-tested above buys 12-month puts, 20%*/}
            {/*    out-of-the-money (OTM) on the SPY*/}
            {/*    ETF. Each month, a 1-month call is sold with strike closest to 1/12th of the value of a 12-month, 20%*/}
            {/*    OTM put. The 12-month put is rolled 7 days prior to maturity, while the short calls are rolled 3 days*/}
            {/*    prior to maturity. <br/><br/>*/}
            {/*    Similarly, the traditonal collar strategy buys a 12-month 20% OTM put and sells a 12-month call of*/}
            {/*    approximately equal value.<br/><br/>*/}
            {/*    The weights of the option overlays and SPY are rebalanced quarterly.<br/><br/>*/}
            {/*    Each option strategy back-test above includes an annual deduction of 0.39% to reflect management fees*/}
            {/*    and commissions.<br/><br/>*/}

            {/*    SPY (TR) has dividends immediately reinvested, shown with no additional management fee.*/}
            {/*</p>*/}
            <p className='font-italic text-muted'>
                Source: Clarivor LLC.<br/>
                Intended for professional investors - options can entail complex risks and may not be suitable for
                particular situations. Listed strikes and maturities are rounded for comparison.<br/>
                This blog post originally appeared on linkedin on 2023-02-03.<br/>

                {/*Strikes and maturities are estimated from listed strikes and tenors for easier comparison.*/}
                {/*All buffers above refer to a SPY 12-month buffer traded on 12/18/2021, providing 10% downside*/}
                {/*protection, collateralized with cash.*/}
                {/*Options can entail complex risks and may not be suitable for all investors.<br/>*/}
                {/*Strikes are selected from the listed market.*/}
                {/*Options are rolled seven days prior to expiration at bid or offer.*/}
                {/*Back-tested results may not be indicative of future returns.*/}
                Options can entail complex risks and may not be suitable for all investors. Nothing in this post should
                be construed as investment advice. <DisclosuresLink/> and <TermsOfUseLink/> apply.
                <br/>
            </p>
        </div>
    )
}


function PostBody(props) {
    return (
        <React.Fragment>
            <section className="pt-0 pt-md-0">
                <ReceiveUpdatesModal/>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-12 col-lg-10 col-xl-9">
                            {/*<Summary/>*/}
                            <Section1/>
                            <BacktestingSourceDisclaimer/>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}


export default function BufferPerformance_20210803(props) {
    const projectId = null  // this is a static blog post

    useEffect(() => {
        document.title = documentTitle
    }, [])

    const headerProps = {
        title: postTitle,
        subtitle: postSubtitle,
        avatarImage: headshot,
        author: postAuthor,
        publishedOnText: publishedOnText,
    }

    return (
        <React.Fragment>
            <HeroSection/>
            <HeaderSection {...headerProps} />
            <BufferPerformanceProjectContainer projectId={projectId}>
                <PostBody/>
            </BufferPerformanceProjectContainer>
            <BlogSignupSection fromLocation={'Blog Post TLT Overwriting'}/>
        </React.Fragment>
    )
}


export function RichSPYPuts20230203(props) {
    return (
        <section className="pt-7 pt-md-10">
            <div className="container">
                <div className="row">
                    <div className="col-12">

                        <div className="card card-row shadow-light-lg mb-6 lift lift-lg">
                            <div className="row no-gutters">
                                <div className="col-12">

<span className="badge badge-pill badge-light badge-float badge-float-inside">
<span className="h6 text-uppercase">hedging</span>
</span>
                                </div>
                                <Link className="col-12 col-md-6 order-md-2 bg-cover card-img-right"
                                      style={{backgroundImage: "url(" + BannerImage500 + ")"}}
                                      to={props.blogPath + path}>

                                    <img src="assets/img/photos/photo-27.jpg" alt="..."
                                         className="img-fluid d-md-none invisible"/>

                                    <div
                                        className="shape shape-left shape-fluid-y svg-shim text-white d-none d-md-block">
                                        <svg viewBox="0 0 112 690" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 0h62.759v172C38.62 384 112 517 112 517v173H0V0z"
                                                  fill="currentColor"></path>
                                        </svg>
                                    </div>

                                </Link>
                                <div className="col-12 col-md-6 order-md-1">

                                    <Link className="card-body" to={props.blogPath + path}>

                                        <h3>
                                            {postTitle}
                                        </h3>

                                        <p className="mb-0 text-muted">
                                            {postSubtitle}
                                        </p>

                                    </Link>

                                    <a className="card-meta" href="#!">

                                        <hr className="card-meta-divider"/>

                                        <div className="avatar avatar-sm mr-2">
                                            <img src={jonAvatar} alt="..."
                                                 className="avatar-img rounded-circle"/>
                                        </div>

                                        <h6 className="text-uppercase text-muted mr-2 mb-0">
                                            Jon Spiegel
                                        </h6>

                                        <p className="h6 text-uppercase text-muted mb-0 ml-auto">
                                            <time dateTime="2020-10-23">{publishedOnShort}</time>
                                        </p>

                                    </a>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>

    )
}
