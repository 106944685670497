import React, {Component, useEffect} from 'react'
import Modal from 'react-modal'
import './ReactModalWrapper.css'

// // import 'dkcss'
// import {dk} from 'dkDummy'
import {mapClasses} from 'util/cssModule'


// export function ModalToggleButton(props) {
//     return (
//         <button type={props.type} className={props.className} data-toggle="modal" data-target={"#modal" + props.modalID}
//                 name={props.name}>
//             {props.children}
//         </button>
//     );
// }
//
const defaultCustomStyle = {
    content: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '0px'
    },
    overlay: {
        zIndex: 1000
    }
}

export default function ReactModalWrapper(props) {
    function closeModal() {
        props.closeModal(props.id)
    }

    useEffect(() => {
        if (props.isOpen) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }
        return function cleanup() {
            document.body.style.overflow = 'auto'
        }
    }, [props.isOpen])

    const customStyle = props.customStyle == null ? defaultCustomStyle : props.customStyle

    return (
        <Modal
            style={customStyle}
            {...props}
            // isOpen={props.isOpen}
            // onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            contentLabel={props.modalTitle}
            // className={'Modal'}
            // overlayClassName={'Overlay'}
            closeTimeoutMS={500}
        >
            <div className={mapClasses(props.cssModule, "modal-header bg-secondary text-white")}>
                <h3 className={mapClasses(props.cssModule, "modal-title")}>
                    {props.modalTitle}
                </h3>
                <button type="button" className={mapClasses(props.cssModule, "close")} onClick={closeModal}
                        style={{paddingTop: '0px', paddingBottom: '0px', marginTop: '0px'}}
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            {props.children}
            {/*<div className={mapClasses(props.cssModule, "modal-body")}>*/}
            {/*    {props.bodyComponent}*/}
            {/*</div>*/}
            {/*{*/}
            {/*    props.footerComponent ?*/}
            {/*        <div className={mapClasses(props.cssModule, "modal-footer")}>*/}
            {/*            {props.footerComponent}*/}
            {/*        </div> : null*/}
            {/*}*/}
        </Modal>
    )
}
