import axios from "axios"

export async function apiGet(url, params, authManager, cancelTokenSource) {
    const headers = {'Authorization': "Bearer " + authManager.accessToken}
    const config = {
        headers: headers,
        params: params,
        cancelToken: cancelTokenSource ? cancelTokenSource.token : null
    }

    return axios.get(url, config)
}


export async function apiPost(url, data, authManager) {
    const headers = {'Authorization': "Bearer " + authManager.accessToken}
    const config = {
        headers: headers,
    }

    return axios.post(url, data, config)
}


export async function apiPut(url, data, authManager) {
    const headers = {'Authorization': "Bearer " + authManager.accessToken}
    const config = {
        headers: headers,
    }

    return axios.put(url, data, config)
}

export async function apiPatch(endpoint_url_with_id, data, authManager) {
    const headers = {'Authorization': "Bearer " + authManager.accessToken}
    const config = {
        headers: headers,
    }

    return axios.patch(endpoint_url_with_id, data, config)
}

export async function apiDelete(endpoint_url_with_id, authManager) {
    const headers = {'Authorization': "Bearer " + authManager.accessToken}
    const config = {
        headers: headers,
    }

    return axios.delete(endpoint_url_with_id, config)
}