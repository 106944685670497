import React, {Component} from 'react'
import heroImage from 'images/sailboat-on-reef-p-775x666.jpeg'
// import heroImage from 'images/heroImage.png'


class AboutHeroSection extends Component {
    render() {

        return (
            <section className={'my-8'}>
                <div className={'container'}>
                    <div className="card card-row shadow-light-lg">
                        <div className="row no-gutters">
                            <div className="col-12 col-md-6 bg-cover card-img-left"
                                 style={{backgroundImage: 'url(' + heroImage + ')'}}>

                                <img src={heroImage} alt="..."
                                     className="img-fluid d-md-none invisible"/>
                                <div
                                    className="shape shape-right shape-fluid-y svg-shim text-white d-none d-md-block">
                                    <svg viewBox="0 0 112 690" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M116 0H51v172C76 384 0 517 0 517v173h116V0z"
                                              fill="currentColor"></path>
                                    </svg>
                                </div>

                            </div>
                            <div className="col-12 col-md-6 order-md-1">
                                <div className="card-body">
                                    <h3 className="h1 ml-3 my-6">
                                        <span className="text-success">Empowering investment managers</span> with
                                        technology and
                                        insight
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default AboutHeroSection