import React, {Component} from 'react';
import {useHistory} from "react-router-dom";
import footerLogo from 'images/clarivor-p-500-inverted.png'
import {Link} from 'react-router-dom'
import {AppContext} from "../../contexts";
import {mixpanel} from "../../../services/mixpanel"
import {createCookie, readCookie} from "util/cookies";
import {track_ux_event} from "../../../services/clarivor-tracking";
import {globalAuthManager} from "../../../services/GlobalServices";
import {mapClasses} from "../../../util/cssModule";
import ReactModalWrapper from "../ReactModalWrapper";
import {PlaceOrderModalBodyAndFooterWithPreview} from "../../investment-app/portfolio/trading/PlaceOrderModal";
import BidAskSlider from "../../investment-app/portfolio/trading/BidAskSlider";
import {v4 as uuidv4} from "uuid";

// import lk from 'lk/dist/assets/css/theme.module.css'
// import {mapClasses} from 'util/cssModule'
const lk = null

const cookieKey = 'accepted-hypothetical-performance'

function acceptedHypotheticalPerformance() {
    createCookie(cookieKey, 'yes', 365, '/');
}

function HypotheticalPerformanceModalView({handleAcceptClicked, handleGoBackClicked}) {


    return (
        <ReactModalWrapper closeModal={null}
                           isOpen={true}
                           modalTitle={'Back-tested Performance'}
            // customStyle={overlayModalStyle}
        >
            <div className='container'>
                <div className="modal-body">
                    Clarivor occasionally uses back-tested and other forms of hypothetical performance on its website to
                    illustrate the potential benefits and risks of various investment strategies. The results presented
                    may
                    not have been achieved by any portfolio that Clarivor manages. This information is intended only for
                    professional investors and
                    investment advisors equipped with the resources and expertise to independently assess and understand the
                    associated risks and
                    constraints. If you are an investment advisor, ensure this performance is relevant to the financial
                    situation and objectives of the clients you serve. By clicking "Accept," you confirm you meet the
                    required
                    criteria and agree to our website's <Link target="_blank" to={'/terms-of-use'}>terms of use</Link>,
                    which requires users accessing hypothetical
                    performance to meet the relevant criteria.
                </div>
                <div className='modal-footer d-flex justify-content-center'>
                    <div className='row justify-content-center'>
                        <div className='col-auto'>
                            <button className='btn btn-warning' onClick={handleGoBackClicked}>Go Back</button>
                        </div>
                        <div className='col-auto'>
                            <button className='btn btn-secondary' onClick={handleAcceptClicked}>Accept</button>
                        </div>
                    </div>
                </div>
            </div>
        </ReactModalWrapper>
    )
}


export default function HypotheticalPerformanceAlert(props) {
    const [justAccepted, setJustAccepted] = React.useState(false)
    const history = useHistory();

    const handleAccept = () => {
        track_ux_event(localStorage.uuid, 'HypotheticalPerformanceAlert accepted', {}, globalAuthManager)
        setJustAccepted(true)
        acceptedHypotheticalPerformance()
    }

    const handleGoBack = () => {
        track_ux_event(localStorage.uuid, 'HypotheticalPerformanceAlert go back', {}, globalAuthManager)
        history.push('/blog')
    }

    if (readCookie(cookieKey) === 'yes') {
        return null
    } else {
        return (
            <HypotheticalPerformanceModalView handleAcceptClicked={handleAccept} handleGoBackClicked={handleGoBack}/>
        )
    }
}
