import React, {useContext, useEffect, useState} from "react"
import headshot from 'images/blog/avatar-100.jpg'
import RequestDemoModal from "../../../pages/common/RequestDemoModal"
import {globalAuthManager, globalServices} from "../../../../services/GlobalServices"
import {apiGet} from "../../../../services/api-call-helpers"
import * as keys from 'keys'
// import {mixpanel} from "../../../../services/mixpanel"
import ReceiveUpdatesModal from "../../../pages/common/ReceiveUpdatesModal"
import {Link} from "react-router-dom"
import BlogSignupSection from "../../common/BlogSignupSection"

import BannerImage500 from "images/blog/Blog_20211012_SepBuffer/choppy-waves-500.jpg"
import BannerImage from "images/blog/Blog_20211012_SepBuffer/choppy-waves.jpg"
import jonAvatar from 'images/blog/avatar-100.jpg'
import chartPerf1m from 'images/blog/Blog_20211012_SepBuffer/performance-chart-1m.png'
import chartDelta12m from 'images/blog/Blog_20211012_SepBuffer/delta-chart-12m.png'
import chartPerf12m from 'images/blog/Blog_20211012_SepBuffer/performance-chart-12m.png'
import HypotheticalPerformanceAlert from "../../../common/alerts/HypotheticalPerformanceAlert";

const TLTOverwriteContext = React.createContext({})

const documentTitle = "Clarivor Blog"
const postTitle = 'When a Buffer Rolls in a Choppy Market'
const postSubtitle = 'In September 2021, buffers outperformed but there were lessons to learn.'
const postAuthor = 'Jon Spiegel, CFA'
const publishedOnText = 'October 13, 2021'
const publishedOnShort = 'Oct 13, 2021'
const path = '/blog/SepBuffer_20211012'

const HeroSection = (props) => (
    <section
        style={{
            backgroundImage: ["url(" + BannerImage + ")"],
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            position: 'relative',
            zIndex: 0,
            backgroundSize: 'cover',
            height: '50vh'
        }}>
    </section>
)

const HeaderSection = (props) => (
    <section className="pt-8 pt-md-11">
        <RequestDemoModal/>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 col-md-12 col-lg-10 col-xl-10">


                    <h1 className="display-4 text-center">
                        {props.title}
                    </h1>


                    <p className="lead mb-7 text-center text-muted">
                        {props.subtitle}
                    </p>


                    <div className="row align-items-center py-5 border-top border-bottom">
                        <div className="col-auto">


                            <div className="avatar avatar-lg">
                                <img src={props.avatarImage} alt="..."
                                     className="avatar-img rounded-circle"/>
                            </div>

                        </div>
                        <div className="col ml-n5">
                            <h6 className="text-uppercase mb-0">
                                {props.author}
                            </h6>
                        </div>
                        <div className="col-auto">
                            <time className="font-size-sm text-muted" dateTime="2019-05-20">
                                {props.publishedOnText}
                            </time>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
)


function Summary(props) {
    return (
        <React.Fragment>
            <h3 className={'font-weight-bold mt-6'}>
                Summary
            </h3>
            <ul>
                <li className='my-2'>
                    In September, buffered strategies linked to equities outperformed traditional equity investments, a
                    result that investors should typically expect during a market pull-back.
                </li>
                <li className='my-2'>
                    However, the behavior of buffers during the month highlighted certain characteristics that investors
                    can learn from.
                </li>
                <li className='my-2'>
                    In particular, a typical equity-linked buffer rolling in mid-September started the month behaving
                    almost like a cash investment and ended the month with significant equity exposure.
                </li>
            </ul>
        </React.Fragment>
    )
}


function Section1(props) {
    return (
        <React.Fragment>
            <h3 className={'font-weight-bold mt-6'}>
                Buffers in September
            </h3>
            <p>

            </p>
            <p>
                Since buffers usually have <Link to={'/blog/Blog_20210525_BufferDelta'}>less than 100% equity
                exposure</Link>, investors should generally expect them to
                outperform their underlying equity benchmarks in a sell-off. This was indeed the case last month.
                However, the timing of the September expiration in the middle of a
                market sell-off resulted in behavior that can be helpful to understand.
            </p><p>
            While buffers expiring in June or December behaved relatively consistently through the month, the
            performance of buffers expiring in mid-September was significantly impacted by the expiration in the
            middle of the month.
        </p><p>
            Going into September, for a typical buffer linked to the SPY ETF, the level of the ETF was significantly
            above the buffer’s upside cap. Therefore, the buffer had very little exposure to SPY’s returns in the
            first half of the month. Compared with the buffer expiring in December 2021, for example, the expiring
            buffer outperformed as SPY started to sell off.
        </p><p>
            However, when the September buffer rolled on the 17th, the equity exposure of the strategy suddenly
            increased. For the rest of the month, the September buffer under-performed the December buffer due to
            this higher exposure.
        </p>
            <p>
                While a buffer strategy’s market exposure normally changes on a roll, the impact on returns might be
                muted if the
                market is relatively stagnant around the expiration date. On the other hand, when volatility is elevated
                or the
                market’s returns are significant, investors may need to pay closer attention to any changes of risk from
                these option strategies.
            </p>

            <div className='card bg-light mb-3'>
                <div className='card-body p-2'>
                    <img style={{width: "100%"}} src={chartPerf1m}/>
                </div>
            </div>

            <h3 className={'font-weight-bold mt-6'}>
                What caused the change in exposure?
            </h3>
            <p>
                As described in <Link to={'/blog/Blog_20210525_BufferDelta'}>an earlier post</Link>, when the equity
                market rallies, the market sensitivity ("delta") of an equity-linked buffer typically decreases.
                In addition, when the underlying equity ETF or index is above the buffer’s
                cap, over time the exposure tends to decrease, such that if the buffer is expiring, and the ETF or index
                is above the cap, there can be effectively zero exposure.</p>
            <p>
                When a typical SPY 12-month buffer was traded in
                September 2020, it offered approximately 15% upside for protection against the first 10% price decline.
                Meanwhile,
                going into September 2021, SPY was up over 36% over the life of the buffer. Clearly, SPY was
                significantly through the September buffer’s cap, causing the
                buffer to have very low delta.
            </p>
            <p>
                The below chart illustrates the deltas of typical SPY buffers expiring in September and December. The
                percentage
                delta reflects amount of sensitivity of the buffers to changes in the price of SPY. For example,
                40% delta means that the value of the buffer is expected to increase (decrease) approximately 0.4% for
                an increase
                (decrease)
                of 1.0% in the price of SPY.
            </p>
            <div className='card bg-light mb-3'>
                <div className='card-body p-2'>
                    <img style={{width: "100%"}} src={chartDelta12m}/>
                </div>
            </div>
            <h3 className={'font-weight-bold mt-6'}>
                Buffers over the past year
            </h3>
            <p>
                As buffer investors should expect, when equities rally 36%, buffers will usually under-perform. In the
                following chart,
                the impact of the buffer caps and reduced deltas are evident in the rising market.
            </p>
            <div className='card bg-light mb-3'>
                <div className='card-body p-2'>
                    <img style={{width: "100%"}} src={chartPerf12m}/>
                </div>
            </div>
            <h3 className={'font-weight-bold mt-6'}>
                Buffers going forward
            </h3>
            <p>
                When investors better understand the behavior of buffered investment strategies, they can use them with
                more confidence in their portfolios.
            </p><p>
            Currently, investors may not expect that stocks will continue
            to rise at the pace they have recently, especially as SPY is only a few percentage
            points from its high on September 2nd. Similarly, with rates historically low, investors may not see
            much room for income or bond price appreciation. In this environment such investors may consider buffers as
            attractive substitutes for traditional equity and bond investments.
        </p>
            <h3 className={'font-weight-bold mt-6'}>
                More on buffers
            </h3>
            <p>
                See the following posts for more of Clarivor's work on buffer strategies.
            </p>
            <ul>
                <li>
                    <Link to='/blog/Blog_20210525_BufferDelta'>Are You Actively Managing Your Buffers?
                        (2021-05-25)</Link>
                </li>
                <li>
                    <Link to='/blog/BufferBondReplacement_20210624'>Buffers as Bond Replacements (2021-06-24)</Link>
                </li>
                <li>
                    <Link to='/blog/BufferPerformance_20210930'>Buffer Performance in September 2021 (2021-09-30)</Link>
                </li>
            </ul>
        </React.Fragment>
    )
}

function BufferPerformanceProjectContainer(props) {
    const [projectObj, setProjectObj] = useState(null)

    useEffect(() => {
        apiGet(keys.API_ENDPOINT.PROJECTS, {id: props.projectId}, globalAuthManager).then(
            res => {
                const [project] = res.data
                setProjectObj(project)
            }
        )
    }, [])

    const contextValue = {
        projectObj: projectObj,
        projectId: props.projectId
    }

    return (
        <TLTOverwriteContext.Provider value={contextValue}>
            {props.children}
        </TLTOverwriteContext.Provider>
    )
}


function DisclosuresLink(props) {
    return (
        <a data-toggle="modal" href="#disclaimerModal"
           onClick={() => null}>Disclosures</a>
        // onClick={() => mixpanel.track('Disclosure Clicked')}>Disclosures</a>
    )
}

function TermsOfUseLink(props) {
    return (
        <Link to={'/terms-of-use'} target={'_blank'}
              onClick={() => null}>Terms of Use</Link>
        // onClick={() => mixpanel.track('Terms of Use Clicked')}>Terms of Use</Link>
    )
}

function BacktestingSourceDisclaimer(props) {
    return (
        <div>
            <hr/>
            <p className='font-italic text-muted'>
                Source: Clarivor LLC. These results are back-tested and may not be indicative of future performance.
                Changes in strategy parameters, such as the buffer level, cap level, or the timing of the roll, can
                materially impact results.
                Each buffer includes an annual deduction of 0.49% to reflect management fees and commissions.
                SPY(TR) has dividends immediately reinvested, shown with no additional management fee. Buffers discussed
                refer to strategies on the SPY ETF using approximately 12-month maturities, designed to protect against
                the first 10% price decline by selling a call with a strike determined from historically prevailing
                market prices.
                Strikes are selected from the listed market.
                The buffer strategies are deemed to be traded at the close on the relevant expiration occurring on the
                third Friday
                of the month.

                {/*Options are rolled seven days prior to expiration at bid or offer.*!/*/}
                {/*Option prices are observed at 3:45pm NY time. Back-tested strategies include 0.47% annualized cost to*/}
                {/*reflect management fees and commissions.*/}
                <br/>
                <br/>
                NOTHING IN THIS POST SHOULD BE CONSTRUED AS INVESTMENT ADVICE. <br/>
                <DisclosuresLink/> and <TermsOfUseLink/> apply.
                <br/>
            </p>
        </div>
    )
}


function PostBody(props) {
    return (
        <React.Fragment>
            <section className="pt-0 pt-md-0">
                <ReceiveUpdatesModal/>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-12 col-lg-10 col-xl-9">
                            <Summary/>
                            <Section1/>
                            <BacktestingSourceDisclaimer/>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}


export default function BufferPerformance_20210803(props) {
    const projectId = null  // this is a static blog post

    useEffect(() => {
        document.title = documentTitle
    }, [])

    const headerProps = {
        title: postTitle,
        subtitle: postSubtitle,
        avatarImage: headshot,
        author: postAuthor,
        publishedOnText: publishedOnShort,
    }

    return (
        <React.Fragment>
            <HeroSection/>
            <HeaderSection {...headerProps} />
            <BufferPerformanceProjectContainer projectId={projectId}>
                <PostBody/>
            </BufferPerformanceProjectContainer>
            <BlogSignupSection fromLocation={'Blog Post TLT Overwriting'}/>
            <div className='container'>
                <HypotheticalPerformanceAlert/>
            </div>
        </React.Fragment>
    )
}


export function RichSepBuffer202110(props) {
    return (
        <section className="pt-7 pt-md-10">
            <div className="container">
                <div className="row">
                    <div className="col-12">

                        <div className="card card-row shadow-light-lg mb-6 lift lift-lg">
                            <div className="row no-gutters">
                                <div className="col-12">

                                <span className="badge badge-pill badge-light badge-float badge-float-inside">
                                <span className="h6 text-uppercase">Buffers</span>
                                </span>

                                </div>
                                <Link className="col-12 col-md-6 order-md-2 bg-cover card-img-right"
                                      style={{backgroundImage: "url(" + BannerImage500 + ")"}}
                                      to={path}>

                                    <img src="assets/img/photos/photo-27.jpg" alt="..."
                                         className="img-fluid d-md-none invisible"/>

                                    <div
                                        className="shape shape-left shape-fluid-y svg-shim text-white d-none d-md-block">
                                        <svg viewBox="0 0 112 690" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 0h62.759v172C38.62 384 112 517 112 517v173H0V0z"
                                                  fill="currentColor"></path>
                                        </svg>
                                    </div>

                                </Link>
                                <div className="col-12 col-md-6 order-md-1">

                                    <Link className="card-body" to={path}>

                                        <h3>
                                            {postTitle}
                                        </h3>

                                        <p className="mb-0 text-muted">
                                            {postSubtitle}
                                        </p>

                                    </Link>

                                    <a className="card-meta" href="#!">

                                        <hr className="card-meta-divider"/>

                                        <div className="avatar avatar-sm mr-2">
                                            <img src={jonAvatar} alt="..."
                                                 className="avatar-img rounded-circle"/>
                                        </div>

                                        <h6 className="text-uppercase text-muted mr-2 mb-0">
                                            Jon Spiegel
                                        </h6>

                                        <p className="h6 text-uppercase text-muted mb-0 ml-auto">
                                            <time dateTime="2020-10-23">{publishedOnShort}</time>
                                        </p>

                                    </a>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>

    )
}
