import React, {useEffect} from 'react'
import {Link} from "react-router-dom"
import NavBar from 'components/common/NavBarDefault'
import Footer from 'components/common/Footer'
import BreadcrumbNav from "../../common/BreadcrumbNav";
// import {setLKBody} from "../../../lkcss"

const JobDescription = (props) => (
    <React.Fragment>
        <h6 className='text-secondary font-weight-bold text-uppercase'>Job Opening</h6>
        <h2 className='text-primary font-weight-bold'>Quantitative Investments Developer</h2>
        <p className='text-secondary'>
            Clarivor is a fintech startup building a platform for cloud-based quantitative investment
            solutions. We are seeking a technical individual to help continue developing our investment capabilities,
            product and infrastructure.
        </p>

        <p className='text-secondary'>
            You’ll work closely with the Founder, a Wall Street veteran with deep experience in quantitative investments
            and derivatives. You'll leverage your skills and curiosity to make a significant impact on the company, as
            well as the industry, by bringing next-generation financial services to the market.
        </p>

        <p className='text-secondary'>
            The role is ideal for candidates with strong programming backgrounds who are seeking to grow their careers
            toward client-focused investment solutions.
        </p>

        <p className='text-secondary'>
            <span className='font-weight-bold text-dark'>Responsibilities</span>:
            <ul>
                <li>Develop software for accessing, screening, trading and managing risk of systematic investment
                    strategies
                </li>
                <li>Research, back-test and implement new investment strategies using options, futures, stocks and
                    ETFs
                </li>
                <li>Monitor and enhance performance of cloud-based applications</li>
                <li>Help prioritize the development of new investment strategies and capabilities</li>
                <li>Help determine the firm’s strategic use of new technologies and quantitative methodologies</li>
            </ul>
        </p>
        <p className='text-secondary'>
            <span className='font-weight-bold text-dark'>Qualifications</span>:
            <ul>
                <li> Bachelor’s or Master’s degree in computer science or related field
                </li>
                <li> 1+ years of work experience (or equivalent projects) in software development or quantitative
                    finance
                </li>
                <li> Preferably a strong knowledge of statistics and/or scientific computation
                </li>
                <li> Experience building applications with Python, JavaScript or C++ and working with large datasets
                </li>
            </ul>
        </p>
        <p className='text-secondary'>
            We’ll offer competitive salary and equity terms based on experience and qualifications.
        </p>
        <p className='text-secondary'>
            Clarivor is based in Midtown, New York City. Remote candidates may apply.
        </p>
        <p className='text-secondary'>
            To apply, please <Link to='/contact' className='font-weight-bold'>contact us here</Link>.
        </p>
        <hr className={'hr-md my-8'}/>
    </React.Fragment>
)

const JobDescriptionWrapper = (props) => (
    <div className='container'>
        <div className='row justify-content-center'>
            <div className='col-8'>
                {props.children}
            </div>
        </div>
    </div>
)

export default function Jobs(props) {
    const [oldTitle, setOldTitle] = React.useState('')

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <React.Fragment>
            <NavBar {...props}/>
            <BreadcrumbNav pathList={['Company', 'Jobs']}/>
            <section className='my-8'>
                <JobDescriptionWrapper>
                    <JobDescription/>
                </JobDescriptionWrapper>
            </section>
            <Footer/>
        </React.Fragment>
    )
}
