import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import React from "react"
import * as keys from "../../../../keys"


function createChartOptions(seriesOptions, strategyIndices, handleClickDatapoint, handleRender) {
    let chartColors = null
    const allColors = Object.values(keys.Color)
    if (strategyIndices && strategyIndices.length > 0) {
        chartColors = []
        for (let i = 0; i < strategyIndices.length; i++) {
            chartColors.push(allColors[strategyIndices[i]])
        }
    }

    let options =
        {
            series: seriesOptions,

            rangeSelector: {
                enabled: false
            },

            navigator: {
                enabled: false
            },

            scrollbar: {
                enabled: false
            },

            chart: {
                backgroundColor: 'transparent',

                events: {
                    render: function (event) {
                        // handleRender(event);
                    }
                },
                style: {
                    fontFamily: "Arial, Helvetica, sans-serif"
                }
            },

            legend: {
                enabled: true,
                itemStyle: {
                    "color": "#333333",
                    "cursor": "pointer",
                    "fontSize": "12px",
                    "fontWeight": "normal",
                    "textOverflow": "ellipsis"
                }
            },

            title: {
                text: 'Strike of Call for Zero Cost Collar (6m, 90% put strike)'
            },

            credits: {
                enabled: false
            },

            xAxis: {
                type: 'datetime'
            },

            yAxis: {
                title: {
                    text: ''
                },

                labels: {
                    formatter: function () {
                        // return (Math.round(this.value) > 0 ? ' + ' : '') + this.value * 100 + '%'
                        return this.value * 100 + '%'
                    }
                },
                plotLines: [{
                    value: 0,
                    width: 2,
                    color: 'silver'
                }]
            },

            plotOptions: {
                series: {
                    allowPointSelect: true,
                    // compare: 'percent',
                    // compareBase: 0,
                    // showInNavigator: true,
                    point: {
                        events: {
                            select: handleClickDatapoint
                        }
                    }
                }
            },

            tooltip: {
                pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
                valueDecimals: 3,
                split: true
            },

            exporting: {enabled: false},

            colors: Object.values(keys.Color)


        }

    return options
}


export default function IndicatorChart(props) {
    let seriesList = props.seriesList

    if (!seriesList || seriesList.length == 0) {
        return null
    }

    // for (let i = 0; i < seriesList.length; i++) {
    //     let data = seriesList[i].data
    //
    //     const datalen = data.length
    //     data = data.slice(datalen - props.days, datalen)
    //
    //     let ref = data[0][1]
    //     for (let j = 0; j < data.length; j++) {
    //         data[j][1] = 100 * (data[j][1] / ref)
    //     }
    //
    //     seriesList[i].data = data
    // }
    //
    return (
        <HighchartsReact highcharts={Highcharts} constructorType={'stockChart'}
                         options={createChartOptions(seriesList, props.colors)}/>
    )
}
