import React, {useContext, useEffect, useState} from "react"
import headshot from 'images/blog/avatar-100.jpg'
import RequestDemoModal from "components/pages/common/RequestDemoModal"
import {globalAuthManager, globalServices} from "services/GlobalServices"
import {apiGet} from "services/api-call-helpers"
import * as keys from 'keys'
// import {mixpanel} from "../../../../services/mixpanel"
import ReceiveUpdatesModal from "components/pages/common/ReceiveUpdatesModal"
import {Link} from "react-router-dom"
import BlogSignupSection from "components/blog/common/BlogSignupSection"

// import BannerImage from 'images/blog/Blog_20230203_SPYPuts/banner.jpg'
import BannerImage500 from 'images/blog/Blog_20230815_LongCallSpreads/ship.jpg'

import ppIllustration from 'images/blog/Blog_20230815_LongCallSpreads/principal-protected-strategy.png'
import callSpreadDiagram from 'images/blog/Blog_20230815_LongCallSpreads/call-spread diagram.png'
import annualizedIncomeChart from 'images/blog/Blog_20230815_LongCallSpreads/annualized-income-chart.jpg'
import selectedStrikeChart from 'images/blog/Blog_20230815_LongCallSpreads/selected-strike-chart.jpg'

import jonAvatar from 'images/blog/avatar-100.jpg'
import {setLKBody} from "../../../lkcss";
import {ParallaxProvider} from "react-scroll-parallax";
import NavBar from "../../common/NavBarDefault";
import AutomatedWorkflowSection from "../homepage/AutomatedWorkflowSection";
import Footer from "../../common/Footer";

const ProjectContext = React.createContext({})

const documentTitle = "404 Page Not Found: Clarivor"
const postTitle = '404 Page Not Found'
const postSubtitle = 'We\'re sorry, the page you are looking for does not exist. It may have been moved, or removed altogether. You might want to try the homepage to find what you are looking for.'
const publishedOnText = ''

const HeroSection = (props) => (
    <section
        className='bg-dark text-white'
        style={{
            position: 'relative',
            zIndex: 0,
            minHeight: '35vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start', // This line has been modified to left align the text.
            backgroundColor: '#f0f0f0', // You can adjust the color as you wish
            color: '#000', // You can adjust the color as you wish
            padding: '20px'
        }}>

        <div className='container bg-dark text-white'>
            <div className={'row justify-content-center'}>
                <div className="col-12 col-md-12 col-lg-10 col-xl-9">
                    <h1>404 Page Not Found</h1>
                    <p>We're sorry, the page you are looking for does not exist. It may have been moved, or removed
                        altogether. You might want to try the homepage to find what you are looking for.</p>
                </div>
            </div>
        </div>

    </section>)


export default function PageNotFound(props) {
    useEffect(() => {
        document.title = documentTitle
    }, [])

    setLKBody()

    return (
        <React.Fragment>
            <NavBar {...props}/>
            <HeroSection/>
            <div className="container d-flex justify-content-center align-items-center" style={{minHeight: '20vh'}}>
                <Link to="/" className="btn btn-primary">Back to clarivor.com</Link>
            </div>
            <Footer {...props}/>
        </React.Fragment>
    )
}
