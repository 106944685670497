import {nominalTypeHack} from "prop-types";
import axios from 'axios'


export default class IndicatorTSManager {
    constructor(apiURL, authManager, projectId) {
        this.url = apiURL
        this.authManager = authManager
        this.dict = {}
        this.projectId = projectId
    }

    retrieveTSList(uuids, callback) {
        let seriesOptions = []
        let nReceived = 0

        for (let i = 0; i < uuids.length; i++) {
            const config = {
                headers: {'Authorization': "Bearer " + this.authManager.accessToken},
                params: {'uuid': uuids[i]}
            }

            axios.get(this.url, config)

                .then(res => {
                        console.log('retrieveTSList', res.data);
                        this.dict[uuids[i]] = res.data[0]
                        seriesOptions[i] = {
                            name: res.data[0].name,
                            data: res.data[0].timeseries,
                            uuid: uuids[i]
                        }
                        console.log('seriesOptions', seriesOptions)
                        nReceived++
                        if (nReceived == uuids.length) {
                            callback(seriesOptions)
                        }
                    }
                );
        }
    }


    async retrieveTSListAsync(uuids) {
        const seriesOptions = Promise.all(uuids.map(async uuid => {

                if (uuid == null) {
                    return null
                }

                const config = {
                    headers: {'Authorization': "Bearer " + this.authManager.accessToken},
                    params: {uuid: uuid, project: this.projectId}
                }
                const res = await axios.get(this.url, config)

                this.dict[uuid] = res.data[0]

                // window.alert(res.data[0].id + res.data[0].name)
                return {
                    name: res.data[0].name,
                    data: JSON.parse(res.data[0].timeseries),
                    uuid: uuid,
                    params: JSON.parse(res.data[0].params),
                    indicator_class: res.data[0].indicator_class,
                }
            }
            )
        )
        return seriesOptions
    }

}