import React, {Component} from 'react';
import {mixpanel} from "../../services/mixpanel"
import {globalAuthManager} from "../../services/GlobalServices";
import {mapClasses} from "../../util/cssModule"

const lk = null
// const mapClasses = (dummy, classstr) => (classstr)

class SignInModal extends Component {
    state = {
        username: '',
        password: '',
        failureMessage: '',
    }



    handleSubmit = (event) => {
        event.preventDefault();
        const signedIn = globalAuthManager.loginAsync(this.state.username, this.state.password)
        if (signedIn) {
            mixpanel.identify(this.state.username)
            mixpanel.track('Signed In')
        } else {
            mixpanel.track('Sign In Failed')
        }
    }

    handleChange = (event) => {
        const targetname = event.target.name
        this.setState({[targetname]: event.target.value})
    }

    handleSuccess = () => {
        this.props.history.push('/app')
    }

    handleFailure = () => {

    }

    render() {
        return (
            <React.Fragment>
                {/* modal doesn't work if first 2 div classnames are hashed via CSS module! */}
                <div className="modal fade" id="modalSigninVertical" tabIndex="-1" role="dialog"
                     aria-labelledby="modalSigninVerticalTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className={mapClasses(lk, "modal-content")}>
                            <div className={mapClasses(lk, "card")}>

                                {/* <!-- Close --> */}
                                <button type="button" className={mapClasses(lk, "modal-close close text-muted")}
                                        data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>

                                {/* <!-- Shape --> */}
                                <div className={mapClasses(lk, "position-relative")}>
                                    <div className="shape shape-bottom shape-fluid-x svg-shim text-white">
                                    </div>
                                </div>

                                {/* <!-- Body --> */}
                                <div className={mapClasses(lk, "card-body")}>

                                    {/* <!-- Heading --> */}
                                    <h2 className={mapClasses(lk, "mb-5 font-weight-bold text-center")}
                                        id="modalSigninVerticalTitle">
                                        Sign In
                                    </h2>

                                    {/* <!-- Text --> */}
                                    {/*<p className={mapClasses(lk, "mb-6 text-center text-muted")}>*/}
                                    {/*    Sign into the beta website to start using Clarivor*/}
                                    {/*</p>*/}

                                    {/* <!-- Form --> */}
                                    <form className={mapClasses(lk, "mb-6")}>

                                        {/* <!-- Email --> */}
                                        <div className={mapClasses(lk, "form-group")}>
                                            <label className={mapClasses(lk, "sr-only")} htmlFor="modalSigninVerticalEmail">
                                                Your email
                                            </label>
                                            <input type="email" onChange={this.handleChange}
                                                   className={mapClasses(lk, "form-control")}
                                                   id="modalSigninVerticalEmail" name='username'
                                                   placeholder="Enter email address"/>
                                        </div>

                                        {/* <!-- Password --> */}
                                        <div className={mapClasses(lk, "form-group mb-5")}>
                                            <label className={mapClasses(lk, "sr-only")}
                                                   htmlFor="modalSigninVerticalPassword">
                                                Your password
                                            </label>
                                            <input type="password" onChange={this.handleChange}
                                                   className={mapClasses(lk, "form-control")} name='password'
                                                   id="modalSigninVerticalPassword" placeholder="Enter password"/>
                                        </div>

                                        {/* <!-- Submit --> */}
                                        <button className={mapClasses(lk, "btn btn-block btn-primary")}
                                                data-dismiss="modal" onClick={this.handleSubmit}>
                                            Sign in
                                        </button>

                                    </form>

                                    {/*<p className={mapClasses(lk, "mb-0 font-size-sm text-center text-muted")}>*/}
                                    {/*    Don't have an account yet? <a href="/pricing">Sign up</a>.*/}
                                    {/*</p>*/}

                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>

        );
    }
}

export default SignInModal;