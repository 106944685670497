import {nominalTypeHack} from "prop-types";
import axios from 'axios'


export default class StockHistoryManager {
    constructor(apiURL, authManager) {
        this.url = apiURL
        this.authManager = authManager
        this.dict = {}

        console.log('AuthManager', authManager)
    }

    async retrieveStockHistoryAsync(symbols) {
        const seriesOptions = Promise.all(symbols.map(async symbol => {
                if (!(symbol in this.dict)) {
                    const config = {
                        headers: {'Authorization': "Bearer " + this.authManager.accessToken},
                        params: {'symbol': symbol}
                    }

                    const res = await axios.get(this.url, config)
                    const series = JSON.parse(res.data[0].data)
                    this.dict[symbol] = {
                        symbol: res.data[0].symbol,
                        data: series
                    }
                } else {
                    console.log('cache found for', symbol)
                }
                return JSON.parse(JSON.stringify(this.dict[symbol]))
            }
            )
        )
        console.log(seriesOptions)
        return seriesOptions
    }
}


