import {nominalTypeHack} from "prop-types"
import axios from 'axios'
import {formatDate} from 'util/dates'
import {sortHistoryDays} from "./account-history-calcs";

export default class AccountHoldingsHistoryManager {
    constructor(apiURL, authManager) {
        this.url = apiURL
        this.authManager = authManager
        this.historyDays = []
    }

    async retrieveHistoryDaysAsync(authManager) {
        let seriesOptions = []
        let nReceived = 0

        const config = {
            headers: {'Authorization': "Bearer " + authManager.accessToken},
            // params: { 'uuid': uuids[i] }
        }

        const res = await axios.get(this.url, config)
        this.historyDays = res.data
        // this.historyDays.sort((a, b) => b.calc_date.localeCompare(a.calc_date))

        // sorted on server side.
        return res.data

        // return this.historyDays
    }

    static getAccountNAVTimeseries(historyDays) {
        let series = []

        for (let i = 0; i < historyDays.length; i++) {
            const calcDate = new Date(historyDays[i].calc_date)
            series.push([calcDate.getTime(), historyDays[i].holdings.NAV])
        }

        series.sort((a, b) => (a[0] > b[0] ? 1 : -1))

        return series
    }

    // include all baskets and root strategies
    static getAccountStrategyNAVTimeseries(historyDays, strategyId) {
        let series = []

        for (let iDay = 0; iDay < historyDays.length; iDay++) {
            let dailyNAV = 0

            const calcDate = new Date(historyDays[iDay].calc_date)

            historyDays[iDay].holdings.strategies.map(strategy => {
                if (strategyId === strategy.strategy_id) {
                    dailyNAV += strategy.NAV
                }
            })

            if (historyDays[iDay].holdings.baskets != null) {
                historyDays[iDay].holdings.baskets.map(basket => {
                    basket.strategies.map(strategy => {
                            if (strategy.strategy_id === strategyId) {
                                dailyNAV += strategy.NAV
                            }
                        }
                    )
                })
            }

            series.push([calcDate.getTime(), dailyNAV])
        }

        series.sort((a, b) => (a[0] > b[0] ? 1 : -1))

        return series
    }
}