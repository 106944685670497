import React, {useContext, useEffect, useState} from "react"
import headshot from 'images/blog/avatar-100.jpg'
import RequestDemoModal from "../../../pages/common/RequestDemoModal"
import {globalAuthManager, globalServices} from "../../../../services/GlobalServices"
import {apiGet} from "../../../../services/api-call-helpers"
import * as keys from 'keys'
// import {mixpanel} from "../../../../services/mixpanel"
import ReceiveUpdatesModal from "../../../pages/common/ReceiveUpdatesModal"
import {Link} from "react-router-dom"
import BlogSignupSection from "../../common/BlogSignupSection"

import BannerImage from 'images/blog/Blog_20220722_SPYCollar/banner.jpg'
import BannerImage500 from 'images/blog/Blog_20220722_SPYCollar/banner500.jpg'

import collarStrikeChart from 'images/blog/Blog_20220722_SPYCollar/collar-strike.png'
import callPriceVsPutPrice from 'images/blog/Blog_20220722_SPYCollar/call-vs-put-price.png'

import prosAndCons from 'images/blog/Blog_20220120_TLTHedges/ProsAndCons.png'

import jonAvatar from 'images/blog/avatar-100.jpg'

const ProjectContext = React.createContext({})

const documentTitle = "Clarivor Blog"
const postTitle = 'Equity Collar Tail Hedge'
const postSubtitle = 'Investors requiring tail risk protection might find collar pricing attractive currently.'
const postAuthor = 'Jon Spiegel, CFA'
const publishedOnText = 'July 25, 2022'
const publishedOnShort = 'Jul 25'
export const path = 'equity-collar-20220725'

const HeroSection = (props) => (
    <section
        style={{
            backgroundImage: ["url(" + BannerImage + ")"],
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            position: 'relative',
            zIndex: 0,
            backgroundSize: 'cover',
            height: '35vh'
        }}>
    </section>
)

const HeaderSection = (props) => (
    <section className="pt-8 pt-md-11">
        <RequestDemoModal/>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 col-md-12 col-lg-10 col-xl-10">


                    <h1 className="display-4 text-center">
                        {props.title}
                    </h1>


                    {/*<p className="lead mb-7 text-center text-muted">*/}
                    <p className="lead px-10 mb-7 text-center text-muted">
                        {props.subtitle}
                    </p>


                    <div className="row align-items-center py-5 border-top border-bottom">
                        <div className="col-auto">


                            <div className="avatar avatar-lg">
                                <img src={props.avatarImage} alt="..."
                                     className="avatar-img rounded-circle"/>
                            </div>

                        </div>
                        <div className="col ml-n5">
                            <h6 className="text-uppercase mb-0">
                                {props.author}
                            </h6>
                        </div>
                        <div className="col-auto">
                            <time className="font-size-sm text-muted" dateTime="2019-05-20">
                                {props.publishedOnText}
                            </time>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
)


function Summary(props) {
    return (
        <React.Fragment>
            <h3 className={'font-weight-bold mt-6'}>
                Summary
            </h3>
            <ul>
                <li className='my-2'>
                    Investors such as individual retirees and defined-benefit pension plans may have objectives
                    that require managing risk against extreme moves in the equity market.
                </li>
                {/*<li className='my-2'>*/}
                {/*    The current interest rate environment could make it unattractive to de-risk by shifting allocations*/}
                {/*    from equities to bonds.*/}
                {/*</li>*/}
                <li className='my-2'>
                    While buying put-options outright is typically expensive, a recent increase in the price of calls
                    relative to puts could make collars attractive for hedging tail risk with zero upfront costs.
                </li>
            </ul>
        </React.Fragment>
    )
}


function Section1(props) {
    const putRef = React.createRef()
    const psRef = React.createRef()
    const collarRef = React.createRef()
    const pscRef = React.createRef()


    return (
        <React.Fragment>
            <h3 className={'font-weight-bold mt-6'}>
                The Challenge of Tail Risk Protection
            </h3>
            <p>
                With new uncertainties in the market in 2022, and recent memory of equities trading over 40% below
                current levels in 2020, some risk-averse investors may want to avoid taking tail risk in equity markets.
            </p>
            <p>
                Buying puts to protect against tail risk can be challenging because the market typically prices put
                options like insurance contracts: a strategy of consistently buying puts often
                under-performs as the puts expire worthless. Meanwhile, most investment managers cannot time the market
                to buy protection only when it's likely to be profitable.
            </p>
            <p>
                Nonetheless, investors such as individual retirees and defined-benefit pension plans may require
                protection against extreme market moves to satisfy their investment objectives. In some environments
                they may decide to increase their allocations to bonds vs stocks in order to reduce the variability of
                their nominal returns. However, given the recent volatility in interest rates, as well as stubbornly
                high inflation, shifting from equities to bonds may not be attractive in the current market.
            </p>
            <h3 className={'font-weight-bold mt-6'}>
                Collars for De-risking
            </h3>
            <p>
                A widely-known potential solution is to buy puts with the proceeds from selling calls, avoiding
                the upfront cost of purchasing the puts. An investor using
                a so-called collar is effectively sacrificing potential for gains above a cap for protection against
                the market falling below a given floor.
            </p>
            <p>
                In different market conditions, the collar may appear more or less attractive depending on the relative
                prices of puts and calls. When calls are relatively cheap, the investor needs to reduce the cap,
                sacrificing more upside, in order to pay for the puts. Conversely, when calls are relatively expensive,
                the investor can increase the cap, and therefore the potential gains, to pay for the same level of
                protection.
            </p>
            <p>
                Recently, due to changes in the supply and demand for puts vs. calls, in part driven by interest rates,
                calls have become much more expensive relative to puts:
            </p>
            <div className='card mx-auto my-6' style={{maxWidth: '90%'}}>
                <div className='card-body p-2  bg-white'>
                    <h3 className='text-center'>
                        Historical Call vs Put Price
                    </h3>
                    <img src={callPriceVsPutPrice} className='img-fluid'/>
                </div>
            </div>
            <p>
                This change in the relative pricing of puts vs. calls means that for a collar with zero upfront cost,
                an investor can now retain more upside when using a collar to obtain the same level of downside
                protection. For example, an investor can currently buy a put on the SPY ETF, 20% out-of-the-money by
                selling a call 15% out-of-the-money. The 15% represents better upside than 97% of trading days over the
                past 10 years.
            </p>
            <div className='card mx-auto my-6' style={{maxWidth: '90%'}}>
                <div className='card-body p-2  bg-white'>
                    <h3 className='text-center'>
                        Collar Upside Over Time
                    </h3>
                    <img src={collarStrikeChart} className='img-fluid'/>
                </div>
            </div>


            {/*<p>*/}
            {/*    As typical during periods of uncertainty, option prices are relatively elevated, making it more*/}
            {/*    expensive to simply buy equity put options to protect a portfolio. For example, a SPY 12-month put, 25%*/}
            {/*    out-of-the-money, would cost approximately 2.5% currently. That's higher than it's been about [85%] of*/}
            {/*    trading days over the past 5 years. An equity allocation employing this strategy could underperform by*/}
            {/*    2.5% in with a strong likelihood.*/}
            {/*</p>*/}
            {/*<p>*/}
            {/*    A common way of reducing the likelihood of under-performance while acquiring tail risk protection is by*/}
            {/*    selling call options and using the proceeds to pay for the protection. In this fashion, the net upfront*/}
            {/*    cost of the trade can be zero. sOften called a collar, this*/}
            {/*    strategy effectively limits the potential upside of the portfolio, over the life of the trade, while*/}
            {/*    also limiting the potential losses.*/}
            {/*</p>*/}
            {/*<p>*/}
            {/*    The amount of upside that needs to be sacrificed to pay for a given level of protection depends on the*/}
            {/*    relative prices of puts and calls. Factors that can impact the upside of a collar with zero upfront cost*/}
            {/*    include: (1) The market's expectation of volatility in a selloff (2) the market's expectation of*/}
            {/*    volatility in a rally (3) the level of interest rates (4) supply vs demand in the market for*/}
            {/*    the "insurance" provided by options.*/}
            {/*</p>*/}
            {/*<p>*/}
            {/*    For example, since the equity market tends to get more volatile when it sells off, a zero-upfront-cost*/}
            {/*    collar strategy usually requires the strike of the put to be further out-of-the-money than the call, in*/}
            {/*    order to make their prices equal.*/}
            {/*</p>*/}
            {/*<p>*/}
            {/*    Interest rates can also have a significant impact on the strike of the call to set the price equal to*/}
            {/*    that of the put. When interest rates are higher, calls get more expensive relative to puts.*/}
            {/*</p>*/}
            {/*<p>*/}
            {/*    While interest rates can be observed directly, the market's expectation of volatility for puts and calls*/}
            {/*    cannot. Pricing models often use "implied volatility" to convert the option's price into a volatility*/}

            {/*</p>*/}
            {/*<p>*/}

            {/*    like credit spreads or insurance premiums, the amount of capital available in the market could cause*/}
            {/*    prices to be different than what statistics might suggest.*/}
            {/*</p>*/}


            {/*<h3 className={'font-weight-bold mt-6'}>*/}
            {/*    Final thoughts*/}
            {/*</h3>*/}
            {/*<p>*/}
            {/*    When equity markets perform like they did in 2019 - 2021, and bonds provide diversification like they*/}
            {/*    did during the temporary March 2020 sell-off, it's hard to improve on holding a traditional portfolio.*/}
            {/*    However, due to a range of factors such positive returns may not persist in coming years, and investors*/}
            {/*    with may look to boost performance with alternative strategies. Short puts may be a valuable tool to*/}
            {/*    consider, given their potential to enhance portfolio returns in a range of market scenarios.*/}
            {/*</p>*/}
        </React.Fragment>
    )
}

function BufferPerformanceProjectContainer(props) {
    const [projectObj, setProjectObj] = useState(null)

    useEffect(() => {
        apiGet(keys.API_ENDPOINT.PROJECTS, {id: props.projectId}, globalAuthManager).then(
            res => {
                const [project] = res.data
                setProjectObj(project)
            }
        )
    }, [])

    const contextValue = {
        projectObj: projectObj,
        projectId: props.projectId
    }

    return (
        <ProjectContext.Provider value={contextValue}>
            {props.children}
        </ProjectContext.Provider>
    )
}


function DisclosuresLink(props) {
    return (
        <a data-toggle="modal" href="#disclaimerModal"
           onClick={() => null}>Disclosures</a>
// onClick={() => mixpanel.track('Disclosure Clicked')}>Disclosures</a>
    )
}

function TermsOfUseLink(props) {
    return (
        <Link to={'/terms-of-use'} target={'_blank'}
              onClick={() => null}>Terms of Use</Link>
// onClick={() => mixpanel.track('Terms of Use Clicked')}>Terms of Use</Link>
    )
}

function BacktestingSourceDisclaimer(props) {
    return (
        <div>
            <hr/>
            <p className='font-italic text-muted'>
                Source: Clarivor LLC. Strikes and maturities are estimated from listed strikes and tenors for easier
                comparison.

                {/*All buffers above refer to a SPY 12-month buffer traded on 12/18/2021, providing 10% downside*/}
                {/*protection, collateralized with cash.*/}
                {/*Options can entail complex risks and may not be suitable for all investors.<br/>*/}
                {/*Each option strategy back-test above includes an annual deduction of 0.49% to reflect management fees*/}
                {/*and commissions. SPY (TR) and AGG (TR) have dividends immediately reinvested, shown with no additional*/}
                {/*management fee.*/}
                {/*Strikes are selected from the listed market.*/}
                {/*Options are rolled seven days prior to expiration at bid or offer.*/}
                Option prices are observed at 3:45pm NY time.
                <br/>
                <br/>
                NOTHING IN THIS POST SHOULD BE CONSTRUED AS INVESTMENT ADVICE. <br/>
                <DisclosuresLink/> and <TermsOfUseLink/> apply.
                <br/>
            </p>
        </div>
    )
}


function PostBody(props) {
    return (
        <React.Fragment>
            <section className="pt-0 pt-md-0">
                <ReceiveUpdatesModal/>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-12 col-lg-10 col-xl-9">
                            <Summary/>
                            <Section1/>
                            <BacktestingSourceDisclaimer/>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}


export default function BufferPerformance_20210803(props) {
    const projectId = null  // this is a static blog post

    useEffect(() => {
        document.title = documentTitle
    }, [])

    const headerProps = {
        title: postTitle,
        subtitle: postSubtitle,
        avatarImage: headshot,
        author: postAuthor,
        publishedOnText: publishedOnText,
    }

    return (
        <React.Fragment>
            <HeroSection/>
            <HeaderSection {...headerProps} />
            <BufferPerformanceProjectContainer projectId={projectId}>
                <PostBody/>
            </BufferPerformanceProjectContainer>
            <BlogSignupSection fromLocation={'Blog Post TLT Overwriting'}/>
        </React.Fragment>
    )
}


export function RichSPYCollar20220722(props) {
    return (
        <section className="pt-7 pt-md-10">
            <div className="container">
                <div className="row">
                    <div className="col-12">

                        <div className="card card-row shadow-light-lg mb-6 lift lift-lg">
                            <div className="row no-gutters">
                                <div className="col-12">

<span className="badge badge-pill badge-light badge-float badge-float-inside">
<span className="h6 text-uppercase">hedging</span>
</span>
                                </div>
                                <Link className="col-12 col-md-6 order-md-2 bg-cover card-img-right"
                                      style={{backgroundImage: "url(" + BannerImage500 + ")"}}
                                      to={props.blogPath + path}>

                                    <img src="assets/img/photos/photo-27.jpg" alt="..."
                                         className="img-fluid d-md-none invisible"/>

                                    <div
                                        className="shape shape-left shape-fluid-y svg-shim text-white d-none d-md-block">
                                        <svg viewBox="0 0 112 690" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 0h62.759v172C38.62 384 112 517 112 517v173H0V0z"
                                                  fill="currentColor"></path>
                                        </svg>
                                    </div>

                                </Link>
                                <div className="col-12 col-md-6 order-md-1">

                                    <Link className="card-body" to={props.blogPath + path}>

                                        <h3>
                                            {postTitle}
                                        </h3>

                                        <p className="mb-0 text-muted">
                                            {postSubtitle}
                                        </p>

                                    </Link>

                                    <a className="card-meta" href="#!">

                                        <hr className="card-meta-divider"/>

                                        <div className="avatar avatar-sm mr-2">
                                            <img src={jonAvatar} alt="..."
                                                 className="avatar-img rounded-circle"/>
                                        </div>

                                        <h6 className="text-uppercase text-muted mr-2 mb-0">
                                            Jon Spiegel
                                        </h6>

                                        <p className="h6 text-uppercase text-muted mb-0 ml-auto">
                                            <time dateTime="2020-10-23">{publishedOnShort}</time>
                                        </p>

                                    </a>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>

    )
}
