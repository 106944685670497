//both outperform 0.3137254901960784
// SPY outperforms only 0.23975044563279857
// TLT outperforms only 0.40641711229946526
// Both underperform 0.040106951871657755
import * as keys from 'keys'

export const spyTltSeriesOptions =
    [{
        name: 'SPY-TLT',
        colorByPoint: true,
        data: [{
            name: 'Only SPY Buy-Write Outperforms',
            y: 23.82,
            color: keys.Color.blue
        }, {
            name: 'SPY and TLT Buy-Writes Outperform',
            y: 39.86,
            color: keys.Color.green
        }, {
            name: 'Only TLT Buy-Write Outperforms',
            y: 31.63,
            color: keys.Color.yellow,
        }, {
            name: 'Neither Buy-Write Outperforms',
            y: 4.68,
            color: keys.Color.red,
            // sliced: true,
            // selected: true
        }]

    }]


export const spyGldSeriesOptions =
    [{
        name: 'SPY-GLD',
        colorByPoint: true,
        data: [{
            name: 'Only SPY Buy-Write Outperforms',
            y: 11.03,
            color: keys.Color.blue
        }, {
            name: 'SPY and GLD Buy-Writes Outperform',
            y: 52.61,
            color: keys.Color.green
        }, {
            name: 'Only GLD Buy-Write Outperforms',
            y: 32.18,
            color: keys.Color.yellow,
        }, {
            name: 'Neither Buy-Write Outperforms',
            y: 4.19,
            color: keys.Color.red,
            // sliced: true,
            // selected: true
        }]

    }]

export const spyEfaSeriesOptions =
    [{
        name: 'SPY-EFA',
        colorByPoint: true,
        data: [{
            name: 'Only SPY Buy-Write Outperforms',
            y: 6.35,
            color: keys.Color.blue
        }, {
            name: 'SPY and EFA Buy-Writes Outperform',
            y: 57.42,
            color: keys.Color.green
        }, {
            name: 'Only EFA Buy-Write Outperforms',
            y: 19.72,
            color: keys.Color.yellow,
        }, {
            name: 'Neither Buy-Write Outperforms',
            y: 16.51,
            color: keys.Color.red,
            // sliced: true,
            // selected: true
        }]
    }]


export function pieChartOptions(title, seriesOptions) {
    return {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            style: {
                fontFamily: 'HKGroteskPro'
            }
        },
        credits: {
            enabled: false
        },
        title: {
            text: title
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                }
            }
        },
        series: seriesOptions
    }
}