import React, {Component} from 'react'
import brandLogo from 'images/clarivor-p-500.png'
import {Link} from 'react-router-dom'
// import lk from "*.module.css"
import {AppContext} from "../contexts"
import SignInModal from "./SignInModal"
import {mixpanel} from "../../services/mixpanel"
import {globalAuthManager} from "../../services/GlobalServices"

// import lk from 'lk/dist/assets/css/theme.module.css'

// not using module:
import {mapClasses} from "../../util/cssModule"
// const mapClasses = (module, classstr) => module == null ? classstr : module[classstr]
// const mapClassesLK = (classname) => lk[classname]

const navBarToggleStyle = {}
const lkNavLinkStyle = {}

const navBarStyle = {
    paddingRight: '1rem',
    paddingLeft: '1rem',
    paddingTop: '1.25rem',
    paddingBottom: '1.25rem',
    border: 'none'
}

const navBarBrandStyle = {
    // paddingTop: '.15rem',
    // paddingBottom: '.15rem',
    // marginRight: '0',
}


const navBarButtonStyle = {
    paddingTop: '0.5625rem',
    paddingRight: '1rem',
    paddingBottom: '0.5625rem',
    paddingLeft: '1rem',
    fontWeight: '400',
    fontFamily: 'Arial, Helvetica, sans-serif',
    fontSize: '1.0625rem',
}
//
// const navBarToggleStyle = {
//     color: '#506690',
//     borderColor: 'transparent'
// }

// const lkNavLinkStyle = {
//     margin: '0px',
//     fontFamily: 'HKGroteskPro',
//     fontSize: '1.0625rem',
//     lineHeight: '1.6',
//     textAlign: 'left',
//     backgroundColor: 'transparent',
//     paddingLeft: '1.5rem',
//     paddingRight: '1.5rem',
//     fontWeight: '600',
// }


class SignInButton extends Component {

    render() {

        return (
            <button type='button' className={mapClasses(null, "navbar-btn btn btn-sm btn-outline-dark lift ml-auto")}
                    data-toggle="modal"
                    data-target="#modalSigninVertical"
                    style={navBarButtonStyle}>
                Sign in
            </button>
        )
    }

}

function RequestDemoButton(props) {
    return (
        <button type='button' className={mapClasses(null, "navbar-btn btn btn-sm btn-primary lift ml-auto")}
                data-toggle="modal"
                data-target="#requestDemoModal"
                style={navBarButtonStyle}>
            Request Demo
        </button>
    )
}


function SignOutButton(props) {
    const appContext = React.useContext(AppContext)

    const logout = () => {
        const sourcePath = props.location ? props.location.pathname : ''
        mixpanel.track('Signed Out', {Source: sourcePath})
        globalAuthManager.logout()
    }

    return (
        <button type='button' className={mapClasses(null, "navbar-btn btn btn-sm btn-light ml-auto")}
                style={navBarButtonStyle}
                onClick={logout}
                data-toggle="tooltip"
                data-placement="top"
                title={"Sign out " + appContext.username}
        >
            Sign out
        </button>
    )
}


function UserOrSignInButton(props) {
    const appContext = React.useContext(AppContext)

    switch (appContext.isLoggedIn) {
        case false:
            return <SignInButton {...props} />
        case true:
            return <SignOutButton {...props} />
        default:
            return (
                <button type='button'
                        className={mapClasses(null, "navbar-btn btn btn-sm btn-light ml-auto text-light")}
                        style={navBarButtonStyle}>
                    Waiting
                </button>
            )
    }
}

export default function NavBarInvestmentApp(props) {

    return (
        <nav className="navbar navbar-expand-md navbar-light mb-2  d-flex" style={navBarStyle}>
            <div className="container-fluid">


                {/* Brand  */}
                <span className={mapClasses(null, "navbar-brand")} style={navBarBrandStyle}>
                        <Link to='/home'>
                            <img src={brandLogo} style={{width: 'auto', maxHeight: '37.5px'}}
                                 className="navbar-brand-img"
                                 alt="..."/>
                        </Link>
                    </span>


                {/* Toggler  */}
                <button className="navbar-toggler collapsed" type="button" data-toggle="collapse"
                        data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="navbar-collapse collapse ml-auto" id="navbarSupportedContent" style={{}}>

                    <RequestDemoButton/>
                </div>

            </div>
        </nav>
    )
}
