import {apiPost} from "./api-call-helpers"
import {API_ENDPOINT} from "../keys"
import {globalAuthManager} from "./GlobalServices"
import {mixpanel} from "services/mixpanel"

export function track_referral_email_link(trackIds, path) {
    const sfTemplateId18 = trackIds.substr(0, 18)
    const sfLeadId15 = trackIds.substr(trackIds.length - 15, 15)
    const acId = trackIds.substr(18, trackIds.length - 33)

    mixpanel.alias('acId: ' + acId)
    mixpanel.track('email_referral', {sfTemplateId18: sfTemplateId18, sfLeadId: sfLeadId15})

    const data = {
        'id': sfTemplateId18 + acId + sfLeadId15,
        'path': path
    }

    track_ux_event(localStorage.uuid, 'email_referral', data, globalAuthManager)

}


export function track_ux_event(uuid, event_name, data, authManager) {
    apiPost(
        API_ENDPOINT.UX_EVENT,
        {
            uuid: uuid,
            name: event_name,
            data: data
        },
        authManager
    )
}