import React, {Component} from 'react'
// import heroImage from 'images/iStock-regatta-crew-p-2000.jpeg'
//import heroImage from 'images/regatta-2020-08-08.jpeg'
import heroImage from 'images/regatta-2020-08-08-2000-50quality.jpeg'
import {Parallax} from 'react-scroll-parallax'

// import {setLKBody} from 'lkcss'
import {mapClasses} from 'util/cssModule'
import {track_ux_event} from "../../../services/clarivor-tracking";
import {globalAuthManager} from "../../../services/GlobalServices";

class HeroSection extends Component {
    render() {
        // setLKBody()

        return (
            <React.Fragment>
                <div className={'px-4'}>
                    <section className={mapClasses(null, "pt-8 pb-10 pt-md-12 pb-md-14 bg-white")}
                             style={{
                                 backgroundImage: ['linear-gradient(90deg, rgba(38, 32, 60, .5), rgba(38, 32, 60, .7) 100%)', "url(" + heroImage + ")"],
                                 backgroundRepeat: 'no-repeat',
                                 position: 'relative',
                                 zIndex: 0,
                                 backgroundSize: 'cover',
                                 // maxHeight: '75vh',
                                 minHeight: '60vh'
                             }}>

                        <div className={mapClasses(null, "container")}>
                            <div className={mapClasses(null, "row align-items-center")}>
                                <div className={mapClasses(null, "col")}></div>
                                <div className={mapClasses(null, "col-12 col-md-8 col-lg-6 mt-8 ")}>
                                    <Parallax y={[-20, 20]}>
                                        <span className={mapClasses(null, "display-2 text-white mb-5")}>
                                            Take More Control of Risk and Reward
                                        </span>
                                        <p className={mapClasses(null, "lead text-white my-6")}>
                                            Invest in custom option strategies efficiently and
                                            transparently
                                        </p>
                                        <a data-toggle="modal" href="#requestDemoModal" style={{textDecoration: 'none'}}
                                           onClick={() => {
                                               track_ux_event(localStorage.uuid, 'Request Demo', {source: 'Homepage Hero'}, globalAuthManager)
                                           }}>
                                            <button className="btn btn-success lift mt-2 d-flex">
                                                <span className={'my-auto'}>Request Demo</span>
                                            </button>
                                        </a>
                                    </Parallax>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div className="position-relative">
                    <div className="shape shape-bottom shape-fluid-x svg-shim text-light">
                        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                        </svg>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default HeroSection