import React, {useContext, useEffect, useState} from "react"
import headshot from 'images/blog/avatar-100.jpg'
import RequestDemoModal from "../../../pages/common/RequestDemoModal"
import {globalAuthManager, globalServices} from "../../../../services/GlobalServices"
import {apiGet} from "../../../../services/api-call-helpers"
import * as keys from 'keys'
// import {mixpanel} from "../../../../services/mixpanel"
import ReceiveUpdatesModal from "../../../pages/common/ReceiveUpdatesModal"
import {Link} from "react-router-dom"
import BlogSignupSection from "../../common/BlogSignupSection"

import BannerImage500 from "images/blog/Blog_20211103_SellTLTPuts/federal-reserve-500.jpg"
import BannerImage from "images/blog/Blog_20211103_SellTLTPuts/federal-reserve.jpg"
import chartAndTable5y from "images/blog/Blog_20211103_SellTLTPuts/performance-chart-table-5y.jpg"
import chartAndTableWithBW1y from "images/blog/Blog_20211103_SellTLTPuts/performance-chart-1y-vs-buywrite.png"
import jonAvatar from 'images/blog/avatar-100.jpg'
import HypotheticalPerformanceAlert from "../../../common/alerts/HypotheticalPerformanceAlert";

const TLTOverwriteContext = React.createContext({})

const documentTitle = "Clarivor Blog"
const postTitle = 'Selling puts on TLT'
const postSubtitle = 'An income idea for tactical investors with a view toward range-bound rates.'
const postAuthor = 'Jon Spiegel, CFA'
const publishedOnText = 'November 4, 2021'
const publishedOnShort = 'Nov 4, 2021'
const path = '/blog/selling-tlt-puts-20211104'

const HeroSection = (props) => (
    <section
        style={{
            backgroundImage: ["url(" + BannerImage + ")"],
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            position: 'relative',
            zIndex: 0,
            backgroundSize: 'cover',
            height: '35vh'
        }}>
    </section>
)

const HeaderSection = (props) => (
    <section className="pt-8 pt-md-11">
        <RequestDemoModal/>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 col-md-12 col-lg-10 col-xl-10">


                    <h1 className="display-4 text-center">
                        {props.title}
                    </h1>


                    <p className="lead mb-7 text-center text-muted">
                        {props.subtitle}
                    </p>


                    <div className="row align-items-center py-5 border-top border-bottom">
                        <div className="col-auto">


                            <div className="avatar avatar-lg">
                                <img src={props.avatarImage} alt="..."
                                     className="avatar-img rounded-circle"/>
                            </div>

                        </div>
                        <div className="col ml-n5">
                            <h6 className="text-uppercase mb-0">
                                {props.author}
                            </h6>
                        </div>
                        <div className="col-auto">
                            <time className="font-size-sm text-muted" dateTime="2019-05-20">
                                {props.publishedOnText}
                            </time>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
)


function Summary(props) {
    return (
        <React.Fragment>
            <h3 className={'font-weight-bold mt-6'}>
                Summary
            </h3>
            <ul>
                <li className='my-2'>
                    Compared with equity options, option prices for TLT, a treasuries ETF, are still
                    relatively high, providing a potential opportunity for tactical investors seeking to generate
                    income.
                </li>
                <li className='my-2'>
                    By selling one-month out-of-the-money (OTM) puts on TLT, investors can collect approximately
                    10% annualized yield, which may serve as a cushion against declines in bond prices.
                </li>
                <li className='my-2'>
                    The strategy could make sense for investors who believe interest rates will be range-bound.
                </li>
            </ul>
        </React.Fragment>
    )
}


function Section1(props) {
    return (
        <React.Fragment>
            <h3 className={'font-weight-bold mt-6'}>
                Strategy Overview
            </h3>
            <p>
                Investors who sell options to generate yield have likely noticed that the prices of short-dated
                options on U.S. large-cap stocks have mostly declined. Such investors may look for
                opportunities to sell options on ETFs that look more rich.
            </p>
            <p>
                Options on TLT are still historically expensive, especially those expiring in about a month. Currently
                an investor can sell a one-month put on TLT, struck 2% below the current ETF price, for
                approximately 0.9% of the price of the ETF, or 10.8% annually. The current option price is higher than
                than it's been on 75% of trading days over the past five years.
            </p>
            <p>
                A short OTM put typically has less exposure to falling bond prices than an investment in TLT
                directly. On the other hand, a short TLT put usually has less upside to rising bond prices than holding
                the bonds through the ETF.
            </p>
            <p>
                The extra income generated by the put strategy can provide a cushion against declines in bond prices.
                Additionally, by selling a 2% OTM put, the strategy is generally protected against the first 2% decline
                in the price of TLT. However, if interest rates rise rapidly, the risk of the strategy may be similar to
                holding TLT directly.
            </p>
            <p>
                Investors may use the short TLT put strategy as a substitute for traditional bond ETFs by
                collateralizing the puts with cash. When using short TLT puts as a replacement for other bond
                allocations, investors should recognize that TLT holds long-term bonds, and may therefore be more
                sensitive to interest rates than other bond investments.
            </p>
            <h3 className={'font-weight-bold mt-6'}>
                Back-tested Performance
            </h3>
            <p>
                A back-test over the past five years illustrates some of the behavior of the short-put strategy
                compared with holding TLT directly.
            </p>
            <ul>
                <li>When the price of TLT is stable or declining, the total return of the short put strategy tends to
                    outperform TLT.
                </li>
                <li>When the price of TLT is rising significantly, TLT tends to outperform the short put strategy.
                </li>
                <li>Overall, the volatility and max-draw-down of the short-put strategy are significantly less than
                    TLT's. (Note, in a rapid bond sell-off the risk of the short-put strategy could be similar to the
                    ETF)
                </li>
            </ul>
            <div className='card bg-light mb-3'>
                <div className='card-body p-2'>
                    <img style={{width: "100%"}} src={chartAndTable5y}/>
                </div>
            </div>

            <h3 className={'font-weight-bold mt-6'}>
                Buy-Write Comparison
            </h3>
            <p>
                Another way to generate yield from selling options on TLT is by selling covered calls. In April <Link
                to='/blog/TLT_Overwriting_20210413'> we
                wrote about</Link> selling 3-month calls on TLT struck 5% OTM.
            </p>
            <p>
                One major difference between these two strategies is that the puts
                tend to be more defensive to higher interest rates, while sacrificing more
                upside to higher bond prices. Below is a back-test comparing the two strategies over the past twelve
                months.
            </p>
            <div className='card bg-light mb-3'>
                <div className='card-body p-2'>
                    <img style={{width: "100%"}} src={chartAndTableWithBW1y}/>
                </div>
            </div>
            <h3 className={'font-weight-bold mt-6'}>
                Conclusion
            </h3>
            <p>
                With interest rates low and equity option prices falling, investors may be looking at tactical ways to
                generate income. For investors who believe interest rates will not move significantly higher or lower,
                selling short-term puts on TLT may be an attractive way to generate higher levels of income. Investors
                using this strategy should pay particular attention to two of the strategy's caveats: significant
                downside exposure to higher interest rates and limited upside from lower rates.
            </p>
        </React.Fragment>
    )
}

function BufferPerformanceProjectContainer(props) {
    const [projectObj, setProjectObj] = useState(null)

    useEffect(() => {
        apiGet(keys.API_ENDPOINT.PROJECTS, {id: props.projectId}, globalAuthManager).then(
            res => {
                const [project] = res.data
                setProjectObj(project)
            }
        )
    }, [])

    const contextValue = {
        projectObj: projectObj,
        projectId: props.projectId
    }

    return (
        <TLTOverwriteContext.Provider value={contextValue}>
            {props.children}
        </TLTOverwriteContext.Provider>
    )
}


function DisclosuresLink(props) {
    return (
        <a data-toggle="modal" href="#disclaimerModal"
           onClick={() => null}>Disclosures</a>
        // onClick={() => mixpanel.track('Disclosure Clicked')}>Disclosures</a>
    )
}

function TermsOfUseLink(props) {
    return (
        <Link to={'/terms-of-use'} target={'_blank'}
              onClick={() => null}>Terms of Use</Link>
        // onClick={() => mixpanel.track('Terms of Use Clicked')}>Terms of Use</Link>
    )
}

function BacktestingSourceDisclaimer(props) {
    return (
        <div>
            <hr/>
            <p className='font-italic text-muted'>
                Source: Clarivor LLC. These results are back-tested and may not be indicative of future performance.
                Changes in strategy parameters, such as maturity, strike, or roll timing, may have a material impact on results.
                Options can entail complex risks and may not be suitable for all investors.<br/>
                Each option strategy back-test above includes an annual deduction of 0.49% to reflect management fees
                and commissions. TLT (TR) has dividends immediately reinvested, shown with no additional management fee.
                Strikes are selected from the listed market.
                Options are rolled seven days prior to expiration at bid or offer.
                Option prices are observed at 3:45pm NY time.
                <br/>
                <br/>
                NOTHING IN THIS POST SHOULD BE CONSTRUED AS INVESTMENT ADVICE. <br/>
                <DisclosuresLink/> and <TermsOfUseLink/> apply.
                <br/>
            </p>
        </div>
    )
}


function PostBody(props) {
    return (
        <React.Fragment>
            <section className="pt-0 pt-md-0">
                <ReceiveUpdatesModal/>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-12 col-lg-10 col-xl-9">
                            <Summary/>
                            <Section1/>
                            <BacktestingSourceDisclaimer/>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}


export default function BufferPerformance_20210803(props) {
    const projectId = null  // this is a static blog post

    useEffect(() => {
        document.title = documentTitle
    }, [])

    const headerProps = {
        title: postTitle,
        subtitle: postSubtitle,
        avatarImage: headshot,
        author: postAuthor,
        publishedOnText: publishedOnShort,
    }

    return (
        <React.Fragment>
            <HeroSection/>
            <HeaderSection {...headerProps} />
            <BufferPerformanceProjectContainer projectId={projectId}>
                <PostBody/>
            </BufferPerformanceProjectContainer>
            <BlogSignupSection fromLocation={'Blog Post TLT Overwriting'}/>
            <div className='container'>
                <HypotheticalPerformanceAlert/>
            </div>
        </React.Fragment>
    )
}


export function RichSellTLTPuts202111(props) {
    return (
        <section className="pt-7 pt-md-10">
            <div className="container">
                <div className="row">
                    <div className="col-12">

                        <div className="card card-row shadow-light-lg mb-6 lift lift-lg">
                            <div className="row no-gutters">
                                <div className="col-12">

                                <span className="badge badge-pill badge-light badge-float badge-float-inside">
                                <span className="h6 text-uppercase">Income</span>
                                </span>
                                </div>
                                <Link className="col-12 col-md-6 order-md-2 bg-cover card-img-right"
                                      style={{backgroundImage: "url(" + BannerImage500 + ")"}}
                                      to={path}>

                                    <img src="assets/img/photos/photo-27.jpg" alt="..."
                                         className="img-fluid d-md-none invisible"/>

                                    <div
                                        className="shape shape-left shape-fluid-y svg-shim text-white d-none d-md-block">
                                        <svg viewBox="0 0 112 690" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 0h62.759v172C38.62 384 112 517 112 517v173H0V0z"
                                                  fill="currentColor"></path>
                                        </svg>
                                    </div>

                                </Link>
                                <div className="col-12 col-md-6 order-md-1">

                                    <Link className="card-body" to={path}>

                                        <h3>
                                            {postTitle}
                                        </h3>

                                        <p className="mb-0 text-muted">
                                            {postSubtitle}
                                        </p>

                                    </Link>

                                    <a className="card-meta" href="#!">

                                        <hr className="card-meta-divider"/>

                                        <div className="avatar avatar-sm mr-2">
                                            <img src={jonAvatar} alt="..."
                                                 className="avatar-img rounded-circle"/>
                                        </div>

                                        <h6 className="text-uppercase text-muted mr-2 mb-0">
                                            Jon Spiegel
                                        </h6>

                                        <p className="h6 text-uppercase text-muted mb-0 ml-auto">
                                            <time dateTime="2020-10-23">{publishedOnShort}</time>
                                        </p>

                                    </a>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>

    )
}
